import { API } from "services/api/api-config";
import Axios from "services/api/axios-interceptor";
import { signalRConstants } from "helper/Constants";
import { ISignalRConnectionInfo } from "common/model/signal-r-connection-info";

export const getWebSocketUrl = (callback: (data: string) => void) => {
    Axios.get(`${API.GET_WEBSOCKET_CONNECTION_URL}`, {
        credentials: 'include',
    })
    .then((response) => {
        if (callback)
        {
            callback(String(response.data));
        }
    })
    .catch((error: any) => {
    });
}

export const signalRConnectionInit = (user: string, apiBaseUrl: string, callback: (data: ISignalRConnectionInfo) => void) => {
    var userIdHeader = signalRConstants.headers.userId.toString();
    var clientType = signalRConstants.headers.clientType.toString();

    Axios.post(`${apiBaseUrl}${API.SIGNALR_NEGOTIATE_URL}`, '',
    {
        [userIdHeader]: user,
        [clientType]: signalRConstants.CPA
    })
    .then((response) => response.data as ISignalRConnectionInfo)
    .then((data) => {
        if (callback)
        {
            callback(data);
        }
    })
    .catch((error: any) => {
    });
}

export const addGroup = (user: string, group: string, apiBaseUrl: string) => {
    var userIdHeader = signalRConstants.headers.userId.toString();
    var clientType = signalRConstants.headers.clientType.toString();
    var data = JSON.stringify({
        recipient: user,
        groupname: group
    })

    Axios.post(`${apiBaseUrl}${API.SIGNALR_ADD_TO_GROUP_URL}`, data, {
        [userIdHeader]: user,
        [clientType]: signalRConstants.CPA
    })
    .then((resp: any) => {
        if (resp.status == 200) {
            console.log("User added to the group successfully")
        }
    })
    .catch((error: any) => {
    });
}