import * as React from "react";
import { CustomModal, GenericModalProps } from "common/components/UIComponents/CustomModal";
import { GatherTemplateNotification, RequiredFieldError, TemplateModalConstants } from "helper/Constants";
import { Form } from "react-bootstrap";
import DropdownComponent from "common/components/UIComponents/DropdownComponent";
import { IGatherTemplateModalData } from "./GatherTemplateList";
import { GatherTemplate, ProcessStep } from "common/model/gather-template-settings";
import { ICustomQuestion } from "common/model/custom-questions";
import { ModalType } from "./GatherTemplateList";
import { IImportedTemplateModel } from "common/model/request-documents";
import { intialdata } from "store/slices/gathertemplate-settings-slice";
import { AppNotifier } from "common/components/toast/Toast";
import "./style.scss";
interface GatherTemplateModalProps extends GenericModalProps {
  intialModalData: GatherTemplate;
  customQuestionsTemplateModel: ICustomQuestion[];
  requestDocumentList: IImportedTemplateModel[];
  onSave: (data: GatherTemplate, modalType: ModalType) => void;
  onHide: () => void;
}

export const GatherTemplateModal: React.FC<GatherTemplateModalProps> = ({
  show,
  intialModalData,
  customQuestionsTemplateModel,
  requestDocumentList,
  onHide,
  onSubmit,
  onSave
}) => {
  const ref = React.useRef<HTMLFormElement>(null);
  const [modalData, setModalData] = React.useState<GatherTemplate>(intialModalData);
  const [validated, setValidated] = React.useState<boolean>(false);
  const [nameValidation, setNameValidation] = React.useState("");

  React.useEffect(() => {
    if (intialModalData) {
      setModalData(intialModalData);
    }
  }, [intialModalData, show]);
  const customQuestionOptions = customQuestionsTemplateModel?.map((question) => ({
    label: question.name,
    value: question.id
  }));

  const requestDocumentOptions = requestDocumentList.map((question) => ({
    label: question.templateName,
    value: question.documentRequestTemplateId
  }));

  const onFormChange: React.ChangeEventHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const modalDataCopy = { ...modalData, [name]: value };
    if(name !== "description")
    if (modalDataCopy.name.trim() !== "") setNameValidation("");
    else setNameValidation(RequiredFieldError);
    setModalData(modalDataCopy);
  };

  const isModalDataValid = (data: GatherTemplate) => {
    let isValid = true;
    if (data.name?.trim().length < 1) {
      isValid = false;
      setNameValidation(RequiredFieldError);
    } else {
      setNameValidation("");
    }
    if (data.processSteps?.length < 1 && data.name?.trim().length > 0) {
      isValid = false;
      AppNotifier.Warning(GatherTemplateNotification.Warning.StepsSingleSelection);
    } else  if (data.processSteps?.includes(ProcessStep.RequestDocuments)) {
        if (data.processSteps?.length < 2) {
        isValid = false;
        AppNotifier.Warning(GatherTemplateNotification.Warning.RequestDocumentSingleSelection);
      }
    }
    setValidated(isValid);
    return isValid;
  };
  const onCheckBoxChange: React.ChangeEventHandler<HTMLInputElement> = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    const stepValue = ProcessStep[name as keyof typeof ProcessStep];

    if (checked) {
      if (!modalData.processSteps.includes(stepValue)) {
        var newdata = { ...modalData, processSteps: [...modalData.processSteps, stepValue] };
        setModalData(newdata);
      }
    } else {
      if (modalData.processSteps.includes(stepValue)) {
        var newdata = { ...modalData, processSteps: modalData.processSteps?.filter((step) => step !== stepValue) };
        if (stepValue === ProcessStep.CustomQuestions) {
          newdata = { ...newdata, customQuestionTemplateId: 0 };
        } else if (stepValue === ProcessStep.RequestDocuments) {
          newdata = { ...newdata, requestDocumentsTemplateId: 0 };
        }
        setModalData(newdata);
      }
    }
  };

  const handleOnSubmit = () => {
    if (isModalDataValid(modalData)) {
      if (intialModalData.uploadTemplateId === 0) {
        onSave(modalData, ModalType.Add);
      } else {
        onSave(modalData, ModalType.Edit);
      }
    }
  };
  const handelTemplateChange = (value) => {
    var newdata = { ...modalData, customQuestionTemplateId: value };
    setModalData(newdata);
  };
  const handelDocumentChange = (value) => {
    var newdata = { ...modalData, requestDocumentsTemplateId: value };
    setModalData(newdata);
  };

  const hideModalHandler = () => {
    onHide();
    setValidated(false);
  };
  return (
    <CustomModal
      onSubmit={handleOnSubmit}
      confirmButtonName={
        intialModalData.uploadTemplateId != 0
          ? TemplateModalConstants.EditModalConfirmButton
          : TemplateModalConstants.AddModalConfirmButton
      }
      title={
        intialModalData.uploadTemplateId != 0
          ? TemplateModalConstants.GatherTemplate.editTemplate
          : TemplateModalConstants.GatherTemplate.addTemplate
      }
      className="template-modal-container create-edit-modal custom-modal-body"
      show={show}
      onHide={hideModalHandler}
    >
      <span className="required-marker required-marker-before">Required</span>
      <Form
        ref={ref}
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className="form-item-group">
          <Form.Label className="required-marker required-marker-after">{TemplateModalConstants.NameLabel}</Form.Label>
          <Form.Control
            type="text"
            name="name"
            defaultValue={intialModalData?.name}
            value={modalData?.name}
            placeholder={TemplateModalConstants.TemplateNamePlaceholder}
            onChange={onFormChange}
            required={true}
            maxLength={TemplateModalConstants.GatherTemplate.inputMaxLength}
          />
          <h6 className="text-danger">{nameValidation}</h6>
        </div>
        <Form.Group className="description-wrapper">
          <Form.Label>{TemplateModalConstants.DesciptionLabel}</Form.Label>
          <Form.Control
            type="text"
            as="textarea"
            name="description"
            defaultValue={intialModalData?.description}
            value={modalData?.description}
            placeholder={TemplateModalConstants.GatherTemplate.placeholder_description}
            onChange={onFormChange}
            maxLength={TemplateModalConstants.GatherTemplate.inputMaxLength}
          />
        </Form.Group>

        <div className="task-options-wrapper">
          <span>{TemplateModalConstants.GatherTemplate.taskOptionsLabel}</span>
          <div className="label-checkbox-wrapper">
            <Form.Check  
              name="ESignDocuments"
              onChange={onCheckBoxChange}
              checked={modalData.processSteps.includes(ProcessStep.ESignDocuments)}
              label={TemplateModalConstants.GatherTemplate.signDocs}
            />
          </div>
          <div className="label-checkbox-wrapper">
            <Form.Check  
              name="CustomQuestions"
              onChange={onCheckBoxChange}
              checked={modalData.processSteps.includes(ProcessStep.CustomQuestions)}
              label={TemplateModalConstants.GatherTemplate.cqLabel}
            />
            <DropdownComponent
              selectedValue={modalData?.customQuestionTemplateId}
              disabled={!modalData.processSteps.includes(ProcessStep.CustomQuestions)}
              options={customQuestionOptions}
              customPlaceHolder={"Select Questionnaire Template"}
              onChange={handelTemplateChange}
              clearable={true}
              overflowClassName="gather-template-overflow"
            />
          </div>
          <div className="label-checkbox-wrapper">
            <Form.Check  
              name="Organizer"
              onChange={onCheckBoxChange}
              checked={modalData.processSteps.includes(ProcessStep.Organizer)}
              label={TemplateModalConstants.GatherTemplate.fillableOrg}
            />
          </div>
          <div className="label-checkbox-wrapper">
            <Form.Check  
              name="RequestDocuments"
              onChange={onCheckBoxChange}
              checked={modalData.processSteps.includes(ProcessStep.RequestDocuments)}
              label={TemplateModalConstants.GatherTemplate.requestDoc}
            />
            <DropdownComponent
              selectedValue={modalData?.requestDocumentsTemplateId}
              disabled={!modalData.processSteps.includes(ProcessStep.RequestDocuments)}
              options={requestDocumentOptions}
              customPlaceHolder={"Select Document(s) Template"}
              onChange={handelDocumentChange}
              clearable={true}
              overflowClassName="gather-template-overflow"
            />
          </div>
        </div>
      </Form>
    </CustomModal>
  );
};
