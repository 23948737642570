import { NotificationType } from "common/enum"

export type drlNotApplicableStatusUpdatedModel = {
    isLoading?: boolean,
    isDrlNotApplicableStatusUpdate: boolean
}

export const initialDrlNotApplicableStatusUpdatedModel: drlNotApplicableStatusUpdatedModel = {
    isLoading : false,
    isDrlNotApplicableStatusUpdate: false
}