import { CircleDeleteIcon } from "assets/svg/SVGIconsCollection";
import { FormBuilderConstants, FormPreviewConstants } from "helper/Constants";
import React from "react";
import { Form } from "react-bootstrap";
import { ISectionItems } from "store/models/CustomQuestionModel";

interface IFollowUpQuestionTitle {
  onChangeStoreHandler: (choiceIndex: number, questionIndex: number, input: string) => void;
  sectionItem: ISectionItems;
  isAdditionalQuestion: boolean;
  choiceIndex: number;
  questionIndex: number;
  onRemoveAdditionalQuestion: (choiceIndex: number, questionIndex: number) => void;
}
const FollowUpQuestionTitle: React.FC<IFollowUpQuestionTitle> = ({
  onChangeStoreHandler,
  sectionItem,
  isAdditionalQuestion,
  choiceIndex,
  questionIndex,
  onRemoveAdditionalQuestion
}) => {
  const [questionTitle, setQuestionTitle] = React.useState(sectionItem.questionTitle);
  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value.trimStart();
    setQuestionTitle(input);
    onChangeStoreHandler(choiceIndex, questionIndex, input);
  };
  const onRemoveQuestionHandler = () => {
    onRemoveAdditionalQuestion(choiceIndex, questionIndex);
  };
  React.useEffect(() => {
    setQuestionTitle(sectionItem.questionTitle);
  }, [sectionItem.questionTitle]);

  return (
    <div className="question-title-wrapper question-container ">
      <Form.Control
        type="text"
        value={questionTitle}
        onChange={onChangeHandler}
        placeholder={FormBuilderConstants.QuestionTitle.placeholder}
        maxLength={FormBuilderConstants.QuestionTitle.maxLength}
        className={sectionItem.isValid === false ? "invalid-input" : ""}
      />
      {sectionItem.isValid === false && <p className="error-text">{FormPreviewConstants.RequiredValidation}</p>}
      <span onClick={onRemoveQuestionHandler}>{isAdditionalQuestion ? <CircleDeleteIcon /> : <></>}</span>
    </div>
  );
};

export default FollowUpQuestionTitle;
