import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { act } from "@testing-library/react";
import { EngagementsType, EngagementsTypeState } from "common/model/engagements-type";



const engagementsType:EngagementsType[]=[{id:0,isSystemDefault:true,isUserDefault:true,name:""}]
const initialEngagementsType:EngagementsTypeState={
    engagementsTypeArray:engagementsType
      
}
const engagementsTypeSlice = createSlice({
    name: 'engagementsType',
    initialState: initialEngagementsType,
    reducers: {
        setEngagementsType(state, action: PayloadAction<EngagementsType[]>)
        {            
            state.engagementsTypeArray=action.payload.sort((a, b) => a.id - b.id);
        },
        addEngagementsType(state, action: PayloadAction<EngagementsType>)
        {
            state.engagementsTypeArray.push(action.payload);
        },
        editEngagementsType(state, action: PayloadAction<EngagementsType>)
        {            
            state.engagementsTypeArray[state.engagementsTypeArray.findIndex((obj: EngagementsType) => obj.id===action.payload.id)]=(action.payload);
        },
        deleteEngagementsType(state, action: PayloadAction<EngagementsType>)
        {
           state.engagementsTypeArray= state.engagementsTypeArray.filter((item :EngagementsType )=> item.id !== action.payload.id);
        },
    }
});

export const {setEngagementsType,addEngagementsType,editEngagementsType,deleteEngagementsType} = engagementsTypeSlice.actions;

export const { reducer: engagementsTypeReducer } = engagementsTypeSlice;