import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const leftMenuInitialState: MenuModel = {
    isLoading: true,
    leftMenuData: [],
    hideLeftmenu: false,
    routeList: [],
    isRoutesEmpty: false,
    isAllRoutesDisabled: false
};

const widgetMenuInitialState: WidgetMenuModel = {
    isLoading: false,
    widgetMenu: [],
    suiteUrl: ''
}


const rbacState: RbacModel = {
    isLoading: false,
    resourceList: [],
}

const initialLayoutState: any = {
    leftMenuData: leftMenuInitialState,
    widgetMenuData: widgetMenuInitialState,
    rbacData: rbacState,
}

const layoutSlice = createSlice({
    name: 'layout',
    initialState: initialLayoutState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload
            
        },
        setLeftMenuData(state, action: PayloadAction<LeftMenuModel>) {
            state.leftMenuData = action.payload.leftMenuData;
            state.isRoutesEmpty = action.payload.leftMenuData?.length == 0;
            state.routeList = action.payload.routeList;
            state.isAllRoutesDisabled = action.payload.routeList?.every(r => !r.isEnabled);
            state.isLoading = false;
        },
        setWidgetMenuData(state, action: PayloadAction<WidgetMenuModel>) {
            
            state.widgetMenuData = action.payload;
        }, 
        setSuiteUrl(state, action: PayloadAction<string>) {
            
            state.widgetMenuData.suiteUrl = action.payload;
        },

        setRbacData(state, action: PayloadAction<WidgetMenuModel>) {
            
            state.rbacData.resourceList = action.payload;
        }
    }
});

export const { setIsLoading, setLeftMenuData, setWidgetMenuData, setSuiteUrl, setRbacData } = layoutSlice.actions;

export default layoutSlice.reducer;
