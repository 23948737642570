import { FC } from "react";
import './Loader.scss';
interface LoaderProps {
    label?: string;
    overLayClassName?: string;
    spinnerClassName?: string;
    labelClassName?: string;
    labelVisibilty?: boolean;
    isScrollableView?: boolean;
    backgroundVisibility?: boolean;
}
const Loader: FC<LoaderProps> = ({ label,
    overLayClassName ='',
    spinnerClassName,
    labelClassName,
    labelVisibilty = true,
    isScrollableView = false,
    backgroundVisibility }) => {
    return <div className={`loader-overlay ${overLayClassName|| ''}`}>
        <div className={isScrollableView ? "loader-scrollable-container" : "loader-inner-container"}>
            <div className={backgroundVisibility ? "loader-container" : "loader-container-transparent"}>
                <div className={`spinner-border text-primary ${spinnerClassName}`} role="status" />
                {labelVisibilty ? <span className={`status-label ${labelClassName}`}>{label || 'Loading...'}</span> : <></>}
            </div>
        </div>
    </div>
}
export default Loader;