import { Action } from "@reduxjs/toolkit"
import { IProcessStep } from "common/model/upload-template"
import { ThunkAction } from "redux-thunk"
import { API, Batch, Proforma } from "services/api/api-config"
import Axios from "services/api/axios-interceptor"
import { AppState } from "store"
import { setSelectedTemplate, setUploadTemplates, setProcessSteps } from "store/slices/upload-template-slice"

export const getAllUploadTemplates = (successCallback : () => void): ThunkAction<void, AppState, unknown, Action> =>
	(dispatch) => {
		try {
			Axios.get(`${API.UPLOADTEMPLATE.GET_ALL_TEMPLATES}`)
				.then((response) => response.data)
				.then((data) => {
					dispatch(setUploadTemplates(data));
                    successCallback();
				})
		}
		catch (error: any) {
		}
	}


export const getSelectedUploadTemplate = (id : number): ThunkAction<void, AppState, unknown, Action> =>
        (dispatch) => {
            try {
                Axios.get(`${API.UPLOADTEMPLATE.GET_TEMPLATE}/${id}`)
                    .then((response) => response.data)
                    .then((data) => {
                        dispatch(setSelectedTemplate(data));
                    })
            }
            catch (error: any) {
            }
        }

export const clearSelectedUploadTemplate = (): ThunkAction<void, AppState, unknown, Action> =>
            (dispatch) => {
                try {
                    dispatch(setSelectedTemplate(null));
                }
                catch (error: any) {
                }
            }

export const getProformaProcessStep = (gatherId: number, successCallback: () => void, failureCallback: () => void): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
        Axios.get(`${API.UPLOADTEMPLATE.GET_TEMPLATE}/${Proforma}/${gatherId}`)
            .then((response) => response.data)
            .then((data) => {
                dispatch(setProcessSteps(data));
                successCallback();
            })
             .catch(err =>{
                failureCallback();
            })
    }

export const getBatchProcessStep = (batchId: number, successCallback: (batchId:number) => void, failureCallback: () => void, returnProcessStep?:(data:IProcessStep[])=> void): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
        Axios.get(`${API.UPLOADTEMPLATE.GET_TEMPLATE}/${Batch}/${batchId}`)
            .then((response) => response.data)
            .then((data) => {
                dispatch(setProcessSteps(data));
                returnProcessStep && returnProcessStep(data);
                successCallback(batchId);
            })
            .catch(err =>{
                failureCallback();
            })
    }

export const resetBatchProcessStep = (): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
        dispatch(setProcessSteps([]));
    }
export const setProcessStepWithoutTemplate = (processSteps: IProcessStep[]): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
        try {
            dispatch(setProcessSteps(processSteps));
        }
        catch (error: any) {
        }
    }
