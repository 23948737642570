import { FileIcon, MoreOptionsIcon } from "assets/svg/SVGIconsCollection";
import React, { useEffect } from "react";
import { Card, Dropdown } from "react-bootstrap";
import { IGatherTemplateModalData } from "./GatherTemplateList";
import { GatherTemplate } from "common/model/gather-template-settings";

export interface TemplateProps {
  children?: React.ReactNode;
  data: GatherTemplate;
  isMenuOpen?: boolean;
  onTemplateClick: Function;
  onEdit: (data: GatherTemplate) => void;
  onDuplicate: (data: GatherTemplate) => void;
  onDelete: (id:number) => void;
}

const popperConfigObj = {
  modifiers: [
    {
      name: "offset",
      options: {
        offset: [20, 10]
      }
    }
  ]
};


const Template: React.FC<TemplateProps> = ({ onEdit, onDelete, onDuplicate, data, isMenuOpen, onTemplateClick }) => {
  return (
    <div className="template-container">
      <Card className="template" onDoubleClick={() => onTemplateClick(data)}>
        <FileIcon />
        <Dropdown className="template-more-options" drop="down">
          <Dropdown.Toggle id="dropdown-basic" className="template-more-options">
            <MoreOptionsIcon />
          </Dropdown.Toggle>

          <Dropdown.Menu popperConfig={popperConfigObj} className="template-more-options-menu">
            <Dropdown.Item onClick={() => onEdit(data)} className={"template-menu-item"}>
              <label className="template-menu-item-label">Edit</label>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => onDuplicate(data)} className={"template-menu-item"}>
              <label className="template-menu-item-label">Duplicate</label>
            </Dropdown.Item>
            <Dropdown.Item onClick={()=>onDelete(data.uploadTemplateId)} className={"template-menu-item"}>
              <label className="template-menu-item-label red">Delete</label>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Card>
      <div className="template-details">
        <div className="template-title">
          <label className="template-name" title={data?.name}>
            {data?.name}
          </label>
        </div>
        <label className="template-description" title={data?.description}>
          {data?.description}
        </label>
      </div>
    </div>
  );
};
export default Template;
