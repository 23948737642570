import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { UndeliveredGatherData, UndeliveredGatherStoreState } from "common/model/undelivered-gather-table";

const initialUndeliveredGatherState: UndeliveredGatherStoreState = {
    loading: false,
    model: {
        count: 0,
        documents: []
    },
    popupLoading: false,
    sourceDocumentMetaData: {},
    query: ''
}

const undeliveredGatherSlice = createSlice({
    name: 'undeliveredGather',
    initialState: initialUndeliveredGatherState,
    reducers: {
        setUndeliveredGatherData : (state, action: PayloadAction<UndeliveredGatherData>) => {
            state.model = action.payload.model;
            state.query = action.payload.query;
        },
        startLoader : (state) => {
            state.loading = true;
        },
        stopLoader : (state) => {
            state.loading = false;
        }
    }
});

export const { startLoader, setUndeliveredGatherData, stopLoader } = undeliveredGatherSlice.actions;

export const { reducer: undeliveredGatherReducer } = undeliveredGatherSlice;