import { ISelectedTemplate } from "store/models/CustomQuestionModel";
import { GatherInfoModel } from "./batch-gather-document-process-model";
import { IDocumentRequestState } from "./request-documents";
import { IProcessStep } from "./upload-template";

export interface IProformaProcessState{
    gatherInfoVM: GatherInfoModel | null,
    organizerDocument: string,
}

export const initalProformaProcessState : IProformaProcessState = {
    gatherInfoVM: null,
    organizerDocument: ""
}

export interface IProformaProcessInfo{
    gatherInfo: GatherInfoModel;
    documentRequestData: IDocumentRequestState;
    questionnaireData: ISelectedTemplate;
    processSteps?: IProcessStep[] | null;
    isUpdateUserId: Boolean;
    locationName: string;
    uploadTemplateId?: number;
}
