import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ScheduleOption } from "common/model/schedule-options";

interface State {
    scheduleOptions : ScheduleOption[];
}

const scheduleOptionList : ScheduleOption[] = [{id:0, scheduleType:"", description: ""}]

const initialScheduleOptionState : State = {
    scheduleOptions : scheduleOptionList
}

const scheduleOptionsSlice = createSlice({
    name:'scheduleOptions',
    initialState: initialScheduleOptionState,
    reducers:{
        setScheduleOptions(state, action: PayloadAction<ScheduleOption[]>){
            state.scheduleOptions = action.payload;
        },
    }
})

export const { setScheduleOptions } = scheduleOptionsSlice.actions;
export const { reducer: scheduleOptionReducer } = scheduleOptionsSlice;