import { useState, useRef, useEffect } from 'react';
import { DropdownArrow, DropUpArrow, ClearIcon } from 'assets/icons/IconCollection';
import { INotifyData, IUserGroupViewModel, UserType } from 'common/model/user';
import './CustomTabDropdown.scss';
import { Form } from 'react-bootstrap';
import "../../../../styles/branding.scss";

const CustomTabDropdown = ({ userList, selectedUsers, onChange, userGroups, isOpen, onToggle, customWidth, customHeight,disabled, onContentOverflow = () => {} }: {
    userList: INotifyData[],
    selectedUsers: INotifyData[],
    onChange: (users: INotifyData[]) => void,
    userGroups: IUserGroupViewModel[],
    isOpen: boolean,
    onToggle: () => void,
    customWidth: any,
    customHeight: any,
    disabled?: boolean,
    onContentOverflow?: (isOverflow: boolean) => void
}) => {
    const [tabIndex, setTabIndex] = useState<UserType>(UserType.User);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const CustomValue = (eachData: INotifyData) => {
        if (eachData.type === UserType.UserGroup) {
            const usergrp = userGroups.find((userGroup: IUserGroupViewModel) => userGroup.groupId === eachData.value);
            if (usergrp) {
                return (
                    <span className={disabled?"user-group-chip disabled":"user-group-chip"}>
                        <span className="chip-label" title={eachData.label}>{eachData.label}</span>
                        <span className="remove-chip" onClick={(e) => handleRemove(e,eachData)}>
                            <ClearIcon fill="#355abf" />
                        </span>
                    </span>
                );
            }
        } else {
            return (
                <span className={disabled?"user-chip disabled":"user-chip"}>
                    <span className="chip-label" title={eachData.label}>{eachData.label}</span>
                    <span className="remove-chip" onClick={(e) => handleRemove(e,eachData)}>
                        <ClearIcon fill="#212529" />
                    </span>
                </span>
            );
        }
    };

    const handleRemove = (e:any,item: any) => {
        e.stopPropagation();
        const updatedSigningValues = selectedUsers && selectedUsers.filter(val => val.value !== item.value);
        onChange(updatedSigningValues);
    };
    
    const ClearSelected = (e:any) => {
        e.stopPropagation();
        onChange([]);
    }

    const handleToggle = (item: INotifyData) => {
        selectedUsers = selectedUsers || [];
        const updatedItem = { ...item };
        const currentIndex = (selectedUsers).findIndex(selectedUser =>
            selectedUser.value === updatedItem.value && selectedUser.type === updatedItem.type
        );
        const newChecked = (selectedUsers).slice();    
        if (currentIndex === -1) {
            newChecked.push(updatedItem);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        onChange(newChecked);
    };


    const renderList = (users: INotifyData[]) => (
        <div className='user-list-data'>
            {users.map((user) => (
                <div
                    key={user.value + user.type}
                    className='user-list-item'>
                    <label className='user-checkbox' htmlFor={`checkbox-${user.value}-${user.type}`}>
                        <Form.Check
                            type="checkbox"
                            id={`checkbox-${user.value}-${user.type}`}
                            label=""
                            checked={selectedUsers && selectedUsers.some(selectedUser =>
                                selectedUser.value === user.value && selectedUser.type === user.type)}
                            onChange={() => handleToggle(user)} 
                        />
                        <span className='user-label' title={user.label}>
                            {user.label}
                        </span>
                    </label>
                </div>
            ))}
        </div>
    );


    const individualUserList = userList.filter(user => user.type === UserType.User);
    const userGroupList = userList.filter(user => user.type === UserType.UserGroup);

    const selectedUserData = (selectedUsers ? selectedUsers.map((user) => CustomValue(user)) : []);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                if (isOpen) {
                    onToggle();
                }
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen, onToggle]);

    useEffect(() => {
        if (dropdownRef.current) {
            const isContentOverflowing = dropdownRef.current.scrollHeight >= customHeight;
            onContentOverflow(isContentOverflowing);
        }
    }, [selectedUsers, customHeight, onContentOverflow]);

    return (
        <div className="custom-tab-dropdown" ref={dropdownRef} style={{ width: `${customWidth}px` }}>
            <div className={disabled?"dropdown-box cursor-not-allowed":"dropdown-box"} >
                <div className={disabled?"flex-container disabled":"flex-container"} onClick={onToggle}>
                    <div className={selectedUserData && selectedUserData.length > 0 ? "dropdown-container" : "select-label"} style={{ maxHeight: customHeight }} ref={dropdownRef}>
                        {selectedUserData && selectedUserData.length > 0 ? selectedUserData : "Select.."}
                        {selectedUserData && selectedUserData.length > 0 && (
                            <div className='clear-value' title='Clear Value' onClick={(e) => ClearSelected(e)}>
                                <ClearIcon />
                            </div>
                        )}
                        <div onClick={onToggle} className="dropdown-icon-button">
                            {isOpen ? <DropUpArrow /> : <DropdownArrow />}
                        </div>
                    </div>
                </div>

                {isOpen && (
                    <div className='custom-dropdown-wrapper'>
                        <div className="dropdown-tab">
                            <div
                                className={`dropdown-tab-link ${tabIndex === UserType.User ? "active" : ""}`}
                                title="User"
                                onClick={() => setTabIndex(UserType.User)}
                            >
                                Users
                            </div>
                            <div
                                className={`dropdown-tab-link ${tabIndex === UserType.UserGroup ? "active" : ""}`}
                                title="User Group"
                                onClick={() => setTabIndex(UserType.UserGroup)}
                            >
                                User Group
                            </div>
                        </div>
                        <div className="dropdown-list-box">
                            {tabIndex === UserType.User && renderList(individualUserList)}
                            {tabIndex === UserType.UserGroup && renderList(userGroupList)}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};


export default CustomTabDropdown;
