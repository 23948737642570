import React, { FC } from "react";
import { CustomModal } from "common/components/UIComponents/CustomModal";
import MyAccountLayout from "./MyAccountLayout";
import "./style.scss";
export interface IMyAccountModalProps {
  show: boolean;
  onHide: () => void;
}
const MyAccountModal: FC<IMyAccountModalProps> = ({ show, onHide }) => {
  return (
    <CustomModal
      title="My Account"
      hideFooter={true}
      size="xl"
      removeBodyPadding={true}
      show={show}
      onHide={onHide}
      className="my-account-modal"
    >
      <MyAccountLayout onHide={onHide} />
    </CustomModal>
  );
};

export default MyAccountModal;
