import { IUserProfile } from "./userModel"

export interface IUserDataState {
    partners: IUserViewModel[],
    safesendUsers: IUserViewModel[],
    loggedInUserProfile: IUserProfile,
    safesendUserGroups: IUserGroupViewModel[]
}

export interface IUserViewModel {
    id: number
    firstName: string,
    lastName?: string,
    emailAddress: string,
}
export interface IUserGroupViewModel{
    groupId: number,
    groupName: string,
    fontColor: string,
    borderColor: string,
    BackgroundColor: string,
    userId: number
}
export interface INotifyData{
    value: number,
    label: string,
    type: UserType
}
export enum UserType {
    None=0,
    User=1,
    UserGroup=2
}

export enum OnehubAccessCodeStatus{
        Active = 0,
        NotRequested = 1,
        Expired = 2,
        Authenticated = 3
}
export interface IOneHubAccessCodeDetail {
    clientName: string;
    clientEmail: string;
    otpDate: Date;
    accessCode: string;
    accessCodeStatus: OnehubAccessCodeStatus;
}

export const initialOneHubAccessCodeDetail: IOneHubAccessCodeDetail[] = [{
    clientName: '',
    clientEmail: '',
    otpDate: new Date(),
    accessCode: '',
    accessCodeStatus: OnehubAccessCodeStatus.NotRequested
}]

export const initialUserDataState: IUserDataState = {
    partners: [],
    safesendUsers: [],
    loggedInUserProfile: {
        firstName: '',
        lastName: '',
        phone: '',
        extension: '',
        fax: '',
        emailAddress: '',
        userId: 0,
        authenticationProviders: [],
        metadata: '',
        countryCode: '',
        mobileNumber: '',
        isMobileVerify: false,
        readonlyFields: [],
        isMFAEnabled: false,
    },
    safesendUserGroups: []
}

export interface ILocationData {
    locations: number[];
}

export const initialLocationData: ILocationData = {
    locations: []
}

export const unloadedState: IUserSignatures = {
    0: {
        signatureDownloadPath: "",
        signatureUploadPath: ""
    }
};

export interface IUserSignatures {
    [index: number]: {
        signatureUploadPath: string,
        signatureDownloadPath: string,
    }
}
