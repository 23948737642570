import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ProcessSteps } from "common/enum";
import { GatherInfoModel } from "common/model/batch-gather-document-process-model";
import { IBatchInfoVM, IBatchProcessState, initalBatchProcessState } from "common/model/batch-info";
import { AdditionalEsignDocument, UploadedBatch, ProcessBatchError } from "common/model/gather-document-process-model-types";
import { IProcessStep } from "common/model/upload-template";

const batchProcessSlice = createSlice({
    name: 'batchprocess',
    initialState: initalBatchProcessState as IBatchProcessState,
    reducers: {
        setBatchInfo(state, action: PayloadAction<IBatchInfoVM>) {
            state.batchInfoVM = action.payload;
        },
        setBatchCSVData(state, action: PayloadAction<ProcessBatchError>){   
            state.batchError = action.payload.uploadedBatch;
            state.count = action.payload.count;
        },
        setBatchGather(state, action: PayloadAction<GatherInfoModel>) {
            state.gatherInfoVM = action.payload;
        },
        setBatchDocument(state, action: PayloadAction<any>) {
            state.organizerDocument = action.payload;
        },
        setBatchIgnoreWarningsAndErrorsSetting(state, action: PayloadAction<{ ignoreErrors: boolean, ignoreWarnings: boolean }>){
            const { ignoreErrors, ignoreWarnings } = action.payload;
                state.batchInfoVM.setting.ignoreWarnings = ignoreWarnings;
                state.batchInfoVM.setting.ignoreErrors = ignoreErrors;
        },
        setSelectedCSVData(state, action: PayloadAction<UploadedBatch>){
            state.selectedCSVData = action.payload;
        },
        setBatchAdditionalEsignDocument(state, action: PayloadAction<AdditionalEsignDocument[]>) {
            state.batchInfoVM.additionalEsignDocuments = action.payload;
        }
    }
})
export const { setBatchInfo, setBatchCSVData, setBatchGather, setBatchDocument, setBatchIgnoreWarningsAndErrorsSetting, setSelectedCSVData, setBatchAdditionalEsignDocument, setBatchProcessStep  } = batchProcessSlice.actions;
export const { reducer: batchProcessReducer } = batchProcessSlice;