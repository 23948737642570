export async function handleBlobwithFileName(response: any) {
    if (response.ok || response.status === 200) {
        const contentDisposition = response.headers.get("content-disposition");
        const fileNameMatch = contentDisposition ? /filename="?([^"]*)"?;/g.exec(contentDisposition) : undefined;
        let fileName = '';
        if (fileNameMatch && fileNameMatch.length > 1) {
            fileName = fileNameMatch[1];
        }
        return { filename: fileName, blob: response.data};
    }
    else {
        return response.json().catch((error: any) => {
            // the status was not ok and there was not custom error provided
            return Promise.reject({
                status: response.status,
                statusText: response.statusText,
            });
        }).then((json: any) => {
            // the status was not ok but custom error provided
            return Promise.reject({
                status: response.status,
                statusText: json,
            });
        });
    }
}