import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { InitialUserPrivilegeChangedModel, IUserPrivilegeChangedModel } from "store/models/UserPrivilegeChangedModel";

const userPrivilegeChangedSlice = createSlice({
    name: 'userPrivilegeChanged',
    initialState: InitialUserPrivilegeChangedModel,
    reducers: {
        setUserPrivilegeChangedModel : (state, action: PayloadAction<IUserPrivilegeChangedModel>) => {
            state.logoutCause = action.payload.logoutCause;
            state.isUserPrivilegeChanged = action.payload.isUserPrivilegeChanged;
        },
        startLoader : (state) => {
            state.loading = true;
        },
        stopLoader : (state) => {
            state.loading = false;
        }
    }
});

export const { startLoader, setUserPrivilegeChangedModel, stopLoader } = userPrivilegeChangedSlice.actions;

export const { reducer: userPrivilegeChangedReducer } = userPrivilegeChangedSlice;