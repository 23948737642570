import { createSlice, PayloadAction } from "@reduxjs/toolkit";
export enum StatusType {
  None,
  Error,
  Success,
  Warning
}
export interface NotificationState {
  message: string;
  type: StatusType;
  statusCode?: number;
}
export interface NotificationAction {
  statusMessage: string;
  statusType: StatusType;
  statusCode?: number;
}
const unloadedState: NotificationState = {} as NotificationState;

const notificationSlice = createSlice({
  name: "notification",
  initialState: unloadedState,
  reducers: {
    pushNotification(state, action: PayloadAction<NotificationAction>) {
      state.message = action.payload.statusMessage;
      state.statusCode = action.payload.statusCode;
      state.type = action.payload.statusType;
    }
  }
});

export const { pushNotification } = notificationSlice.actions;

export default notificationSlice.reducer;
