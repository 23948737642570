import { FC, useEffect, useState } from "react";
import { MessageType } from "../client-instructions";
import { ModalType } from "../client-instructions/parts/AddEditModal";
import MessageBox from "./parts/MessageBox";
import MessageListBox from "./parts/MessageListBox";
import SavedMessageModal from "./parts/SavedMessageModal";
import {
  addSavedMessagesapi,
  deleteSavedMessagesapi,
  editSavedMessagesapi,
  getSavedMessages
} from "store/services/savedMessages-service";
import { useAppDispatch, useAppSelector } from "common/hooks/redux-hooks";
import { getGatherCompanySettingsModel } from "store/services/companySettings-service";
import { SavedMessageModel } from "common/model/saved-message";
import { GatherCompanySettingsModel } from "common/model/company-settings";
import { AppNotifier } from "common/components/toast/Toast";
import { DeleteModalConstants, PageLoaderKeys, ValidationConstants } from "helper/Constants";
import ConfirmDialog from "common/components/modal/ConfirmDialog";
import LoaderWrapper from "common/pages/LoaderWrapper";
export interface IMessageData {
  id: number;
  name: string;
  body: string;
  isDefault: boolean;
  allowEdit: boolean;
}
export const initialModalData: IMessageData = {
  id: 0,
  name: "",
  body: "",
  isDefault: false,
  allowEdit: true
};
const initialSavedMsgData: SavedMessageModel = { text: "", id: 0, isEditable: true, name: "", isSystemDefault: false };

const SavedMessagesPage: FC = () => {
  const savedMessageFromStore = useAppSelector((state) => state.savedMessages.savedMessageArray);
  const companySettings: GatherCompanySettingsModel = useAppSelector((state) => state.companySetting);
  const dispatch = useAppDispatch();

  const [showAddModal, setShowAddModal] = useState(false);
  const [listingData, setListingData] = useState<SavedMessageModel[]>(savedMessageFromStore);
  const [selectedEmailMessage, setSelectedEmailMessage] = useState<SavedMessageModel>(initialSavedMsgData);
  const [initialData, setInitialData] = useState<SavedMessageModel>(initialSavedMsgData);
  const [modalType, setModalType] = useState(ModalType.NONE);
  const [refresh, setRefresh] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    dispatch(getGatherCompanySettingsModel());
    dispatch(getSavedMessages());
  }, []);


  useEffect(() => {
    if (savedMessageFromStore) {
      const defaultMessage = savedMessageFromStore.find((message:SavedMessageModel) => message.id === companySettings.defaultSettings.savedMessage)
      const systemDefaultMessage = savedMessageFromStore.find((message:SavedMessageModel) => message.isSystemDefault === true)
      setSelectedEmailMessage(defaultMessage ? defaultMessage : systemDefaultMessage);
      const index = savedMessageFromStore.findIndex((item: SavedMessageModel) => item.isSystemDefault === true);
      const savedMessageData = [...savedMessageFromStore];
      savedMessageData.splice(0, 0, savedMessageData[index]);
      savedMessageData.splice(index + 1, 1);
      setListingData(savedMessageData);
    }
  }, [savedMessageFromStore, companySettings.defaultSettings.savedMessage]);

  const onAddEmailMessages = () => {
    setModalType(ModalType.ADD);
    setInitialData(initialSavedMsgData);
    setShowAddModal(true);
  };

  const refreshPage = () => {
    setRefresh(!refresh);
  };
  const onEditEmail = () => {
    setModalType(ModalType.EDIT);
    setInitialData(selectedEmailMessage);
    setShowAddModal(true);
  };
  const onDeleteEmail = () => {
    dispatch(deleteSavedMessagesapi(selectedEmailMessage, () => {
      setShowDeleteModal(false);
      setSelectedEmailMessage(initialSavedMsgData);
    }));

  };
  const onModalConfirm = (data: SavedMessageModel, setDefault: boolean) => {
    if (modalType === ModalType.ADD) {
      dispatch(addSavedMessagesapi(data, setDefault, companySettings, () => {
        setSelectedEmailMessage(data)
      }));
      setShowAddModal(false);
    } else {
      dispatch(editSavedMessagesapi(data, setDefault, companySettings, () => {
        setSelectedEmailMessage(data)
      }));
      setShowAddModal(false);
    }
  };
  const onModalCancel = () => {
    setShowAddModal(false);
    setModalType(ModalType.NONE);
    setInitialData(selectedEmailMessage);
  };
  const onSelectMessage = (index: number, messageType: MessageType, array: SavedMessageModel) => {
    setSelectedEmailMessage(array);
    setInitialData(array);
  };

  const onDeleteClick = () => {
    if (selectedEmailMessage.id === 0 || companySettings.defaultSettings.savedMessage === selectedEmailMessage.id) {
      AppNotifier.Error(ValidationConstants.Warning.DeleteMessageWarning);
      return;
    }
    setShowDeleteModal(true);
  }

  const onDeleteCancel = () => {
    setShowDeleteModal(false);
  }
  return (
    <div className="settings-container client-settings">
      <LoaderWrapper keys={PageLoaderKeys.SavedMessages} isScrollableView>
        <header>
          <h3>Saved Messages</h3>
        </header>
        <section className="border-bottom-0">
          <h4>Message from Tax Preparer</h4>
          <h6>Click on '+' button to create new template message</h6>
          <div className="message-item-container">
            <MessageListBox
              messageType={MessageType.CLIENT_INSTRUCTION}
              companysettings={companySettings}
              onSelectMessage={onSelectMessage}
              data={listingData}
              onAddMessage={onAddEmailMessages}
              selectedMessage={selectedEmailMessage ?? initialSavedMsgData}
            />
            <MessageBox
              selectedMessage={selectedEmailMessage ?? initialSavedMsgData}
              onEdit={onEditEmail}
              onDelete={onDeleteClick} />
          </div>
        </section>
        {showAddModal && (
          <SavedMessageModal
            modalType={modalType}
            initialData={initialData}
            show={showAddModal}
            companySettings={companySettings}
            onSubmit={onModalConfirm}
            onHide={onModalCancel}
            data={listingData}
          />
        )}
        <ConfirmDialog
          title={DeleteModalConstants.SavedMessage.title}
          message={DeleteModalConstants.SavedMessage.message}
          show={showDeleteModal}
          onHide={onDeleteCancel}
          onSubmit={onDeleteEmail}
        />
      </LoaderWrapper>
    </div>
  );
};
export default SavedMessagesPage;
