/* This is to keep all the API enpoints in one place . Here, You can add/update any API route and You can add/update any API endpoint
   You can use this endpoints anywhere in the application by importing the API object
   Sample usage:
   For this enpoint `https://orion-dev-organizers-cpa-api.safesendwebsites.com/api/organizer/clients/GetClientInfoAsync`,
   You can use the below 
   {API.ORGANIZER.CLIENTS.GET_CLIENT_INFO_ASYNC}
*/
const API_BASE_URL = `${process.env.REACT_APP_API_URL}api`;
const SSSUITE_API_BASE_URL = `${process.env.REACT_APP_SSSUITE_API_URL}api`;

export const FORETHOUGHT_URL = "https://solve-widget.forethought.ai/embed.js";
export const APP_INSIGHT_URL = `${API_BASE_URL}/ApplicationInsights`;

// API routes
const MY_ACCOUNT = "MyAccount";
const WEB_SOCKET = "web-socket";
const ORGANIZER = "organizer";
const GATHER = "gather";
const RECYCLED_GATHER = "RecycledGather";
const RESTORE = "Restore";
const USER_SETTINGS = "UserSetting";
const USER_MANAGEMENT = "user-management";
const USERS = "users";
const USERGROUPS = "user-group";
const DOWNLOAD = "download";
const MENU = "menu";
const RESOURCE = "resource";
const CUSTOM_QUESTIONS_TEMPLATES = "gather/questionnaire-templates";
const CUSTOM_QUESTIONS_FORMS = "gather/questionnaire-forms";
const CUSTOM_QUESTIONS_SETTINGS = "gather/questionnaire-forms/settings";

const DELIVERED_GATHER = "delivered-gather";
const QUESTIONNAIRE = "gather/questionnaire-forms"
const CUSTOM_QUESTIONS = "gather/gather-questionnaire-forms";
const CUSTOM_QUESTION_TEMPLATES = "gather/questionnaire-templates";
const UNDELIVERED_GATHER = "undelivered-gather";
const ARCHIVED_GATHER = "archived-gather";
const UPLOADMODAL = "upload";
const COMPANY = "company";
const BATCH_PROCESS = "batch/processing";
const SAVED_MESSAGE = "saved-messages";
const INPROCESS_GATHER = "inprocess-gather";
const INPROCESS_BATCH = "inprocess-batch";
const SAVE_REPORT_PROBLEM = "SaveHelpReportProblem";
const SIGNALR = "signalr";
const CLIENT_MANAGEMENT = "client-management/client-info";
const COLUMN_SETTING = "column-setting";
const GATHER_TEMPLATE_SETTING = "gather/gathertemplate-setting";
const COMPANY_SETTINGS = "gather/company-settings";
const ALL_ENGAGEMENT_TYPE="gather/engagementtype-settings/get";
const ENGAGEMENT_TYPE="gather/engagementtype-settings/get-all";
const ENGAGEMENT_TYPE_ADD="gather/engagementtype-settings/save";
const ENGAGEMENT_TYPE_EDIT="gather/engagementtype-settings/edit";
const ENGAGEMENT_TYPE_DELETE="gather/engagementtype-settings/delete";
const SAVED_MESSAGES = "saved-messages/get-all";
const ADD_SAVED_MESSAGES = "saved-messages/save"
const EDIT_SAVED_MESSAGES = "saved-messages/edit";
const DELETE_SAVED_MESSAGE = "saved-messages/delete";
const EMAIL_TEMPLATE = "email-messages/GetEmailMessages";
const ADD_EMAIL_TEMPLATE = "email-messages/AddEmailMessage";
const EDIT_EMAIL_TEMPLATE = "email-messages/UpdateEmailMessage";
const DELETE_EMAIL_TEMPLATE = "email-messages/DeleteEmailMessage";
const REMINDER_SETTINGS = "reminder-settings";
const SEND_REMINDER = "sendReminder";
const UPDATE_AUTO_REMINDER = "updateAutomaticReminder";
const REMINDER = "reminder";
const USER = "user";
const UPLOAD_TEMPLATE = "upload-templates";
const CLIENT_INFO = "client-management/client-info";
const Preview = "preview";
export const Batch = "batch";
export const Processing = "processing";
export const CSVData = "CSVData";
export const Proforma = "proforma";
export const InProcessBatch = "inprocess-batch";
export const InProcessGather = "inprocess-gather";

// API endpoints
export const API = {
  // login and user
  STAY_ALIVE_ASYNC: `${API_BASE_URL}/${MY_ACCOUNT}/StayAliveAsync`,
  USER_SETTINGS: {
    DEFAULT: `${API_BASE_URL}/${USER_SETTINGS}/Default`,
    SAVE_USER_SETTINGS: `${API_BASE_URL}/${USER_SETTINGS}/Default`,
    UPDATE_USER_SETTINGS: `${API_BASE_URL}/${USER_SETTINGS}/DefaultSettings`,
  },
  COMPANY_MANAGEMENT: {
    GET_COMPANY_PROFILE: `${SSSUITE_API_BASE_URL}/${COMPANY}/company-profile`,
    GET_COMPANY_LOCATIONS: `${API_BASE_URL}/${COMPANY}/office-locations`,
    GET_COMPANY_SIGNATURE_LINK: `${API_BASE_URL}/my-downloads/company-signature-link`,
    GET_COMPANY_LOGO:`${SSSUITE_API_BASE_URL}/logo/company-logo`,
    IS_TEST_COMPANY:`${API_BASE_URL}/${COMPANY}/isTestCompany`,
    GET_COMMON_SETTINGS:`${API_BASE_URL}/${COMPANY}/common-settings`
  },
  RECYCLED_GATHER: {
    RECYCLED_GATHER: `${API_BASE_URL}/${GATHER}/${RECYCLED_GATHER}`,
    RECYCLED_GATHER_RESTORE: `${API_BASE_URL}/${GATHER}/${RECYCLED_GATHER}/${RESTORE}`
  },
  MY_DOWNLOADS: {
    MY_DOWNLOADS: `${API_BASE_URL}/my-downloads`,
    CLEAR_ALL_MY_DOWNLOADS: `${API_BASE_URL}/my-downloads/clear-all`,
    DOWNLOAD_MY_DOWNLOADS: `${API_BASE_URL}/my-downloads/download-zip`,
    DOWNLOAD_ALL_MY_DOWNLOADS: `${API_BASE_URL}/my-downloads/download-all`
  },
  USER_MANAGEMENT: {
    USER_DETAILS: `${SSSUITE_API_BASE_URL}/${USER_MANAGEMENT}/user-details`,
    RESET_USER_STATUS_CACHE: `${SSSUITE_API_BASE_URL}/${USER_MANAGEMENT}/reset-user-status-cache`,
    GET_USERS: `${SSSUITE_API_BASE_URL}/users`,
    SAVE_USER_DETAILS: `${SSSUITE_API_BASE_URL}/${USER_MANAGEMENT}/user-details`,
    UPDATE_PASSWORD: `${SSSUITE_API_BASE_URL}/${USER_MANAGEMENT}/user-password`,
    GET_PASSWORD_POLICY: `${SSSUITE_API_BASE_URL}/settings/password-policy`
  },
  OTP_MANAGEMENT: {
    SEND_OTP: `${SSSUITE_API_BASE_URL}/otp/send-otp`,
    VALIDATE_OTP: `${SSSUITE_API_BASE_URL}/otp/verify-otp`,
    MFA_OTP_LENGTH: `${SSSUITE_API_BASE_URL}/helper/mfa-otp-length`
  },
  MENU: {
    PRODUCT_LEFT_MENU: `${SSSUITE_API_BASE_URL}/${MENU}/product-left-menu`,
    PRODUCT_WIDGET_MENU: `${SSSUITE_API_BASE_URL}/${MENU}/widget-menu`,
    SUITE_URL:`${SSSUITE_API_BASE_URL}/helper/suite-url`
  },
  RBAC: {
    RBAC_RESOURCE_LIST: `${SSSUITE_API_BASE_URL}/${RESOURCE}`,
  },
  USERS: {
    GET_USERS: `${SSSUITE_API_BASE_URL}/${USERS}`,
    PARTNERS: `${SSSUITE_API_BASE_URL}/${USERS}/partners`,
    SAFESENDUSERSNOTINEXCHANGEANDSIGNATURE: `${SSSUITE_API_BASE_URL}/${USERS}/not-in-exchange-and-signature`
  },
  USER_GROUPS: {
    SAFESENDUSERGROUPS: `${SSSUITE_API_BASE_URL}/${USERGROUPS}/all-groups`
  },
  CUSTOMQUESTIONS: {
    GET_CUSTOM_QUESTIONS: `${API_BASE_URL}/${CUSTOM_QUESTIONS}`,
    GET_CUSTOM_QUESTION_TEMPLATES: `${API_BASE_URL}/${CUSTOM_QUESTION_TEMPLATES}`,
    GET_QUESTIONNAIRE_IN_USE: `${API_BASE_URL}/${CUSTOM_QUESTIONS}/HasTemplateIsInUse`
  },
  // login and user 

  COMPANY_SETTINGS: {
    GET_COMPANY_SETTINGS: `${API_BASE_URL}/${COMPANY_SETTINGS}`,
    GET_UTE_SETTINGS: `${API_BASE_URL}/${COMPANY_SETTINGS}/ute-setting`
  },
  SAVEDMESSAGE: {
    GET_SAVED_MESSAGES: `${API_BASE_URL}/${SAVED_MESSAGES}`,
    ADD_SAVED_MESSAGES: `${API_BASE_URL}/${ADD_SAVED_MESSAGES}`,
    EDIT_SAVED_MESSAGES: `${API_BASE_URL}/${EDIT_SAVED_MESSAGES}`,
    DELETE_SAVED_MESSAGE: `${API_BASE_URL}/${DELETE_SAVED_MESSAGE}`
  },
  ENGAGEMENT_TYPE: {
    GET_ENGAGEMENT_TYPE: `${API_BASE_URL}/${ENGAGEMENT_TYPE}`,
    ADD_ENGAGEMENT_TYPE: `${API_BASE_URL}/${ENGAGEMENT_TYPE_ADD}`,
    EDIT_ENGAGEMENT_TYPE: `${API_BASE_URL}/${ENGAGEMENT_TYPE_EDIT}`,
    DELETE_ENGAGEMENT_TYPE: `${API_BASE_URL}/${ENGAGEMENT_TYPE_DELETE}`,
    GET_ALL_ENGAGEMENT_TYPE: `${API_BASE_URL}/${ALL_ENGAGEMENT_TYPE}`
  },
  CLIENTINSTRUCTION:
  {
    GET_CLIENTINSTRUCTION: `${API_BASE_URL}/${EMAIL_TEMPLATE}`,
    ADD_CLIENTINSTRUCTION: `${API_BASE_URL}/${ADD_EMAIL_TEMPLATE}`,
    EDIT_CLIENTINSTRUCTION: `${API_BASE_URL}/${EDIT_EMAIL_TEMPLATE}`,
    DELETE_CLIENTINSTRUCTION: `${API_BASE_URL}/${DELETE_EMAIL_TEMPLATE}`
  },

  // common
  GET_WEBSOCKET_CONNECTION_URL: `${API_BASE_URL}/${WEB_SOCKET}/ConnectionUrl`,
  SIGNALR_NEGOTIATE_URL: `${SIGNALR}/Negotiate`,
  SIGNALR_ADD_TO_GROUP_URL: `${SIGNALR}/AddToGroup`,
  DOWNLOAD: `${API_BASE_URL}/${DOWNLOAD}/GetCompanySignatureUploadLinkAsync`,
  COMPANY: {
    GET_COMPANY_LOCATIONS: `${API_BASE_URL}/${COMPANY}/profile`,
    GET_COMPANY_SETTINGS: `${API_BASE_URL}/${COMPANY_SETTINGS}`,
    GET_COMPANY_PROFILE: `${API_BASE_URL}/${COMPANY}/profile`,
    GET_USER_LOCATIONS: `${API_BASE_URL}/${COMPANY}/user-locations`
  },
  USER: {
    GET_OFFICE_LOCATIONS: `${API_BASE_URL}/${USER}/office-locations`,
    GET_CLIENT_INFO: `${API_BASE_URL}/${CLIENT_INFO}/all`,
    GET_ONEHUB_ACCESS_CODE: `${SSSUITE_API_BASE_URL}/one-hub/access-code`,
    GENERATE_ONEHUB_ACCESS_CODE: `${SSSUITE_API_BASE_URL}/one-hub/generate-access-code`,
    GET_LOGIN_HISTORY: `${API_BASE_URL}/${USER}/login-history`,
    UPDATE_LOGOUT_DATE: `${SSSUITE_API_BASE_URL}/login-history/update-logout-date`
  },

  // organizer
  ORGANIZER: {
    CLIENTS: {
      COMPARE_CLIENT_INFO_ASYNC: `${API_BASE_URL}/${ORGANIZER}/clients/CompareClientInfoAsync`,
      GET_CLIENT_INFO_ASYNC: `${API_BASE_URL}/${ORGANIZER}/clients/GetClientInfoAsync`,
      ADD_CLIENT_INFO_ASYNC: `${API_BASE_URL}/${ORGANIZER}/clients/AddClientInfoAsync`,
      EDIT_CLIENT_INFO_ASYNC: `${API_BASE_URL}/${ORGANIZER}/clients/EditClientInfoAsync`,
    },
  },
  DELIVERED_GATHER: {
    GET_DELIVERED_GATHER_DATA: `${API_BASE_URL}/${DELIVERED_GATHER}`,
    RECYCLE_GATHER: `${API_BASE_URL}/${DELIVERED_GATHER}/recycle`,
    UPDATE_MANUALLY: `${API_BASE_URL}/${DELIVERED_GATHER}/UpdateStatusToManual`,
    ARCHIVE_GATHER: `${API_BASE_URL}/${DELIVERED_GATHER}/archive`,
    SAVE_REPORT_PROBLEM: `${API_BASE_URL}/${DELIVERED_GATHER}/${SAVE_REPORT_PROBLEM}`,
    REPORT_COLUMN_SETTING: `${API_BASE_URL}/${COLUMN_SETTING}`,
    CLOSE_REOPEN_GATHER: `${API_BASE_URL}/${DELIVERED_GATHER}/CloseOpenGathersByFirm`,
    EXPORT_DELIVERED_GATHER_DATA: `${API_BASE_URL}/${DELIVERED_GATHER}/Export`,
    GET_UTE_MERGE_DETAILS: `${API_BASE_URL}/${DELIVERED_GATHER}/client-onehub-account-merge-details`,
    DOWNLOAD: {
      GET_DOWNLOADABLE_DOCUMENT: `${API_BASE_URL}/${DELIVERED_GATHER}/${DOWNLOAD}/downloadable-document`,
      GET_SOURCE_DOCUMENT_METADATA: `${API_BASE_URL}/${DELIVERED_GATHER}/${DOWNLOAD}/source-document-metadata`,
      GET_SOURCE_DOCUMENT: `${API_BASE_URL}/${DELIVERED_GATHER}/${DOWNLOAD}/source-document`,
      GET_SIGNED_ENGAGEMENT_LETTER: `${API_BASE_URL}/${DELIVERED_GATHER}/${DOWNLOAD}/signed-engagement-letter`,
      GET_FILLED_ORGANIZER: `${API_BASE_URL}/${DELIVERED_GATHER}/${DOWNLOAD}/filled-organizer`,
      DOWNLOAD_ALL: `${API_BASE_URL}/${DELIVERED_GATHER}/${DOWNLOAD}/download-all`,
      SOURCE_DOCUMENTS: `${API_BASE_URL}/${DELIVERED_GATHER}/${DOWNLOAD}/source-documents`,
      ADD_MYDOWNLOAD: `${API_BASE_URL}/${DELIVERED_GATHER}/${DOWNLOAD}/add-mydownload`,
      MERGED_SOURCEDOCUMENTS: `${API_BASE_URL}/${DELIVERED_GATHER}/${DOWNLOAD}/addMerged-myDownload`,
    },
    Preview: `${API_BASE_URL}/${Preview}`,
    RESEND_ACCESS_LINK: `${API_BASE_URL}/${DELIVERED_GATHER}/resend-access-link`,
    ACCESS_CODE: `${API_BASE_URL}/${DELIVERED_GATHER}/access-code`,
    CLOSE_GATHER_BY_FIRM: `${API_BASE_URL}/${DELIVERED_GATHER}/close-gather-by-firm`,
    RE_OPEN_GATHER_BY_FIRM: `${API_BASE_URL}/${DELIVERED_GATHER}/re-open-gather-by-firm`,
    RE_OPEN_GATHER: `${API_BASE_URL}/${DELIVERED_GATHER}/re-open-gather`,
    RE_OPEN_SOURCE_DOC_UPLOAD: `${API_BASE_URL}/${DELIVERED_GATHER}/re-open-source-document-upload`,
    UNLOCK_GATHER: `${API_BASE_URL}/${DELIVERED_GATHER}/unlock-gather`,
    UNLOCK_ONE_HUB: `${SSSUITE_API_BASE_URL}/one-hub/unlock-account?emailAddress=`,
    GET_CLIENT_TRACKING: `${API_BASE_URL}/${DELIVERED_GATHER}/client-tracking`,
    GET_CLIENT_DELIVERY_STATUS: `${API_BASE_URL}/${DELIVERED_GATHER}/client-delivery-status`,
  },
  ARCHIVED_GATHER: {
    GET_ARCHIVED_GATHER_DATA: `${API_BASE_URL}/${ARCHIVED_GATHER}`,
    RECYCLE_GATHER: `${API_BASE_URL}/${ARCHIVED_GATHER}/Recycle`,
    RESTORE_GATHER: `${API_BASE_URL}/${ARCHIVED_GATHER}/RestoreArchivedGather`,
    CLOSE_REOPEN_GATHER: `${API_BASE_URL}/${ARCHIVED_GATHER}/CloseOpenGathersByFirm`,
    CLOSE_GATHER: `${API_BASE_URL}/${ARCHIVED_GATHER}/close-gather-by-firm`,
    DOWNLOAD_GATHER: `${API_BASE_URL}/${ARCHIVED_GATHER}/Download`,
    REOPEN_GATHER: `${API_BASE_URL}/${ARCHIVED_GATHER}/re-open-gather-by-firm`,
    ACCESS_CODE: `${API_BASE_URL}/${ARCHIVED_GATHER}/access-code`,
    RESEND_ACCESS_LINK: `${API_BASE_URL}/${ARCHIVED_GATHER}/resend-access-link`,
    SAVE_REPORT_PROBLEM: `${API_BASE_URL}/${ARCHIVED_GATHER}/${SAVE_REPORT_PROBLEM}`,
    EXPORT_ARCHIVED_GATHER_DATA: `${API_BASE_URL}/${ARCHIVED_GATHER}/Export`,
    GET_UTE_MERGE_DETAILS: `${API_BASE_URL}/${ARCHIVED_GATHER}/client-onehub-account-merge-details`,
    DOWNLOAD: {
      GET_DOWNLOADABLE_DOCUMENT: `${API_BASE_URL}/${ARCHIVED_GATHER}/${DOWNLOAD}/downloadable-document`,
      GET_SOURCE_DOCUMENT_METADATA: `${API_BASE_URL}/${ARCHIVED_GATHER}/${DOWNLOAD}/source-document-metadata`,
      GET_SOURCE_DOCUMENT: `${API_BASE_URL}/${ARCHIVED_GATHER}/${DOWNLOAD}/source-document`,
      GET_SIGNED_ENGAGEMENT_LETTER: `${API_BASE_URL}/${ARCHIVED_GATHER}/${DOWNLOAD}/signed-engagement-letter`,
      GET_FILLED_ORGANIZER: `${API_BASE_URL}/${ARCHIVED_GATHER}/${DOWNLOAD}/filled-organizer`,
      DOWNLOAD_ALL: `${API_BASE_URL}/${ARCHIVED_GATHER}/${DOWNLOAD}/download-all`,
      SOURCE_DOCUMENTS: `${API_BASE_URL}/${ARCHIVED_GATHER}/${DOWNLOAD}/source-documents`,
      ADD_MYDOWNLOAD: `${API_BASE_URL}/${ARCHIVED_GATHER}/${DOWNLOAD}/add-mydownload`,
      MERGED_SOURCEDOCUMENTS: `${API_BASE_URL}/${ARCHIVED_GATHER}/${DOWNLOAD}/addMerged-myDownload`,
    },
    GET_CLIENT_TRACKING: `${API_BASE_URL}/${ARCHIVED_GATHER}/client-tracking`,
    UNLOCK_GATHER: `${API_BASE_URL}/${ARCHIVED_GATHER}/unlock-gather`,
    GET_CLIENT_DELIVERY_STATUS: `${API_BASE_URL}/${ARCHIVED_GATHER}/client-delivery-status`,
  },
  // in-process gather
  INPROCESS_GATHER: {
    SAVE_REPORT_PROBLEM: `${API_BASE_URL}/${INPROCESS_GATHER}/${SAVE_REPORT_PROBLEM}`,
    GET_INPROCESS_GATHER_DATA: `${API_BASE_URL}/${INPROCESS_GATHER}`,
    DELETE_INPROCESS_GATHER_DATA: `${API_BASE_URL}/${INPROCESS_GATHER}`,
    EXPORT_INPROCESS_GATHER_DATA: `${API_BASE_URL}/${INPROCESS_GATHER}/Export`
  },
  UNDELIVERED_GATHER: {
    GET_UNDELIVERED_GATHER_DATA: `${API_BASE_URL}/${UNDELIVERED_GATHER}`,
    RECYCLE_GATHER: `${API_BASE_URL}/${UNDELIVERED_GATHER}/DeleteUndeliveredBatch`,
    Download_UNDELIVERED_GATHER_DATA: `${API_BASE_URL}/${UNDELIVERED_GATHER}/GetUndeliveredCsvAsync`,
    EXPORT_UNDELIVERED_GATHER_DATA: `${API_BASE_URL}/${UNDELIVERED_GATHER}/Export`
  },
  SETTINGS: {
    CustomQuestions: {
      CUSTOM_QUESTIONS_TEMPLATES: `${API_BASE_URL}/${CUSTOM_QUESTIONS_TEMPLATES}`,
      CUSTOM_QUESTIONS_FORM: `${API_BASE_URL}/${CUSTOM_QUESTIONS_FORMS}`,
      CUSTOM_QUESTIONS_SETTINGS: `${API_BASE_URL}/${CUSTOM_QUESTIONS_SETTINGS}`,

    }
  },
  SAVEDMESSAGES: {
    GET_ALL_SAVED_MESSAGES: `${API_BASE_URL}/${SAVED_MESSAGE}/get-all`,
    ADD_SAVED_MESSAGE: `${API_BASE_URL}/${SAVED_MESSAGE}/save`,
    EDIT_SAVED_MESSAGE: `${API_BASE_URL}/${SAVED_MESSAGE}/edit`
  },
  GATHER: {
    PROCESS: {
      GET_BATCH: `${API_BASE_URL}/batch/${Processing}`,
      GET_BATCH_GATHER: `${API_BASE_URL}/batch/processing/batchGather`,
      GET_BATCH_ERROR: `${API_BASE_URL}/batch/processing/${CSVData}`,
      GET_BATCH_CSVData: `${API_BASE_URL}/batch/processing/${CSVData}`,
      SET_BATCH_CSVData: `${API_BASE_URL}/batch/processing/${CSVData}`,
      GET_BATCH_ADDITIONAL_ESIGN: `${API_BASE_URL}/batch/processing/GetBatchAdditionalEsignDocuments`,
      GENERATE_BATCH_PREVIEW: `${API_BASE_URL}/preview/batch-preview`,
      GENERATE_GATHER_PREVIEW: `${API_BASE_URL}/preview`,
      GET_SCHEDULES: `${API_BASE_URL}/schedules`,

      PROFORMA: {
        PROCESS_PROFORMA: `${API_BASE_URL}/gather/${Processing}`,
        UPDATE_GATHER_STATUS: `${API_BASE_URL}/gather/processing/UpdateDocumentStatus`,
        DELIVER_PROFORMA: `${API_BASE_URL}/gather/processing/deliver`,
        INTILIAZE_PROFORMA:  `${API_BASE_URL}/gather/processing/initialize`
      }
    },
    USER: {
      REQUEST_DELEGATED_ERO_SIGNERS: `${API_BASE_URL}/UserManagement/AllDelegatedEroSigners`,
      REQUEST_DOWNLOAD_PATH: `${API_BASE_URL}/UserSetting/GetUserSignLinkAsync`
    },
    DOCUMENT: {
      GET_DOCUMENT: `${API_BASE_URL}/${GATHER}/${ORGANIZER}`,
      Delete_csv: `${API_BASE_URL}/${GATHER}/${ORGANIZER}/DeleteCsv`,
      Delete_zip: `${API_BASE_URL}/${GATHER}/${ORGANIZER}/DeleteZip`,
    },
    DOCUMENTREQUEST: {
      LIST: `${API_BASE_URL}/${GATHER}/document-request-list`,
      GET_IMPORTED_LIST: `${API_BASE_URL}/${GATHER}/document-request-list/imported-document-request`,
      SaveOrUpdateBatchDocumentRequestList: `${API_BASE_URL}/${GATHER}/document-request-list`,
      GET_DRL_NAMES: `${API_BASE_URL}/${GATHER}/document-request-list/drl-names`,
      UPDATE_DRL_MAPPING: `${API_BASE_URL}/${GATHER}/document-request-list/update-drl-mapping`,
      CREATE_DRL_NAME: `${API_BASE_URL}/${GATHER}/document-request-list/create-drl`,
      UPDATE_DRL_NOT_APPLICABLE_STATUS: `${API_BASE_URL}/${GATHER}/document-request-list/update-drl-not-applicable-status`
    },
    CLIENTS: {
      GET_CLIENT_DETAILS: `${API_BASE_URL}/client/`,
      UPDATE_CLIENTS: `${API_BASE_URL}/client/UpdateClients`,
      SAVE_CLIENT: `${API_BASE_URL}/client`,
    }
  },
  PREPARERMESSAGES: {
    GET_SAVED_MESSAGES: `${API_BASE_URL}/saved-messages/GetSavedMessages`
  },
  UPLOAD: {
    ORGANIZER_UPLOAD: {
      UPLOAD_LINK: `${API_BASE_URL}/${UPLOADMODAL}/organizer/upload-link`,
      SUBMIT: `${API_BASE_URL}/${UPLOADMODAL}/organizer/submit`
    },
    BATCHUPLOAD: {
      DOWNLOAD_CSV_TEMPLATE: `${API_BASE_URL}/${UPLOADMODAL}/batch/download-csv-template`,
      CSV_UPLOAD_LINK: `${API_BASE_URL}/${UPLOADMODAL}/batch/csv-upload-link`,
      ZIP_UPLOAD_LINK: `${API_BASE_URL}/${UPLOADMODAL}/batch/zip-upload-link`,
      SUBMIT: `${API_BASE_URL}/${UPLOADMODAL}/batch/submit`
    },
    ESIGN_DOCUMENT_UPLOAD: {
      BATCH_UPLOAD_LINK: `${API_BASE_URL}/${UPLOADMODAL}/batch/esign-document/upload-link`,
      DELETE_BATCH_UPLOADED_DOCUMENT: `${API_BASE_URL}/${UPLOADMODAL}/batch/esign-document`,
      UPLOAD_LINK: `${API_BASE_URL}/${UPLOADMODAL}/esign-document/upload-link`,
      DELETE_UPLOADED_DOCUMENT: `${API_BASE_URL}/${UPLOADMODAL}/esign-document`,
      SUBMIT: `${API_BASE_URL}/${UPLOADMODAL}/esign-documen/submit`,
      CONVERT_DOC_TO_PDF_DOCUMENT_LINK: `${API_BASE_URL}/${UPLOADMODAL}/ConvertDocToPdfDocumentLink`,
    },
    PROFORMAUPLOAD: {
      ORGANIZER_UPLOAD_LINK: `${API_BASE_URL}/${UPLOADMODAL}/proforma/organizer-upload-link`,
      SUBMIT: `${API_BASE_URL}/${UPLOADMODAL}/proforma/submit`,
      ORGANIZER_LINK: `${API_BASE_URL}/${UPLOADMODAL}/proforma/organizer`
    }
  },
  BATCHPROCESS: {
    GET_DETAILS: `${API_BASE_URL}/${BATCH_PROCESS}/get`,
    UPDATE_DETAILS: `${API_BASE_URL}/${BATCH_PROCESS}/update`,
    DELIVER: `${API_BASE_URL}/${BATCH_PROCESS}/deliver`
  },
  //Reminder
  REMINDER: {
    GET_REMINDER_SETTINGS: `${API_BASE_URL}/${REMINDER}/${REMINDER_SETTINGS}`,
    SEND_REMINDER: `${API_BASE_URL}/${REMINDER}/${SEND_REMINDER}`,
    UPDATE_AUTO_REMINDER: `${API_BASE_URL}/${REMINDER}/${UPDATE_AUTO_REMINDER}`
  },

  INPROCESS_BATCH: {
    GET_INPROCESS_BATCH_DATA: `${API_BASE_URL}/${INPROCESS_BATCH}`,
    GET_INPROCESS_GATHER_FOR_BATCH_DATA: `${API_BASE_URL}/${INPROCESS_BATCH}/GetBatchGather`,
    DELETE_INPROCESS_BATCH_DATA: `${API_BASE_URL}/${INPROCESS_BATCH}`,
    UPDATE_INPROCESS_BATCH_DATA: `${API_BASE_URL}/${INPROCESS_BATCH}`,
    DELETE_INPROCESS_GATHER_IN_BATCH_DATA: `${API_BASE_URL}/${INPROCESS_BATCH}`,
    EXPORT_INPROCESS_BATCH_DATA: `${API_BASE_URL}/${INPROCESS_BATCH}/Export`,
    DOWNLOAD_INPROCESS_BATCH_DATA: `${API_BASE_URL}/gather/BatchExcelDownload`
  },
  UPLOADTEMPLATE: {
    GET_ALL_TEMPLATES: `${API_BASE_URL}/${UPLOAD_TEMPLATE}/all`,
    GET_TEMPLATE: `${API_BASE_URL}/${UPLOAD_TEMPLATE}`
  },

  CLIENT_MANAGEMENT: {
    COMPARE_CLIENT_INFO: `${API_BASE_URL}/${CLIENT_MANAGEMENT}/compare`,
    ADD_NEW_CLIENT: `${API_BASE_URL}/${CLIENT_MANAGEMENT}/add`,
    GET_CLIENT_INFO: `${API_BASE_URL}/${CLIENT_MANAGEMENT}?searchQuery=`,
    UPDATE_CLIENT_INFO: `${API_BASE_URL}/${CLIENT_MANAGEMENT}`,
    SEARCH_CLIENT_INFO: `${API_BASE_URL}/${CLIENT_INFO}/all`
  },
  GATHER_TEMPLATE_SETTING:
  {
    GATHER_TEMPLATE_SETTING_GETALL: `${API_BASE_URL}/${GATHER_TEMPLATE_SETTING}/get-all`,
    GATHER_TEMPLATE_SETTING_ADD: `${API_BASE_URL}/${GATHER_TEMPLATE_SETTING}/add`,
    GATHER_TEMPLATE_SETTING_EDIT: `${API_BASE_URL}/${GATHER_TEMPLATE_SETTING}/edit`,
    GATHER_TEMPLATE_SETTING_DELETE: `${API_BASE_URL}/${GATHER_TEMPLATE_SETTING}/delete`,
    GATHER_TEMPLATE_SETTING_CHECKADDSTATUS: `${API_BASE_URL}/${GATHER_TEMPLATE_SETTING}/checkAdd`
  },
  FILTERS: {
    BASE: `${API_BASE_URL}/Gather/Filter`,
  },
  FILE: {
    IS_PASSWORD_PROTECTED: `${API_BASE_URL}/file/is-password-protected`
  },  
  UTE: {
    GET_MERGE_DETAILS: `${SSSUITE_API_BASE_URL}/one-hub/linked-merge-details`
  }
};
