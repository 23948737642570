import { FC } from 'react';
import { useSelector } from 'react-redux';
import { signinRedirect, signinRedirectWithCallbackArgs } from 'oidc-client/userService';
import LoadingView from 'common/pages/LoadingScreen';
import { Redirect } from 'react-router-dom';
import { setRestrictedAccessPath } from 'store/slices/restricted-access-slice';
import { store } from 'store';

const Login: FC = (props: any) => {
    const { user, isLoadingUser } = useSelector((state: any) => state)
    store.dispatch(setRestrictedAccessPath(''));
    if (!user) {
        if (isLoadingUser) {
            return <LoadingView showLogo={true} />
        }
        if (props.location && props.location.state
            && props.location.state.redirectedFrom && props.location.state.redirectedFrom.pathname !== '/') {
            signinRedirectWithCallbackArgs(props.location.state.redirectedFrom.pathname
                + (props.location.state.redirectedFrom.search ? props.location.state.redirectedFrom.search : '')
            );
        } else {
            signinRedirect();
        }
        return <></>
    }
    return <Redirect to={'/'} />
}

Login.displayName = 'Login';
export default Login;
