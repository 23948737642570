import { signoutRedirect } from 'oidc-client/userService';
import React from 'react'

export const RestrictedAccess: React.FC = () => {

    const onLogin = () => {
        signoutRedirect();
    }  
    return (
        <div style={{ marginLeft: "10px" }} className='restricted-access'>
            <div className="marB15">
                <h1 className="fontfamily-inital-heading" >
                    Restricted access
                </h1>
            </div>

            <div className="marB15">
                <h4 className="fontfamily-inital-instruction">
                    This Client's IP address is not allowed to access the server.
                </h4>
            </div>

            <div>
                <a
                    className="fontfamily-inital-login"
                    onClick={onLogin}
                >
                    Click here to login.
                </a>
            </div>
        </div>
    );
};
export default RestrictedAccess;
