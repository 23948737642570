import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUserViewModel, unloadedState } from "common/model/user";
import { IDelegatedEROSigner, IUserDefaultSettings } from "common/model/userSetting";

const initialUserSettingState: any = {
    delegatedSigners: [],
    isLoading: false,
    userSignatures: unloadedState
}

const userSettingSlice = createSlice({
    name: 'userSetting',
    initialState: initialUserSettingState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload
        },
        setDelegatedSigners(state, action: PayloadAction<IDelegatedEROSigner[]>) {
            state.delegatedSigners = action.payload
            state.isLoading = false
        },
        getDefaultSettingsData(state, action: PayloadAction<IUserDefaultSettings[]>) {
            state.defaultUserSettings = action.payload;
        },
        setUsers(state, action: PayloadAction<IUserViewModel[]>) {
            state.users = action.payload;
        },
        setUserSignatures(state, action:PayloadAction<any>){
            if (state.userSignatures[action.payload.userId]) {
                state.userSignatures[action.payload.userId].signatureDownloadPath = action.payload.path;
            }
            else {
                state.userSignatures[action.payload.userId] = {
                    signatureDownloadPath: action.payload.path,
                    signatureUploadPath: "",
                };
        }
    }
}
});

export const { getDefaultSettingsData, setIsLoading, setDelegatedSigners, setUsers, setUserSignatures } = userSettingSlice.actions;

export default userSettingSlice.reducer;
