import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { act } from "@testing-library/react";
import { SavedMessageModel } from "common/model/saved-message";


const savedMessage:SavedMessageModel[]=[{body:"",id:0,isAllowToEdit:true,name:""}]
const initialSavedMessages:any={
    savedMessageArray:savedMessage
      
}
const savedMessageSlice = createSlice({
    name: 'savedMessage',
    initialState: initialSavedMessages,
    reducers: {
        setSavedMessages(state, action: PayloadAction<SavedMessageModel[]>)
        {
            
            state.savedMessageArray=action.payload;
        },
        addSavedMessages(state, action: PayloadAction<SavedMessageModel>)
        {
            
            state.savedMessageArray.push(action.payload);
        },
        editSavedMessages(state, action: PayloadAction<SavedMessageModel>)
        {
            
            state.savedMessageArray[state.savedMessageArray.findIndex((obj: SavedMessageModel) => obj.id===action.payload.id)]=(action.payload);
        },
        deleteSavedMessages(state, action: PayloadAction<SavedMessageModel>)
        {
           state.savedMessageArray= state.savedMessageArray.filter((item :SavedMessageModel )=> item.id !== action.payload.id);
        },
    }
});

export const {setSavedMessages,addSavedMessages,editSavedMessages,deleteSavedMessages} = savedMessageSlice.actions;

export default savedMessageSlice.reducer;