import React from "react";
import { GeneralSettingConstants } from "helper/Constants";
import DropdownComponent from "common/components/UIComponents/DropdownComponent";
import { GatherCompanySettingsModel } from "common/model/company-settings";
import { RequestDocumentOptionsArray } from "pages/archieved-organizers/Columns";
import { Form } from "react-bootstrap";
import { SettingsType } from ".";
interface IUploadPercentageSection {
  disableDropdown: boolean;
  state: GatherCompanySettingsModel;
  k1DocumentUploadNotification: boolean;
  sourceDocumentUploadNotification: boolean;
  updateSettings: (type: SettingsType, payload: any) => void;
}

const UploadPercentageSection: React.FC<IUploadPercentageSection> = ({
  disableDropdown,
  state,
  k1DocumentUploadNotification,
  sourceDocumentUploadNotification,
  updateSettings
}) => {
  const options = RequestDocumentOptionsArray;

  return (
    <div className="sub-section-item completion-wrapper">
      <div className="select-label-wrapper">
        <div>
          <div className="completion-form">
            <Form.Check  
              checked={sourceDocumentUploadNotification}
              disabled={!disableDropdown}
              onChange={(event) => updateSettings(SettingsType.SOURCE_DOC_UPLOAD_NOTIFICATION, event?.target?.checked ?? false)}
              type="checkbox"
            />
          </div>
          <p>{GeneralSettingConstants.uploadDocumentPercentageMsg}</p>
          <DropdownComponent
            selectedValue={
              state.sourceDocumentSettings.documentUploadPercentage <= 5
                ? 5
                : state.sourceDocumentSettings.documentUploadPercentage
            }
            onChange={(event) => updateSettings(SettingsType.SOURCE_DOC_UPLOAD_PERCENTAGE, event)}
            disabled={!disableDropdown || !sourceDocumentUploadNotification}
            searchable={false}
            id="dd-1"
            options={options}
            clearable={false}
          />
          <p>{GeneralSettingConstants.uploadDocumentPercentageMsgTrail}</p>
        </div>
      </div>
      <div className="select-label-wrapper">
        <div>
          <div className="completion-form">
            <Form.Check  
              checked={k1DocumentUploadNotification}
              disabled={!disableDropdown}
              onChange={(event) => updateSettings(SettingsType.K1_DOC_UPLOAD_NOTIFICATION, event?.target?.checked ?? false)}
              type="checkbox"
            />
          </div>
          <p>{GeneralSettingConstants.UploadK1PercentageMsg}</p>
          <DropdownComponent
            selectedValue={
              state.sourceDocumentSettings.k1UploadPercentage <= 5 ? 5 : state.sourceDocumentSettings.k1UploadPercentage
            }
            onChange={(event) => updateSettings(SettingsType.K1_DOC_UPLOAD_PERCENTAGE, event)}
            disabled={!disableDropdown || !k1DocumentUploadNotification}
            searchable={false}
            id="dd-2"
            options={options}
            clearable={false}
          />
          <p>{GeneralSettingConstants.UploadK1PercentageMsgTrail}</p>
        </div>
      </div>
    </div>
  );
};

export default UploadPercentageSection;
