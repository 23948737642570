import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IColumnSetting, DeliveredGatherData, IDeliveredGatherStoreState, IColumnSettingState } from "common/model/delivered-gather-table";
import { PAGESIZE } from "helper/Constants";

const initialDeliveredGatherState: IDeliveredGatherStoreState = {
    loading: false,
    model: {
        count: 0,
        documents: []
    },
    popupLoading: false,
    sourceDocumentMetaData: {},
    query: '',
    page: 1,
    pageSize: PAGESIZE
}

const deliveredGatherSlice = createSlice({
    name: 'deliveredGather',
    initialState: initialDeliveredGatherState,
    reducers: {
        setDeliveredGatherData: (state, action: PayloadAction<DeliveredGatherData>) => {
            state.model = action.payload.model;
            state.query = action.payload.query;
            state.page = action.payload.page;
            state.pageSize = action.payload.pageSize;
        },
        startLoader: (state) => {
            state.loading = true;
        },
        stopLoader: (state) => {
            state.loading = false;
        }
    }
});

export const { startLoader, setDeliveredGatherData, stopLoader } = deliveredGatherSlice.actions;

export const { reducer: deliveredGatherReducer } = deliveredGatherSlice;

const columnSettingInitialState: IColumnSettingState = {
    reportColumnSettings: [],
    isLoading: false
}

const reportColumnSettingSlice = createSlice({
    name: 'reportColumnSetting',
    initialState: columnSettingInitialState,
    reducers: {
        setReportColumnSettings : (state, action: PayloadAction<IColumnSetting[]>) => {
            state.reportColumnSettings = action.payload;
            state.isLoading = true;
        }
    }
});

export const { setReportColumnSettings } = reportColumnSettingSlice.actions;

export const { reducer: reportColumnSettingReducer } = reportColumnSettingSlice;