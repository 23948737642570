import { ThunkAction, Action } from '@reduxjs/toolkit';
import { ProductType } from '@sssuite-component-ui/ss-suite-layout';
import { API } from "services/api/api-config"
import Axios from "services/api/axios-interceptor";
import { AppState } from 'store';
import {setIsLoading, setLeftMenuData, setRbacData, setSuiteUrl, setWidgetMenuData} from 'store/slices/layout-slice';
import { getLeftMenuRouteData } from "rbac/rbac-helper";
import { ResourceValidator } from '@sssuite-component-ui/resource-id-validator';
import { RbacPermission } from 'rbac/rbac-constants';
import { AppNotifier } from 'common/components/toast/Toast';
import { RequestNotification } from 'helper/Constants';

export let validator: ResourceValidator;


export const getProductLeftMenuItems = (): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
        try {
            dispatch(setIsLoading(true));
            Axios.get(`${API.MENU.PRODUCT_LEFT_MENU}/${ProductType.Gather}`)
                .then(response => response.data)
                .then((data) => {
                    const response: LeftMenuModel = {
                        leftMenuData: data,
                        routeList: getLeftMenuRouteData(data)
                    }
                    dispatch(setLeftMenuData(response));
                })
        }
        catch (error: any) {
            dispatch(setIsLoading(false));
        }
    }

export const getProductWidgetMenuItems = (): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
        try {
            Axios.get(`${API.MENU.PRODUCT_WIDGET_MENU}/${ProductType.Gather}`)
                .then(response => response.data)
                .then((data) => {
                    const response: WidgetMenuModel = {
                        isLoading: false,
                        widgetMenu: data,
                        suiteUrl: ""
                    }
                    dispatch(setWidgetMenuData(response));
                })
        }
        catch (error: any) {
           // handle error
        }

    }
    export const getSuiteUrl = (): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
        try {
            Axios.get(`${API.MENU.SUITE_URL}`)
                .then(response => response.data)
                .then((data) => {
                   if(data){
                    dispatch(setSuiteUrl(data));
                   }
                })
        }
        catch (error: any) {
           AppNotifier.Error(RequestNotification.Failure.AllGathers);
        }

    }

    export const getRbacResourceList = (): ThunkAction<void, AppState, unknown, Action> =>
        (dispatch) => {
            try {
                Axios.get(`${API.RBAC.RBAC_RESOURCE_LIST}/${ProductType.Gather}`)
                    .then(response => response.data)
                    .then((data) => {
                        dispatch(setRbacData(data));
                        validator = new ResourceValidator(data || [], "disable", "click", RbacPermission.OperationDenied);
                        validator.start();
                    })
            }
            catch (error: any) {
               // handle error
            }
    
        }


