import { useEffect, useState } from "react";
import AddEditModal, { ModalType } from "./parts/AddEditModal";
import MessageListBox from "./parts/MessageListBox";
import MessageBox from "./parts/MessageBox";
import { Option } from "react-select";
import DropdownComponent from "common/components/UIComponents/DropdownComponent";
import "../../settings/style.scss";
import "./style.scss";
import { useAppDispatch, useAppSelector } from "common/hooks/redux-hooks";
import {
  addEmailInstructions,
  addRemainderEmail,
  deleteEmailInstructions,
  editEmailInstructions,
  editRemainderMail,
  getEmailInstructions
} from "store/services/clientInstructions-service";
import { getGatherCompanySettingsModel } from "store/services/companySettings-service";
import { EmailTemplateMessage } from "common/model/client-instruction";
import { EmailTemplateType } from "common/enum";
import { DefaultSetting } from "common/model/company-settings";
import EmailMessageList from "./parts/EmailMessageList";
import EmailMessageBox from "./parts/EmailMessageBox";
import { setSelectedOption } from "store/slices/clientInstructions-slice";
import { AddEditModalDialog, DeleteInstructionModal, PageLoaderKeys } from "helper/Constants";
import { AppNotifier } from "common/components/toast/Toast";
import ConfirmDialog from "common/components/modal/ConfirmDialog";
import LoaderWrapper from "common/pages/LoaderWrapper";

export interface IMessageData {
  id: number;
  name: string;
  subject: string;
  body: string;
  isDefault: boolean;
}

export enum ReminderOptionsValue {
  NONE = 0,
  EL_REMINDER = 4,
  TAX_ORG_REMINDER = 7,
  SOURCE_DOC_REMINDER = 17
}

const ReminderOptions: Option[] = [
  { label: "Engagement Letter Reminder", value: ReminderOptionsValue.EL_REMINDER },
  { label: "Tax Organizer Reminder", value: ReminderOptionsValue.TAX_ORG_REMINDER },
  { label: "Source Document Reminder", value: ReminderOptionsValue.SOURCE_DOC_REMINDER }
];

export enum MessageType {
  NONE,
  CLIENT_INSTRUCTION,
  EL,
  REMAINDER_EMAILS
}

export const initialModalData: EmailTemplateMessage = {
  id: 0,
  name: "",
  subject: "",
  body: "",
  type: 0,
  isSystemDefault: false
};

const initialDefaultSetting: DefaultSetting = {
  savedMessage: 0,
  initialEmail: 0,
  downloadEngagementLetterEmail: 0,
  esignReminderEmail: 0,
  organizerReminderEmail: 0,
  sourceDocReminderEmail: 0
}

const ClientIntstructionsPage = () => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEmailAddModal, setShowEmailAddModal] = useState(false);
  const [showELAddModal, setShowELAddModal] = useState(false);
  const [selectedEmailMessage, setSelectedEmailMessage] = useState(0);
  const [selectedELMessage, setSelectedELMessage] = useState(0);
  const [selectedRemainderMessage, setSelectedRemainderMessage] = useState(0);
  const [emailMessages, setEmailMessages] = useState<EmailTemplateMessage[]>([initialModalData]);
  const [remainderEmails, setRemainderEmails] = useState<EmailTemplateMessage[]>([initialModalData]);
  const [elMessages, setELMessages] = useState<EmailTemplateMessage[]>([initialModalData]);
  const [messageType, setMessageType] = useState(MessageType.NONE);
  const [initialData, setInitialData] = useState(initialModalData);
  const [modalType, setModalType] = useState(ModalType.NONE);
  const [companyDefaultSetting, setDefaultSetting] = useState<DefaultSetting>(initialDefaultSetting);
  const [selectedReminderOption, setSelectedReminderOption] = useState<ReminderOptionsValue>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isDeletingEL, setIsDeletingEL] = useState(false);
  const [isDeletingEmailInstruction, setIsDeletingEmailInstruction] = useState(false);
  const [isDeletingRemainderMail, setIsDeletingRemainderMail] = useState(false);

  const dispatch = useAppDispatch();
  const state = useAppSelector(state => state.clientInstructions)
  const companySettings = useAppSelector(state => state.companySetting)
  const systemDefaults = state.emailTemplateArray && state.emailTemplateArray.filter((item: EmailTemplateMessage) => item.isSystemDefault === true);
  const nonSystemDefaults = state.emailTemplateArray && state.emailTemplateArray.filter((item: EmailTemplateMessage) => item.isSystemDefault === false);
  const emailTemplateData = [...systemDefaults, ...nonSystemDefaults];

  useEffect(() => {
    if (state.emailTemplateArray.length > 0) {
      setEmailMessages(emailTemplateData.filter((obj: EmailTemplateMessage) => obj.type === EmailTemplateType.InitialMail));
      setELMessages(emailTemplateData.filter((obj: EmailTemplateMessage) => obj.type === EmailTemplateType.DownloadEngagementLetter));
      setRemainderEmails(emailTemplateData.filter((obj: EmailTemplateMessage) => obj.type === state.selectedOption))
      setDefaultSetting(companySettings.defaultSettings)
      onChangeReminderOptions(state.selectedOption)
    }
  }, [state.emailTemplateArray, companySettings]);

  useEffect(() => {
    setSelectedEmailMessage(emailMessages.findIndex(e => e.id == companyDefaultSetting.initialEmail))
    setSelectedRemainderMessage(remainderEmails.findIndex(e => e.id == (state.selectedOption === ReminderOptionsValue.EL_REMINDER ? companyDefaultSetting.esignReminderEmail : state.selectedOption === ReminderOptionsValue.SOURCE_DOC_REMINDER ? companyDefaultSetting.sourceDocReminderEmail : companyDefaultSetting.organizerReminderEmail)))
    setSelectedELMessage(elMessages.findIndex(e => e.id == companyDefaultSetting.downloadEngagementLetterEmail));
  }, [companyDefaultSetting, elMessages, remainderEmails, emailMessages])

  useEffect(() => { setStatesOnChange() }, [])

  const onAddEmailMessages = (messageType: MessageType) => {
    setModalType(ModalType.ADD);
    setInitialData(initialModalData);
    setShowEmailAddModal(true);
    setMessageType(messageType)
  };

  const onAddELMessages = (messageType: MessageType) => {
    setModalType(ModalType.ADD);
    setInitialData(initialModalData);
    setShowELAddModal(true);
    setMessageType(messageType)
  };

  const onAddRemainderMessage = (messageType: MessageType) => {
    setModalType(ModalType.ADD);
    var data = { ...initialModalData };

    if (state.selectedOption === ReminderOptionsValue.EL_REMINDER)
      data.type = EmailTemplateType.EngagementLetterCompleted;
    else if (state.selectedOption === ReminderOptionsValue.TAX_ORG_REMINDER)
      data.type = EmailTemplateType.OrganizerReminder
    else
      data.type = EmailTemplateType.SourceDocumentReminderMail

    setInitialData(data);
    setShowAddModal(true);
    setMessageType(messageType)
  };

  const onEditEmail = () => {
    setModalType(ModalType.EDIT);
    setInitialData(emailMessages[selectedEmailMessage]);
    setShowEmailAddModal(true);
  };

  const onEditRemainder = () => {
    setModalType(ModalType.EDIT);
    setInitialData(remainderEmails[selectedRemainderMessage]);
    setShowAddModal(true);
  };

  const onEditEL = () => {
    setModalType(ModalType.EDIT);
    setInitialData(elMessages[selectedELMessage]);
    setShowELAddModal(true);
  };

  const onDeleteEmail = () => {
    if (
      !emailMessages[selectedEmailMessage] ||
      emailMessages[selectedEmailMessage].id == companySettings.defaultSettings.initialEmail
    ) {
      AppNotifier.Error(AddEditModalDialog.CLIENT_INSTRUCTION.DELETE_DEFAULT_INSTRUCTION_WARNING);
      return;
    }
    else {
      setIsDeletingEmailInstruction(true);
      setShowDeleteModal(true);
    }
  };

  const onDeleteReminderEmail = () => {
    if (
      !remainderEmails[selectedRemainderMessage] ||
      remainderEmails[selectedRemainderMessage].id == companySettings.defaultSettings.sourceDocReminderEmail ||
      remainderEmails[selectedRemainderMessage].id == companySettings.defaultSettings.esignReminderEmail ||
      remainderEmails[selectedRemainderMessage].id == companySettings.defaultSettings.organizerReminderEmail ||
      remainderEmails[selectedRemainderMessage].name === AddEditModalDialog.CLIENT_INSTRUCTION.SYSTEM_DEFAULT_TEXT) {
      AppNotifier.Error(AddEditModalDialog.CLIENT_INSTRUCTION.DELETE_DEFAULT_INSTRUCTION_WARNING);
      return;
    }
    else {
      setShowDeleteModal(true);
      setIsDeletingRemainderMail(true);
    }
  };
  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    setIsDeletingEL(false);
    setIsDeletingEmailInstruction(false);
    setIsDeletingRemainderMail(false);
  }

  const onDelete = () => {
    if (isDeletingEL) {
      dispatch(deleteEmailInstructions(elMessages[selectedELMessage], closeDeleteModal()));
    }
    else if (isDeletingEmailInstruction) {
      dispatch(deleteEmailInstructions(emailMessages[selectedEmailMessage], closeDeleteModal()))
    }
    else if (isDeletingRemainderMail) {
      dispatch(deleteEmailInstructions(remainderEmails[selectedRemainderMessage], closeDeleteModal()));
    }
  }
  const onDeleteEL = () => {
    if (
      !elMessages[selectedELMessage] ||
      elMessages[selectedELMessage].id == companySettings.defaultSettings.downloadEngagementLetterEmail
    ) {
      AppNotifier.Error(AddEditModalDialog.CLIENT_INSTRUCTION.DELETE_DEFAULT_INSTRUCTION_WARNING);
      return;
    }
    else {
      setIsDeletingEL(true);
      setShowDeleteModal(true);
    }
  };

  const onModalConfirm = (data: EmailTemplateMessage, setDefault: boolean) => {
    if (messageType === MessageType.CLIENT_INSTRUCTION) {
      if (modalType === ModalType.ADD) {
        data.type = EmailTemplateType.InitialMail;
        dispatch(addEmailInstructions(data, setDefault, companySettings))
      } else {
        dispatch(editEmailInstructions(data, setDefault, companySettings))
      }
    } else if (messageType === MessageType.EL) {
      if (modalType === ModalType.ADD) {
        data.type = EmailTemplateType.DownloadEngagementLetter;
        dispatch(addEmailInstructions(data, setDefault, companySettings))
      } else {
        dispatch(editEmailInstructions(data, setDefault, companySettings))
      }
    } else if (messageType === MessageType.REMAINDER_EMAILS) {
      if (modalType === ModalType.ADD) {
        dispatch(addRemainderEmail(data, setDefault, companySettings))
      } else {
        dispatch(editRemainderMail(data, setDefault, companySettings))
      }
    }
    setShowAddModal(false);
    setShowELAddModal(false);
    setShowEmailAddModal(false);
  };

  const onModalCancel = () => {
    setShowAddModal(false);
    setShowELAddModal(false);
    setShowEmailAddModal(false);
    setModalType(ModalType.NONE);
    setInitialData(initialModalData);
  };

  const onSelectMessage = (index: number, messageType: MessageType) => {
    if (messageType === MessageType.CLIENT_INSTRUCTION) {
      setSelectedEmailMessage(index);
      setMessageType(MessageType.CLIENT_INSTRUCTION);
    } else if (messageType === MessageType.EL) {
      setSelectedELMessage(index);
      setMessageType(MessageType.EL);
    } else if (messageType === MessageType.REMAINDER_EMAILS) {
      setSelectedRemainderMessage(index);
      setMessageType(MessageType.REMAINDER_EMAILS);
    }
  };

  const onChangeReminderOptions = (value: number) => {
    setSelectedRemainderMessage(0);
    dispatch(setSelectedOption(value === null ? ReminderOptionsValue.NONE : value))
    setRemainderEmails(emailTemplateData.filter((obj: EmailTemplateMessage) => obj.type === value))
  };

  const getSelectedReminderElement = () => {
    return (
      state.selectedOption != 0 && (
        <div className="message-item-container reminder-list-wrapper">
          <EmailMessageList
            messageType={MessageType.REMAINDER_EMAILS}
            onSelectMessage={onSelectMessage}
            data={remainderEmails}
            onAddMessage={onAddRemainderMessage}
            defaultSettings={companyDefaultSetting}
            selectedMessage={remainderEmails[selectedRemainderMessage]}
          />
          <EmailMessageBox selectedMessage={remainderEmails[selectedRemainderMessage]} onEdit={onEditRemainder} onDelete={onDeleteReminderEmail} />
        </div>
      )
    );
  };

  const setStatesOnChange = async () => {
    await Promise.all([
      dispatch(getEmailInstructions()),
      dispatch(getGatherCompanySettingsModel()),
      dispatch(setSelectedOption(ReminderOptionsValue.NONE))
    ]);
  };

  return (
    <div>
      {(<div className="settings-container client-settings">
        <LoaderWrapper keys={PageLoaderKeys.ClientInstructions}
          isScrollableView>
          <header>
            <h3>Client Instructions</h3>
          </header>
          <section>
            <h4>Initial Email</h4>
            <h6>Organizer initial email sent to client</h6>
            <div className="message-item-container">
              <MessageListBox
                messageType={MessageType.CLIENT_INSTRUCTION}
                defaultSettings={companyDefaultSetting}
                onSelectMessage={onSelectMessage}
                data={emailMessages}
                onAddMessage={onAddEmailMessages}
                selectedMessage={emailMessages[selectedEmailMessage] ?? initialData}
              />
              <MessageBox
                selectedMessage={emailMessages[selectedEmailMessage] ?? initialData}
                onEdit={onEditEmail}
                onDelete={onDeleteEmail} />
            </div>
          </section>
          <section>
            <h4>Reminders</h4>
            <h6>Reminders sent to client</h6>
            <div className="select-with-label">
              <p>Select Reminder</p>
              <DropdownComponent
                searchable={false}
                clearable={true}
                id="dd-3"
                onChange={onChangeReminderOptions}
                options={ReminderOptions}
                customPlaceHolder="Choose Reminder Type"
                selectedValue={state.selectedOption}
              />
            </div>

            {getSelectedReminderElement()}
          </section>
          <section className="border-bottom-0">
            <h4>Download Engagement Letter</h4>
            <div className="message-item-container">
              <MessageListBox
                messageType={MessageType.EL}
                defaultSettings={companyDefaultSetting}
                onSelectMessage={onSelectMessage}
                data={elMessages}
                onAddMessage={onAddELMessages}
                selectedMessage={elMessages[selectedELMessage] ?? initialData}
              />
              <MessageBox
                selectedMessage={elMessages[selectedELMessage] ?? initialData}
                onEdit={onEditEL}
                onDelete={onDeleteEL} />
            </div>
          </section>
          <AddEditModal
            modalType={modalType}
            intialData={initialData}
            show={showAddModal}
            onSubmit={onModalConfirm}
            onHide={onModalCancel}
            data={remainderEmails}
            isDefaultMessage={(
              companySettings.defaultSettings.esignReminderEmail === initialData.id ||
              companySettings.defaultSettings.organizerReminderEmail === initialData.id ||
              companySettings.defaultSettings.sourceDocReminderEmail === initialData.id)}
          />
          <AddEditModal
            modalType={modalType}
            intialData={initialData}
            show={showELAddModal}
            onSubmit={onModalConfirm}
            onHide={onModalCancel}
            data={elMessages}
            isDefaultMessage={(
              companySettings.defaultSettings.downloadEngagementLetterEmail === initialData.id)}
          />
          <AddEditModal
            modalType={modalType}
            intialData={initialData}
            show={showEmailAddModal}
            onSubmit={onModalConfirm}
            onHide={onModalCancel}
            data={emailMessages}
            isDefaultMessage={(
              companySettings.defaultSettings.initialEmail === initialData.id)}
          />
          <ConfirmDialog
            show={showDeleteModal}
            title={DeleteInstructionModal.TITLE}
            message={DeleteInstructionModal.BODY}
            onSubmit={onDelete}
            onHide={() => {
              setShowDeleteModal(false);
            }} />
        </LoaderWrapper>
      </div>)}
    </div>
  );
};
export default ClientIntstructionsPage;