import * as React from "react";
import Choice from "./Choice";
import {
  IHandleChoiceIdentifierPayload,
  IHandleChoiceTextOnChangePayload,
  IItemIdentifier,
  ISectionItems
} from "store/models/CustomQuestionModel";
import { useAppDispatch, useAppSelector } from "common/hooks/redux-hooks";
import { HandleAddChoice, HandleChoiceTextOnChange, HandleRemoveChoice } from "store/slices/form-builder";
import { getMaxOptionsDisbaledTooltip } from "helper/HelperFunctions";
import { PlusCircleIcon } from "assets/svg/SVGIconsCollection";
import { AutomationLocators } from "helper/AutomationLocators";
import { AppState } from "store";

interface IMultiChoiceQuestionProps {
  sectionItem: ISectionItems;
  sectionItemIndex: number;
  sectionIndex: number;
}
const MultiChoiceQuestion: React.FC<IMultiChoiceQuestionProps> = ({ sectionItem, sectionIndex, sectionItemIndex }) => {
  const customQuestionSettings = useAppSelector((state: AppState) => state.formBuilderReducer.customQuestionSettings);
  const dispatch = useAppDispatch();
  const onChangeHandler = (text: string, choiceIndex: number) => {
    const payload: IHandleChoiceTextOnChangePayload = {
      choiceText: text,
      sectionIndex: sectionIndex,
      sectionItemIndex: sectionItemIndex,
      choiceIndex: choiceIndex
    };
    dispatch(HandleChoiceTextOnChange(payload));
  };

  const onAddChoiceHandler = () => {
    if (sectionItem.choices.length < customQuestionSettings.choiceLimit) {
      const payload: IItemIdentifier = {
        sectionIndex: sectionIndex,
        sectionItemIndex: sectionItemIndex
      };
      dispatch(HandleAddChoice(payload));
    }
  };
  const onRemoveChoiceHandler = (choiceIndex: number) => {
    if (sectionItem.choices.length > 1) {
      const payload: IHandleChoiceIdentifierPayload = {
        sectionIndex: sectionIndex,
        sectionItemIndex: sectionItemIndex,
        choiceIndex: choiceIndex
      };
      dispatch(HandleRemoveChoice(payload));
    }
  };
  return (
    <div className="question-type-wrapper">
      <div className="options-wrapper">
        {sectionItem.choices &&
          sectionItem.choices.length > 0 &&
          sectionItem.choices.map((choice, choiceIndex) => (
            <Choice
              choiceIndex={choiceIndex}
              choice={choice}
              onChangeHandler={onChangeHandler}
              onRemoveChoiceHandler={onRemoveChoiceHandler}
              sectionItem={sectionItem}
            />
          ))}
        <div
          data-test-auto={AutomationLocators.Settings.CustomQuestions.Section.questionType.multiChoice.addChoice}
          title={
            sectionItem.choices.length >= customQuestionSettings.choiceLimit
              ? getMaxOptionsDisbaledTooltip(customQuestionSettings.choiceLimit)
              : ""
          }
          className={`add-options-button ${sectionItem.choices.length >= customQuestionSettings.choiceLimit ? "isDisabled" : ""}`}
          onClick={onAddChoiceHandler}
        >
          <PlusCircleIcon />
          Add option
        </div>
      </div>
    </div>
  );
};

export default MultiChoiceQuestion;
