import { FC } from 'react'
import { ReactComponent as SSOLogoWithTitle } from 'assets/icons/logo.svg';
import './styles/LoadingScreen.scss';

type LoaderProps = {
    showLogo?: boolean;
}
const LoadingScreen: FC<LoaderProps> = ({showLogo}) => (
    <div className='loading-container'>
        {showLogo ? <SSOLogoWithTitle width={350} /> : <></>}
        <div className='loading-text' data-title='dot-spin-loader'>
            <div className='dot-spin-loader'></div>
        </div>
    </div>
)
LoadingScreen.displayName = 'LoadingView';
export default LoadingScreen;
