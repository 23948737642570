import * as React from "react";
import { Form } from "react-bootstrap";

import { EyeLashIcon, StackIcon } from "assets/svg/SVGIconsCollection";
import {
  getQuestionsLimitErrorMessage,
  getTotalQuestions,
  removeUnwantedWhitespacesFromText,
  updateSectionAndQuestionnaireOrder
} from "helper/HelperFunctions";
import { ChangeSecurityConstants, FormBuilderConstants, FormPreviewConstants, PageLoaderKeys, TemplateModalConstants } from "helper/Constants";
import { CloseIcon } from "assets/icons/IconCollection";
import {
  IFormData,
  ISectionItems,
  ISections,
  ITitleOnChangeHandlerPayload,
  intialSectionData
} from "store/models/CustomQuestionModel";
import { AutomationLocators } from "helper/AutomationLocators";
import Section from "./parts/section/Section";
import { SectionModal } from "common/components/modal/SectionModal";
import SectionMergeModal from "common/components/modal/SectionMergeModal";
import SectionMoveModal from "common/components/modal/SectionMoveModal";
import { useAppDispatch, useAppSelector } from "common/hooks/redux-hooks";
import {
  DeleteSectionData,
  HandleDescriptionOnChange,
  HandleSectionReordering,
  HandleTitleOnChange,
  MergeSectionHandler,
  RenameSectionModalData,
  SaveSectionModalData,
  SetIsInvalidFlag
} from "store/slices/form-builder";
import { AppState } from "store";
import "./FormBuilder.scss";
import "./Button.scss";
import { AppNotifier } from "common/components/toast/Toast";
import { CreateQuestionerForm, UpdateQuestionerForm } from "store/services/custom-questions-service";
import { QuestionType } from "common/model/custom-questions";
import { CustomQuestionConstants } from "pages/Constants";
import { Prompt } from "react-router-dom";
import LoaderWrapper from "common/pages/LoaderWrapper";

type FormBuilderProps = {
  isCreateTemplateForm: boolean;
  isRedirectedFromPreviewTab: boolean;
  redirectToTemplateListing: () => void;
  onClickPreview: () => void;
  resetIsRedirectedFromPreviewTab: () => void;
};

const FormBuilder: React.FC<FormBuilderProps> = ({
  isCreateTemplateForm,
  isRedirectedFromPreviewTab,
  redirectToTemplateListing,
  onClickPreview,
  resetIsRedirectedFromPreviewTab
}) => {
  const formData = useAppSelector((state: AppState) => state.formBuilderReducer.formData);
  const customQuestionSettings = useAppSelector((state: AppState) => state.formBuilderReducer.customQuestionSettings);

  const [localFormData, setLocalFormData] = React.useState(formData);
  const [showAddSectionModal, setShowAddSectionModal] = React.useState(false);
  const [showMergeSectionModal, setShowMergeSectionModal] = React.useState(false);
  const [showMoveSectionModal, setShowMoveSectionModal] = React.useState(false);
  const [selectedSection, setSelectedSection] = React.useState<ISections>(intialSectionData);
  const [activeSection, setActiveSection] = React.useState(0);
  const [validated, setValidated] = React.useState<boolean>(false);
  const [showPrompt, setShowPrompt] = React.useState<boolean>(false);

  const ref = React.useRef<HTMLFormElement>(null);

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    localStorage.setItem("updatedFormData", "");
    if (!isRedirectedFromPreviewTab) {
      localStorage.setItem("formData", JSON.stringify(formData));
    }
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);

  React.useEffect(() => {
    localStorage.setItem("updatedFormData", JSON.stringify(formData));
    if (localStorage.getItem("updatedFormData") !== localStorage.getItem("formData")) {
      setShowPrompt(true);
    } else {
      setShowPrompt(false);
    }
    if (localStorage.getItem("updatedFormData")?.includes("chosen")) {
      let hasChosenIncludedInFormData = localStorage.getItem("formData")?.includes("chosen");
      if (!hasChosenIncludedInFormData) {
        localStorage.setItem("formData", JSON.stringify(formData));
      }
    }
    setLocalFormData(formData);
  }, [formData]);

  const alertUser = (e) => {
    if (localStorage.getItem("updatedFormData") !== localStorage.getItem("formData")) {
      e.preventDefault();
      e.returnValue = "";
      return "";
    }
  };

  const onConfirmClick = (sectionName: string, isRenameModal: boolean) => {
    setShowAddSectionModal(false);
    if (isRenameModal) {
      dispatch(RenameSectionModalData({ sectionName, sectionIndex: activeSection }));
    } else if (isQuestionLimitValid()) {
      if (selectedSection.sectionName === intialSectionData.sectionName) {
        dispatch(SaveSectionModalData(sectionName));
      }
    }
    setSelectedSection(intialSectionData);
  };
  const onMergeClick = (sourceSection: ISections, targetSection: ISections[]) => {
    const targetSectionItems: ISectionItems[] = [];
    const indexsToRemove: number[] = [];
    const targetSectionNames: number[] = [];
    targetSection.forEach((section: ISections) => {
      targetSectionNames.push(section.sectionId);
      section.sectionItems?.forEach((item: ISectionItems) => {
        targetSectionItems.push(item);
      });
    });
    const mergedSectionItems: ISectionItems[] = [...sourceSection.sectionItems, ...targetSectionItems];
    const sectionToMerge: ISections = { ...sourceSection };
    sectionToMerge.sectionItems = mergedSectionItems;
    let indexToMerge: number = -1;
    const data: IFormData = { ...localFormData };
    const sections: ISections[] = [...data.sections];
    sections?.forEach((section: ISections, index: number) => {
      if (section.sectionId === sourceSection.sectionId) {
        indexToMerge = index;
      } else {
        targetSectionNames?.forEach((sectionId: number) => {
          if (section.sectionId === sectionId) {
            indexsToRemove.push(index);
          }
        });
      }
    });
    sections[indexToMerge] = sectionToMerge;
    let count: number = 0;
    indexsToRemove.forEach((index: number) => {
      sections.splice(index - count, 1);
      count++;
    });
    data.sections = sections;
    dispatch(MergeSectionHandler(data));
    setShowMergeSectionModal(false);
    setSelectedSection(intialSectionData);
  };

  const onModalClose = () => {
    setShowAddSectionModal(false);
    setShowMergeSectionModal(false);
    setShowMoveSectionModal(false);
    setSelectedSection(intialSectionData);
  };
  const handleRenameSection = () => {
    setShowAddSectionModal(true);
  };
  const handleMergeSection = () => {
    setShowMergeSectionModal(true);
  };
  const handleMoveSection = () => {
    setShowMoveSectionModal(true);
  };
  const handleDeleteSection = (sectionIndex: number) => {
    dispatch(DeleteSectionData(sectionIndex));
  };
  const handleReorderSections = (sections) => {
    sections = sections.map((section, index) => {
      section.order = index + 1;
      if (section.id) {
        delete section.id;
      }
      return section;
    });
    let data = { ...localFormData, sections: sections };
    dispatch(HandleSectionReordering(data));
    onModalClose(); //TODO :Handle other cases as well
    if (!isCreateTemplateForm) {
      // dispatch(
      //   EditTemplate(data, () => {
      //     setShowMoveSectionModal(false);
      //     setLocalFormData(data);
      //   })
      // );
    } else {
      onModalClose();
      AppNotifier.Success(TemplateModalConstants.EditSuccessMessage);
    }
  };

  const isQuestionLimitValid = () => {
    if (getTotalQuestions(localFormData) + 1 > customQuestionSettings.questionLimit) {
      AppNotifier.Error(getQuestionsLimitErrorMessage(customQuestionSettings.questionLimit, "add section"));
      return false;
    }
    return true;
  };
  const isFormDataValid = () => {
    let isValid = true;
    let hasDuplicateChoice = false;
    const form = ref.current;
    if (!!form && form.checkValidity()) {
      setValidated(false);
    } else {
      isValid = false;
      setValidated(true);
    }
    localFormData.sections.forEach((section) => {
      section.sectionItems.forEach((sectionItem) => {
        if (sectionItem.questionTitle === "") {
          isValid = false;
          sectionItem = { ...sectionItem, isValid: false };
        }
      });
    });
    if (formData.title === "" || formData.description === "") {
      isValid = false;
    }
    localFormData.sections.forEach((section) => {
      section.sectionItems.forEach((sectionItem) => {
        let choiceTexts: string[] = [];
        sectionItem.choices.forEach((choice) => {
          if (choice.text === "") {
            isValid = false;
            choice = { ...choice, isValid: false };
          } else {
            let choiceText = removeUnwantedWhitespacesFromText(choice.text.toLowerCase());
            if (choiceTexts.includes(choiceText)) {
              hasDuplicateChoice = true;
              choice = { ...choice, isDuplicate: true };
            }
            choiceTexts.push(choiceText);
          }
        });
      });
    });
    localFormData.sections.forEach((section) => {
      section.sectionItems.forEach((sectionItem) => {
        sectionItem.choices.forEach((choice) => {
          if (choice.subQuestions && choice.subQuestions.length > 0) {
            choice.subQuestions.forEach((subQuestion) => {
              if (subQuestion.questionTitle === "") {
                isValid = false;
              }
            });
          }
        });
      });
    });
    localFormData.sections.forEach((section) => {
      section.sectionItems.forEach((sectionItem) => {
        sectionItem.choices.forEach((choice) => {
          if (choice.subQuestions && choice.subQuestions.length > 0) {
            choice.subQuestions.forEach((subQuestion) => {
              if (subQuestion.questionTypeId === QuestionType.MULTICHOICE) {
                let choiceTexts: string[] = [];
                subQuestion.choices.forEach((subQuestionChoice) => {
                  if (subQuestionChoice.text === "") {
                    isValid = false;
                  } else {
                    let subQuestionChoiceText = removeUnwantedWhitespacesFromText(subQuestionChoice.text.toLowerCase());
                    if (choiceTexts.includes(subQuestionChoiceText)) {
                      hasDuplicateChoice = true;
                      subQuestionChoice = { ...subQuestionChoice, isDuplicate: true };
                    }
                    choiceTexts.push(subQuestionChoiceText);
                  }
                });
              }
            });
          }
        });
      });
    });

    if (!isValid || hasDuplicateChoice) {
      dispatch(SetIsInvalidFlag());
      AppNotifier.Error(FormBuilderConstants.FORM_VALIDATION_ERROR);
    }
    return isValid && !hasDuplicateChoice;
  };
  const handleClose = () => {
    if (localStorage.getItem("updatedFormData") !== localStorage.getItem("formData")) {
      if (window.confirm(CustomQuestionConstants.PromptMessage) == true) {
        resetIsRedirectedFromPreviewTab();
        redirectToTemplateListing();
      }
    } else {
      redirectToTemplateListing();
    }
  };
  const addSectionHandler = () => {
    if (isQuestionLimitValid()) {
      setShowAddSectionModal(true);
    }
  };

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value.trimStart();
    const payload: ITitleOnChangeHandlerPayload = {
      value: input
    };
    if (e.target.name === "title") {
      dispatch(HandleTitleOnChange(payload));
    } else {
      dispatch(HandleDescriptionOnChange(payload));
    }
  };
  const SaveTemplate = (data: any) => {
    dispatch(CreateQuestionerForm(data, redirectToTemplateListing));
  };
  const EditTemplate = (data: any) => {
    dispatch(UpdateQuestionerForm(data, redirectToTemplateListing));
  };
  const handleFormSave = () => {
    if (getTotalQuestions(localFormData) > 0)
      if (isFormDataValid()) {
        const updateData = updateSectionAndQuestionnaireOrder(localFormData);
        resetIsRedirectedFromPreviewTab();
        isCreateTemplateForm ? SaveTemplate(updateData) : EditTemplate(updateData);
      }
  };
  return (
    <div className="form-builder-container">
      <LoaderWrapper keys={PageLoaderKeys.CustomQuestions} isScrollableView>
        <Prompt when={showPrompt} message={CustomQuestionConstants.PromptMessage} />
        <header>
          <div className="header-top-container">
            <div className="closeIcon" onClick={handleClose}>
              <CloseIcon />
            </div>
          </div>

          <div className="header-bottom-container">
            <span title={formData.template?.name} className="ellipsis">
              {formData.template.name}
            </span>
            <div>
              <button
                className="preview-button cq-btn-with-icon"
                data-test-auto={AutomationLocators.Settings.CustomQuestions.Preview}
                title={getTotalQuestions(formData) === 0 ? FormBuilderConstants.PREVIEW_DISABLED_TOOLTIP : ""}
                disabled={getTotalQuestions(formData) === 0}
                onClick={onClickPreview}
              >
                <EyeLashIcon />
                Preview
              </button>

              <button
                data-test-auto={AutomationLocators.Settings.CustomQuestions.Save}
                title={getTotalQuestions(formData) === 0 ? FormBuilderConstants.SAVE_DISABLED_TOOLTIP : ""}
                disabled={getTotalQuestions(formData) === 0}
                onClick={handleFormSave}
                className="cq-btn-primary"
              >
                Save
              </button>
            </div>
          </div>
        </header>

        <main>
          <Form
            ref={ref}
            noValidate
            validated={validated}
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <Form.Group>
              <div className="questionnaire-header-container">
                <div className="inner-container">
                  <div className="field-container">
                    <Form.Control
                      className={`title ellipsis ${formData.isTitleValid === false ? "invalid-input" : ""}`}
                      type="text"
                      as="input"
                      name="title"
                      title={formData.title}
                      value={formData.title}
                      required
                      placeholder={TemplateModalConstants.UntitledQuestionnaire}
                      onChange={onChangeHandler}
                      maxLength={TemplateModalConstants.NameMaxLength}
                    />
                    {formData.isTitleValid === false && <p className="error-text">{FormPreviewConstants.RequiredValidation}</p>}
                  </div>
                  <div className="field-container description-container">
                    <Form.Control
                      className={`description ellipsis ${formData.isDescriptionValid === false ? "invalid-input" : ""}`}
                      type="text"
                      as="input"
                      name="description"
                      title={formData.description}
                      value={formData.description}
                      required
                      placeholder={TemplateModalConstants.QuestionnaireDescription}
                      onChange={onChangeHandler}
                      maxLength={TemplateModalConstants.DesciptionMaxLength}
                    />
                    {formData.isDescriptionValid === false && (
                      <p className="error-text">{FormPreviewConstants.RequiredValidation}</p>
                    )}
                  </div>
                </div>

                <p>
                  <i>* </i>Required
                </p>
              </div>
            </Form.Group>
          </Form>
          {localFormData &&
            localFormData.sections.length > 0 &&
            localFormData.sections.map((section, sectionIndex) => (
              <Section
                key={sectionIndex}
                section={section}
                sectionIndex={sectionIndex}
                handleRenameSection={handleRenameSection}
                handleMergeSection={handleMergeSection}
                handleMoveSection={handleMoveSection}
                handleDeleteSection={handleDeleteSection}
                setSelectedSection={setSelectedSection}
                setActiveSection={setActiveSection}
              />
            ))}
          <footer className="form-footer">
            <button className="cq-btn-with-icon cq-btn-blue cq-btn-primary" onClick={addSectionHandler}>
              <StackIcon />
              {TemplateModalConstants.AddNewSection}
            </button>
          </footer>
        </main>

        {showAddSectionModal && (
          <SectionModal
            show={showAddSectionModal}
            onConfirm={onConfirmClick}
            onClose={onModalClose}
            selectedSection={selectedSection}
          />
        )}
        {showMergeSectionModal && (
          <SectionMergeModal
            show={showMergeSectionModal}
            onConfirm={onMergeClick}
            onClose={onModalClose}
            sourceSection={selectedSection}
            sections={localFormData.sections}
          />
        )}
        {showMoveSectionModal && (
          <SectionMoveModal
            show={showMoveSectionModal}
            onConfirm={handleReorderSections}
            onClose={onModalClose}
            sections={localFormData.sections}
          />
        )}
      </LoaderWrapper>
    </div>
  );
};

export default FormBuilder;
