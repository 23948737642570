export enum ProductTypes {
    General = "General",
    SafeSend_Returns = "SafeSend Returns",
    SafeSend_Signatures = "SafeSend Signatures",
    SafeSend_Organizers = "SafeSend Organizers",
    SafeSend_Gather = "SafeSend Gather",
}

export enum LogoutCause {
    None = 0,
    UserPermissionChange = 1,
    UserDelete = 2,
    ProductTierChange = 3,
    CompanyDisable = 4,
}
export enum ProcessSteps {
    ClientInfo = "Client Information",
    Organizer = "Organizer",
    ESignDocuments = "E-Sign Documents",
    CustomQuestions = "Custom Questions",
    RequestDocuments = "Request Documents",
    DeliveryOptions = "Delivery",
    Errors = "Errors",

}
export enum TaxSoftwares {
    CCH,
    UT,
    GS,
    LT = 4
}
export enum DocumentTypes {  
    None = 0,  
    ConsentForm = 1,    
    EngagementLetter = 2,
    Others = 3,
    EngagementLetterBookmark = 4,
    Deleted = 5
}

export enum ProcessStepStatus {
    Default = 0,
    Started = 1,
    Skipped = 2,
    Completed = 3,
    Disabled = 4
}

export enum EmailTemplateType {
    None = 0,
    InitialMail = 1, // Gather and EL combined
    ReminderMail = 2,
    OrganizerCompleted = 3,
    EngagementLetterCompleted = 4,
    InitialEmailGather = 5,
    InitialEmailEngagementLetter = 6,
    OrganizerReminder = 7,
    DownloadEngagementLetter = 8,
    DownloadOrganizer = 9,
    BatchRecognized = 10,
    BatchExportExcel = 11,
    DownloadAllNotificationMail = 12,
    GatherReportProblem = 13,
    GatherProformaUndeliverableMail = 14,
    GatherBatchUndeliverableMail = 15,
    OrganizerReminderMail = 16,
    SourceDocumentReminderMail = 17,
    SpouseReviewMail = 18,
    SecondSignerEngagementLetterMail = 19,
    OrganizerCompletedCpa = 21,
    NotifyReceivedSourceDocuments = 22,
    NotifySourceDocumentMarkedCompletedToCpa = 23,
    NotifySourceDocumentUploadReopened = 24,
    GatherSourceDocumentsUpdated = 25,
    NotifyReopenedGather = 26,
    GatherSourceDocumentsUpdatedTPandSpouse = 27,
    OTPEmail = 28,
    GatherLocked = 29,
    ReopenedGatherByFirm = 30,
    EsignReminder = 31
}

export enum NotificationType {
    None = 0,
    DeliverySuccess = 1,
    DeliveryFailed = 2,
    RecognizerCompleted = 3,
    RecognizerFailed = 4,
    Esigned = 5,
    PartiallySigned = 6,
    OrganizerCompleted = 7,
    OrganizerPartiallyCompleted = 8,
    PartiallyReviewed = 9,
    Reviewed = 10,
    QuestionnaireCompleted = 11,
    SourceDocumentUploaded = 12,
    SourceDocumentCompleted = 13,
    SourceDocumentDownloaded = 14,
    ClosedByFirm = 15,
    GatherDeleted = 16,
    BatchProcessingSuccess = 17,
    BatchProcessingPartiallySuccess = 18,
    BatchProcessingFailed = 19,
    BulkDownloadSuccess = 20,
    BulkDownloadFailed = 21,
    DrlExtractionCompleted = 31,
    DrlExtractionFailed = 32,
    DRLNotApplicableStatusUpdated = 34
}
