import {
  ControlRole,
  ControlType,
  DataType,
  FormGroupStatus,
  FormType,
  GroupType,
  OrganizerClientType
} from "common/enum/GatherEnums";
import { QuestionType } from "./custom-questions";

export interface IGatherFilter {
  name: string;
  sort: Sort;
  fields: { [id: string]: string };
  searchText: string;
  isDefaultFilter: boolean;
  filterType: GatherFilterType;
}
export enum GatherFilterType {
  None = 0,
  InProcess = 1,
  Delivered = 2,
  Archived = 3
}
export enum FilterActionType {
  SET_DEFAULT = 0,
  REMOVE_DEFAULT,
  UPDATE_CURRENT_FILTER
}

export interface Sort {
  column: string;
  direction: SortDirections;
}
export enum SignatureStatus {
  None = 0,
  Esigned = 1,
  Reviewed = 2,
  NA = 3
}
export enum ReviewStatus {
  None = 0,
  SentForReview = 1,
  PendingReview = 2,
  Reviewed = 3,
  Ignored = 4
}

export enum SortDirections {
  None = 0,
  Ascending = 1,
  Descending = 2
}

export enum OrganizerPageOptions {
  Organizer = 1,
  BatchOrganizer
}

export const GatherPageOptionsList = [
  { label: "One-off Gathers", value: 1 },
  { label: "Batch Gathers", value: 2 }
];

export enum EngagementLetterStatus {
  NA = 25,
  AwaitingSignature = 21,
  PartiallySigned = 22,
  ESigned = 23,
  EngagementLetterDownloaded = 24,
  Reviewed = 26,
  ManuallySigned = 28,
  PartiallyReviewed = 29
}

export const EngagementLetterStatusList = {
  NA: "N/A",
  AwaitingSignature: "Awaiting Signature",
  PartiallySigned: "Partially Signed",
  ESigned: "E-Signed",
  EngagementLetterDownloaded: "Downloaded",
  Reviewed: "Reviewed",
  ManuallySigned: "Manually Signed",
  PartiallyReviewed: "Partially Reviewed"
};

export const GetEngagementLetterStatus = (key: number): string => {
  const statusMap: { [key: number]: string } = {
    [EngagementLetterStatus.AwaitingSignature]: EngagementLetterStatusList.AwaitingSignature,
    [EngagementLetterStatus.PartiallySigned]: EngagementLetterStatusList.PartiallySigned,
    [EngagementLetterStatus.ESigned]: EngagementLetterStatusList.ESigned,
    [EngagementLetterStatus.EngagementLetterDownloaded]: EngagementLetterStatusList.EngagementLetterDownloaded,
    [EngagementLetterStatus.NA]: EngagementLetterStatusList.NA,
    [EngagementLetterStatus.Reviewed]: EngagementLetterStatusList.Reviewed,
    [EngagementLetterStatus.ManuallySigned]: EngagementLetterStatusList.ManuallySigned,
    [EngagementLetterStatus.PartiallyReviewed]: EngagementLetterStatusList.PartiallyReviewed
  };

  return statusMap[key] || EngagementLetterStatusList.NA;
}

export enum SourceDocumentStatus {
  None = 0,
  AwaitingUpload = 1,
  Uploaded = 2,
  Downloaded = 3,
  Completed = 4,
  NA = 5
}

export const SourceDocumentStatusList = {
  None: "None",
  AwaitingUpload: "Awaiting Upload",
  Uploaded: "Uploaded",
  Downloaded: "Downloaded",
  Completed: "Finished",
  NA: "N/A"
};

export const GetSourceDocumentStatus = (key: number): string => {
  const statusMap: { [key: number]: string } = {
    [SourceDocumentStatus.None]: SourceDocumentStatusList.None,
    [SourceDocumentStatus.AwaitingUpload]: SourceDocumentStatusList.AwaitingUpload,
    [SourceDocumentStatus.Uploaded]: SourceDocumentStatusList.Uploaded,
    [SourceDocumentStatus.Downloaded]: SourceDocumentStatusList.Downloaded,
    [SourceDocumentStatus.Completed]: SourceDocumentStatusList.Completed,
    [SourceDocumentStatus.NA]: SourceDocumentStatusList.NA,
  };
  return statusMap[key] || SourceDocumentStatusList.None;
}

export enum OrganizerStatus {
  None = 0,
  Delivered = 1,
  PartiallyCompleted = 2,
  Completed = 3,
  OrganizerDownloaded = 4,
  Dropped = 5,
  ManuallyCompleted = 6,
  ClosedByFirm = 7,
  QuestionnaireCompleted = 8,
  NA = 25,
}

export const OrganizerStatusList = {
  None: "None",
  Delivered: "Delivered",
  PartiallyCompleted: "Partially Completed",
  Completed: "Completed",
  OrganizerDownloaded: "Downloaded",
  Dropped: "Dropped",
  ManuallyCompleted: "Manually Completed",
  ClosedByFirm: "Closed By Firm",
  QuestionnaireCompleted: "Questionnaire Completed",
  NA: "N/A"
};

export const GetOrganizerStatus = (key: number): string => {
  const statusMap: { [key: number]: string } = {
    [OrganizerStatus.None]: OrganizerStatusList.None,
    [OrganizerStatus.Delivered]: OrganizerStatusList.Delivered,
    [OrganizerStatus.PartiallyCompleted]: OrganizerStatusList.PartiallyCompleted,
    [OrganizerStatus.Completed]: OrganizerStatusList.Completed,
    [OrganizerStatus.OrganizerDownloaded]: OrganizerStatusList.OrganizerDownloaded,
    [OrganizerStatus.Dropped]: OrganizerStatusList.Dropped,
    [OrganizerStatus.ManuallyCompleted]: OrganizerStatusList.ManuallyCompleted,
    [OrganizerStatus.ClosedByFirm]: OrganizerStatusList.ClosedByFirm,
    [OrganizerStatus.QuestionnaireCompleted]: OrganizerStatusList.QuestionnaireCompleted,
    [OrganizerStatus.NA]: OrganizerStatusList.NA
  };
  return statusMap[key] || OrganizerStatusList.None;
}

export interface FormGroup {
  id: number;
  gatherId: number;
  name: string;
  type: GroupType;
  status: FormGroupStatus;
  uploadedBy?: number;
  uploadedOn?: Date;
  forms: Form[];
}

export interface Form {
  formId: number;
  formGuid: string;
  formGroupId: number;
  formType: FormType;
  pageNo: number;
  formName: string;
  bookmark: string;
  rotationDegree?: number;
  individualFilePageNo?: number;
  enableSignature: boolean;
  scheduleTypes?: number[];
}
export interface OrganizerForm extends Form {
  enabledSourceDocument: boolean;
  formData: OrganizerFormData;
}

export interface OrganizerFormData {
  controlList: Control[];
}
export interface EngagementLetterForm extends Form {
  formData: EngagementLetterFormData;
}

export interface EngagementLetterFormData {
  controlList: Control[];
}

export interface BoundingRectangle {
  left: number;
  top: number;
  width: number;
  height: number;
}

export interface Control {
  id: string;
  signerId: number;
  name: string;
  controlType: ControlType;
  dataType: DataType;
  tooltip: string;
  readonly: boolean;
  required: boolean;
  boundingRectangle: BoundingRectangle;
  customData: string;
  controlRole: ControlRole;
}
export const initialControl: Control = {
  controlType: ControlType.None,
  id: "",
  name: "",
  signerId: 0,
  dataType: DataType.None,
  tooltip: "",
  readonly: false,
  required: false,
  boundingRectangle: {
    left: 0,
    top: 0,
    width: 0,
    height: 0
  } as BoundingRectangle,
  customData: "",
  controlRole: ControlRole.None
};
export interface OrganizerForm extends Form {
  enabledSourceDocument: boolean;
  formData: OrganizerFormData;
}

export interface OrganizerFormData {
  controlList: Control[];
}

export interface BookmarkPage {
  pageNo: number;
  pageTitle: string;
}

export interface Bookmarks {
  heading: string;
  pages: BookmarkPage[];
}

export interface OrganizerBookmarks {
  heading: string;
  pages: OrganizerBookmarkPage[];
}

export interface OrganizerBookmarkPage extends BookmarkPage {
  enabledSourceDocument: boolean;
  enableSignature: boolean;
  scheduleTypes: number[];
}
export interface SignatureControlsDictionary {
  [pageNo: number]: Control[];
}

export interface PdfPageSignatureControl {
  signatureControls: Control[];
  signerId: number;
  focusGroup: UserGroup;
}
export enum UserGroup {
  None,
  Sender,
  Reciever
}
export interface UserModel {
  userId: number;
  firstName: string;
}

export interface TextboxData {
  value: string;
}

export interface Signature extends Control {
  controlData: SignatureData;
}

export interface SignatureData {
  name: string;
  fileGuid: string;
}

export interface OrganizerDocumentSignatureSettings {
  signatureStampUser: number;
}

export interface DateControl extends Control {
  placeholder: string;
  textPlacement: TextPlacement;
  controlData: DateControlData;
}

export interface DateControlData {
  value: Date;
}

export enum TextPlacement {
  Left = 1,
  Right = 2,
  Center = 3
}

export interface RadioButton extends Control {
  controlData: RadioButtonControlData;
}

export interface RadioButtonControlData {
  selected: boolean;
}

export interface RadioButtonGroup extends Control {
  controlData: RadioButtonGroupControlData;
}

export interface RadioButtonGroupControlData {
  items: RadioButton[];
  selectedId: string;
}

export interface TextboxData {
  value: string;
}

export interface Textbox extends Control {
  maxLength: number;
  placeholder: string;
  textPlacement: TextPlacement;
  controlData: TextboxData;
}
export interface QuestionControl extends Control {
  questionNo: string;
  textbox: Textbox;
  radioButtonGroup: RadioButtonGroup;
  sectionId: string;
  subQuestions: SubQuestionMapping[];
  parentQuestionId: string;
  type: QuestionType;
  required: boolean;
  inputTextbox: Textbox;
}

export interface SectionControl extends Control {
  section: Textbox;
}

export interface SubQuestionMapping {
  subQuestionIds: string[];
  condition: boolean;
}
export interface SignatureCheckBoxControl extends Control {
  page: number;
  disabled: boolean;
  items: ChoosableControlData[];
}

export interface SignatureRadioButtonControl extends Control {
  page: number;
  disabled: boolean;
  items: ChoosableControlData[];
}

export class ChoosableControlData {
  id: string = "";
  name: string = "";
  top: number = 0;
  left: number = 0;
  width: number = 0;
  height: number = 0;
  value: any;

  public static create(id: string, name: string, top: number, left: number, width: number, height: number, value: any) {
    var data = new ChoosableControlData();
    data.id = id;
    data.name = name;
    data.top = top;
    data.left = left;
    data.width = width;
    data.height = height;
    data.value = value;

    return data;
  }

  public static createNullObject() {
    var data = new ChoosableControlData();
    data.id = "";
    data.name = "";
    data.top = 0;
    data.left = 0;
    data.width = 0;
    data.height = 0;
    data.value = { checked: false, value: "" };
    return data;
  }
}

export interface IEROSigner {
  value: number;
  label: string;
  isEnableSignatureDelegation: boolean;
  eroImage: string;
  disabled?: boolean;
}

export interface GeneralRemainder {
  enabled: boolean;
  reminderDays: number;
  isReadOnly: boolean;
}

export interface OrganizerClient {
  clientGuid: string;
  id: number;
  name: string;
  ssn: string;
  emailAddress: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  dob: Date;
  mobileNumber: string;
  countryCode: string;
  clientType: OrganizerClientType;
  isDeceased: boolean;
  signingOrder: number;
  signatureStatus: SignatureStatus;
  signedOn: Date | undefined;
  deliveredOn: Date | undefined;
  accessLink: string;
  documentSigner: boolean;
  organizerStatus: number;
  locationId: number;
  ero: number;
}
export const initialOrganizerClient: OrganizerClient = {
  clientGuid: "",
  id: 0,
  name: "",
  ssn: "",
  emailAddress: "",
  address: "",
  city: "",
  state: "",
  zip: "",
  dob: new Date(),
  mobileNumber: "",
  countryCode: "",
  clientType: OrganizerClientType.Taxpayer,
  isDeceased: false,
  signatureStatus: SignatureStatus.None,
  signingOrder: 0,
  signedOn: undefined,
  deliveredOn: undefined,
  accessLink: "",
  documentSigner: false,
  organizerStatus: 0,
  locationId: 0,
  ero: 0
};
export interface GatherTransaction {
  gatherId: number;
  eventId: GatherEvent;
  actedOn: Date;
  actedBy: IDocumentTransactionUser;
  eventData: GatherEventData;
}

export interface GatherEventData {
  fileName: string;
  ipAddress: string;
  mailEventId: MailEvent;
  recipient: any;
  isFromOneHub: boolean;
}

export enum GatherEvent {
  Created = 1,
  Emailed = 2,
  OTPAuthenticationFailed = 3,
  Reminder = 4,
  ResentAccessLink = 5,
  EngagementLetterEsigned = 6,
  FilledOrganizer = 7,
  CompletedOrganizer = 8,
  UploadedDocument = 9,
  CpaOrganizerDownloaded = 10,
  CpaEngagementDownloaded = 11,
  CpaSourceDocumentDownloaded = 12,
  ClientOrganizerDownloaded = 13,
  ClientEngagementLetterDownloaded = 14,
  ClientCustomQuestionnaireDownloaded = 15,
  DeleteSourceDocument = 16,
  OTPEmailed = 17,
  OTPTexted = 18,
  OTPAuthenticated = 19,
  EngagementLetterViewed = 20,
  EngagementLetterCompleted = 21,
  EngagementLetterReviewed = 22,
  ManualSigningReminder = 23,
  ManualOrganizerReminder = 24,
  SourceDocumentReminder = 25,
  AccessCodeEmail = 26,
  OTPAccesslocked = 27,
  OrganizerSendForReview = 28,
  OrganizerReviewed = 29,
  AccessLinkSelected = 30,
  CpaEngagementLetterManuallySigned = 31,
  CpaOrganizerManuallyCompleted = 32,
  SourceDocumentUploadMarkedCompleted = 33,
  SourceDocumentUploadReopened = 34,
  ArchivedGather = 35,
  RestoreArchivedGather = 36,
  RecycleGather = 37,
  RestoreRecycledGather = 38,
  AutomaticSigningReminder = 39,
  AutomaticOrganizerReminder = 40,
  ReopenGather = 41,
  ClosedGatherByFirm = 42,
  ReopenedGatherByFirm = 43,
  CustomQuestionFilled = 44,
  CustomQuestionCompleted = 45,
  CpaDownloadedOrganizerWithCustomQuestions = 46,
  SourceDocumentUploadProgressNotified = 47,
  K1DocumentUploadProgressNotified = 48,
  TpSpouseEmailAddressUpdated = 49,
  OfficeLocationUpdated = 50,
  TpSpouseNameUpdated = 51,
  SecondSignerEmailAddressUpdated = 52,
  AccessCodeTexted = 54,
  CpaMergedSourceDocumentDownloaded = 55,
}

export const GatherEventWithDescription = {
  'Emailed': 'Initial email',
  'Reminder': 'Reminder email sent',
  'Created': 'Initial email',
  'ResentAccessLink': 'Access link resent',
  'EngagementLetterEsigned': 'Engagement letter eSigned',
  'FilledOrganizer': 'Filled organizer',
  'CompletedOrganizer': 'Completed organizer',
  'UploadedDocument': 'Uploaded source document',
  'CpaOrganizerDownloaded': 'Organizer downloaded',
  'CpaEngagementDownloaded': 'Engagement letter downloaded',
  'CpaSourceDocumentDownloaded': 'Source document downloaded',
  'ClientOrganizerDownloaded': 'Organizer downloaded',
  'ClientEngagementLetterDownloaded': 'Engagement letter downloaded',
  'DeleteSourceDocument': 'Deleted document',
  'OTPEmailed': 'OTP emailed',
  'OTPTexted': 'OTP texted',
  'OTPAuthenticated': 'OTP authenticated',
  'OTPAuthenticationFailed': 'OTP authentication failed',
  'EngagementLetterViewed': 'Engagement letter viewed',
  'EngagementLetterCompleted': 'Engagement letter eSign completed',
  'EngagementLetterReviewed': 'Engagement letter reviewed',
  'ManualSigningReminder': 'Manual Signing Reminder email',
  'ManualOrganizerReminder': 'Manual Organizer Reminder email',
  'SourceDocumentReminder': 'Source Document Reminder email',
  'AccessCodeEmail': 'Access Code Email',
  'OrganizerSendForReview': 'Organizer Sent for Review',
  'OrganizerReviewed': 'Organizer Reviewed',
  'CpaEngagementLetterManuallySigned': 'Engagement letter changed to manually signed',
  'CpaOrganizerManuallyCompleted': 'Organizer changed to manually completed',
  'SourceDocumentUploadMarkedCompleted': 'Source Document upload completed',
  'SourceDocumentUploadReopened': 'Source Document upload reopened for Gather Request',
  'ArchivedGather': 'Gather Request Archived',
  'RestoreArchivedGather': 'Restored From Archived Organizers',
  'RestoreRecycledGather': 'Gather Request Restored From Recycle Bin',
  'AutomaticSigningReminder': 'Automatic Signing Reminder email',
  'AutomaticOrganizerReminder': 'Automatic Organizer Reminder email',
  'ReopenGather': 'Organizer has been Reopened',
  'RecycleGather': 'Gather Request sent to Recycle Bin',
  'ClosedGatherByFirm': 'Gather request closed',
  'ReopenedGatherByFirm': 'Closed Gather request reopened',
  'CustomQuestionFilled': 'Custom Question Filled',
  'CustomQuestionCompleted': 'Custom Question Completed',
  'CpaDownloadedOrganizerWithCustomQuestions': 'Organizer with Custom Questionnaire Downloaded',
  'SourceDocumentUploadProgressNotified': 'Source Document upload email',
  'K1DocumentUploadProgressNotified': 'K1 Document upload email',
  'TpSpouseEmailAddressUpdated': 'Email updated',
  'OfficeLocationUpdated': 'Office Location updated',
  'TpSpouseNameUpdated': 'name updated',
  'SecondSignerEmailAddressUpdated': 'email id updated',
  'AccessCodeTexted': 'Access Code Texted',
  'CpaMergedSourceDocumentDownloaded': 'Merged Source documents downloaded'
}
export interface IDocumentTransaction {
  documentId: number;
  eventId: DocumentEvent;
  actedOn: Date;
  actedBy: IDocumentTransactionUser;
  eventData: IDocumentEventData;
}

export interface IDocumentTransactionUser {
  firstName: string;
  lastName: string;
  emailAddress: string;
}

export interface IDocumentEventData {
  fileName: string;
  ipAddress: string;
  mailEventId: MailEvent;
  recipient: IDocumentTransactionUser;
}
export interface IManualCompletionRequest {
  gatherIds: number[];
  updateEngagementLetter: boolean;
  updateOrganizer: boolean;
}

export const initialManualCompletionRequest: IManualCompletionRequest = {
  gatherIds: [],
  updateEngagementLetter: false,
  updateOrganizer: false
};

export enum MailEvent {
  None,
  Click = 1,
  Delivered = 2,
  Opened = 3,
  Processed = 4,
  Dropped = 5,
  Bounce = 6,
  Deferred = 7,
  SpamReport = 8,
  ScheduleFailed = 9,
  Sent = 10
}

export enum DocumentEvent {
  None,
  Created = 1,
  DocumentAssignTo = 2,
  DocumentEROStampDecline = 3,
  EROSignDecline = 4,
  DocumentDeliveryApproved = 5,
  Emailed = 6,
  DocumentChangeStatusManual = 7,
  TaxReturnAccessed = 8,
  DocumentAuthenticated = 9,
  AuthenticationFailed = 10,
  AccessLocked = 11,
  OTPAuthenticationFailed = 12,
  OTPAccessLocked = 13,
  TaxReturnReviewed = 14,
  Viewed = 15,
  EfileFormsDownloaded = 16,
  FileUploaded = 17,
  TaxpayerEsigned = 18,
  SpouseEsigned = 19,
  VouchersReviewed = 20,
  VoucherReminderDisabled = 21,
  VoucherReminderEnabled = 22,
  AttachmentDownloaded = 23,
  AllAttachmentDownloaded = 24,
  AllK1DocumentDownloaded = 25,
  DocumentAttachmentsReviewed = 26,
  PaymentSystemAccessed = 27,
  InvoiceReviewed = 28,
  Finished = 29,
  AccessLinkEmailed = 30,
  TransmittalReviewed = 31,
  DocumentTaxSaved = 32,
  K1DistributedTo = 33,
  DocumentDownloadAll = 34,
  DocumentFilingInstructionsDownloaded = 35,
  DocumentTaxReturnDownloaded = 36,
  DocumentVouchersDownloaded = 37,
  DocumentInvoiceDownloaded = 38,
  DocumentSignedEfileDownloaded = 39,
  KBAPassed = 40,
  KBAFailed = 41,
  DocumentKBASuccess = 42,
  DocumentKBAFailed = 43,
  DOBChanged = 44,
  DocumentOptOutESignOptionSelected = 45,
  DocumentSigned = 46,
  SignedDocumentReviewed = 47,
  VoucherReminderUpdated = 48,
  Deleted = 49,
  DocumentFileDeleted = 50,
  ShareholderConsentReceived = 51,
  ShareholderConsentDeclined = 52,
  K1DocumentDownloaded = 53,
  K1InstructionDocumentDownloaded = 54,
  DocumentMailStatus = 55,
  ReturnIsRecalled = 56,
  VoucherRemainderMailStatus = 57,
  DocumentRequestRecallReturn = 58,
  DocumentRequestDeleteReturn = 59,
  Shared = 60,
  Cancelled = 61,
  Filed = 62,
  DocumentRejected = 63,
  AccessCodeEmail = 64,
  PartnershipEsigned = 65,
  DocumentSignedEfileDownloadedByCPA = 66,
  ReminderSendForSigning = 67,
  DownloadLinkSend = 68,
  PaymentCompleted = 69,
  NotDefined = 75,
  UploadInProgress = 76,
  DeliverdToTaxcaddy = 77,
  TaxcaddyDeliveryFailed = 78,
  SigningReminderSend = 79,
  SigningReminderMailStatus = 80,
  K1ShareHolderReminderMailStatus = 81,
  K1TaxpayerReminderMailStatus = 82
}

export interface IClientAccessCodeDetail {
  clientAccessCodes: IClientAccessCode[];
  clientEvents: GatherTransaction[];
}

export const initialClientAccessCodeDetail: IClientAccessCodeDetail = {
  clientAccessCodes: [],
  clientEvents: []
};
export enum ClientType {
  Undefied = "none",
  Taxpayer = "Taxpayer",
  Spouse = "Spouse",
  Partner = "Partner",
  PartnerShip = "PartnerShip",
  ShareHolder = "ShareHolder"
}
export interface IClientAccessCode {
  clientName: string;
  clientGuid: string;
  gatherId: number;
  email: string;
  isDeceased: boolean;
  clientType: ClientType;
  createdOn: Date;
  otp: string;
  retryCount: number;
}

export interface ILocationData {
  locations: number[];
}
