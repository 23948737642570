export const AutomationLocators = {
  Delivered: {
    Table: "GTR_delivered_table",
    HeaderActions: {
      Refresh: "GTR_delivered_header_refresh",
      ExportExcel: "GTR_delivered_header_export_excel",
      SendReminder: "GTR_delivered_header_send_reminder",
      Download: "GTR_delivered_header_download",
      ChangeStatus: "GTR_delivered_header_change_status",
      Archive: "GTR_delivered_header_archive",
      Delete: "GTR_delivered_header_delete",
      CloseOpen: "GTR_delivered_header_close_open"
    },
    InLineActions: {
      Download: "GTR_delivered_inline_download",
      Delete: "GTR_delivered_inline_delete",
      ClientTracking: {
        Table: "GTR_delivered_inline_client_tracking_table",
        Button: "GTR_delivered_inline_client_tracking_button"
      }
    },
    RowActions: {
      Organizer: "GTR_delivered_row_organizer",
      Signature: "GTR_delivered_row_signature",
      SourceDocument: "GTR_delivered_row_source_document",
    }
  },
  Single: {
    Table: "GTR_inprocess_single_table",
    HeaderActions: {
      Refresh: "GTR_inprocess_single_refresh",
      ExportExcel: "GTR_inprocess_single_export_excel",
      Delete: "GTR_inprocess_single_delete"
    },
    InLineActions: {
      Download: "GTR_inprocess_single_inline_process",
      Delete: "GTR_inprocess_single_inline_delete",
      EditClientInfo: "GTR_inprocess_single_inline_edit_client_info",
      ReportProblem: "GTR_inprocess_single_inline_report_problem"
    }
  },
  Batch: {
    Table: "GTR_inprocess_batch_table",
    HeaderActions: {
      Refresh: "GTR_inprocess_batch_refresh",
      ExportExcel: "GTR_inprocess_batch_export_excel"
    },
    InLineActions: {
      Download: "GTR_inprocess_batch_inline_process",
      Delete: "GTR_inprocess_batch_inline_delete",
      EditClientInfo: "GTR_inprocess_batch_inline_edit_client_info",
      ReportProblem: "GTR_inprocess_batch_inline_report_problem"
    }
  },
  RecycleBin: {
    Table: "GTR_recycle_bin_table",
    HeaderActions: {
      Delete: "GTR_recycle_bin_header_delete",
      Restore: "GTR_recycle_bin_header_restore",
      Refresh: "GTR_recycle_bin_header_refresh"
    }
  },
  Undelivered: {
    Table: "GTR_undelivered_table",
    HeaderActions: {
      Refresh: "GTR_undelivered_header_refresh",
      ExportExcel: "GTR_undelivered_header_export_excel"
    },
    InLineActions: {
      Download: "GTR_undelivered_inline_download",
      Delete: "GTR_undelivered_inline_delete"
    }
  },
  Settings: {
    CustomQuestions: {
      Preview: "settings_cq_preview",
      Save: "settings_cq_save",
      Section: {
        questionTitle: "settings_cq_section_question_title",
        duplicate: "settings_cq_section_duplicate",
        delete: "settings_cq_section_delete",
        required: "settings_cq_section_required",
        addQuestion: "settings_cq_section_add_question",
        questionDropDown: "settings_cq_section_question_dd",
        questionType: {
          multiChoice: {
            deleteChoice: "settings_cq_section_question_multi_delete",
            addChoice: "settings_cq_section_question_multi_add_choice",
            choiceText: "settings_cq_section_question_multi_choicetext"
          }
        }
      },
      AddSection: "settings_cq_section_add",
      DeleteSectionWidget: "settings_cq_section_delete_section_widget",
      DeleteSectionDropdown: "settings_cq_section_delete_section_dd",
      RenameSection: "settings_cq_section_rename",
      PreviewClose: "settings_cq_preview_close",
      PreviewRestart: "settings_cq_preview_restart",
      PreviewAnswer: "settings_cq_preview_answer",
      SectionDeleteConfirm: "settings_cq_section_delete_confirm",
      SectionDeleteCancel: "settings_cq_section_delete_cancel"
    }
  }
};
