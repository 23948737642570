import { LogoutCause } from "common/enum";

export interface IUserPrivilegeChangedModel {
    logoutCause: LogoutCause,
    isUserPrivilegeChanged: boolean,
    loading?: boolean
}

export const InitialUserPrivilegeChangedModel:IUserPrivilegeChangedModel = {
    logoutCause: LogoutCause.None,
    isUserPrivilegeChanged: false,
    loading: false
}

