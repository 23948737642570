import Axios from "services/api/axios-interceptor";
import { Action, ThunkAction } from "@reduxjs/toolkit";
import { AppState } from "store"
import { API } from "services/api/api-config";
import { setLoginHistory } from "store/slices/login-history-slice";
import { IUserLoginHistoryModel } from "common/model/login-history";
import { AppNotifier } from "common/components/toast/Toast";
import { IUserDefaultSettings } from "common/model/userSetting.js";

export const getLoginHistory = (pageNumber: number, pageSize: number): ThunkAction<void, AppState, unknown, Action> =>
(dispatch) => {
        Axios.get(`${API.USER.GET_LOGIN_HISTORY}/${pageNumber}/${pageSize}`,
        {
            method: 'GET',
            credentials: 'include'
        }
       )
       .then((response) => response.data as IUserLoginHistoryModel)
       .then((data: IUserLoginHistoryModel) => {
           dispatch(setLoginHistory(data))
       }).catch((error: any) => {
            AppNotifier.Error("Failed to Fetch the login history");
         })
}

export const updateLastLoginHistory = (defaultSettings: IUserDefaultSettings,
    successcallback?: () => void): ThunkAction<void, AppState, unknown, Action> =>
        (dispatch) => {
            Axios.put(`${API.USER_SETTINGS.UPDATE_USER_SETTINGS}`, defaultSettings,
                {
                    method:'PUT',
                     credentials: 'include'
                }
            ).then((response) =>  {
                if (response) {
                    AppNotifier.Success("Your settings have been updated");
                   
                    successcallback && successcallback();
                  } else {
                    AppNotifier.Error("Failed to update settings");
                    
                  }
            })
            .catch((error: any) => {
                AppNotifier.Error("Failed to update settings");
            });
        }