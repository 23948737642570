import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EmailTemplateMessage } from "common/model/client-instruction"
import { ReminderOptionsValue } from "pages/settings/client-instructions";
 
const emailTemplate:EmailTemplateMessage[]=[{
    id: 0,
    name: "",
    subject: "",
    body: "",
    type: 0,
    isSystemDefault: false
  }]

const initialClientinstructionState:any={
    emailTemplateArray:emailTemplate,
    selectedOption:ReminderOptionsValue.NONE
}

  const clientInstructionSlice = createSlice({
    name: 'clientinstruction',
    initialState:initialClientinstructionState,
    reducers: {
        setEmailTemplates(state, action: PayloadAction<EmailTemplateMessage[]>)
        {
            state.emailTemplateArray = action.payload;
        },
        editEmailTemplate(state, action: PayloadAction<EmailTemplateMessage>)
        {
            state.emailTemplateArray[state.emailTemplateArray.findIndex((obj: EmailTemplateMessage) => obj.id===action.payload.id)]=action.payload;
        },
        addEmailTemplate(state, action: PayloadAction<EmailTemplateMessage>)
        {
            state.emailTemplateArray.push(action.payload)
        },
        deleteEmailTemplate(state, action: PayloadAction<EmailTemplateMessage>)
        {
           state.emailTemplateArray = state.emailTemplateArray.filter((item :EmailTemplateMessage )=> item.id !== action.payload.id);
        },
        setSelectedOption(state, action: PayloadAction<ReminderOptionsValue>)
        {
           state.selectedOption = action.payload;
        },
    },
  });
 
  export const {
    setEmailTemplates,
    editEmailTemplate,
    addEmailTemplate,
    deleteEmailTemplate,
    setSelectedOption} = clientInstructionSlice.actions;
  export default clientInstructionSlice.reducer;