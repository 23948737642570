import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ISavedMessage } from "common/model/saved-message-model";

export interface ISavedMessageState {
    savedMessages : ISavedMessage[];
}

const initialSavedMessageState : ISavedMessageState = {
    savedMessages : []
}

const savedMessageSlice = createSlice({
    name:'savedMessage',
    initialState: initialSavedMessageState,
    reducers:{
        setSavedMessageData(state, action:PayloadAction<ISavedMessage[]>){
            state.savedMessages = action.payload;
        },
        addSavedMessage(state, action: PayloadAction<ISavedMessage>)
        {
            state.savedMessages.push(action.payload);
        },
        editSavedMessage(state, action: PayloadAction<ISavedMessage>)
        {            
            state.savedMessages[state.savedMessages.findIndex((obj: ISavedMessage) => obj.id===action.payload.id)]=(action.payload);
        },
    }
})

export const { setSavedMessageData, addSavedMessage, editSavedMessage } = savedMessageSlice.actions;
export const { reducer: savedMessageReducer } = savedMessageSlice;