import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { customQuestionsInitialState, ICustomQuestion, ICustomQuestionsState } from "store/models/CustomQuestionModel";
import { ISelectedTemplate } from "common/model/custom-questions";
const customQuestionTemplates = createSlice({
  name: "customQuestionTemplates",
  initialState: customQuestionsInitialState as ICustomQuestionsState,
  reducers: {
    startLoader(customQuestionsState) {
      customQuestionsState.isLoading = true;
    },
    stopLoader(customQuestionsState) {
      customQuestionsState.isLoading = true;
    },
    saveCustomQuestions(customQuestionsState, action: PayloadAction<ICustomQuestionsState>) {
      customQuestionsState.customQuestions = action.payload.customQuestions;
    },
    addCustomQuestions(customQuestionsState, action: PayloadAction<ICustomQuestion>) {
      customQuestionsState.customQuestions.push(action.payload) 
    },
    editCustomQuestionslist(customQuestionsState, action: PayloadAction<ICustomQuestion>) {
      customQuestionsState.customQuestions[customQuestionsState.customQuestions.findIndex((obj:ICustomQuestion)=> obj.id === action.payload.id)]=action.payload; 
    },
    setQuestionnaireTemplateList(state, action:PayloadAction<any>){
      state.questionnaireTemplates = action.payload;
    },
    setSelectedCustomQuestion(state, action:PayloadAction<ISelectedTemplate>){
            state.selectedTemplate = action?.payload?.formId != 0 ? action.payload : null;
    }
  }
});

export const { startLoader, stopLoader, saveCustomQuestions, setQuestionnaireTemplateList, setSelectedCustomQuestion, addCustomQuestions, editCustomQuestionslist } = customQuestionTemplates.actions;

export default customQuestionTemplates.reducer;
