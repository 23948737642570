import { useEffect } from 'react';
import queryString from 'query-string';
import Route from 'route';
import { signinRedirectCallback } from './userService';
import LoadingScreen from 'common/pages/LoadingScreen';
import { useHistory } from 'react-router-dom';
 
const SigninOidc = () => {
    const history =useHistory();
    useEffect(() => {
        signinRedirectCallback();
        return () => {
            if (history?.location?.search) {
                const params = queryString.parse(history.location.search);
                if (params.callback_args) {
                    history.push(params.callback_args)
                } else {
                    Route.redirect("/");
                }
            } else {
                Route.redirect("/");
            }
        }
    }, [])
 
    return <LoadingScreen showLogo={true} />
}
 
export default SigninOidc;