import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {IDocumentRequestCategoryVM, IDocumentRequestState, IImportedTemplateModel, initialDocumentRequestState } from "common/model/request-documents";

interface State {
    importedTemplateModel : IImportedTemplateModel[],
    isLoading: boolean,
    documentRequestModel: IDocumentRequestState,
    documentRequestCategory: IDocumentRequestCategoryVM[] | null
}

const initialBatchState: State = {
    importedTemplateModel : [],
    isLoading : false,
    documentRequestModel : initialDocumentRequestState,
    documentRequestCategory : []
}

const documentRequestSlice = createSlice({
    name: 'documentRequest',
    initialState: initialBatchState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload
        },
        setImportedDocumentRequest(state, action: PayloadAction<any>) {
            state.importedTemplateModel = action.payload;
            state.isLoading = false;
        },
        setDocumentRequest(state, action: PayloadAction<any>) {
            state.documentRequestModel = action.payload;
            state.isLoading = false;
        },
        setIsTemplateSelected(state, action: PayloadAction<any>) {
            state.documentRequestModel.isTemplateSelected = action.payload;
        },
        setImportedTemplateList(state, action: PayloadAction<any>) {
            state.documentRequestModel.importedDocumentRequestList = action.payload;
        },
        setDocumentRequestCategory(state, action: PayloadAction<any>) {
            state.documentRequestCategory = action.payload;
        },
        setAutoDRTemplateList(state, action: PayloadAction<any>) {
            state.documentRequestModel.autoDocumentRequestList = action.payload;
        },
        resetDocumentRequestState(state) {
            state.importedTemplateModel = [];
            state.isLoading = false;
            state.documentRequestModel = initialDocumentRequestState;
            state.documentRequestSection = [];
        }
    }
});

export const { setIsLoading, setImportedDocumentRequest, setDocumentRequest, setIsTemplateSelected, setImportedTemplateList, setDocumentRequestCategory, setAutoDRTemplateList, resetDocumentRequestState } = documentRequestSlice.actions;

export default documentRequestSlice.reducer;
