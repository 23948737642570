import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IUteGatherState } from "common/model/ute-gather-model";

const initialUteGatherState: IUteGatherState = {
  loading: false,
  linkedDetails: []
};

const uteGatherSlice = createSlice({
  name: "uteGather",
  initialState: initialUteGatherState,
  reducers: {
    setUteLinkedDetails: (state, action: PayloadAction<IUteGatherState>) => {
      state.linkedDetails = action.payload.linkedDetails;
      state.loading = false;
    },
    startLoader: (state) => {
      state.loading = true;
    },
    stopLoader: (state) => {
      state.loading = false;
    }
  }
});

export const { startLoader, setUteLinkedDetails, stopLoader } = uteGatherSlice.actions;
export const { reducer: uteGatherReducer } = uteGatherSlice;
