import React, { FC } from "react";
import { IMyAccountLayout } from "./MyAccountLayout";
import Password from "./Password";
import Profile from "./Profile";

interface IUserSettingsContentProps extends IMyAccountLayout {
    eventKey: string;
}
const MyAccountContent: FC<IUserSettingsContentProps> = ({ eventKey, onHide }) => {
    let content;
    switch (eventKey) {
        case "profile":
            content = <Profile onHide={onHide} />;
            break;
        case "password":
            content = <Password onHide={onHide} />;
            break;
        default:
            content = <></>;
    }
    return content;
};

export default MyAccountContent;