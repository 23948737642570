import React, { useEffect } from "react";
import { AddIcon } from "assets/icons/IconCollection";
import { MessageType } from "../../client-instructions";
import { SavedMessageModel } from "common/model/saved-message";
import { GatherCompanySettingsModel } from "common/model/company-settings";
import { MessageSettingsConstants } from "pages/Constants";

export interface IMessageListBoxProps {
  data: SavedMessageModel[];
  companysettings: GatherCompanySettingsModel;
  onAddMessage: () => void;
  onSelectMessage: (index: number, messageType: MessageType, array: SavedMessageModel) => void;
  messageType: MessageType;
  selectedMessage: SavedMessageModel;
}

const MessageListBox: React.FC<IMessageListBoxProps> = ({
  data,
  companysettings,
  messageType,
  onAddMessage,
  onSelectMessage,
  selectedMessage
}) => {
  const isActive = (id: number) => {
    if (selectedMessage.id === id) {
      return true;
    } else return false;
  };

  return (
    <div className="message-list-box">
      <div>
        {data &&
          data.length > 0 &&
          data.map((ele, index, array) => {
            return (
              <div
                key={ele.id}
                className={`message-wrapper ${isActive(ele.id) ? "active-msg" : ""}`}
                onClick={() => onSelectMessage(index, messageType, ele)}
                title={ele.name}
              >
                <p className="d-inline-block text-truncate">{ele.name}</p>
                {companysettings.defaultSettings.savedMessage === ele.id && <span>Default</span>}
              </div>
            );
          })}
      </div>
      <button onClick={onAddMessage} className="btn-with-icon" title={MessageSettingsConstants.AddMessage}>
        <AddIcon />
        Add
      </button>
    </div>
  );
};

export default MessageListBox;
