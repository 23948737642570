import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LogoutCause } from "common/enum";
import { UserPreviligeState } from "common/model/authentication";

const authenticationInitialState: any = {
    user: null,
    isLoadingUser: false,
};

const userPrivilegeInitialState: UserPreviligeState = {
    logoutCause: LogoutCause.None,
    userPrivilegesChanged: false,
    isResetCacheApiCalled: false,
}

const initialAuthState: any = {
    authentication: authenticationInitialState,
    userPrivilege: userPrivilegeInitialState,
    isLoggedOut: false,
}

const authSlice = createSlice({
    name: 'auth',
    initialState: initialAuthState,
    reducers: {
        setAuthentication(state, action: PayloadAction<any>) {
            state.authentication = { user: action.payload, isLoadingUser: false };
        },
        setUserPrivilege(state, action: PayloadAction<UserPreviligeState>) {
            state.userPrivilege = action.payload;
        },
        setLoggedOut(state, action:PayloadAction<boolean>) {
            state.isLoggedOut = action.payload;
        },
    }
});

export const { setAuthentication, setUserPrivilege, setLoggedOut } = authSlice.actions;

export default authSlice.reducer;
