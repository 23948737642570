import { ProductType } from "@sssuite-component-ui/ss-suite-layout";
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { API } from "services/api/api-config";
import Axios from "services/api/axios-interceptor";
import { AppState } from "store";
import { setAuthentication, setUserPrivilege } from "store/slices/auth-slice";

export const userExpired = (): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
        dispatch(setAuthentication(null));
    }

export const userFound = (user: any): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
        dispatch(setAuthentication(user));
    }

export const userRenewed = (user: any): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
        dispatch(setAuthentication(user));
    }

export const userPrivilegeChanged = (payload: any): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
        dispatch(setUserPrivilege(payload));
    }

export const resetUserCache = async () => {
    try {
        Axios.get(`${API.USER_MANAGEMENT.RESET_USER_STATUS_CACHE}/${ProductType.Gather}`, {
            credentials: 'include',
            method: 'GET',
        }).then((handleResponse) => {
            //  To do
        })
            .catch(error => {
                // To do 
            });
    }
    catch (error: any) {
        // handle error
    }
}
export const updateLogoutDate = (callback: () => void) => {
    Axios.put(`${API.USER.UPDATE_LOGOUT_DATE}`, {
    }).then((handleResponse) => {
        callback();
    })
        .catch(error => {
            // To do 
        });
}