import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Console } from "console";
import {
  generateRandomNumber,
  generateRandomString,
  removeUnwantedWhitespacesFromText,
  updateSectionAndQuestionnaireOrder
} from "helper/HelperFunctions";
import {
  IAddSubQuestionPayload,
  IDupicateQuestionPayload,
  IDupicateSectionPayload,
  IFormData,
  IHandleAddChoiceIdentifierPayload,
  IHandleChoiceIdentifierPayload,
  IHandleChoiceTextOnChangePayload,
  IHandleQuestionTypeChangePayload,
  IHandleSubQuestionTypeChangePayload,
  IItemIdentifier,
  initialMCQOptions,
  initialYesNoChoiceData,
  intialFormData,
  intialQuestionnaireState,
  intialSectionItemData,
  IOnChangeHandlerPayload,
  IQuestionReOrderPayload,
  IRemoveSubQuestionPayload,
  ISections,
  ISubQuestionChoiceIdentifierPayload,
  ISubQuestionChoiceTextOnChangePayload,
  ISubQuestionTitlePayload,
  ITemplateModalData,
  ITitleOnChangeHandlerPayload,
  QuestionType,
  initialChoiceData,
  GetAdditionalQuestion,
  IQuestionnaireState,
  ICustomQuestionSettings
} from "store/models/CustomQuestionModel";

const formBuilderSlice = createSlice({
  name: "formBuilder",
  initialState: intialQuestionnaireState as IQuestionnaireState,
  reducers: {
    setCustomQuestion(state, action: PayloadAction<IFormData>) {
      state.formData = action.payload;
    },
    setCustomQuestionSettings(state, action: PayloadAction<ICustomQuestionSettings>) {
      state.customQuestionSettings = action.payload;
    },
    SaveTemplateModalData(draft, action: PayloadAction<ITemplateModalData>) {
      draft.formData.template = action.payload;
    },
    SaveSectionModalData(draft, action: PayloadAction<string>) {
      const order: number = draft.formData.sections.length;
      draft.formData.sections.push({
        id: generateRandomNumber(),
        sectionId: generateRandomNumber(),
        order: order + 1,
        sectionName: action.payload,
        sectionItems: [intialSectionItemData]
      });
    },
    RenameSectionModalData(draft, action: PayloadAction<{ sectionName: string; sectionIndex: number }>) {
      const { sectionIndex, sectionName } = action.payload;
      draft.formData.sections[sectionIndex].sectionName = sectionName;
    },

    ClearFormData(draft) {
      draft.formData = intialFormData;
    },
    //start from here
    DeleteSectionData(draft, action: PayloadAction<number>) {
      let sections = draft.formData.sections;
      sections.splice(action.payload, 1);
      sections = sections.map((section: ISections, index: number) => {
        return { ...section, order: index + 1 };
      });
      draft.formData.sections = sections;
    },
    HandleQuestionTitleOnChange(draft, action: PayloadAction<IOnChangeHandlerPayload>) {
      const { sectionIndex, sectionItemIndex, value } = action.payload;
      if (value !== "") {
        draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].isValid = true;
      } else {
        draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].isValid = false;
      }
      draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].questionTitle = value;
    },
    AddNewQuestionHandler(draft, action: PayloadAction<number>) {
      draft.formData.sections[action.payload].sectionItems.push({
        ...intialSectionItemData,
        id: generateRandomString()
      });
    },
    MergeSectionHandler(draft, action: PayloadAction<IFormData>) {
      draft.formData = updateSectionAndQuestionnaireOrder(action.payload);
    },
    DuplicateSectionHandler(draft, action: PayloadAction<IDupicateSectionPayload>) {
      const { sectionIndex, section } = action.payload;
      let sections = draft.formData.sections;
      sections.splice(sectionIndex + 1, 0, { ...section, id: generateRandomNumber() });
      sections = sections.map((section, index) => {
        return { ...section, order: index + 1 };
      });
      draft.formData.sections = sections;
    },
    DuplicateQuestionHandler(draft, action: PayloadAction<IDupicateQuestionPayload>) {
      const { sectionIndex, sectionItemIndex, sectionItem } = action.payload;
      draft.formData.sections[sectionIndex].sectionItems.splice(sectionItemIndex + 1, 0, {
        ...sectionItem,
        id: generateRandomString()
      });
    },
    DeleteQuestionHandler(draft, action: PayloadAction<IItemIdentifier>) {
      const { sectionIndex, sectionItemIndex } = action.payload;
      draft.formData.sections[sectionIndex].sectionItems.splice(sectionItemIndex, 1);
    },
    ChangeRequiredQuestionHandler(draft, action: PayloadAction<IItemIdentifier>) {
      const { sectionIndex, sectionItemIndex } = action.payload;
      draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].isRequired =
        !draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].isRequired;
      if (draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].questionTypeId == QuestionType.YESNO) {
        draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].choices.map((choice) => {
          choice.subQuestions.map((subQuestion) => {
            subQuestion.isRequired = draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].isRequired;
          });
        });
      }
    },
    HandleTitleOnChange(draft, action: PayloadAction<ITitleOnChangeHandlerPayload>) {
      const { value } = action.payload;
      if (value !== "") {
        draft.formData.isTitleValid = true;
      } else {
        draft.formData.isTitleValid = false;
      }
      draft.formData.title = value;
    },
    HandleDescriptionOnChange(draft, action: PayloadAction<ITitleOnChangeHandlerPayload>) {
      const { value } = action.payload;
      if (value !== "") {
        draft.formData.isDescriptionValid = true;
      } else {
        draft.formData.isDescriptionValid = false;
      }
      draft.formData.description = value;
    },
    SetIsInvalidFlag(draft) {
      draft.formData.sections.forEach((section) => {
        section.sectionItems.forEach((sectionItem) => {
          if (sectionItem.questionTitle === "") {
            sectionItem.isValid = false;
          }
        });
      });
      if (draft.formData.title === "") {
        draft.formData.isTitleValid = false;
      }
      if (draft.formData.description === "") {
        draft.formData.isDescriptionValid = false;
      }
      draft.formData.sections.forEach((section) => {
        section.sectionItems.forEach((sectionItem) => {
          let choiceTexts: string[] = [];
          sectionItem.choices.forEach((choice) => {
            if (choice.text === "") {
              choice.isValid = false;
            } else {
              let choiceText = removeUnwantedWhitespacesFromText(choice.text.toLowerCase());
              if (choiceTexts.includes(choiceText)) {
                choice.isDuplicate = true;
              }
              choiceTexts.push(choiceText);
            }
          });
        });
      });
      draft.formData.sections.forEach((section) => {
        section.sectionItems.forEach((sectionItem) => {
          sectionItem.choices.forEach((choice) => {
            if (choice.subQuestions && choice.subQuestions.length > 0) {
              choice.subQuestions.forEach((subQuestion) => {
                if (subQuestion.questionTitle === "") {
                  subQuestion.isValid = false;
                }
              });
            }
          });
        });
      });
      draft.formData.sections.forEach((section) => {
        section.sectionItems.forEach((sectionItem) => {
          sectionItem.choices.forEach((choice) => {
            if (choice.subQuestions && choice.subQuestions.length > 0) {
              choice.subQuestions.forEach((subQuestion) => {
                if (subQuestion.questionTypeId === QuestionType.MULTICHOICE) {
                  let choiceTexts: string[] = [];
                  subQuestion.choices.forEach((subQuestionChoice) => {
                    if (subQuestionChoice.text === "") {
                      subQuestionChoice.isValid = false;
                    } else {
                      let subQuestionChoiceText = removeUnwantedWhitespacesFromText(subQuestionChoice.text.toLowerCase());
                      if (choiceTexts.includes(subQuestionChoiceText)) {
                        subQuestionChoice.isDuplicate = true;
                      }
                      choiceTexts.push(subQuestionChoiceText);
                    }
                  });
                }
              });
            }
          });
        });
      });
    },
    HandleQuestionReordering(draft, action: PayloadAction<IQuestionReOrderPayload>) {
      draft.formData.sections[action.payload.sectionIndex].sectionItems = action.payload.sectionItems;
    },
    HandleSectionReordering(draft, action: PayloadAction<IFormData>) {
      draft.formData = action.payload;
    },
    HandleQuestionTypeChange(draft, action: PayloadAction<IHandleQuestionTypeChangePayload>) {
      const { questionType, sectionIndex, sectionItemIndex } = action.payload;
      draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].questionTypeId = questionType;
      if (questionType === QuestionType.YESNO) {
        draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].choices = initialYesNoChoiceData;
      }
      if (questionType === QuestionType.MULTICHOICE) {
        draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].choices = initialMCQOptions;
      }
      if (questionType === QuestionType.PARAGRAPH) {
        draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].choices = [];
      }
    },
    HandleChoiceTextOnChange(draft, action: PayloadAction<IHandleChoiceTextOnChangePayload>) {
      const { sectionIndex, sectionItemIndex, choiceIndex, choiceText } = action.payload;
      if (draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].choices) {
        if (choiceText !== "") {
          draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].choices[choiceIndex].isValid = true;
        } else {
          draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].choices[choiceIndex].isValid = false;
        }

        draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].choices[choiceIndex].isDuplicate = false;
        draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].choices[choiceIndex].text = choiceText;
      }
    },
    HandleAddChoice(draft, action: PayloadAction<IItemIdentifier>) {
      const { sectionIndex, sectionItemIndex } = action.payload;

      draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].choices.push({
        ...initialChoiceData,
        id: generateRandomNumber()
      });
    },
    HandleRemoveChoice(draft, action: PayloadAction<IHandleChoiceIdentifierPayload>) {
      const { sectionIndex, sectionItemIndex, choiceIndex } = action.payload;
      draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].choices.splice(choiceIndex, 1);
    },
    OnClickYesNoCheckbox(draft, action: PayloadAction<IHandleChoiceIdentifierPayload>) {
      const { sectionIndex, sectionItemIndex, choiceIndex } = action.payload;
      const selectedChoice = draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].choices[choiceIndex];
      if (selectedChoice.subQuestions && selectedChoice.subQuestions.length === 0) {
        selectedChoice.subQuestions.push(intialSectionItemData);
      } else {
        selectedChoice.subQuestions.length = 0;
      }
    },
    OnAddAdditionalQuestion(draft, action: PayloadAction<IAddSubQuestionPayload>) {
      const { sectionIndex, sectionItemIndex, choiceIndex, questionType } = action.payload;
      const selectedChoice = draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].choices[choiceIndex];
      if (selectedChoice.subQuestions && selectedChoice.subQuestions.length > 0) {
        selectedChoice.subQuestions.push(GetAdditionalQuestion(questionType));
        if (questionType == QuestionType.YESNO) {
          selectedChoice.subQuestions[selectedChoice.subQuestions.length - 1].choices = initialYesNoChoiceData;
        }
        if (questionType == QuestionType.MULTICHOICE) {
          selectedChoice.subQuestions[selectedChoice.subQuestions.length - 1].choices = initialMCQOptions;
        }
      }
    },
    OnRemoveAdditionalQuestion(draft, action: PayloadAction<IRemoveSubQuestionPayload>) {
      const { sectionIndex, sectionItemIndex, choiceIndex, questionIndex } = action.payload;
      draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].choices[choiceIndex].subQuestions.splice(
        questionIndex,
        1
      );
    },
    OnSubQuestionTypeChange(draft, action: PayloadAction<IHandleSubQuestionTypeChangePayload>) {
      const { sectionIndex, sectionItemIndex, choiceIndex, questionType, subQuestionIndex } = action.payload;
      const selectedChoice = draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].choices[choiceIndex];
      for (let selectedSubQuestion of selectedChoice.subQuestions) {
        selectedSubQuestion.questionTypeId = questionType;
        if (questionType === QuestionType.YESNO) {
          selectedSubQuestion.choices = initialYesNoChoiceData;
        }
        if (questionType === QuestionType.MULTICHOICE) {
          selectedSubQuestion.choices = initialMCQOptions;
        }
      }
    },
    OnChangeSubQuestionTitle(draft, action: PayloadAction<ISubQuestionTitlePayload>) {
      const { sectionIndex, sectionItemIndex, choiceIndex, value, questionIndex } = action.payload;
      const selectedChoice = draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].choices[choiceIndex];

      const selectedSubQuestion = selectedChoice.subQuestions[questionIndex];

      if (value !== "") {
        selectedSubQuestion.isValid = true;
      } else {
        selectedSubQuestion.isValid = false;
      }
      selectedSubQuestion.questionTitle = value;
      selectedSubQuestion.SectionItemId = generateRandomNumber();
      return draft;
    },
    HandleSubQuestionChoiceTextOnChange(draft, action: PayloadAction<ISubQuestionChoiceTextOnChangePayload>) {
      const { sectionIndex, sectionItemIndex, choiceIndex, questionIndex, choiceText, subQuestionChoiceIndex } = action.payload;
      const selectedChoice = draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].choices[choiceIndex];

      const selectedSubQuestion = selectedChoice.subQuestions[questionIndex];
      if (choiceText !== "") {
        selectedSubQuestion.choices[subQuestionChoiceIndex].isValid = true;
      } else {
        selectedSubQuestion.choices[subQuestionChoiceIndex].isValid = false;
      }

      selectedSubQuestion.choices[subQuestionChoiceIndex].isDuplicate = false;
      selectedSubQuestion.choices[subQuestionChoiceIndex].text = choiceText;
    },
    HandleSubQuestionAddChoice(draft, action: PayloadAction<IHandleAddChoiceIdentifierPayload>) {
      const { sectionIndex, sectionItemIndex, choiceIndex, questionIndex } = action.payload;

      draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].choices[choiceIndex].subQuestions[
        questionIndex
      ].choices.push({
        ...initialChoiceData,
        id: generateRandomNumber()
      });
    },
    HandleSubQuestionRemoveChoice(draft, action: PayloadAction<ISubQuestionChoiceIdentifierPayload>) {
      const { sectionIndex, sectionItemIndex, choiceIndex, subQuestionChoiceIndex, questionIndex } = action.payload;
      draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].choices[choiceIndex].subQuestions[
        questionIndex
      ].choices.splice(subQuestionChoiceIndex, 1);

      return draft;
    },
    HandleResetSubQuestions(draft, action: PayloadAction<{ data: IItemIdentifier; isChecked: boolean }>) {
      const { sectionIndex, sectionItemIndex } = action.payload.data;
      if (action.payload.isChecked) {
        draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].hasFollowUpQuestion = true;
      } else {
        draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].hasFollowUpQuestion = false;
        let choices = draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].choices;
        if (choices?.length > 0) {
          choices.forEach((choice) => {
            choice.isValid = true;
            choice.subQuestions = [];
          });
        }
      }
    }
  }
});

export const {
  setCustomQuestion,
  setCustomQuestionSettings,
  SaveTemplateModalData,
  SaveSectionModalData,
  RenameSectionModalData,
  ClearFormData,
  DeleteSectionData,
  HandleQuestionTitleOnChange,
  AddNewQuestionHandler,
  MergeSectionHandler,
  DuplicateSectionHandler,
  DuplicateQuestionHandler,
  DeleteQuestionHandler,
  ChangeRequiredQuestionHandler,
  HandleTitleOnChange,
  HandleDescriptionOnChange,
  SetIsInvalidFlag,
  HandleQuestionReordering,
  HandleSectionReordering,
  HandleQuestionTypeChange,
  HandleChoiceTextOnChange,
  HandleAddChoice,
  HandleRemoveChoice,
  OnClickYesNoCheckbox,
  OnAddAdditionalQuestion,
  OnRemoveAdditionalQuestion,
  OnSubQuestionTypeChange,
  OnChangeSubQuestionTitle,
  HandleSubQuestionChoiceTextOnChange,
  HandleSubQuestionAddChoice,
  HandleSubQuestionRemoveChoice,
  HandleResetSubQuestions
} = formBuilderSlice.actions;

export default formBuilderSlice.reducer;
