import React, { useEffect, memo } from "react";
import * as signalR from '@microsoft/signalr';
import { getWebSocketUrl, signalRConnectionInit, addGroup } from 'store/services/signalR-service';
import { InProcessGatherStoreState } from "common/model/inprocess-gather-table";
import { ArchivedGatherStoreState } from "common/model/archived-gather-table";
import { ISignalRConnectionInfo } from "common/model/signal-r-connection-info";
import { NotificationType } from "common/enum";
import { BatchStatus, ClientType, GatherStatus } from "common/enum/GatherEnums";
import { setInProcessGatherData } from "store/slices/inprocess-gather-slice";
import { useAppDispatch } from "common/hooks/redux-hooks";
import { store } from 'store';
import { IDeliveredGatherStoreState } from "common/model/delivered-gather-table";
import { setDeliveredGatherData } from "store/slices/delivered-gather-slice";
import { setArchivedGatherData } from "store/slices/archived-gather-slice";
import { EngagementLetterStatus, OrganizerStatus, SignatureStatus, SourceDocumentStatus } from "common/model/GatherDocumentModel";
import { LogoutCause } from "common/enum";
import { setUserPrivilegeChangedModel } from "store/slices/user-previlege-changed-slice";
import { signalRConstants } from "helper/Constants";
import { setInProcessBatchData } from "store/slices/inprocess-batch-slice";
import { InProcessBatchStoreState } from "common/model/inprocess-Batch-table";
import { setDrlNotApplicableStatusUpdatedModel } from "store/slices/drlNotApplicableStatusUpdatedSlice";

interface SignalRProps {
    company_id: number;
    user_id: number;
    getMyDownloadList: (callback?: any) => void;
}

export interface INotificationMessage {
    gatherId: number;
    batchId: number;
    companyId: number;
    notificationType: NotificationType;
    requestedDrlCount?: number;
    uploadedDrlCount?: number;
    customData: Map<string, any>;
}

export interface INotificationMetaData {
    clientId: string
}

export interface IUpdateDocumentData {
    gatherId:number ,
    organizerStatus?:OrganizerStatus,
    engagementLetterStatus?:EngagementLetterStatus,
    sourceDocumentStatus?: SourceDocumentStatus,
    requestedDrlCount?: number,
    uploadedDrlCount?: number,
    notificationCustomData?: { [key: string]: any },
}

export interface IUserAutoLogoutNotificationMessage {
    Users: number;
    JobId: number;
    logoutCause: LogoutCause;
    CompanyId: number;
}

export const SignalRWebSocket: React.FC<SignalRProps> = ({ company_id, user_id, getMyDownloadList }) => {
    var user = "";
    var group = "";
    var apiBaseUrl = "";

    const dispatch = useAppDispatch();
    const getWebSocketGroup = (companyId: number) => {
        var group = "00000000" + companyId;
        return signalRConstants.GTR + group.substring(group.length - 8);
    };

    const handleWebSocketUrlResponse = (url: string) => {
        apiBaseUrl = url;
        user = company_id + '_' + user_id;
        group = getWebSocketGroup(company_id);
        signalRConnectionInit(user, apiBaseUrl, initiateConnection);
    };

    const initiateConnection = (info: ISignalRConnectionInfo) => {
        info.accessToken = info.accessToken || info.accessKey;
        info.url = info.url || info.endpoint;
        const options = {
            accessTokenFactory: () => info.accessToken
        };
        const connection = new signalR.HubConnectionBuilder()
            .withUrl(info.url, options)
            .configureLogging(signalR.LogLevel.Information)
            .build();
        console.log(connection);
        connection.onclose(() => {
            startConnection(connection);
        });
        startConnection(connection);
    };

    const startConnection = (connection: any) => {
        connection.start()
            .then(function () {
                connection.on('UserPrivilegesChanged', processUserPrivilegesChanged);
                connection.on('GatherDocumentUpdate', processGatherUpdate);
                connection.on('BulkDownload', processDownloadNowDownloads);
                addGroup(user, group, apiBaseUrl);
                connection.invoke('getConnectionId')
                    .then(function (connectionId: any) {
                    });
            })
            .catch(function (err: any) {
                console.error(err);
                setTimeout(startConnection, 5000, connection);
            });
    };

    const processGatherUpdate = (notificationMessage: INotificationMessage) => {     
        ProcessInProcessPageNotification(notificationMessage);
        ProcessBatchInProcessPageNotification(notificationMessage);
        ProcessDeliveredOrArchivedPageNotification(notificationMessage);
    }

    const ProcessInProcessPageNotification = (notificationMessage: INotificationMessage) => 
    {
        let value:keyof typeof NotificationType = notificationMessage.notificationType
        let gatherStatus = GatherStatus.None;
        switch (NotificationType[value]) {
            case NotificationType.DeliverySuccess:
                gatherStatus = GatherStatus.Delivered;
                break;
            case NotificationType.DeliveryFailed:
                gatherStatus = GatherStatus.DeliveryFailed;
                break;
            case NotificationType.RecognizerCompleted:
                gatherStatus = GatherStatus.Ready;
                break;
            case NotificationType.RecognizerFailed:
                gatherStatus = GatherStatus.ErrorProcessingUpload;
                break;
            case NotificationType.DrlExtractionCompleted:
                gatherStatus = GatherStatus.Ready;
                break;
            case NotificationType.DrlExtractionFailed:
                gatherStatus = GatherStatus.ErrorDrlExtraction;
                break;
        }
        updateInProcessPageDocument(notificationMessage.gatherId, gatherStatus);
    }

    const ProcessBatchInProcessPageNotification = (notificationMessage: INotificationMessage) => {
        let value:keyof typeof NotificationType = notificationMessage.notificationType
        switch (NotificationType[value]) { 
            case NotificationType.BatchProcessingSuccess:
                updateBatchInProcessPageDocument(notificationMessage.batchId, BatchStatus.UploadProcessingSuccess);
                break;
            case NotificationType.BatchProcessingPartiallySuccess:
                updateBatchInProcessPageDocument(notificationMessage.batchId, BatchStatus.UploadProcessingPartiallySucceeded);
                break;
            case NotificationType.BatchProcessingFailed:
                updateBatchInProcessPageDocument(notificationMessage.batchId, BatchStatus.ErrorProcessingUpload);
                break;
        }
    }

    const ProcessDeliveredOrArchivedPageNotification = (notificationMessage: INotificationMessage) => 
    {
        let value: keyof typeof NotificationType = notificationMessage.notificationType;    
        switch (NotificationType[value]) {
            case NotificationType.OrganizerCompleted:
                updateDeliveredDocument({ gatherId: notificationMessage.gatherId, organizerStatus: OrganizerStatus.Completed });
                break;
            case NotificationType.OrganizerPartiallyCompleted:
                updateDeliveredDocument({ gatherId: notificationMessage.gatherId, organizerStatus: OrganizerStatus.PartiallyCompleted });
                break;
            case NotificationType.Reviewed:
                updateDeliveredDocument({ gatherId: notificationMessage.gatherId, engagementLetterStatus: EngagementLetterStatus.Reviewed, notificationCustomData: notificationMessage.customData});
                break;
            case NotificationType.PartiallyReviewed:
                updateDeliveredDocument({ gatherId: notificationMessage.gatherId, engagementLetterStatus: EngagementLetterStatus.PartiallyReviewed, notificationCustomData: notificationMessage.customData});
                break;
            case NotificationType.PartiallySigned:
                updateDeliveredDocument({ gatherId: notificationMessage.gatherId, engagementLetterStatus: EngagementLetterStatus.PartiallySigned, notificationCustomData: notificationMessage.customData});
                break;
            case NotificationType.Esigned:
                updateDeliveredDocument({ gatherId: notificationMessage.gatherId, engagementLetterStatus: EngagementLetterStatus.ESigned, notificationCustomData: notificationMessage.customData});
                break;
            case NotificationType.QuestionnaireCompleted:
                updateDeliveredDocument({ gatherId: notificationMessage.gatherId, organizerStatus: OrganizerStatus.QuestionnaireCompleted });
                break;
            case NotificationType.SourceDocumentCompleted:
                updateDeliveredDocument({ gatherId: notificationMessage.gatherId, sourceDocumentStatus: SourceDocumentStatus.Completed, requestedDrlCount: notificationMessage.requestedDrlCount, uploadedDrlCount: notificationMessage.uploadedDrlCount });
                break;
            case NotificationType.SourceDocumentUploaded:
                handleNotApplicableStatusUpdatedMessage(notificationMessage);
                break; 
        }
    }
    
    const handleNotApplicableStatusUpdatedMessage = (notificationMessage: INotificationMessage) => {
        dispatch(setDrlNotApplicableStatusUpdatedModel({isDrlNotApplicableStatusUpdate: true}));
        updateDeliveredDocument({ gatherId: notificationMessage.gatherId, sourceDocumentStatus: SourceDocumentStatus.Uploaded, requestedDrlCount: notificationMessage.requestedDrlCount, uploadedDrlCount: notificationMessage.uploadedDrlCount });
    };

    const updateInProcessPageDocument = (gatherId:number , gatherStatus:GatherStatus) => {      
        let documentFound:boolean = false;  
        const inprocessGatherData: InProcessGatherStoreState = store.getState().inProcessGatherReducer;
        const { documents } = inprocessGatherData.model ?? [];  
        var updatedDocuments = documents.map(document => {
            if (document.id === gatherId)
            {                
                documentFound = true;
                return {...document, status:gatherStatus};
            }
            return document;
        });

        if (documentFound)
        {      
            var updatedModel = {...inprocessGatherData.model, documents: updatedDocuments};
            dispatch(setInProcessGatherData({ ...inprocessGatherData, model:updatedModel}));
            return;
        }        
    };

    const updateBatchInProcessPageDocument = (batchId:number, batchStatus:BatchStatus) => {            
        let batchFound:boolean = false;  
        const inprocessBatchData: InProcessBatchStoreState = store.getState().inProcessBatchReducer;
        const { batches } = inprocessBatchData.model ?? [];
        var updatedBatches = batches.map(batch => {
            if (batch.id === batchId)
            {
                batchFound = true;
                return {...batch, status: batchStatus};
            }
            return batch;
        });

        if (batchFound)
        {
            var updatedModel = {...inprocessBatchData.model, batches: updatedBatches};            
            dispatch(setInProcessBatchData({...inprocessBatchData, model:updatedModel}))
        }
    }

    const updateDeliveredDocument = (data: IUpdateDocumentData) => { 
        const deliveredGatherData : IDeliveredGatherStoreState = store.getState().deliveredGatherReducer;
        const { documents } = deliveredGatherData.model ?? [];  
        let documentFound : boolean = false;
        var updatedDocuments = documents.map(document => {
            if (document.id === data.gatherId)
            {
                documentFound = true;
                if (data.organizerStatus != undefined)
                {
                    return {...document, previousOrganizerStatus: document.organizerStatus, organizerStatus: data.organizerStatus};
                }
                if (data.engagementLetterStatus != undefined)
                {
                    let clientType = data.notificationCustomData && data.notificationCustomData["ClientType"] as ClientType;
                    let notificationClientSignatureStatus = data.notificationCustomData && data.notificationCustomData["ClientSignatureStatus"] as SignatureStatus;
                    if (clientType == ClientType.Taxpayer) {
                        return {...document, engagementLetterStatus: data.engagementLetterStatus, tpSignerStatus: notificationClientSignatureStatus};
                    }
                    else if (clientType == ClientType.Spouse) {
                        return {...document, engagementLetterStatus: data.engagementLetterStatus, spouseSignerStatus: notificationClientSignatureStatus};
                    }
                    else {
                        return {...document, engagementLetterStatus: data.engagementLetterStatus};
                    }
                }
                if (data.sourceDocumentStatus != undefined)
                {
                    if (data.requestedDrlCount && data.uploadedDrlCount)
                    {                            
                        return {
                            ...document, 
                            sourceDocumentStatus: data.sourceDocumentStatus,
                            requestedDRLCount: data.requestedDrlCount,
                            uploadedDRLCount: data.uploadedDrlCount 
                        };
                    }
                    return {
                        ...document, 
                        sourceDocumentStatus: data.sourceDocumentStatus 
                    };
                }
            }
            return document;
        });
        if (documentFound)
        {            
            var updatedModel = {...deliveredGatherData.model, documents: updatedDocuments};
            dispatch(setDeliveredGatherData({ ...deliveredGatherData, model:updatedModel}));
            return;
        }
        updateArchivedDocument(data);
    };

    const updateArchivedDocument = (data: IUpdateDocumentData) => { 
        const archivedGatherData: ArchivedGatherStoreState = store.getState().archivedGatherReducer;
        const { documents } = archivedGatherData.model ?? [];  
        var updatedDocuments = documents.map(document => {
            if (document.id === data.gatherId)
            {
                if (data.organizerStatus != undefined)
                {
                    return {...document, previousOrganizerStatus: document.organizerStatus, organizerStatus: data.organizerStatus};
                }
                if (data.engagementLetterStatus != undefined)
                {
                    return {...document, engagementLetterStatus: data.engagementLetterStatus};
                }
                if (data.sourceDocumentStatus != undefined)
                {
                    if (data.requestedDrlCount && data.uploadedDrlCount)
                    {                            
                        return {
                            ...document, 
                            sourceDocumentStatus: data.sourceDocumentStatus,
                            requestedDRLCount: data.requestedDrlCount,
                            uploadedDRLCount: data.uploadedDrlCount 
                        };
                    }
                    return {
                        ...document, 
                        sourceDocumentStatus: data.sourceDocumentStatus 
                    };
                }
            }
            return document;
        });
        var updatedModel = {...archivedGatherData.model, documents: updatedDocuments};
        dispatch(setArchivedGatherData({ ...archivedGatherData, model:updatedModel}));
    };

    const processUserPrivilegesChanged = (notificationMessage: IUserAutoLogoutNotificationMessage) => {   
        dispatch(setUserPrivilegeChangedModel({logoutCause: notificationMessage.logoutCause, isUserPrivilegeChanged: true}));
    }

    const processDownloadNowDownloads = (notificationMessage: INotificationMessage) => {
        let value: keyof typeof NotificationType = notificationMessage.notificationType
        switch (NotificationType[value]) {
            case NotificationType.BulkDownloadSuccess:
            case NotificationType.BulkDownloadFailed:
                console.log("signal R notification type", notificationMessage.notificationType);
                getMyDownloadList();
                break;
        }
    }

    useEffect(() => {
        getWebSocketUrl(handleWebSocketUrlResponse);
    }, [])
    return <div />;
}

export const MemoizedSignalRWebSocket = memo(SignalRWebSocket);