import * as React from "react";
import SectionItemPreview from "./SectionItemPreview";
import { ISections } from "store/models/CustomQuestionModel";
import { CircleDeleteIcon } from "assets/svg/SVGIconsCollection";
import { CustomQuestionsTabConstants } from "helper/Constants";
import ConfirmDialog from "common/components/modal/ConfirmDialog";

interface SectionPreviewProps {
  section: ISections;
  sectionsCount?: number;
  sectionIndex: number;
  resetValidations: boolean;
  isEditMode?: boolean;
  isEditable?: boolean;
  onSectionDelete?: Function;
  onQuestionDelete?: Function;
}

const SectionPreview: React.FC<SectionPreviewProps> = ({
  section,
  sectionsCount,
  sectionIndex,
  resetValidations,
  isEditMode,
  isEditable,
  onSectionDelete,
  onQuestionDelete
}) => {
  const [questionaireSection, setQuestionaireSection] = React.useState({ ...section });
  const [showDeleteSectionModal, setShowDeleteSectionModal] = React.useState(false);
  const [showDeleteQuestionModal, setShowDeleteQuestionModal] = React.useState(false);
  React.useEffect(() => {
    setQuestionaireSection(section);
  }, [section]);

  const onSectionDeleteClick = () => {
    if (questionaireSection?.sectionItems.length > 0) {
      setShowDeleteSectionModal(true);
    }
  };
  const onQuestionDeleteClick = (sectionItem: any) => {
    if (questionaireSection?.sectionItems.length > 1) {
      let index: number = questionaireSection.sectionItems.indexOf(sectionItem);
      questionaireSection.sectionItems.splice(index, 1);
      if (onQuestionDelete) {
        onQuestionDelete(questionaireSection);
      }
    }
    setQuestionaireSection({ ...questionaireSection });
  };
  return (
    <div className="preview-section-container">
      <div className="preview-section-header">
        <p>{questionaireSection.sectionName}</p>
        {isEditMode && (
          <button
            className="section-delete-button"
            disabled={sectionsCount === 1 || !isEditable}
            onClick={onSectionDeleteClick}
            title={sectionsCount === 1 ? CustomQuestionsTabConstants.AtleastOneSectionRequired : ""}
          >
            <CircleDeleteIcon />
          </button>
        )}
      </div>

      {questionaireSection && questionaireSection.sectionItems.length > 0 && (
        <div className="mt-1-5">
          {questionaireSection.sectionItems.map((sectionItem, sectionItemIndex) => (
            <SectionItemPreview
              sectionItem={sectionItem}
              sectionItemIndex={sectionItemIndex}
              sectionIndex={sectionIndex}
              sectionItemsCount={questionaireSection.sectionItems.length}
              resetValidations={resetValidations}
              isEditMode={isEditMode}
              isEditable={isEditable}
              onQuestionDelete={onQuestionDeleteClick}
            />
          ))}
        </div>
      )}
      {showDeleteSectionModal && (
        <ConfirmDialog
          title={CustomQuestionsTabConstants.DeleteSectionTitle}
          show={showDeleteSectionModal}
          onHide={() => setShowDeleteSectionModal(false)}
          onSubmit={() => onSectionDelete && onSectionDelete(questionaireSection)}
          message={CustomQuestionsTabConstants.DeleteSectionConfirmation}
        />
      )}
    </div>
  );
};

export default SectionPreview;
