import * as React from "react";
import { AutomationLocators } from "helper/AutomationLocators";
import { RestartIcon } from "assets/svg/SVGIconsCollection";
import SectionPreview from "./parts/SectionPreview";
import { useAppDispatch, useAppSelector } from "common/hooks/redux-hooks";
import { IntialiseCqPreview } from "store/slices/custom-questions-preview";
import "./Preview.scss";
import { AppState } from "store";
import { Prompt } from "react-router";
import { CustomQuestionConstants } from "pages/Constants";
import { PageLoaderKeys } from "helper/Constants";
import LoaderWrapper from "common/pages/LoaderWrapper";
interface IPreviewProps {
  onClickPreviewClose: (formData: any) => void;
}

const Preview: React.FC<IPreviewProps> = ({ onClickPreviewClose }) => {
  const dispatch = useAppDispatch();
  const [resetValidations, setResetValidations] = React.useState(false);
  const formData = useAppSelector((state: AppState) => state.customQuestionsPreviewReducer.formData);
  const intialFormData = useAppSelector((state: AppState) => state.formBuilderReducer.formData);
  const [showPrompt, setShowPropmt] = React.useState<boolean>(false);

  React.useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    if (localStorage.getItem("updatedFormData") !== localStorage.getItem("formData")) {
      setShowPropmt(true);
    } else {
      setShowPropmt(false);
    }
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);

  const alertUser = (e) => {
    if (localStorage.getItem("updatedFormData") !== localStorage.getItem("formData")) {
      e.preventDefault();
      e.returnValue = "";
      return "";
    }
  };

  const handleOnClickRestart = () => {
    dispatch(IntialiseCqPreview(intialFormData));
    setResetValidations(!resetValidations);
  };

  const handleOnClickClose = () => {
    onClickPreviewClose({ title: formData.title, description: formData.description });
  };

  return (
    <div className="form-builder-container">
      <LoaderWrapper keys={PageLoaderKeys.CustomQuestions} isScrollableView>
        <Prompt when={showPrompt} message={CustomQuestionConstants.PromptMessage} />
        <header>
          <div className="header-bottom-container">
            <span title={formData.template.name} className="ellipsis">
              {formData.template.name}
            </span>
            <div>
              <button
                className="preview-button cq-btn-with-icon"
                data-test-auto={AutomationLocators.Settings.CustomQuestions.PreviewRestart}
                onClick={handleOnClickRestart}
              >
                <RestartIcon />
                Restart
              </button>

              <button
                data-test-auto={AutomationLocators.Settings.CustomQuestions.PreviewClose}
                onClick={handleOnClickClose}
                className="cq-btn-primary"
              >
                Close
              </button>
            </div>
          </div>
        </header>

        <main>
          <div className="questionnaire-header-container-with-more-padding">
            <div className="preview-title">{formData.title}</div>
            <div className="description-container">
              <div className="preview-description">{formData.description}</div>
            </div>
          </div>

          {formData &&
            formData.sections.length > 0 &&
            formData.sections.map((section, sectionIndex) => (
              <SectionPreview resetValidations={resetValidations} sectionIndex={sectionIndex} section={section} />
            ))}
        </main>
      </LoaderWrapper>
    </div>
  );
};

export default Preview;
