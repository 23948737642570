import React, { ReactNode, useState } from "react";
import { ExclamationMarkIcon } from "assets/icons/IconCollection";
import { CrossIcon, InfoMarkIcon, SuccessMarkIcon } from "assets/svg/SVGIconsCollection";
import "./Alert.scss";
export enum AlertType {
  SUCCESS,
  WARNING,
  ERROR,
  INFO
}
interface IAlertProps {
  message?: string;
  type: AlertType;
  isInline?: boolean;
  children?: ReactNode;
  clearable?: boolean;
}
const getAlertIcon = (type: AlertType) => {
  switch (type) {
    case AlertType.SUCCESS:
      return <SuccessMarkIcon />;
    case AlertType.WARNING:
      return <ExclamationMarkIcon color="#D69F38" />;
    case AlertType.ERROR:
      return <ExclamationMarkIcon color="#B8433C" />;
    case AlertType.INFO:
      return <InfoMarkIcon />;
    default:
      return <></>;
  }
};

const getAlertClassName = (type: AlertType) => {
  switch (type) {
    case AlertType.SUCCESS:
      return "alert-success";
    case AlertType.WARNING:
      return "alert-warning";
    case AlertType.ERROR:
      return "alert-error";
    case AlertType.INFO:
      return "alert-info";
    default:
      return "";
  }
};

const Alert: React.FC<IAlertProps> = ({ message, type, isInline, children, clearable }) => {
  const [visible, setVisible] = useState(true);
  const handleClose = () => {
    setVisible(false);
  };
  if (!visible) return null;
  return (
    <div className={`alert-wrapper ${getAlertClassName(type)} ${isInline ? "inline-alert" : ""}`}>
      {getAlertIcon(type)}
      {message && <span>{message}</span>}
      {children}
      {clearable && (
        <div onClick={handleClose} className="cross-icon-wrapper">
          <CrossIcon width={16} height={16} />
        </div>
      )}
    </div>
  );
};

export default Alert;
