import React from "react";
import { GeneralSettingConstants } from "helper/Constants";
import { getDaysOptions } from "helper/HelperFunctions";
import DropdownComponent from "common/components/UIComponents/DropdownComponent";
import { GatherCompanySettingsModel } from "common/model/company-settings";
import { SettingsType } from ".";
interface IAutomaticRemindersProps {
  disableDropdown: boolean;
  state: GatherCompanySettingsModel;
  updateSettings: (type: SettingsType, payload: any) => void;
}
const AutomaticReminders: React.FC<IAutomaticRemindersProps> = ({ disableDropdown, updateSettings, state }) => {
  const options = getDaysOptions();
  return (
    <div className="sub-section-item">
      <div className="select-label-wrapper">
        <h5>Signing</h5>
        <div>
          <p>{GeneralSettingConstants.sendReminderSigningMsg}</p>
          <DropdownComponent
            selectedValue={state.reminderSettings.esignReminder.reminderDays}
            onChange={(event) => updateSettings(SettingsType.ESIGN_REMINDER, event)}
            searchable={false}
            id="dd-1"
            disabled={disableDropdown}
            options={options}
            clearable={false}
          />
          <p>{GeneralSettingConstants.sendReminderSigningMsgTrail}</p>
        </div>
      </div>
      <div className="select-label-wrapper">
        <h5>Organizers</h5>
        <div>
          <p>{GeneralSettingConstants.sendReminderSigningMsg}</p>
          <DropdownComponent
            selectedValue={state.reminderSettings.organizerReminder.reminderDays}
            onChange={(event) => updateSettings(SettingsType.ORG_REMINDER, event)}
            searchable={false}
            id="dd-2"
            disabled={disableDropdown}
            options={options}
            clearable={false}
          />
          <p>{GeneralSettingConstants.sendReminderSigningMsgTrail}</p>
        </div>
      </div>
    </div>
  );
};

export default AutomaticReminders;
