import { ParagraphIcon, DownIcon, MCQIcon, YesNoQuestionIcon } from "assets/svg/SVGIconsCollection";
import React from "react";
import { Dropdown } from "react-bootstrap";
import { QuestionType } from "store/models/CustomQuestionModel";
const questionTypes = [
  { type: QuestionType.PARAGRAPH, label: "Paragraph", icon: <ParagraphIcon /> },
  { type: QuestionType.YESNO, label: "Yes/No", icon: <YesNoQuestionIcon /> },
  { type: QuestionType.MULTICHOICE, label: "Multiple Choice", icon: <MCQIcon /> }
];

interface IQuestionTypeDropdownProps {
  isFollowUpDropdown?: boolean;
  selectedQuestionType: QuestionType;
  onQuestionTypeChange: (questionType: QuestionType) => void;
}

const QuestionTypeDropdown: React.FC<IQuestionTypeDropdownProps> = ({
  selectedQuestionType,
  onQuestionTypeChange,
  isFollowUpDropdown
}) => {
  const getDropdownToogleIcon = (selectedQuestionType: QuestionType, getIcon?: boolean) => {
    const [filteredData] = questionTypes.filter((questionTypes) => questionTypes.type === selectedQuestionType);
    if (getIcon) {
      return filteredData.icon;
    }
    return filteredData.label;
  };
  return (
    <div className="question-type-dropdown-wrapper">
      {!isFollowUpDropdown && <p>Type</p>}
      <Dropdown drop="down">
        <Dropdown.Toggle>
          <div>
            {getDropdownToogleIcon(selectedQuestionType, true)}
            <label className="dropdown-toggle-label">{getDropdownToogleIcon(selectedQuestionType)}</label>
          </div>

          <DownIcon />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {questionTypes.length > 0 &&
            questionTypes.map((questionType) => {
              return (
                <Dropdown.Item
                  key={questionType.label}
                  onClick={() => onQuestionTypeChange(questionType.type)}
                  className="type-menu-item"
                >
                  {questionType.icon}
                  <label className="type-menu-item-label"> {questionType.label}</label>
                </Dropdown.Item>
              );
            })}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default QuestionTypeDropdown;
