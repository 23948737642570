import React from "react";
import { Form } from "react-bootstrap";
import SectionItemFooter from "./SectionItemFooter";
import { useDispatch } from "react-redux";

import QuestionTypeDropdown from "./QuestionTypeDropdown";
import {
  IHandleQuestionTypeChangePayload,
  IOnChangeHandlerPayload,
  ISectionItems,
  QuestionType
} from "store/models/CustomQuestionModel";
import { GrabIcon, MCQIcon, ParagraphIcon, YesNoQuestionIcon } from "assets/svg/SVGIconsCollection";
import { HandleQuestionTitleOnChange, HandleQuestionTypeChange } from "store/slices/form-builder";
import ParagraphQuestion from "./question-types/ParagraphQuestion";
import YesNoQuestion from "./question-types/YesNoQuestion";
import MultiChoiceQuestion from "./question-types/MultiChoiceQuestion";
import { FormBuilderConstants, FormPreviewConstants } from "helper/Constants";
import { AutomationLocators } from "helper/AutomationLocators";

import "../section-item/question-types/QuestionTypes.scss";
import "./SectionItem.scss";
interface IExpandedSectionItemProps {
  sectionItem: ISectionItems;
  sectionItemIndex: number;
  sectionIndex: number;
  totalquestions: number;
}

const questionTypes = [
  { type: QuestionType.PARAGRAPH, label: "Paragraph", icon: <ParagraphIcon /> },
  { type: QuestionType.YESNO, label: "Yes/No", icon: <YesNoQuestionIcon /> },
  { type: QuestionType.MULTICHOICE, label: "Multiple Choice", icon: <MCQIcon /> }
];
const ExpandedSectionItem: React.FC<IExpandedSectionItemProps> = ({
  sectionItem,
  sectionIndex,
  sectionItemIndex,
  totalquestions
}) => {
  const dispatch = useDispatch();
  const [questionTitle, setQuestionTitle] = React.useState(sectionItem.questionTitle);
  const [selectedQuestionType, setSelectedQuestionType] = React.useState<QuestionType>(sectionItem.questionTypeId);
  React.useEffect(() => {
    setQuestionTitle(sectionItem.questionTitle);    
    setSelectedQuestionType(sectionItem.questionTypeId);
  }, [sectionItem]);
  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value.trimStart();
    setQuestionTitle(input);
    const payload: IOnChangeHandlerPayload = {
      value: input,
      sectionIndex,
      sectionItemIndex
    };
    setQuestionTitle(input);
    dispatch(HandleQuestionTitleOnChange(payload));
  };

  const onQuestionTypeChange = (selectedType) => {
    setSelectedQuestionType(selectedType);
    const payload: IHandleQuestionTypeChangePayload = { sectionIndex, sectionItemIndex, questionType: selectedType };
    dispatch(HandleQuestionTypeChange(payload));
  };
  const getQuestionType = (
    questionType: QuestionType,
    sectionItem: ISectionItems,
    sectionIndex: number,
    sectionItemIndex: number
  ) => {
    switch (questionType) {
      case QuestionType.PARAGRAPH:
        return <ParagraphQuestion />;
      case QuestionType.YESNO:
        return <YesNoQuestion sectionItem={sectionItem} sectionItemIndex={sectionItemIndex} sectionIndex={sectionIndex} />;
      case QuestionType.MULTICHOICE:
        return <MultiChoiceQuestion sectionItem={sectionItem} sectionItemIndex={sectionItemIndex} sectionIndex={sectionIndex} />;

      default:
        break;
    }
  };

  return (
    <main className="section-item-container">
      <div className="reorder-icon-wrapper">
        <GrabIcon />
      </div>
      <div className={`question-container ${selectedQuestionType === QuestionType.YESNO ? "margin-bottom" : ""}`}>
        <div className="question-title-wrapper">
          <span>{sectionItemIndex + 1}.</span>
          <Form.Control
            data-test-auto={AutomationLocators.Settings.CustomQuestions.Section.questionTitle}
            type="text"
            value={questionTitle}
            onChange={onChangeHandler}
            placeholder={FormBuilderConstants.QuestionTitle.placeholder}
            maxLength={FormBuilderConstants.QuestionTitle.maxLength}
            className={sectionItem.isValid === false ? "invalid-input" : ""}
          />
          {sectionItem.isValid === false && <p className="error-text">{FormPreviewConstants.RequiredValidation}</p>}
        </div>

        <QuestionTypeDropdown selectedQuestionType={selectedQuestionType} onQuestionTypeChange={onQuestionTypeChange} />
      </div>
      {getQuestionType(selectedQuestionType, sectionItem, sectionIndex, sectionItemIndex)}
      <SectionItemFooter
        sectionItem={sectionItem}
        sectionItemIndex={sectionItemIndex}
        sectionIndex={sectionIndex}
        totalquestions={totalquestions}
      />
    </main>
  );
};

export default ExpandedSectionItem;
