import React from "react";
import { DeleteIcon, EditIcon } from "assets/icons/IconCollection";
import { Form } from "react-bootstrap";
import { SavedMessageModel } from "common/model/saved-message";
import { convertTextToHtmlInSavedMessage } from "helper/HelperFunctions";
import { MessageSettingsConstants } from "pages/Constants";
import DOMPurify from "dompurify";
interface IMessageBoxProps {
  selectedMessage: SavedMessageModel;
  onEdit: () => void;
  onDelete: () => void;
}

const MessageBox: React.FC<IMessageBoxProps> = ({ selectedMessage, onEdit, onDelete }) => {

  const isSystemDefaultMessage = selectedMessage.isSystemDefault;
  const sanitizedHtml = DOMPurify.sanitize(selectedMessage.text);
  return (
    <div className="message-box-wrapper">
      <header>Preview</header>
      <div>
        <div className="item-group">
          <span>Name</span>
          <p>{selectedMessage.name}</p>
        </div>
        <div className="item-group body-field rich-body-preview">
          <span>Body</span>
          <p
            dangerouslySetInnerHTML={{
              __html: convertTextToHtmlInSavedMessage(sanitizedHtml)
            }}
          ></p>
        </div>
        {selectedMessage.isEditable && (
          <div className="settings-form-item margin-top-10-px">
            <Form.Check disabled checked={selectedMessage.isEditable} type="checkbox" />
            <Form.Label>Allow users to edit before sending</Form.Label>
          </div>
        )}
      </div>
      <footer>
        <button
          onClick={onEdit}
          className="btn-with-icon"
          title={MessageSettingsConstants.EditMessage}
        >
          <EditIcon />
          Edit
        </button>
        {!isSystemDefaultMessage?
         (<button
          onClick={onDelete}
          className="btn-with-icon delete-icon"
          title={MessageSettingsConstants.DeleteMessage}
        >
          <DeleteIcon />
          Delete
        </button>):<></>}
      </footer>
    </div>
  );
};

export default MessageBox;
