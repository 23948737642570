import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ILocationData, initialLocationData } from "common/model/user";

const userOfficeLocationSlice = createSlice({
    name: 'userLocation',
    initialState: initialLocationData as ILocationData,
    reducers: {
        setUserOfficeLocations(state, action: PayloadAction<number[]>) {
            state.locations = action.payload
        }
    }
})

export const { setUserOfficeLocations } = userOfficeLocationSlice.actions;
export const { reducer: userLocationReducer } = userOfficeLocationSlice;