import React, { useEffect, useRef, useState } from "react";
import "./PhoneInput.scss";
import PhoneInput, { CountryData } from "react-phone-input-2";
import { isValidTenDigitNumber, validateCountryCode } from "helper/GatherHelper";

interface ICustomePhoneInputProps {
  initialCountryCode: string | null;
  initialMobileNumber: string;
  isMobileDisabled?: boolean;
  isCountryCodeDisabled?: boolean;
  onChangeUserMobile: (mobile: string, countryCode: string) => void;
}

export const CustomePhone: React.FC<ICustomePhoneInputProps> = ({
  initialCountryCode,
  initialMobileNumber,
  isMobileDisabled,
  isCountryCodeDisabled,
  onChangeUserMobile
}) => {
  initialCountryCode = initialCountryCode && validateCountryCode(initialCountryCode) ? initialCountryCode : "";
  const [countryCode, setCountryCode] = useState(initialCountryCode);
  const [mobileNumber, setMobileNumber] = useState(initialMobileNumber);
  const [showResetButton, setShowResetButton] = useState(false);
  const parentRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setCountryCode(initialCountryCode);
    setMobileNumber(initialMobileNumber);
    if (
      (initialMobileNumber != null && initialMobileNumber.length > 0) ||
      (initialCountryCode != null && initialCountryCode.length > 0)
    ) {
      setShowResetButton(true);
    }
  }, [initialCountryCode, initialMobileNumber]);

  const handleChangeUserMobile = (event: React.ChangeEvent<HTMLInputElement>): void => {
    let mobileNumber = event.target.value;
    let isErase = mobileNumber.length < initialMobileNumber?.length;
    if (isErase || isValidTenDigitNumber(mobileNumber)) {
      setMobileNumber(mobileNumber);
      onChangeUserMobile(mobileNumber, countryCode || "");
      setShowResetButton(true);
    }
  };

  const handleChangecountryCode = (fullValue: string, countryObject: CountryData): void => {
    setCountryCode(`+${countryObject.dialCode}`);
    onChangeUserMobile(mobileNumber, `+${countryObject.dialCode}`);
    setShowResetButton(true);
  };
  const onReset = (): void => {
    onChangeUserMobile("", "");
    setCountryCode(null);
    setShowResetButton(false);
  };
  const getCurrentWidth = () => {
    if (parentRef.current) {
      return `${parentRef.current?.offsetWidth}px`;
    }
    return "290px";
  };

  return (
    <div ref={parentRef} className="mobile-section">
      <PhoneInput
        key={countryCode}
        containerClass={isCountryCodeDisabled?"input-cc-mobile-disabled":"input-cc-mobile"}
        autoFormat={false}
        country={'us'}
        placeholder=""
        value={countryCode}
        onChange={handleChangecountryCode}
        disabled={isCountryCodeDisabled}
        dropdownStyle={{
          width: getCurrentWidth()
        }}
      />
      <input
        type="text"
        name="user-mobile"
        id="user-mobile"
        value={mobileNumber}
        onChange={handleChangeUserMobile}
        className={isMobileDisabled ? "user-mobile-disabled" : "user-mobile"}
        maxLength={10}
        disabled={isMobileDisabled}
      />
      {showResetButton && mobileNumber &&(
        <button type="button" onClick={onReset} disabled={isCountryCodeDisabled || isMobileDisabled} className="reset-button">
          x
        </button>
      )}
    </div>
  );
};
