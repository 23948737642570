import { useEffect } from 'react';
import { signoutRedirectCallback } from 'oidc-client/userService';
import LoadingView from 'common/pages/LoadingScreen';
import { useHistory } from 'react-router-dom';

function SignoutOidc() {
    const history =useHistory();
    useEffect(() => {
        async function signoutAsync() {
            await signoutRedirectCallback()
            history.push('/login');
        }
        signoutAsync()
    }, [])

    return <LoadingView showLogo={true} />
}

export default SignoutOidc;
