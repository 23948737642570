import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { API } from "services/api/api-config";
import Axios from "services/api/axios-interceptor";
import { AppState } from "store";
import { StatusType, pushNotification } from "store/slices/notification";
import { setIsLoading, setDelegatedSigners, setUserSignatures } from "store/slices/userSetting-slice";

export const requestDelegatedSigners = (userId : number): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
        try {
           dispatch(setIsLoading(true));
           Axios.get(`${API.GATHER.USER.REQUEST_DELEGATED_ERO_SIGNERS}/${userId}`,{
                method: 'GET',
                credentials: 'include'
           })
                .then(response => response)
                .then((data:any)=>{
                    dispatch(setDelegatedSigners(data.data));
                })
        }
        catch (error: any) {

        }
    }

export const requestDownloadPath = (userId: number, callback?: (data?: any) => void): ThunkAction<void, AppState, unknown, Action> => 
    (dispatch, getState) => {
        let state = getState().userSettingReducer;
        if (!state.userSignatures[userId] || (state.userSignatures[userId] && state.userSignatures[userId].signatureDownloadPath == "")) {
            Axios.get(`${API.GATHER.USER.REQUEST_DOWNLOAD_PATH}/${userId}`,{
                method: 'GET',
                credentials: 'include'
            })
                .then(response => response)
                .then((data:any) => {
                    if (callback) {
                        callback(data.sas);
                    }
                    dispatch(setUserSignatures({path: data.data.sas, userId: userId}));
                }).catch(error => {
                    dispatch(pushNotification({
                        statusMessage: error.statusText,
                        statusType: StatusType.Error,
                        statusCode: error?.status
                    }));
                });
            // addTask(fetchTask);
        }
        else {
            if (callback) {
                callback(state.userSignatures[userId].signatureDownloadPath);
            }
        }
    }