import { FC } from 'react';
import { Logout } from 'helper/Constants';
import { CustomModal } from 'common/components/UIComponents/CustomModal';

interface LogoutModalProps {
    show: boolean;
    onHide: () => void;
    onLogout: () => void;
}

const LogoutModal: FC<LogoutModalProps> = ({ show, onLogout, onHide }) => {
    return <CustomModal
        title={Logout.Title}
        show={show}
        hideCancelButton={false}
        cancelButtonName={Logout.No}
        confirmButtonName={Logout.Yes}
        onHide={onHide}
        onSubmit={onLogout}
        isConfirmModal={false}
        confirmButtonClassName="logout-confirmation-button"
    >
        <p> {Logout.LogoutConfirmation}</p>
    </CustomModal>
}
export default LogoutModal;
