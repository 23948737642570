import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RecycledGatherData, RecycledGatherStoreState } from "common/model/recycle-gather-table";

const initialRecycledGatherState: RecycledGatherStoreState = {
    loading: false,
    model: {
        count: 0,
        documents: []
    },
    popupLoading: false,
    sourceDocumentMetaData: {},
    query: ''
}

const recycledGatherSlice = createSlice({
    name: 'recycledGather',
    initialState: initialRecycledGatherState,
    reducers: {
        setRecycledGatherData: (state, action: PayloadAction<RecycledGatherData>) => {
            state.model = action.payload.model;
            state.query = action.payload.query;
        },
        startLoader: (state) => {
            state.loading = true;
        },
        stopLoader: (state) => {
            state.loading = false;
        }
    }
});

export const { startLoader, setRecycledGatherData, stopLoader } = recycledGatherSlice.actions;

export const { reducer: recycledGatherReducer } = recycledGatherSlice;