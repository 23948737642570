import { Action } from "@reduxjs/toolkit"
import { ThunkAction } from "redux-thunk"
import { API } from "services/api/api-config"
import Axios from "services/api/axios-interceptor"
import { AppState } from "store"
import { AppNotifier } from "common/components/toast/Toast"
import { ErrorMessage, RequestNotification } from "helper/Constants"

export const saveOneTimePassword =
    (mobileNumber: string, countryCode: string): ThunkAction<void, AppState, unknown, Action> =>
        (dispatch) => {
            // startLoader();
            Axios.post(
                `${API.OTP_MANAGEMENT.SEND_OTP}`
                , { mobileNumber: mobileNumber, countryCode: countryCode }, {
                credentials: 'include'
            }
            )
                .then(() => {
                    // stopLoader();
                })
                .catch(function (error) {
                    // stopLoader();
                    AppNotifier.Error(RequestNotification.Failure.AccessCode);
                });
        };

export const validateOTP =
    (
        otp: string,
        mobileNumber: string,
        callback: (isOTPValid: boolean) => void
    ): ThunkAction<void, AppState, unknown, Action> =>
        (dispatch) => {
            // startLoader();
            Axios.post(
                `${API.OTP_MANAGEMENT.VALIDATE_OTP}`,
                { mobileNumber: mobileNumber, otp: otp },
                {
                    credentials: 'include'
                }
            )
                .then((response: any) => {
                    // stopLoader();
                    const data = response;
                    if (response === true || response.data === true) {
                        AppNotifier.Success(RequestNotification.Success.validOTP);
                        callback(true);
                    }
                    else {
                        AppNotifier.Error(RequestNotification.Failure.InvalidOTP);
                        callback(false);
                    }
                })
                .catch(function (error) {
                    // stopLoader();
                    AppNotifier.Error(ErrorMessage);
                });
        };

export const requestMFAOTPLength = (successCallback?: (data: number) => void): ThunkAction<void, AppState, unknown, Action> =>
    () => {
        //start loader
        Axios.get(API.OTP_MANAGEMENT.MFA_OTP_LENGTH)
            .then((response) => response.data as number)
            .then((data) => {
                //stop loader
                if (successCallback) {
                    successCallback(data);
                }
            })
            .catch((error) => {
                AppNotifier.Error(RequestNotification.Failure.MFA_OTP_LENGTH);
            });
    };
