import * as React from "react";

import { useDispatch } from "react-redux";
import { IQuestionReOrderPayload, ISectionItems, ISections } from "store/models/CustomQuestionModel";
import { CollapseIcon, ExpandIcon } from "assets/svg/SVGIconsCollection";
import ExpandedSectionItem from "./section-item/ExpandedSectionItem";
import CollapsedSectionItem from "./section-item/CollapsedSectionItem";
import SectionFooter from "./SectionFooter";
import "./Section.scss";
import { ReactSortable } from "react-sortablejs";
import { HandleQuestionReordering } from "store/slices/form-builder";
interface SectionProps {
  section: ISections;
  sectionIndex: number;
  setSelectedSection: React.Dispatch<React.SetStateAction<ISections>>;
  setActiveSection: React.Dispatch<React.SetStateAction<number>>;
  handleRenameSection: () => void;
  handleMergeSection: () => void;
  handleMoveSection: () => void;
  handleDeleteSection: (sectionIndex: number) => void;
}

const Section: React.FC<SectionProps> = ({
  section,
  sectionIndex,
  setSelectedSection,
  handleRenameSection,
  handleMergeSection,
  handleMoveSection,
  handleDeleteSection,
  setActiveSection
}) => {
  const dispatch = useDispatch();
  const [isExpandedView, toogleIsExpandedView] = React.useState(true);
  const handleSectionReOrdering = (updatedSectionItems: ISectionItems[]) => {
    const payload: IQuestionReOrderPayload = {
      sectionIndex: sectionIndex,
      sectionItems: updatedSectionItems
    };
    dispatch(HandleQuestionReordering(payload));
  };
  return (
    <div className="section-container">
            <span title={section.sectionName} className="ellipsis">
                {section.sectionName}
            </span>
            <div className="toggle-button-wrapper">
                {isExpandedView ? (
                    <span onClick={() => toogleIsExpandedView(!isExpandedView)}>
                        <ExpandIcon />
                    </span>
                ) : (
                    <span onClick={() => toogleIsExpandedView(!isExpandedView)}>
                        <CollapseIcon />
                    </span>
                )}
            </div>
            <ReactSortable
                list={JSON.parse(JSON.stringify(section.sectionItems))}
                setList={handleSectionReOrdering}
                ghostClass="ghost"
                easing="ease-out"
                handle=".reorder-icon-wrapper"
                makeOptions={{
                    forceAutoScrollFallback: true,
                    scroll: true,
                    scrollSensitivity: 30
                }}
            >
                {section?.sectionItems.length > 0 &&
                    section.sectionItems.map((sectionItem, sectionItemIndex, sectionItems) =>
                        isExpandedView ? (
                            <ExpandedSectionItem
                                key={sectionItemIndex}
                                sectionItem={sectionItem}
                                sectionItemIndex={sectionItemIndex}
                                sectionIndex={sectionIndex}
                                totalquestions={sectionItems.length}
                            />
                        ) : (
                            <CollapsedSectionItem
                                key={sectionItemIndex}
                                sectionItem={sectionItem}
                                sectionItemIndex={sectionItemIndex}
                            />
                        )
                    )}
            </ReactSortable>
            <SectionFooter
                sectionIndex={sectionIndex}
                section={section}
                handleMergeSection={handleMergeSection}
                handleMoveSection={handleMoveSection}
                handleRenameSection={handleRenameSection}
                setActiveSection={setActiveSection}
                setSelectedSection={setSelectedSection}
                handleDeleteSection={handleDeleteSection}
                isExpandedView={isExpandedView}
            />
        </div>
  );
};

export default Section;
