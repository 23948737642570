export interface IDefinedPasswordPolicy {
    passwordPolicy: PasswordPolicyType,
    defaultSettings: IPasswordSettings,
    isEnabled: boolean
}

export interface IPasswordSettings {
    passwordOptions: PasswordOptions;
    length: number;
    passwordAge: number;
}

export enum PasswordOptions {
    None = 0,
    UpperCase = 1,
    LowerCase = 2,
    Numbers = 4,
    SpecialCharacters = 8,
}

export enum PasswordPolicyType {
    NoPolicy = 'NoPolicy',
    SSRDefault = 'SSRDefault',
    FirmDefinedPolicy = 'FirmDefinedPolicy',
}

export interface IPasswordPolicySettings {
    passwordPolicy: PasswordPolicyType;
    passwordSettings: IPasswordSettings;
    modifiedDate: Date;
}



export const initialPasswordPolicySettings: IPasswordPolicySettings = {
    passwordPolicy: PasswordPolicyType.NoPolicy,
    modifiedDate: new Date(),
    passwordSettings: {
        passwordOptions: PasswordOptions.None,
        length: 8,
        passwordAge: 0,
    },
};

