

export type GatherTemplate={
    uploadTemplateId:number
    name:string
    description:string
    isDefault:boolean
    customQuestionTemplateId:number
    requestDocumentsTemplateId:number
    processSteps:ProcessStep[]
} 

export enum ProcessStep {
    Errors = 1,
    ClientInformation = 2,
    Organizer = 3,
    ESignDocuments = 4,
    CustomQuestions = 5,
    RequestDocuments = 6,
    Delivery = 7
} 

export type GatherTemplateListState =
{
    templateList: GatherTemplate[],  
    addStatus:boolean,
    isLoading: boolean
}