import React, { FC } from "react";
import { ToastContainer, toast } from "react-toastify";
import { CloseIcon, ErrorIcon, SuccessIcon, WarningIconToast } from "assets/svg/SVGIconsCollection";
import "react-toastify/dist/ReactToastify.css";
import { StatusType } from "store/slices/notification";

export enum ToastPosition {
  "top-right" = "top-right",
  "top-center" = "top-center",
  "top-left" = "top-left"
}
const options = {
  position: "top-right" as ToastPosition,
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  closeButton: (
    <div className="close-button">
      <CloseIcon />
    </div>
  )
};

const ToastBody: FC<{ message: string | React.ReactNode; type: StatusType }> = ({ message, type }) => (
  <div className={`toast-wrapper ${getTypeClassname(type)}`}>
    <div className="toast-icon">{toastIcon(type)}</div>
    <div className="toast-message">{message}</div>
  </div>
);

export class AppNotifier {
  static Success = (message: string) => {
    return toast.success(<ToastBody message={message} type={StatusType.Success} />, { ...options });
  };
  static Error = (message: string) => {
    return toast.error(<ToastBody message={message} type={StatusType.Error} />, { ...options });
  };
  static Warning = (message: string | React.ReactNode) => {
    return toast.warn(<ToastBody message={message} type={StatusType.Warning} />, { ...options });
  };
}

const toastIcon = (type: StatusType) => {
  switch (type) {
    case StatusType.Success:
      return <SuccessIcon />;
    case StatusType.Error:
      return <ErrorIcon />;
    case StatusType.Warning:
      return <WarningIconToast />;
    default:
      return <></>;
  }
};
const getTypeClassname = (type: StatusType) => {
  switch (type) {
    case StatusType.Success:
      return "success";
    case StatusType.Error:
      return "error";
    case StatusType.Warning:
      return "warning";
    default:
      return "";
  }
};

export class Toaster extends React.Component<any, any> {
  render() {
    return (
      <div className="font14">
        <ToastContainer
          className="toastify-container"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick={true}
          rtl={false}
          pauseOnHover
        />
      </div>
    );
  }
}
