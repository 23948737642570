export type DefaultSetting = {
    savedMessage: number;
    initialEmail: number;
    esignReminderEmail: number;
    organizerReminderEmail: number;
    sourceDocReminderEmail: number;
    downloadEngagementLetterEmail: number;
};
export enum AuthenticationOption  {
    None= 0,
    Text= 1,
    Email= 2
};

type AuthenticationSetting ={
    authenticationOption: AuthenticationOption;
}

type SourceDocumentSetting ={
    enabledSourceDocuments: boolean;
    notifyEveryTimeUploadOrDeleteSrcDoc: boolean;
    schNotification1PerDayUploadOrDeleteSrcDoc: boolean;
    noAdditionalCPANotificationRequired: boolean;
    enabledDocumentUploadNotification: boolean;
    enabledDocumentUploadPercentageNotification: boolean;
    enabledK1UploadPercentageNotification: boolean;
    documentUploadPercentage: number;
    k1UploadPercentage: number;
}


type ReminderOption= {
    reminderDays: number;
    enabled: boolean;
    isReadOnly: boolean;
}

type ReminderSetting ={
    automaticReminders:boolean;
    organizerReminder: ReminderOption;
    esignReminder: ReminderOption;
}
export interface ICompanyLogoSetting {
    isSsrLogo: boolean;
    logoPath: string;
    updatedLogo: Blob;
    isLogoUpdated: boolean;
    isLogoChangedToMyLogo: boolean;
    isCompanyNameChanged: boolean
}

export interface GatherCompanySettingsModel {
    defaultSettings: DefaultSetting;
    authenticationSettings: AuthenticationSetting;
    sourceDocumentSettings: SourceDocumentSetting;
    reminderSettings: ReminderSetting;
    sourceDocumentSettingChangeFlag: boolean;
}

export interface IUteSettings {
    uteEnabled: boolean;
    uteModified: string;
}