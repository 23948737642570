import React, { useEffect } from "react";
import { DeleteIcon, EditIcon } from "assets/icons/IconCollection";
import { IMessageData } from "..";
import { EmailTemplateMessage } from "common/model/client-instruction";
import { convertTextToHtmlInSavedMessage } from "helper/HelperFunctions";
import { MessageSettingsConstants } from "pages/Constants";
import DOMPurify from "dompurify";
interface IMessageBoxProps {
  selectedMessage: EmailTemplateMessage;
  onEdit: () => void;
  onDelete: () => void;
}

const MessageBox: React.FC<IMessageBoxProps> = ({ selectedMessage, onEdit, onDelete }) => {
  const sanitizedHtml = DOMPurify.sanitize(selectedMessage.body);
 useEffect(()=>{},[selectedMessage])
  return (
    <div className="message-box-wrapper">
      <header>Preview</header>
      <div>
        <div className="item-group">
          <span>Name</span>
          <p>{selectedMessage.name}</p>
        </div>
        <div className="item-group">
          <span>Subject</span>
          <p>{selectedMessage.subject}</p>
        </div>
        <div className="item-group body-field">
          <span>Body</span>
          <p
            dangerouslySetInnerHTML={{
              __html: convertTextToHtmlInSavedMessage(sanitizedHtml)
            }}
          ></p>
        </div>
      </div>
      <footer>
        <button
          onClick={onEdit}
          className="btn-with-icon"
          title={MessageSettingsConstants.EditMessage}
        >
          <EditIcon />
          Edit
        </button>
        {!selectedMessage.isSystemDefault?
        (<button
          onClick={onDelete}
          className="btn-with-icon delete-icon"
          title={MessageSettingsConstants.DeleteMessage}
        >
          <DeleteIcon />
          Delete
        </button>):<></>}
      </footer>
    </div>
  );
};

export default MessageBox;
