import { ThunkAction, Action } from '@reduxjs/toolkit';
import { GatherCompanySettingsModel } from 'common/model/company-settings';
import { SavedMessageModel } from 'common/model/saved-message';
import { API } from "services/api/api-config"
import Axios from "services/api/axios-interceptor";
import { AppState } from 'store';
import { addSavedMessages, deleteSavedMessages, editSavedMessages, setSavedMessages } from 'store/slices/settingSavedMessages-slice';
import { setDefaultSavedmsg } from './companySettings-service';
import { AppNotifier } from 'common/components/toast/Toast';
import { EngagementTypeApiConstants, SavedMessageConstants } from 'helper/Constants';
import { EngagementsType } from 'common/model/engagements-type';
import { addEngagementsType, deleteEngagementsType, editEngagementsType, setEngagementsType } from 'store/slices/engagement-type-slice';
import {  } from 'store/slices/loading-status-slice';


export const getEngagementsType = (callback?: () => void): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
        try {
            Axios.get(`${API.ENGAGEMENT_TYPE.GET_ENGAGEMENT_TYPE}`, {
                method: 'GET',
                credentials: 'include'
            })
                .then(response => {
                    // Assuming response.data contains the settings data
                    const engagementsType: EngagementsType[] = response.data as EngagementsType[];
                    // Dispatch the action to update the Redux store
                    dispatch(setEngagementsType(engagementsType));
                    callback && callback();
                }).catch(() => {
                    callback && callback();
                }) 
        }
        catch (error: any) {
            AppNotifier.Error(EngagementTypeApiConstants.Failure.Get);
        }
    }

    export const getAllEngagementsType = (callback?: (engagementsType: EngagementsType[]) => void): ThunkAction<void, AppState, unknown, Action> =>
        (dispatch) => {
            try {
                Axios.get(`${API.ENGAGEMENT_TYPE.GET_ALL_ENGAGEMENT_TYPE}`, {
                    method: 'GET',
                    credentials: 'include'
                })
                    .then(response => {
                        // Assuming response.data contains the settings data
                        const engagementsType: EngagementsType[] = response.data as EngagementsType[];
                        // Dispatch the action to update the Redux store
                        callback && callback(engagementsType);
                    })
            }
            catch (error: any) {
                AppNotifier.Error(EngagementTypeApiConstants.Failure.Get);
            }
        } 

export const addEngagementsTypeapi = (
    addedEngagenetType: EngagementsType,
    callback?: () => void): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
        Axios.post(`${API.ENGAGEMENT_TYPE.ADD_ENGAGEMENT_TYPE}`, addedEngagenetType, {
            credentials: 'include'
        })
            .then(response => {

                // Assuming response.data contains the settings data
                addedEngagenetType.id = response.data as number;
                // Dispatch the action to update the Redux store
                dispatch(addEngagementsType(addedEngagenetType))
                AppNotifier.Success(EngagementTypeApiConstants.Success.Add);
                callback && callback();
            }).catch(() => {
                AppNotifier.Error(EngagementTypeApiConstants.Failure.Add);
            })
    }

export const editEngagementsTypeapi = (
    editedEngagementsType: EngagementsType,
    callback?: () => void): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
        Axios.put(`${API.ENGAGEMENT_TYPE.EDIT_ENGAGEMENT_TYPE}`, editedEngagementsType, {
            credentials: 'include'
        })
            .then(response => {
                dispatch(editEngagementsType(editedEngagementsType))
                AppNotifier.Success(EngagementTypeApiConstants.Success.Edit);
                callback && callback();
            }).catch(() => {
                AppNotifier.Error(EngagementTypeApiConstants.Failure.Edit);
            })
    }

export const deleteEngagementTypesApi  = (deletemessage: EngagementsType, callback?: () => void): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
        const data = {
            id: deletemessage.id,
            name: deletemessage.name,            
        }
        Axios.deleteWithPayload(`${API.ENGAGEMENT_TYPE.DELETE_ENGAGEMENT_TYPE}`, data, {
            headers: { credentials: 'include' }
        })
            .then(response => {

                dispatch(deleteEngagementsType(deletemessage))
                AppNotifier.Success(EngagementTypeApiConstants.Success.Delete);
                callback && callback();
            }).catch(() => {
                AppNotifier.Error(EngagementTypeApiConstants.Failure.Delete);
            })
    }