import { createBrowserHistory } from 'history';
import * as RoutePaths from './paths';

export const history = createBrowserHistory();

const redirect = (path: string, state?: any) => {
    history.push(path, state);
}

export default {
    redirect: redirect,
    history: history,
    ORGANIZER_REPORT: RoutePaths.ORGANIZER_REPORT,
    DELIVERED_ORGANIZER_REPORT: RoutePaths.DELIVERED_ORGANIZER_REPORT,
    ARCHIVED_ORGANIZER_REPORT: RoutePaths.ARCHIVED_ORGANIZER_REPORT,
    UNDELIVERED_BATCH_ORGANIZER_REPORT: RoutePaths.UNDELIVERED_BATCH_ORGANIZER_REPORT,
    BATCH_EXPORT_EXCEL_DOWNLOAD: RoutePaths.BATCH_EXPORT_EXCEL_DOWNLOAD,
    RECYCLED_ORGANIZER: RoutePaths.RECYCLED_ORGANIZER,
    CLIENT_INSTRUCTION: RoutePaths.CLIENT_INSTRUCTION,
    SAVED_MESSAGES: RoutePaths.SAVED_MESSAGES,
    GENERAL: RoutePaths.GENERAL,
    CUSTOM_QUESTIONS: RoutePaths.CUSTOM_QUESTIONS,
    GATHER_TEMPLATE:RoutePaths.GATHER_TEMPLATE,
    DEFAULT: RoutePaths.DEFAULT,
    FEATURE_DISABLED: RoutePaths.FEATURE_DISABLED,
    LOGIN: RoutePaths.LOGIN,
    SIGNIN_OIDC: RoutePaths.SIGNIN_OIDC,
    SILENT_SIGNIN_OIDC: RoutePaths.SILENT_SIGNIN_OIDC,
    SIGNOUT_CALLBACK_OIDC: RoutePaths.SIGNOUT_CALLBACK_OIDC,
    RESTRICTED_ACCESS: RoutePaths.RESTRICTED_ACCESS
}


