export enum OrganizerPageOptions {
  Organizer = 1,
  BatchOrganizer
}

export enum GatherStatus {
  None = 0,
  Uploaded = 1,
  ProcessingUpload = 2,
  ErrorProcessingUpload = 3,
  Ready = 4,
  InProcess = 5,
  DeliveryInProgress = 6,
  DeliveryFailed = 7,
  Delivered = 8,
  Completed = 9,
  ReadyForDrlExtraction = 10,
  DrlExtractionInProgress = 11,
  ErrorDrlExtraction = 12,
}
export enum UploadMethod {
  Proforma = 1,
  Blank = 2,
  Batch = 3
}

export const DocumentStatusList = {
  None: "None",
  Uploaded: "Uploaded",
  Ready: "Ready",
  Error: "Error",
  Processing: "Processing",
  DeliveryInProgress: "Delivery In Progress",
  PreparingForDelivery: "Preparing For Delivery",
  Delivered: "Delivered",
  PartiallyCompleted: "Partially Completed",
  Completed: "Completed",
  Downloaded: "Downloaded",
  DeliveryFailed: "Delivery Failed"
};
export const BatchStatusLabel = {
  None: "None",
  Uploaded: "Uploaded",
  Error: "Error",
  Success: "Success",
  PartiallySucceeded: "Partially Succeeded",
  Delivered: "Delivered",
  InProgress: "In Progress",
  PartiallyDelivered: "Partially Delivered",
  DeliveryInProgress: "Delivery In Progress",
  PreparingForDelivery: "Preparing For Delivery",
  Processing: "Processing",
  Delayed: "Delayed",
  DeliveryFailed: "Delivery Failed"
}

export const BatchStatusList = {
  None: BatchStatusLabel.None,
  Uploaded: BatchStatusLabel.Uploaded,
  Error: BatchStatusLabel.Error,
  Success: BatchStatusLabel.Success,
  PartialSuccess: BatchStatusLabel.PartiallySucceeded,
  Delivered: BatchStatusLabel.Delivered,
  InProgress: BatchStatusLabel.InProgress,
  PartialDeliver: BatchStatusLabel.PartiallyDelivered,
  DeliveryInProgress: BatchStatusLabel.DeliveryInProgress,
  ReadyForValidation: BatchStatusLabel.InProgress,
  ValidationInprogress: BatchStatusLabel.InProgress,
  ValidationSuccess: BatchStatusLabel.InProgress,
  ValidationPartiallySucceeded: BatchStatusLabel.InProgress,
  ValidationFailed: BatchStatusLabel.Error,
  ReadyForRecognition: BatchStatusLabel.InProgress,
  RecognitionInprogress: BatchStatusLabel.InProgress,
  RecognitionCompleted: BatchStatusLabel.InProgress,
  PreparingForDelivery: BatchStatusLabel.PreparingForDelivery,
  Processing: BatchStatusLabel.InProgress,
  Delayed: BatchStatusLabel.Delayed,
  DeliveryFailed: BatchStatusLabel.DeliveryFailed
};
export enum EngagementType {
  None = 0,
  E1040 = 1,
  Other = 2
}

export enum EngagementTypeString {
  None = "None",
  E1040 = "1040",
  Other = "Other"
}

export const EngagementList = {
  1: "1040",
  2: "Other"
};

export const EngagementBatchList = {
  1: "1040",
};

export enum ClientType {
  Undefined = 0,
  Taxpayer = 1,
  Spouse = 2,
  Partner = 3,
  PartnerShip = 4,
  ShareHolder = 5
};

export enum DRClientType {
  Taxpayer = 1,
  Spouse = 2,
  Joint = 3
}

export enum DefaultSenderInfoType {
  None = "None",
  Company = "Company",
  User = "User"
};
export enum DownloadType {
  DownloadNow = "DownloadNow",
  DownloadToMyDownloads = "DownloadToMyDownloads",
  PromptMeEveryTime = "PromptMeEveryTime"
}
export enum GroupType {
  None = 0,
  Organizer = 1,
  Engagement = 2,
  Removed = 3,
  CustomQuestion = 4,
  AdditionalEFile = 5,
  OrganizerWithSignature = 6,
  OrganizerRemoved = 7
}
export enum FormType {
  None = 0,
  OrganizerClient = 1,
  Organizer = 2,
  EngagementLetter = 3,
  Removed = 4,
  AdditionalQuestions = 5,
  OrganizerWithSign = 6,
  OrganizerRemoved = 7
}
export enum BatchStatus {
  None = 0,
  Uploaded = 1,
  ReadyForValidation = 2,
  ValidationInProgress = 3,
  ValidationFailed = 4,
  ProcessingUpload = 5,
  ErrorProcessingUpload = 6,
  UploadProcessingPartiallySucceeded = 7,
  UploadProcessingSuccess = 8,
  InProcess = 9,
  PreparingForDelivery = 10,
  DeliveryInProgress = 11,
  DeliveryFailed = 12,
  PartiallyDelivered = 13,
  Delivered = 14,
  Delayed = 15
}
export enum FormGroupStatus {
  None = 0,

  // organizer
  Delievered = 1,
  PartiallyCompleted = 2,
  Completed = 3,
  OrganizerDownloaded = 4,
  Dropped = 5,
  ManuallyCompleted = 6,
  QuestionnaireCompleted = 8,

  // engagement 
  AwaitingESign = 21,
  PartiallySigned = 22,
  ESigned = 23,
  EngagementLetterDownloaded = 24,
  NA = 25,
  Reviewed = 26,
  Declined = 27,
  ManuallySigned = 28
}
export enum ControlType {
  None = 0,
  Textbox = 1,
  Checkbox = 2,
  Date = 3,
  Dropdown = 4,
  Grid = 5,
  Signature = 6,
  RadioButton = 7,
  RadioButtonGroup = 8,
  QuestionControl = 9,
  Section = 10,
  SignatureCheckBoxControl = 11,
  SignatureRadioButtonControl = 12
}
export enum DataType {
  None = 0,
  AlphaNumeric = 1,
  Numeric = 2,
  SSN = 3,
  TextArea = 4,
  Label = 5,
  Amount = 6,
  Name = 7,
  Initial = 8,
  Email = 9,
  Text = 10,
  CompanyField = 11,
  Title = 12,
}
export enum ControlRole {
  None = 0,
  Taxpayer = 1,
  Spouse = 2,
  Ero = 3
}

export const EngagementLetterStatusOptionsList = [
  { label: "AWAITING SIGNATURE", value: 21 },
  { label: "REVIEWED", value: 26 },
  { label: "PARTIALLY SIGNED", value: 22 },
  { label: "E-SIGNED", value: 23 },
  { label: "DOWNLOADED", value: 24 },
  { label: "MANUALLY SIGNED", value: 28 },
  { label: "PARTIALLY REVIEWED", value: 29 },
  { label: "N/A", value: 25 }
];

export const OrganizerStatusOptionsList = [
  { label: "DELIVERED", value: 1 },
  { label: "PARTIALLY COMPLETED", value: 2 },
  { label: "COMPLETED", value: 3 },
  { label: "DOWNLOADED", value: 4 },
  { label: "DROPPED", value: 5 },
  { label: "MANUALLY COMPLETED", value: 6 },
  { label: "CLOSED BY FIRM", value: 7 },
  { label: "QUESTIONNAIRE COMPLETED", value: 8 },
  { label: "N/A", value: 25 }
];

export enum AccordianItemType {
  OrganizerBookmark = 0,
  EngagementLetterBookmark = 1,
  EngagementLetter = 2,
  ConsentForm = 3,
  Others = 4,
  Deleted = 5,

}
export enum OrganizerClientType {
  Taxpayer = 1,
  Spouse
}
export enum TaxSoftware {
  None = "None",
  ProSystems = "ProSystems",
  UltraTax = "UltraTax",
  GoSystem = "GoSystem",
  UltraTaxM18 = "UltraTaxM18",
  Lacerte = "Lacerte",
  Drake = "Drake"
}

export const TaxSoftwareTypeLabels = {
  [TaxSoftware.None]: "None",
  [TaxSoftware.ProSystems]: "CCH",
  [TaxSoftware.UltraTax]: "UT",
  [TaxSoftware.GoSystem]: "GS",
  [TaxSoftware.Lacerte]: "LT",
  [TaxSoftware.UltraTaxM18]: "UltraTaxM18",
  [TaxSoftware.Drake]: "Drake"
}
export enum TemplateOptions {
  Errors = 1,
  ClientInformation = 2,
  SignDocuments = 4,
  AskCustomQuestions = 5,
  SendFillableOrganizer = 3,
  RequestDocuments = 6,
  Delivery = 7
}
export enum ProformaFlowTabSequence {
  ClientInformation = 0,
  Organizer = 1,
  ESignDocument = 2,
  CustomQuestion = 3,
  DocumentRequest = 4,
  Delivery = 5
}