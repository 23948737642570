import { useAppDispatch } from "common/hooks/redux-hooks";
import { AutomationLocators } from "helper/AutomationLocators";
import { FormPreviewConstants } from "helper/Constants";
import * as React from "react";
import { Form } from "react-bootstrap";
import { IOnChangeHandlerPayload, IParagraphQuestion } from "store/models/CustomQuestionModel";
import { HandleParagraphOnChange } from "store/slices/custom-questions-preview";

interface ParagraphQuestionProps {
  questionDetails: IParagraphQuestion;
  isRequired: boolean;
  resetValidations: boolean;
  sectionIndex: number;
  sectionItemIndex: number;
  isEditMode?: boolean;
  followupQuestionId?: number;
}

const ParagraphQuestion: React.FC<ParagraphQuestionProps> = ({
  questionDetails,
  isRequired,
  sectionIndex,
  sectionItemIndex,
  resetValidations,
  isEditMode,
  followupQuestionId
}) => {
  const dispatch = useAppDispatch();
  const [answerState, setAnswerState] = React.useState(questionDetails.answer);
  const [isValid, setIsValid] = React.useState(true);

  React.useEffect(() => {
    setIsValid(true);
    setAnswerState("");
  }, [resetValidations, followupQuestionId]);
  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value.trimStart();
    setAnswerState(input);
    const payload: IOnChangeHandlerPayload = {
      value: input,
      sectionIndex,
      sectionItemIndex
    };
    setAnswerState(input);
    dispatch(HandleParagraphOnChange(payload));
    if (input === "") {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  };

  return (
    <>
      <Form.Control
        onChange={onChangeHandler}
        as="textarea"
        data-test-auto={AutomationLocators.Settings.CustomQuestions.PreviewAnswer}
        placeholder={FormPreviewConstants.ParagraphTypeQuestion.placeholder}
        value={answerState}
        required={isRequired}
        maxLength={FormPreviewConstants.ParagraphTypeQuestion.maxLength}
        className={`non-resizable ${!isValid && isRequired ? "invalid-input" : isEditMode ? "non-editable" : ""}`}
      />
      {!isValid && isRequired && <p className="error-text">{FormPreviewConstants.RequiredValidation}</p>}
    </>
  );
};

export default ParagraphQuestion;
