import React, { FC, useRef, useState } from "react";
import { Col, Form, InputGroup, OverlayTrigger, Row, Tooltip, Button } from "react-bootstrap";
import * as Validation from "helper/Validations";
import { getPasswordOptionLabel } from "helper/HelperFunctions";
import { IMyAccountLayout } from "./MyAccountLayout";
import { EyeIcon, EyeSlashIcon } from "assets/svg/SVGIconsCollection";
import { IPasswordPolicySettings, PasswordOptions } from "common/model/PasswordPolicySettings.model";
import { ChangePasswordConstants, ChangeSecurityConstants, ValidationContants } from "helper/Constants";
import { AppNotifier } from "common/components/toast/Toast";
import { savePassword } from "store/services/password-service";
import { useAppDispatch, useAppSelector } from "common/hooks/redux-hooks";
import { AppState } from "store";
import ConfirmDialog from "../ConfirmDialog";
import Alert, { AlertType } from "common/components/alert/Alert";

interface IVisibility {
  current: boolean;
  new: boolean;
  confirm: boolean;
}
interface IPasswordForm {
  current: string;
  new: string;
  confirm: string;
}
interface IMyPasswordState {
  message: string;
  showAlert: boolean;
  saving: boolean;
}
export interface IPasswordState {
  password: string;
  newPassword: string;
}
const Password: FC<IMyAccountLayout> = (props) => {
  const ref = useRef<HTMLFormElement>(null);
  const [validated, setValidated] = useState<boolean>(false);
  const [toggleVisibility, setToggleVisibility] = useState<IVisibility>({ current: false, new: false, confirm: false });
  const [passwordData, setPasswordData] = useState<IPasswordForm>({ current: "", new: "", confirm: "" });
  const passwordPolicySettings: IPasswordPolicySettings = useAppSelector((state: AppState) => state.passwordPolicyReducer);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [state, setState] = useState<IMyPasswordState>({
    message: "",
    showAlert: false,
    saving: false
  });
  const dispatch = useAppDispatch();
  const handleSubmit: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const form = ref.current;
    if (form) {
      setValidated(false);
      submitChanges();
    } else {
      setValidated(true);
    }
  };

  const handleVisibility = (name: string) => {
    setToggleVisibility({
      ...toggleVisibility,
      [name]: !toggleVisibility[name as keyof IVisibility]
    });
  };

  const handleOnChange: React.ChangeEventHandler = (e) => {
    const element = e.target as HTMLInputElement;
    const data = { ...passwordData, [element.id]: element.value };
    setPasswordData(data);
    setState({
      message: "",
      showAlert: false,
      saving: false
    });
  };
  const passwordoptions = () => {
    const items: any[] = [];
    const selectedOptions = Object.keys(PasswordOptions)
      .filter((key) => !isNaN(Number(key)))
      .map((key) => Number(key))
      .filter((option) => option > 0 && (option & passwordPolicySettings.passwordSettings.passwordOptions) === option);

    selectedOptions.forEach((option) => {
      const label = getPasswordOptionLabel(option);
      items.push(<li> {label}</li>);
    });

    return <div>{items}</div>;
  };

  const submitChanges = () => {
    if (!passwordData.current || passwordData.current.trim() === "") {
      setState({
        ...state,
        message: ValidationContants.OldPasswordWarning,
        showAlert: true
      });
      return false;
    }

    if (!passwordData.new || passwordData.new.trim() === "") {
      setState({
        ...state,
        message: ValidationContants.NewPasswordWarning,
        showAlert: true
      });
      return false;
    }

    if (!passwordData.confirm || passwordData.confirm.trim() === "") {
      setState({
        ...state,
        message: ValidationContants.ConfirmPasswordWarning,
        showAlert: true
      });
      return false;
    }

    const validationResult = Validation.validatePasswordBasedOnPolicy(passwordData.new, passwordPolicySettings.passwordSettings);
    if (validationResult.isError) {
      setState({
        ...state,
        message: validationResult.errorDescription,
        showAlert: true
      });
      return false;
    }

    if (passwordData.new.trim() === passwordData.confirm.trim()) {
      setShowConfirmationModal(true);
    } else {
      setState({
        ...state,
        message: ValidationContants.PasswordNotMatchedWarning,
        showAlert: true
      });
      return false;
    }
  };

  const handlePasswordConfirmation = () => {
    setShowConfirmationModal(false);
    setState({
      message: "",
      showAlert: false,
      saving: true
    });
    const passwordstate: IPasswordState = { password: passwordData.current, newPassword: passwordData.new };
    dispatch(
      savePassword(passwordstate, false, (response: any, error: any) => {
        setState({ ...state, saving: false });
        if (error) {
          AppNotifier.Error(ChangeSecurityConstants.SECURITY_UPDATE_FAILED);
        } else {
          if (response) {
            AppNotifier.Success(ChangeSecurityConstants.SECURITY_UPDATE_SUCCESS);
            setState({
              ...state,
              message: "",
              showAlert: false
            });
            setPasswordData({
              current: "",
              new: "",
              confirm: ""
            });
            props.onHide();
          } else {
            setState({
              ...state,
              message: response,
              showAlert: false
            });
            AppNotifier.Error(ChangeSecurityConstants.PASSWORD_UPDATE_FAILED);
          }
        }
      })
    );
  };

  const hideModal: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    props.onHide();
  };
  return (
    <div>
      <h3 className="heading-blue-1">{ChangePasswordConstants.TITLE}</h3>
      <p className="text-gray-description">{ChangePasswordConstants.DESCRIPTION}</p>

      {state.showAlert && <Alert type={AlertType.WARNING} message={state.message} />}
      <Row>
        <Col>
          <Form ref={ref} id="change-password-form" noValidate validated={validated} className="me-3">
            <Form.Group controlId="current">
              <Form.Label>{ChangePasswordConstants.LABEL_CURRENT_PASSWORD}</Form.Label>
              <InputGroup className="mb-3 input-with-toggle">
                <Form.Control
                  size="sm"
                  type={toggleVisibility["current"] ? "text" : "password"}
                  onChange={handleOnChange}
                  placeholder={ChangePasswordConstants.PLACEHOLDER_CURRENT_PASSWORD}
                />
                <InputGroup.Text onClick={() => handleVisibility("current")}>
                  {toggleVisibility["current"] ? <EyeIcon /> : <EyeSlashIcon />}
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>

            <Form.Group controlId="new">
              <Form.Label>{ChangePasswordConstants.LABEL_NEW_PASSWORD}</Form.Label>
              <InputGroup className="mb-3 input-with-toggle">
                <Form.Control
                  size="sm"
                  type={toggleVisibility["new"] ? "text" : "password"}
                  onChange={handleOnChange}
                  placeholder={ChangePasswordConstants.PLACEHOLDER_NEW_PASSWORD}
                />
                <InputGroup.Text onClick={() => handleVisibility("new")}>
                  {toggleVisibility["new"] ? <EyeIcon /> : <EyeSlashIcon />}
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>

            <Form.Group controlId="confirm">
              <Form.Label>{ChangePasswordConstants.LABEL_CONFIRM_PASSWORD}</Form.Label>
              <InputGroup className="mb-3 input-with-toggle">
                <Form.Control
                  size="sm"
                  type={toggleVisibility["confirm"] ? "text" : "password"}
                  onChange={handleOnChange}
                  placeholder={ChangePasswordConstants.PLACEHOLDER_CONFIRM_PASSWORD}
                />
                <InputGroup.Text onClick={() => handleVisibility("confirm")}>
                  {toggleVisibility["confirm"] ? <EyeIcon /> : <EyeSlashIcon />}
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
          </Form>
        </Col>
        {passwordPolicySettings && (
          <Col className="password-policies" xs={5}>
            <div className="mx-3 mb-2">
              <b>
                <u>{ChangePasswordConstants.PASSWORD_POLICY_TITLE}</u>
              </b>
            </div>
            <ul>
              <li className="margin-bottom-10-px">
                {ChangePasswordConstants.PASSWORD_LENGTH}
                {passwordPolicySettings.passwordSettings.length}
              </li>
              {passwordPolicySettings.passwordSettings.passwordOptions != PasswordOptions.None && (
                <li>
                  {ChangePasswordConstants.PASSWORD_REQUIRED}
                  <ul className="margin-top-10-px">{passwordoptions()}</ul>
                  {(passwordPolicySettings.passwordSettings.passwordOptions & PasswordOptions.SpecialCharacters) ===
                    PasswordOptions.SpecialCharacters && (
                    <OverlayTrigger overlay={<Tooltip>{ChangePasswordConstants.PASSWORD_CHARACTERS_LIST}</Tooltip>}>
                      <div className="margin-top-20-px tooltip-target">{ChangePasswordConstants.PASSWORD_SPECIAL_CHARACTERS}</div>
                    </OverlayTrigger>
                  )}
                </li>
              )}
            </ul>
          </Col>
        )}
      </Row>
      <div className="profile-footer d-flex justify-content-end">
        <Button
          data-test-auto="71675d6a-a9ae-4e67-8d42-bf4a5f6b77de"
          className="button-default ss-btn-secondary"
          onClick={hideModal}
        >
          {ChangePasswordConstants.CANCEL_BUTOTN}
        </Button>
        <Button style={{ marginLeft: "8px" }} className="button-default ss-btn-primary" onClick={handleSubmit}>
          {ChangePasswordConstants.OK_BUTTON}
        </Button>
      </div>

      <ConfirmDialog
        show={showConfirmationModal}
        title={ChangeSecurityConstants.SECURITY_CHANGE_MODAL_TITLE}
        confirmButtonName={ChangeSecurityConstants.CONFIRM_BUTTON_NAME}
        onSubmit={handlePasswordConfirmation}
        onHide={() => {
          setShowConfirmationModal(false);
        }}
        message={ChangeSecurityConstants.SECURITY_CHANGE_LOGOUT_WARNING}
      />
    </div>
  );
};

export default Password;
