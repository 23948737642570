import * as React from "react";
import { Form } from "react-bootstrap";
import FollowupQuestion from "./FollowupQuestion";
import { IChoiceModel, ISectionItems } from "store/models/CustomQuestionModel";
import { FormPreviewConstants } from "helper/Constants";

interface IYesNoQuestionProps {
  isEditMode?: boolean;
  sectionIndex: number;
  sectionItemIndex: number;
  isRequired: boolean;
  choices: IChoiceModel[];
  resetValidations: boolean;
  followupQuestionId?: number;
}

const YesNoQuestion: React.FC<IYesNoQuestionProps> = ({
  sectionIndex,
  sectionItemIndex,
  isEditMode,
  choices,
  isRequired,
  resetValidations,
  followupQuestionId
}) => {
  const [options, setOptions] = React.useState<IChoiceModel[]>([...choices]);
  const [isValid, setIsValid] = React.useState(true);
  const [followupQuestion, setFollowupQuestion] = React.useState<ISectionItems[]>([]);

  React.useEffect(() => {
    setIsValid(true);
    let updatedOptions: IChoiceModel[] = options.map((option) => {
      return { ...option, isSelected: false };
    });
    setOptions(updatedOptions);
    setFollowupQuestion([]);
  }, [resetValidations, followupQuestionId]);

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>, choice: IChoiceModel) => {
    if (choice.isSelected) {
      e.preventDefault();
      return;
    }
    if (!(isEditMode === true)) {
      let updatedOptions: IChoiceModel[] = options.map((option) => {
        return { ...option, isSelected: option.text === choice.text && e.target.checked };
      });
      setIsValid(e.target.checked);
      setOptions(updatedOptions);

      if (choice.subQuestions.length > 0) {
        setFollowupQuestion(choice.subQuestions);
      } else {
        setFollowupQuestion([]);
      }
    }
  };

  const onOptionClick = (e: React.MouseEvent<HTMLInputElement>, choice: IChoiceModel) => {
    if (choice.isSelected) {
      e.preventDefault();
      return;
    }
    if (!(isEditMode === true)) {
      let updatedOptions: IChoiceModel[] = options.map((option) => {
        return { ...option, isSelected: false };
      });
      setIsValid(false);
      setOptions(updatedOptions);
      setFollowupQuestion([]);
    }
  };
  return (
    <div className={`answer-wrapper ${sectionItemIndex >= 99 ? "padding-24" : sectionItemIndex >= 9 ? "padding-12" : ""}`}>
      <Form>
        <div className={isEditMode ? "non-editable" : ""}>
          <div className={followupQuestion.length > 0 ? "sub-question sub-question-question-border" : ""}>
            <div className="yes-no-preview-wrapper">
              {options?.map((option: IChoiceModel) => (
                <Form.Check
                  key={option.text + sectionItemIndex}
                  inline
                  label={option.text}
                  name="radio-group"
                  type="radio"
                  value={sectionItemIndex}
                  onChange={(e) => onChangeHandler(e, option)}
                  onClick={(e) => onOptionClick(e, option)}
                  checked={option.isSelected}
                  className={!isValid && isRequired ? "yesno-error-radio" : " "}
                />
              ))}
              {!isValid && isRequired && <p className="error-text">{FormPreviewConstants.RequiredValidation}</p>}
            </div>
          </div>
          <div className="yes-no-followup-wrapper">
            {followupQuestion &&
              followupQuestion.length > 0 &&
              followupQuestion.map((question) => {
                return (
                  <>
                    <label>
                      {question.questionTitle}
                      <span className="cq-error-red">{isRequired && "*"}</span>
                    </label>
                    <FollowupQuestion
                      resetValidations={resetValidations}
                      sectionIndex={sectionIndex}
                      sectionItemIndex={sectionItemIndex}
                      sectionItem={question}
                      isParentQuestionRequired={isRequired}
                      followupQuestionId={question.SectionItemId}
                    />
                  </>
                );
              })}
          </div>
        </div>
      </Form>
    </div>
  );
};

export default YesNoQuestion;
