import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface RestrictedAccessState {
    routePath : string;
}

const restrictedAccessState : RestrictedAccessState = {
    routePath : ''
}

const restrictedAccessSlice = createSlice({
    name:'restrictedAccess',
    initialState: restrictedAccessState,
    reducers:{
        setRestrictedAccessPath(state, action:PayloadAction<string>){
            state.routePath = action.payload;
        },
    }
})

export const { setRestrictedAccessPath } = restrictedAccessSlice.actions;
export const { reducer: restrictedAccessReducer } = restrictedAccessSlice;