import * as React from 'react';
import { UiTextConstants } from '../helper/Constants';
import { FeatureDisabledIcon } from 'assets/svg/SVGIconsCollection';
import './DisablePage.scss';

const FeatureDisabled: React.FC<any> = () => {
    return <div className="product-access-denied">
        <div>
            <FeatureDisabledIcon width={200} height={200} />
        </div>
        <br />
        <div className="product-access-denied-text">
            <h3><b>{UiTextConstants.FeatureDisabled}</b></h3>
            <div>
                <div>
                    {UiTextConstants.FeatureDisabledMessageLine1}<br />
                    {UiTextConstants.FeatureDisabledMessageLine2}
                </div>
            </div>
        </div>
    </div>
}
export default FeatureDisabled;