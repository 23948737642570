import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthenticationOption, GatherCompanySettingsModel } from "common/model/company-settings";
import { GatherTemplate, GatherTemplateListState } from "common/model/gather-template-settings";


export const intialdata : GatherTemplate =
{
        uploadTemplateId: 0,
        name: "string",
        description: "string",
        isDefault: true,
        customQuestionTemplateId: 0,
        requestDocumentsTemplateId: 0,
        processSteps: [
          1
        ]
}


export const initialTemplateList: GatherTemplateListState  = {
    templateList: [intialdata],
    addStatus:false,
    isLoading: false
  };

const gatherTemplateSlice = createSlice({
    name: 'gatherTemplateSlice',
    initialState:initialTemplateList,
    reducers: {
        setGatherTemplateData(state, action: PayloadAction<GatherTemplate[]>) {
            state.templateList = [...action.payload];
          },
          addGatherTemplateData(state, action: PayloadAction<GatherTemplate>) {
            state.templateList.push(action.payload)
          },
          editGatherTemplateData(state, action: PayloadAction<GatherTemplate>) {
            state.templateList[state.templateList.findIndex((obj: GatherTemplate) => obj.uploadTemplateId===action.payload.uploadTemplateId)]=action.payload;
          },
          deleteGatherTemplateData(state, action: PayloadAction<number>)
        {
           state.templateList = state.templateList.filter((item :GatherTemplate )=> item.uploadTemplateId !== action.payload);
        },
        setAddStatus(state, action: PayloadAction<boolean>)
        {
           state.addStatus = action.payload;
        },
        startLoader(customQuestionsState) {
          customQuestionsState.isLoading = true;
        },
        stopLoader(customQuestionsState) {
          customQuestionsState.isLoading = true;
        },

    },
  });

  export const {setGatherTemplateData,addGatherTemplateData,editGatherTemplateData,deleteGatherTemplateData,setAddStatus,stopLoader,startLoader} = gatherTemplateSlice.actions;
  export const { reducer: gatherTemplateReducer } = gatherTemplateSlice;

