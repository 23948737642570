import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IUserProfile } from "common/model/userModel";
import { IUserDataState, IUserGroupViewModel, IUserViewModel, initialUserDataState } from "common/model/user";

const userSlice = createSlice({
    name: 'user',
    initialState: initialUserDataState as IUserDataState,
    reducers: {
        setPartners(state, action: PayloadAction<IUserViewModel[]>) {
            state.partners = action.payload;
        },
        setSafeSendUsers(state, action: PayloadAction<IUserViewModel[]>) {
            state.safesendUsers = action.payload;
        },
        setRequestUserProfile(state, action: PayloadAction<IUserProfile>) {
            state.loggedInUserProfile = action.payload
        },
        setSafeSendUserGroups(state, action: PayloadAction<IUserGroupViewModel[]>) {
            state.safesendUserGroups = action.payload;
        },
    }
})
export const { setPartners, setSafeSendUsers, setRequestUserProfile, setSafeSendUserGroups } = userSlice.actions;
export const { reducer: userReducer } = userSlice;