import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IFormData, intialQuestionnaireState, IOnChangeHandlerPayload } from "store/models/CustomQuestionModel";

const customQuestionsPreview = createSlice({
  name: "customQuestionsPreview",
  initialState: intialQuestionnaireState,
  reducers: {
    startLoader(customQuestionsState, action: PayloadAction<boolean>) {
      customQuestionsState.isLoading = true;
    },
    stopLoader(customQuestionsState, action: PayloadAction<boolean>) {
      customQuestionsState.isLoading = true;
    },
    HandleParagraphOnChange(customQuestionsState, action: PayloadAction<IOnChangeHandlerPayload>) {
      const { sectionIndex, sectionItemIndex, value } = action.payload;
      if (customQuestionsState.formData.sections[sectionIndex]) {
        customQuestionsState.formData.sections[sectionIndex].sectionItems[sectionItemIndex].questionDetails.answer = value;
      }
    },
    IntialiseCqPreview(customQuestionsState, action: PayloadAction<IFormData>) {
      customQuestionsState.formData = action.payload;
    }
  }
});

export const { startLoader, stopLoader, IntialiseCqPreview ,HandleParagraphOnChange} = customQuestionsPreview.actions;

export default customQuestionsPreview.reducer;
