import { Action } from "@reduxjs/toolkit"
import { ThunkAction } from "redux-thunk"
import { API } from "services/api/api-config"
import Axios from "services/api/axios-interceptor"
import { AppState } from "store"
import { IPasswordState } from "common/components/modal/my-account/Password"
import { IPasswordPolicySettings } from "common/model/PasswordPolicySettings.model"
import { AppNotifier } from "common/components/toast/Toast"
import { ErrorMessage } from "helper/Constants"
import { setPasswordPolicy } from "store/slices/password-slice"

export const savePassword =
    (
        passwordState: IPasswordState,
        notification: boolean,
        onResponse: (response: any, error: any) => void
    ): ThunkAction<void, AppState, unknown, Action> =>
        (dispatch) => {
            // startLoader();
            Axios.put(`${API.USER_MANAGEMENT.UPDATE_PASSWORD}`, JSON.stringify(passwordState)
                ,
                {
                    credentials: 'include'
                }
            )
                .then((response: any) => {
                    // stopLoader();
                    const data = response;
                    if (onResponse) {
                        onResponse(data, null);
                    }
                })
                .catch((error) => {
                    // stopLoader();
                    if (onResponse) {
                        onResponse(null, error);
                    }
                });
        };

export const requestDefinedPasswordPolicies = (): ThunkAction<void, AppState, unknown, Action> => {
    return (dispatch) => {
        // dispatch(startLoader());
        try {
            Axios.get(`${API.USER_MANAGEMENT.GET_PASSWORD_POLICY}`, {
                method: 'GET',
                credentials: 'include'
            })
                .then(response => response.data)
                .then((data) => {
                    var response = data as IPasswordPolicySettings;
                    dispatch(setPasswordPolicy(response));
                    // dispatch(stopLoader());
                })

        }
        catch (error: any) {
            // dispatch(stopLoader());
            AppNotifier.Error(ErrorMessage);
        }
    }

}

