import React, { useEffect, useState } from "react";
import { AddIcon, DeleteIcon, EditIcon } from "assets/icons/IconCollection";
import "../style.scss";
import { SavedMessageModel } from "common/model/saved-message";
import { GatherCompanySettingsModel } from "common/model/company-settings";
import { EngagementTypeSettingsConstants, MessageSettingsConstants } from "pages/Constants";
import { EngagementsType } from "common/model/engagements-type";

export interface IMessageListBoxProps {
    data: EngagementsType[];
    onEdit: (data: EngagementsType) => void;
    onDelete: (data: EngagementsType) => void;
}

const EngagementTypeListBox: React.FC<IMessageListBoxProps> = ({
    data,
    onEdit,
    onDelete
}) => {
    const [list, setlist] = useState([{ id: 0, isSystemDefault: false, isUserDefault: false, name: "" }]);

    useEffect(() => { if(data){setlist(data)} }, [data])
    return (
        <div className="message-list-box engagement-type-list-box">
            <div>
                {data &&
                    data.length > 0 &&
                    list.map((ele) => {
                        return (
                            <div
                                key={ele.id}
                                className={`message-wrapper row-padding-engagement-type`}
                                title={ele.name}
                            >
                                <div className="flex-justify">
                                    <p className="d-inline-block text-truncate engagementtype-max-width">{ele.name}</p>
                                    {ele.isUserDefault === true && <span>Default</span>}
                                </div>
                                <div className="diplay-flex-div">
                                    <button onClick={() => onEdit(ele)}
                                        className="btn-with-icon no-border-icon"
                                        title={EngagementTypeSettingsConstants.EditMessage}
                                    >
                                        <EditIcon />
                                        Edit
                                    </button>

                                    {!ele.isSystemDefault &&
                                        <button
                                            onClick={() => onDelete(ele)}
                                            className="btn-with-icon no-border-icon"
                                            title={EngagementTypeSettingsConstants.DeleteMessage}
                                        >
                                            <DeleteIcon />
                                            Delete
                                        </button>
                                    }
                                </div>

                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

export default EngagementTypeListBox;
