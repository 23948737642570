import * as React from "react";
import { Form } from "react-bootstrap";
import { IChoiceModel, ISectionItems, QuestionType, initialYesNoChoiceData } from "store/models/CustomQuestionModel";
import { CustomQuestionsTabConstants } from "helper/Constants";
import { CircleDeleteIcon } from "assets/svg/SVGIconsCollection";
import ParagraphQuestion from "./question-types/ParagraphQuestion";
import YesNoQuestion from "./question-types/YesNoQuestion";
import MultiChoiceQuestionPreview from "./question-types/MultiChoiceQuestionPreview";
import "../parts/question-types/QuestionTypes.scss";

interface SectionItemPreviewProps {
  sectionIndex: number;
  sectionItemIndex: number;
  sectionItem: ISectionItems;
  sectionItemsCount?: number;
  resetValidations: boolean;
  isEditMode?: boolean;
  isEditable?: boolean;
  onQuestionDelete?: Function;
}

const SectionItemPreview: React.FC<SectionItemPreviewProps> = ({
  sectionItem,
  sectionItemIndex,
  sectionIndex,
  sectionItemsCount,
  resetValidations,
  isEditMode,
  isEditable,
  onQuestionDelete
}) => {
  const getQuestionControl = () => {
    switch (sectionItem.questionTypeId) {
      case QuestionType.PARAGRAPH:
        return (
          <ParagraphQuestion
            isEditMode={isEditMode}
            sectionIndex={sectionIndex}
            sectionItemIndex={sectionItemIndex}
            isRequired={sectionItem.isRequired}
            questionDetails={sectionItem.questionDetails}
            resetValidations={resetValidations}
          />
        );
      case QuestionType.YESNO:
        let choices: IChoiceModel[] =
          sectionItem.choices?.map((choice) => {
            return { ...choice, isSelected: false };
          }) || [];

        return (
          <YesNoQuestion
            choices={choices?.length > 0 ? choices : initialYesNoChoiceData}
            isEditMode={isEditMode}
            sectionIndex={sectionIndex}
            sectionItemIndex={sectionItemIndex}
            isRequired={sectionItem.isRequired}
            resetValidations={resetValidations}
          />
        );
      case QuestionType.MULTICHOICE:
        return (
          <MultiChoiceQuestionPreview
            isEditMode={isEditMode}
            resetValidations={resetValidations}
            choices={sectionItem.choices}
            isRequired={sectionItem.isRequired}
          />
        );
      default:
        return <p>Control Not found</p>;
    }
  };
  const onDeleteClick = () => {
    if (onQuestionDelete) {
      onQuestionDelete(sectionItem);
    }
  };
  return (
    <Form.Group className="question-preview-container">
      <div className="preview-question-container">
        <div className="question">
          <Form.Label>
            <span>{sectionItemIndex + 1}.</span>
            {sectionItem.questionTitle}
            {sectionItem.isRequired && <span className="cq-error-red"> *</span>}
          </Form.Label>
          <div className={`answer-container ${isEditMode ? "cursor-not-allowed" : ""}`}>
            {getQuestionControl()}
            {isEditMode && (
              <button
                className="section-delete-button"
                title={sectionItemsCount === 1 ? CustomQuestionsTabConstants.AtleastOneQuestionRequired : ""}
                disabled={sectionItemsCount === 1 || !isEditable}
                onClick={onDeleteClick}
              >
                <CircleDeleteIcon />
              </button>
            )}
          </div>
        </div>
      </div>
    </Form.Group>
  );
};

export default SectionItemPreview;
