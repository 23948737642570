import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ArchivedGatherData, ArchivedGatherStoreState } from "common/model/archived-gather-table";
import { PAGESIZE } from "helper/Constants";

const initialArchivedGatherState: ArchivedGatherStoreState = {
    loading: false,
    model: {
        count: 0,
        documents: []
    },
    popupLoading: false,
    sourceDocumentMetaData: {},
    query: '',
    page: 1,
    pageSize: PAGESIZE
}

const archivedGatherSlice = createSlice({
    name: 'archivedGather',
    initialState: initialArchivedGatherState,
    reducers: {
        setArchivedGatherData: (state, action: PayloadAction<ArchivedGatherData>) => {
            state.model = action.payload.model;
            state.query = action.payload.query;
            state.page = action.payload.page;
            state.pageSize = action.payload.pageSize;
        },
        startLoader: (state) => {
            state.loading = true;
        },
        stopLoader: (state) => {
            state.loading = false;
        }
    }
});

export const { startLoader, setArchivedGatherData, stopLoader } = archivedGatherSlice.actions;

export const { reducer: archivedGatherReducer } = archivedGatherSlice;