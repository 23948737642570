/* Contains all the routes of the application */
import React, { Suspense, lazy } from "react";
import { Route, Switch } from "react-router-dom";
import Login from "common/pages/Login";
import PreLogin from "common/pages/PreLogin";
import SigninOidc from "oidc-client/SigninOidc";
import SignoutOidc from "oidc-client/SignoutOidc";
import SilentSigninOidc from "oidc-client/silentSigninOidc";
import AppLayout from "common/pages/AppLayout";
import AppRoute from "route";
import GenaralSettingsPage from "pages/settings/general";
import SavedMessagesPage from "pages/settings/saved-messages";
import CustomQuestionsPage from 'pages/settings/custom-questions/CustomQuestionsTemplateList';
import ClientIntstructionsPage from "pages/settings/client-instructions";
import { useAppSelector } from "common/hooks/redux-hooks";
import { AppState } from "store";
import { Toaster } from "common/components/toast/Toast";
import GatherTemplateList from "pages/settings/gather-templates/GatherTemplateList";
import RBACRoute from "./rbac-routes";
import FeatureDisabled from "Authorization/FeatureDisabled";
import Loader from "common/components/loader/Loader";
import RestrictedAccess from "RestrictedAccess";

const SendOrganizersPage = lazy(() => import("pages/send-organizers/send-organizers-tables/ReportLayout"));
const DeliveredOrganizersPage = lazy(() => import("pages/delivered-organizers/ReportLayout"));
const UndeliveredBatchPage = lazy(() => import("pages/undelivered-batch/ReportLayout"));
const ArchievedOrganizersPage = lazy(() => import("pages/archieved-organizers/ReportLayout"));
const RecylceBinPage = lazy(() => import("pages/recycle-bin/ReportLayout"));
const BatchExcelDownload = lazy(() => import("pages/send-organizers/components/BatchExcelDownload"));

const AppRoutes: React.FC = () => {
  const authentication: any = useAppSelector((state: AppState) => state.authReducer?.authentication);
  if (authentication?.user) {
    document.cookie = "id_Token=token";
    return (
      <>
        <AppLayout>
          <Toaster />
          <Suspense fallback={<Loader />}>
            <Switch>
              <Route path={AppRoute.FEATURE_DISABLED} component={FeatureDisabled} />
              <Route path={AppRoute.RESTRICTED_ACCESS} component={RestrictedAccess} />
              <RBACRoute path={AppRoute.ORGANIZER_REPORT} component={SendOrganizersPage} />
              <RBACRoute path={AppRoute.DELIVERED_ORGANIZER_REPORT} component={DeliveredOrganizersPage} />
              <RBACRoute path={AppRoute.UNDELIVERED_BATCH_ORGANIZER_REPORT} component={UndeliveredBatchPage} />
              <RBACRoute path={AppRoute.ARCHIVED_ORGANIZER_REPORT} component={ArchievedOrganizersPage} />
              <RBACRoute path={AppRoute.RECYCLED_ORGANIZER} component={RecylceBinPage} />
              <RBACRoute path={AppRoute.SAVED_MESSAGES} component={SavedMessagesPage} />
              <RBACRoute path={AppRoute.CLIENT_INSTRUCTION} component={ClientIntstructionsPage} />
              <RBACRoute path={AppRoute.GENERAL} component={GenaralSettingsPage} />
              <RBACRoute path={AppRoute.CUSTOM_QUESTIONS} component={CustomQuestionsPage} />
              <RBACRoute path={AppRoute.GATHER_TEMPLATE} component={GatherTemplateList} />
              <RBACRoute path={AppRoute.BATCH_EXPORT_EXCEL_DOWNLOAD} component={BatchExcelDownload} skipAuthorization = {true} />
              <RBACRoute path="/" checkPath={AppRoute.ORGANIZER_REPORT} component={SendOrganizersPage} />
            </Switch>
          </Suspense>
        </AppLayout>
      </>
    );
  } else {
    return (
      <>
        <Suspense fallback={<Loader />}>
          <Switch>
            <Route path={AppRoute.LOGIN} component={Login} />
            <Route path={AppRoute.SIGNIN_OIDC} component={SigninOidc} />
            <Route path={AppRoute.SILENT_SIGNIN_OIDC} component={SilentSigninOidc} />
            <Route path={AppRoute.SIGNOUT_CALLBACK_OIDC} component={SignoutOidc} />
            <Route path="*" component={PreLogin} />
          </Switch>
        </Suspense>
      </>
    );
  }
};
export default AppRoutes;
