import React from "react";
import { Editor } from "@tinymce/tinymce-react";

interface TinyMCEComponentProps {
  messageBody: string;
  changeStateTinymceBody: (event: any) => void;
  uniquekey: string;
  onDragOver?: () => void;
  isSystemDefaultType? : boolean;
  readOnly?: boolean;
  customHeight?: number;
}

const TinyMCEComponent: React.FC<TinyMCEComponentProps> = (props) => {
  const { customHeight = 180 } = props;

  return (
    <div className={props.isSystemDefaultType ? "disabled" : ""}>
      <Editor
        tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
        key={props.uniquekey}
        value={props.messageBody}
        onEditorChange={props.changeStateTinymceBody}
        disabled={props.readOnly}
        init={{
          height: customHeight,
          menubar: false,
          promotion: false,
          branding: false,
          statusbar: false,
          plugins: [
            "advlist",
            "autolink",
            "lists",
            "link",
            "image",
            "charmap",
            "anchor",
            "searchreplace",
            "visualblocks",
            "code",
            "fullscreen",
            "insertdatetime",
            "media",
            "table",
            "preview",
            "help",
            "wordcount"
          ],
          toolbar:
            "bold italic textcolor backcolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat | help",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }::-webkit-scrollbar {width: 8px;background-color: inherit;}::-webkit-scrollbar-track {box-shadow: inset 0 0 5px #f6f6f6;border-radius: 10px;}::-webkit-scrollbar-thumb {background: #0d6efd40;border-radius: 10px;}::-webkit-scrollbar-thumb:hover {background: #0973ba;}"
        }}
      />
    </div>
  );
};

export default TinyMCEComponent;
