import { Guid } from "helper/Guid";

export interface IMyDownloadRequest {
    jobId: Guid,
    fileName: string
}

export interface IMyDownloadAllResponse {
    uri: string,
    fileName: string
}

export interface IDownloadedZipFilesModel {
    id: number,
    jobId: Guid,
    createdBy: number,
    requestFilters: IBulkOperationFilter,
    fileName: string,
    createdOn: string,
    fileSize: number,
    status: DownloadStatus,
    updatedOn: string,
    message: string
}

export interface IBulkOperationFilter {
    clientId: string,
    engagementLetterStatus: string,
    organizerStatus: string,
    sourceDocumentStatus: string,
    taxpayerName: string,
    ero: string,
    sentBy: string,
    deselectedIds: any,
    archivedBy: string,
    isArchived: boolean,
    batchName: string,
    engagementType: string,
    documentStatus: string,
    deliveredOn: string,
    taxYear: string
}

export enum DownloadStatus {
    None = 0,
    InProgress = 1,
    Ready = 2,
    Error = 3,
    PartialReady = 4
}