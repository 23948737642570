import React, { useEffect, useState } from "react";

import MultiChoiceQuestionPreview from "./MultiChoiceQuestionPreview";
import ParagraphQuestion from "./ParagraphQuestion";
import YesNoQuestion from "./YesNoQuestion";
import { IChoiceModel, ISectionItems, QuestionType, initialYesNoChoiceData } from "store/models/CustomQuestionModel";
interface IFollowupQuestionProps {
  sectionItem: ISectionItems;
  sectionItemIndex: number;
  sectionIndex: number;
  resetValidations: boolean;
  isParentQuestionRequired: boolean;
  followupQuestionId: number;
}

const FollowupQuestion: React.FC<IFollowupQuestionProps> = ({
  sectionItem,
  sectionItemIndex,
  sectionIndex,
  resetValidations,
  isParentQuestionRequired,
  followupQuestionId
}) => {
  const getQuestionType = () => {
    switch (sectionItem.questionTypeId) {
      case QuestionType.PARAGRAPH:
        return (
          <ParagraphQuestion
            sectionIndex={sectionIndex}
            sectionItemIndex={sectionItemIndex}
            resetValidations={resetValidations}
            isRequired={isParentQuestionRequired}
            questionDetails={sectionItem.questionDetails}
            followupQuestionId={followupQuestionId}
          />
        );
      case QuestionType.YESNO:
        let choices: IChoiceModel[] =
          sectionItem.choices?.map((choice) => {
            return { ...choice, isSelected: false };
          }) || [];

        return (
          <YesNoQuestion
            choices={choices?.length > 0 ? choices : initialYesNoChoiceData}
            sectionIndex={sectionIndex}
            sectionItemIndex={sectionItemIndex}
            isRequired={isParentQuestionRequired}
            resetValidations={resetValidations}
            followupQuestionId={followupQuestionId}
          />
        );
      case QuestionType.MULTICHOICE:
        return (
          <MultiChoiceQuestionPreview
            choices={sectionItem.choices}
            resetValidations={resetValidations}
            isRequired={isParentQuestionRequired}
            followupQuestionId={followupQuestionId}
          />
        );

      default:
        break;
    }
  };
  return <div className="followup-question-wrapper">{getQuestionType()}</div>;
};

export default FollowupQuestion;
