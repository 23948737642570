import React, { useEffect, useState } from "react";
import { CustomModal } from "common/components/UIComponents/CustomModal";
import { Form } from "react-bootstrap";
import { AddEditModalDialog } from "helper/Constants";
import TinyMCEComponent from "common/components/tinymce/TinyMCE";
import { SavedMessageModel } from "common/model/saved-message";
import { GatherCompanySettingsModel } from "common/model/company-settings";
import { IValidationState } from "pages/settings/client-instructions/parts/AddEditModal";
import { AppNotifier } from "common/components/toast/Toast";
import { EngagementsType } from "common/model/engagements-type";
import VariablesList from "../client-instructions/parts/VariablesList";
import Alert, { AlertType } from "common/components/alert/Alert";


export enum ModalType {
  NONE,
  ADD,
  EDIT
}
interface IEngagementTypeModal {
  show: boolean;
  onSubmit: (data: EngagementsType) => void;
  onHide: () => void;
  modalType: ModalType;
  initialData: EngagementsType;
  data: EngagementsType[];
}
export const initialValidationMessages = {
  name: "",
  text: ""
};

const EngagementTypeAddEditModal: React.FC<IEngagementTypeModal> = ({
  show,
  onHide,
  onSubmit,
  modalType,
  initialData,
  data
}) => {
  const ref = React.useRef<HTMLFormElement>(null);
  const [modalData, setModalData] = useState<EngagementsType>(initialData);
  const [namevalidation, setNamevalidation] = useState("");
  const [isClose, setIsClose] = React.useState(true);
  const isSystemDefaultMessage = modalData.isSystemDefault;
  const [validated, setValidated] = React.useState<boolean>(false);
  useEffect(() => {
    setNamevalidation("");
    setModalData(initialData);
  }, [initialData]);

  const checkDuplicate = (messageDetail: EngagementsType) => {
    let count: number = 0;
    const emailMessages = data;
    emailMessages != null &&
      emailMessages.map((message, index) => {
        if (message.name.trim().toLowerCase() === messageDetail.name.trim().toLowerCase() && message.id !== messageDetail.id)
          count++;
      });
    return count;
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value.trimStart();
    if (input) {
      setNamevalidation("");
    } else {
      setNamevalidation(AddEditModalDialog.ENGAGEMENT_TYPE.NAME_VALIDATION_TEXT)
      setValidated(false);
    }
    setModalData({ ...modalData, name: input });
  };

  const isModalDataValid = () => {
    let isValid = true;
    if (checkDuplicate(modalData) > 0) {
      AppNotifier.Warning(AddEditModalDialog.ENGAGEMENT_TYPE.DUPLICATE_INSTRUCTION_NAME);
      isValid = false;
    }
    if (modalData.name.trim().length === 0) {
      setNamevalidation(AddEditModalDialog.ENGAGEMENT_TYPE.NAME_VALIDATION_TEXT)
      isValid = false;
      setValidated(false);
    }
    else if (!/^[a-zA-Z0-9 ]+$/.test(modalData.name)) {
      setNamevalidation(AddEditModalDialog.ENGAGEMENT_TYPE.NAME_ALAPANUMERIC_VALIDATION)
      isValid = false;
      setValidated(false);
    }
    return isValid;
  };
  const onSetDefaultChange = () => {
    setModalData({ ...modalData, isUserDefault: !modalData.isUserDefault })
  }
  const onCancel = () => {
    setValidated(false)
    onHide();
  };

  const handleOnSubmit = () => {
    const form = ref.current;
    setValidated(false)
    if (!!form && isModalDataValid()) {
      onSubmit(modalData);
    }
  };


  return (
    <CustomModal
      show={show}
      onSubmit={handleOnSubmit}
      onHide={onCancel}
      title={modalType === ModalType.ADD ? AddEditModalDialog.ENGAGEMENT_TYPE.ADD_TITLE : AddEditModalDialog.ENGAGEMENT_TYPE.EDIT_TITLE}
      confirmButtonName={
        modalType === ModalType.ADD
          ? AddEditModalDialog.CLIENT_INSTRUCTION.ADD_BUTTON_TEXT
          : AddEditModalDialog.CLIENT_INSTRUCTION.UPDATE_BUTTON_TEXT
      }
      onCancelButtonClick={onCancel}
      className="popup-engagement-type-modal"
      confirmDisabled={validated}
    >
      <Alert
        type={AlertType.INFO}
        children={
          <div className="flex-column-start">
            <>
              <p>{AddEditModalDialog.ENGAGEMENT_TYPE.INFO_MESSAGE}</p>
            </>
          </div>
        }
      />

      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleOnSubmit();
        }}
        ref={ref}
        noValidate
        validated={validated}
      >
        <div className="form-item-group engagement-type-addedit-value-width">
          <Form.Label className="required-marker required-marker-after">Engagement Type</Form.Label>
          <Form.Control
            className="ss-input"
            value={modalData?.name}
            type="text"
            name="name"
            onChange={handleOnChange}
            title={isSystemDefaultMessage ? AddEditModalDialog.ENGAGEMENT_TYPE.SYSTEM_DEFAULT_HOVER_TEXT : ""}
            disabled={isSystemDefaultMessage}
            placeholder={AddEditModalDialog.ENGAGEMENT_TYPE.NAME_PLACEHOLDER_TEXT}
            required={true}
            maxLength={50}
          />
          <div className="engagement-type-addedit-modal">
            <h6 className="text-danger">{namevalidation}</h6>
          </div>

        </div>
        <div className="settings-form-item default-checkbox">
          <Form.Check type="checkbox" checked={modalData.isUserDefault} onChange={onSetDefaultChange} disabled={initialData.isUserDefault} />
          <Form.Label>Set as Default</Form.Label>
        </div>
      </Form>
    </CustomModal>
  );
};

export default EngagementTypeAddEditModal;
