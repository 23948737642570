import { ThunkAction, Action } from '@reduxjs/toolkit';
import { GatherCompanySettingsModel } from 'common/model/company-settings';
import { SavedMessageModel } from 'common/model/saved-message';
import { API } from "services/api/api-config"
import Axios from "services/api/axios-interceptor";
import { AppState } from 'store';
import { addSavedMessages, deleteSavedMessages, editSavedMessages, setSavedMessages } from 'store/slices/settingSavedMessages-slice';
import { setDefaultSavedmsg } from './companySettings-service';
import { AppNotifier } from 'common/components/toast/Toast';
import { SavedMessageConstants } from 'helper/Constants';



export const getSavedMessages = (): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
        try {

            Axios.get(`${API.SAVEDMESSAGE.GET_SAVED_MESSAGES}`, {
                method: 'GET',
                credentials: 'include'
            })
                .then(response => {
                    // Assuming response.data contains the settings data
                    const savedMessages: SavedMessageModel[] = response.data as SavedMessageModel[];
                    // Dispatch the action to update the Redux store
                    dispatch(setSavedMessages(savedMessages));
                })
        }
        catch (error: any) {
            AppNotifier.Error(SavedMessageConstants.Failure.Get);
        }
    }

export const addSavedMessagesapi = (
    addedMessage: SavedMessageModel,
    setdefault: boolean,
    companysetting: GatherCompanySettingsModel,
    callback?: () => void): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
        Axios.post(`${API.SAVEDMESSAGE.ADD_SAVED_MESSAGES}`, addedMessage, {
            credentials: 'include'
        })
            .then(response => {

                // Assuming response.data contains the settings data
                addedMessage.id = response.data as number;
                // Dispatch the action to update the Redux store
                dispatch(addSavedMessages(addedMessage))
                if (setdefault) {
                    dispatch(setDefaultSavedmsg(companysetting, addedMessage.id))
                }

                AppNotifier.Success(SavedMessageConstants.Success.Add);
                callback && callback();
            }).catch(() => {
                AppNotifier.Error(SavedMessageConstants.Failure.Add);
            })
    }

export const editSavedMessagesapi = (
    editedmessage: SavedMessageModel,
    setdefault: boolean,
    companysetting: GatherCompanySettingsModel,
    callback?: () => void): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
        Axios.put(`${API.SAVEDMESSAGE.EDIT_SAVED_MESSAGES}`, editedmessage, {
            credentials: 'include'
        })
            .then(response => {
                dispatch(editSavedMessages(editedmessage))
                if (setdefault === true) {
                    dispatch(setDefaultSavedmsg(companysetting, editedmessage.id))
                }else if(companysetting.defaultSettings.savedMessage == editedmessage.id){
                    dispatch(setDefaultSavedmsg(companysetting, 0))
                }

                AppNotifier.Success(SavedMessageConstants.Success.Edit);
                callback && callback();
            }).catch(() => {
                AppNotifier.Error(SavedMessageConstants.Failure.Edit);
            })
    }

export const deleteSavedMessagesapi = (deletemessage: SavedMessageModel, callback?: () => void): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
        const data = {
            id: deletemessage.id,
            name: deletemessage.name,
            body: deletemessage,
            isAllowToEdit: deletemessage.isEditable
        }
        Axios.deleteWithPayload(`${API.SAVEDMESSAGE.DELETE_SAVED_MESSAGE}`, data, {
            headers: { credentials: 'include' }
        })
            .then(response => {

                dispatch(deleteSavedMessages(deletemessage))
                AppNotifier.Success(SavedMessageConstants.Success.Delete);
                callback && callback();
            }).catch(() => {
                AppNotifier.Error(SavedMessageConstants.Failure.Delete);
            })
    }