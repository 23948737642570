import { ProcessStepStatus } from "common/enum";

export interface IUploadTemplateState {
    templates : IUploadTemplate[] | null,
    selectedTemplate : IUploadTemplateDetails | null,
    processSteps : IProcessStep[] | null
}

export interface IUploadTemplate {
    id : number;
    name : string;
    isDefault : boolean;
}

export interface IUploadTemplateDetails {
    id : number;
    name : string;
    processSteps : IProcessStep[];
    customQuestionTemplateId? :number;
    requestDocumentsTemplateId? :number;
}

export interface IProcessStep {
    id : number;
    name : string;
    order : number;
    status : ProcessStepStatus;
}

export const initialUploadTemplateState: IUploadTemplateState = {
    templates : [],
    selectedTemplate : null,
    processSteps : null
}
