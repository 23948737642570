import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialPasswordPolicySettings, IPasswordPolicySettings } from "common/model/PasswordPolicySettings.model";

const password = createSlice({
    name: "passwordPolicy",
    initialState: initialPasswordPolicySettings,
    reducers: {
        setPasswordPolicy: (state, action: PayloadAction<IPasswordPolicySettings>) => {
            state.passwordPolicy = action.payload.passwordPolicy;
            state.passwordSettings = action.payload.passwordSettings;
            state.modifiedDate = action.payload.modifiedDate;
        }
    }
}
)
export const { setPasswordPolicy } = password.actions;
export const { reducer: passwordPolicyReducer } = password;