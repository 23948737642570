export const ResourceIdConstants = {

    ResendAccessLinkInDeliveredGather: "GTRDLVRY_G_RSACLK",
    ResendAccessLinkInArchivedGather: "GTRARC_G_RSACLK",

    ViewAccessCodeInDeliveredGather: "GTRDLVRY_G_VWACCD",
    ViewAccessCodeInArchivedGather: "GTRARC_G_VWACCD",

    ReopenSourceDocumentUpload: "GTRDLVRY_G_ROSDU",
    ReopenOrganizer: "GTRDLVRY_G_ROORG",

    SendReminderToolBar : "GTRDLVRY_T_SNRMR",
    SendReminderGrid : "GTRDLVRY_G_SNRMR",

    DeleteGatherInProcessToolBar : "GTRINPRO_T_DELETE",
    DeleteGatherInProcessGridProforma : "GTRINPRO_G_DELPFM",
    DeleteBatchGatherInProcessGridBatch : "GTRINPRO_G_DELBTHORG",
    DeleteBarchInProcessGridBatch : "GTRINPRO_G_DELBTH",
    DeleteDeliveredGatherToolBar : "GTRDLVRY_T_DELETE",
    DeleteDeliveredGatherGird : "GTRDLVRY_G_DELETE",
    DeleteUndeliveredBatchGird : "GTRUDB_G_DELETE",
    DeleteArchivedGatherToolBar : "GTRARC_T_DELETE",
    DeleteArchivedGatherGrid : "GTRARC_G_DELETE",
    DeleteRecycleBinToolBar: "GTRRCB_T_DELETE",

    DownloadDeliveredGatherToolBar: "GTRDLVRY_T_DNLD",
    DownloadDeliveredGatherGird : "GTRDLVRY_G_DNLD",
    DownloadUndeliveredBatchGird : "GTRUDB_G_DNLD",
    DownloadArchivedGatherToolBar : "GTRARC_T_DNLD",
    DownloadArchivedGatherGird : "GTRARC_G_DNLD",

    NewGather: "GTRINPRO_T_NEWGTR",
    ProcessGather: "GTRINPRO_G_PROPFM",
    ProcessBatchGathers: "GTRINPRO_G_PROBTH",
    DeliverGather: "GTRINPRO_M_DLVRYPFM",
    DeliverBatchGathers: "GTRINPRO_M_DLVRYBTH",
    
    ChangeStatusDeliveredGatherToolBar : "GTRDLVRY_T_CHNSTS",
    RestoreArchivedToolbar : "GTRARC_T_RSTR",
    RestoreArchivedGrid : "GTRARC_G_RSTR",
    RestoreRecycleBinToolbar : "GTRRCB_T_RSTR",
    ArchiveActionDeliveredToolbar: "GTRDLVRY_T_ARCV",

    ReportProblemAppHeader: "GTRAPP_H_RPTPRO",
    ReportProblemGatherInProcessProformaGrid: "GTRINPRO_G_RPPFM",
    ReportProblemGatherInProcessBatchGrid: "GTRINPRO_G_RPBTH",
    ReportProblemDeliveredGather: "GTRDLVRY_G_RPTPRO",
    ReportProblemArchivedGather: "GTRARC_G_RPTPRO",

    ExportToExcelGatherInProcessProformaToolBar : "GTRINPRO_T_ETXLPFM",
    ExportToExcelGatherInProcessBatchToolBar : "GTRINPRO_T_ETXLBTH",
    ExportToExcelDeliveredGatherToolBar : "GTRDLVRY_T_ETXL",
    ExportToExcelUndeliveredBatchToolBar : "GTRUDB_T_ETXL",
    ExportToExcelArchivedGatherToolBar : "GTRARC_T_ETXL",

    DeliveredGatherGirdClientViewBtn : "GTRDLVRY_G_CLIVW",
    ArchivedGatherGirdClientViewBtn : "GTRARC_G_CLIVW",

    GatherInProcessGirdEditClientBtnProforma : "GTRINPRO_G_EDTCLIPFM",
    GatherInProcessGirdEditClientBtnBatch : "GTRINPRO_G_EDTCLIBTH",
    DeliveredGatherGirdEditClientBtn : "GTRDLVRY_G_EDTCLI",

    DeliveredGatherGirdClientTrackingBtn : "GTRDLVRY_G_CLITRK",
    ArchivedGatherGirdClientTrackingBtn : "GTRARC_G_CLITRK",

    ClosedByFirmDeliveredGrid:"GTRDLVRY_G_CLSBFIRM",
    ClosedByFirmDeliveredToolbar:"GTRDLVRY_T_CLSBFIRM",
    ClosedByFirmArchiveGrid:"GTRARC_G_CLSBFIRM",
    ClosedByFirmArchiveToolbar:"GTRARC_T_CLSBFIRM",
    None:"NONE"

}

export const RbacPermission = {
    OperationDenied: "You do not have permission to perform this operation,\nplease contact your system administrator for more\ninformation.",
    PageDenied: "You do not have permissions to access this page, please contact your system administrator for more information."
}
