import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IProcessStep, IUploadTemplate, IUploadTemplateDetails, IUploadTemplateState, initialUploadTemplateState } from "common/model/upload-template";

const userSlice = createSlice({
    name: 'uploadTemplate',
    initialState: initialUploadTemplateState as IUploadTemplateState,
    reducers: {
        setUploadTemplates(state, action: PayloadAction<IUploadTemplate[]>) {
            state.templates = action.payload;
        },
        setSelectedTemplate(state, action: PayloadAction<IUploadTemplateDetails | null>) {
            state.selectedTemplate = action.payload;
        }, 
        setProcessSteps(state, action: PayloadAction<IProcessStep[]>) {
            state.processSteps = action.payload;
        },
    }
})
export const { setUploadTemplates, setSelectedTemplate, setProcessSteps } = userSlice.actions;
export const { reducer: uploadTemplateReducer } = userSlice;