import React, { FC, useEffect, useRef, useState } from "react";
import { Button, Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { IMyAccountLayout } from "./MyAccountLayout";
import { InfoCircle } from "assets/svg/SVGIconsCollection";
import { countryCodeDisplay, phoneNumberDisplay, GetCountryCode, getDefaultCountryCode } from "helper/HelperFunctions";
import { PhoneNumber } from "common/components/PhoneNumber";
import * as Validation from "helper/Validations";
import { IUserProfile } from "common/model/userModel";
import { MyAccountConstants, ProfileInformation, ValidationContants } from "helper/Constants";
import { useAppDispatch, useAppSelector } from "common/hooks/redux-hooks";
import { AppState } from "store";
import { AppNotifier } from "common/components/toast/Toast";
import { requestUserProfile, saveMyAccount } from "store/services/user-service";
import { requestMFAOTPLength, saveOneTimePassword, validateOTP } from "store/services/otp-service";
import { CustomePhone } from "common/components/phone-number/PhoneInput";

interface IMyAccountForm extends IUserProfile {
  existingEmailAddress: string;
  showChangePassword: boolean;
  showOtpVerification: boolean;
  saving: boolean;
  otpValue: string;
  disableVerifyLink: boolean;
  ptin: string;
  title: string;
}

export interface CountryData {
  name: string;
  dialCode: string;
  countryCode: string;
  format: string;
}

const initialFormState: IMyAccountForm = {
  existingEmailAddress: "",
  showChangePassword: false,
  showOtpVerification: false,
  saving: false,
  otpValue: "",
  disableVerifyLink: false,
  firstName: "",
  lastName: "",
  phone: "",
  extension: "",
  fax: "",
  emailAddress: "",
  userId: 0,
  authenticationProviders: [],
  metadata: "",
  countryCode: "",
  mobileNumber: "",
  isMobileVerify: false,
  ptin: "",
  title: "",
  isMFAEnabled: false,
  readonlyFields: []
};

const Profile: FC<IMyAccountLayout> = (props) => {
  const ref = useRef<HTMLFormElement>(null);
  const [formDetail, setFormDetail] = useState<IMyAccountForm>(initialFormState);
  const [validated, setValidated] = useState<boolean>(false);
  const [mobileValidation, setMobileValidation] = useState<{ message: string; error: boolean }>({ message: "", error: false });
  const profile: IUserProfile = useAppSelector((state: AppState) => state.userReducer.loggedInUserProfile);
  const [mfaOTPLength, setMfaOTPLength] = useState<number>(0);

  const onFormChange: React.ChangeEventHandler = (e) => {
    const element = e.target as HTMLInputElement;
    const data = { ...formDetail, [element.id]: element.value };
    setFormDetail(data);
  };
  const onFaxChange: React.ChangeEventHandler = (e) => {
    const element = e.target as HTMLInputElement;
    if (element.value.match(/^\d+$/) || element.value === "") {
      setFormDetail({ ...formDetail, fax: element.value });
    } else {
      e.preventDefault();
      e.stopPropagation();
    }
  };
  const onExtChange: React.ChangeEventHandler = (e) => {
    const element = e.target as HTMLInputElement;
    if (element.value.match(/^\d+$/) || element.value === "") {
      setFormDetail({ ...formDetail, extension: element.value });
    } else {
      e.preventDefault();
      e.stopPropagation();
    }
  };
  const onChangeMobileNumber = (mobile: string, countryCode: string) => {
    setFormDetail({
      ...formDetail,
      mobileNumber: `${mobile}`,
      countryCode: `${countryCode}`,
      disableVerifyLink: false,
      isMobileVerify: false
    });
  };

  const dispatch = useAppDispatch();

  const handleSubmit: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const validatedMobileNumberData = validateMobileNumber();
    setMobileValidation(validatedMobileNumberData);
    const validPtin = Validation.isValidatePTIN(formDetail.ptin);
    const validFax = Validation.validateFax(formDetail.fax, true) || !Validation.NullandEmptyCheck(formDetail.fax);
    const validPhone =
      formDetail.phone.length === ProfileInformation.PHONE_NUMBER_LENGTH || (!formDetail.phone && !formDetail.extension);
    const validExt = formDetail.extension.length < ProfileInformation.EXTENSION_LENGTH;
    const validName = Validation.ValidateName(formDetail.firstName) && Validation.ValidateName(formDetail.lastName);
    const allValid = !validatedMobileNumberData.error && validPtin && validFax && validPhone && validExt && validName;

    const form = ref.current;
    if (!!form && form.checkValidity() && allValid) {
      setFormDetail({
        ...formDetail,
        saving: true,
        showOtpVerification: false,
        otpValue: "",
        disableVerifyLink: false
      });
      dispatch(
        saveMyAccount(formDetail, false, (response: any, error: any) => {
          setFormDetail({ ...formDetail, saving: false });
          if (error) {
            AppNotifier.Error(MyAccountConstants.UserUpdateFailedMessage);
          } else {
            setFormDetail({
              ...formDetail
            });
            if (response) {
              AppNotifier.Success(MyAccountConstants.APIResponse.UserUpdateSuccess);
              props.onHide();
              dispatch(requestUserProfile());
            } else {
              AppNotifier.Error(MyAccountConstants.UserUpdateFailedMessage);
            }
          }
        })
      );
      setValidated(false);
    } else {
      setValidated(true);
    }
  };

  const onVerifyClick = () => {
    const validationData = validateMobileNumber(true);
    setMobileValidation(validationData);
    if (!validationData.error) {
      setFormDetail({ ...formDetail, disableVerifyLink: true });
      const DISABLE_VERIFY_LINK_TIMEOUT = 108000;
      setTimeout(() => {
        setFormDetail({ ...formDetail, disableVerifyLink: false });
      }, DISABLE_VERIFY_LINK_TIMEOUT);
      const mobileNumber = formDetail.countryCode + formDetail.mobileNumber;
      const countryCode = formDetail.countryCode;
      setFormDetail({
        ...formDetail,
        showOtpVerification: true,
        otpValue: ""
      });
      dispatch(saveOneTimePassword(mobileNumber, countryCode));
    }
  };

  const onChangeOtpValue = (event: any) => {
    if (Validation.ValidateTenDigitNumber(event)) {
      const value = event.target.value;
      setFormDetail({ ...formDetail, otpValue: value });
    }
  };

  const onOtpSubmit = () => {
    // eslint-disable-next-line no-restricted-globals
    if (Validation.ValidateTenDigitNumber(event)) {
      const value = formDetail.otpValue;
      if (mfaOTPLength === value.length) {
        const mobileNumber = formDetail.countryCode + formDetail.mobileNumber;
        dispatch(validateOTP(value, mobileNumber, hideVerifyLink));
      }
    }
  };

  const hideVerifyLink = (isOTPValid: boolean) => {
    if (isOTPValid) {
      setFormDetail({
        ...formDetail,
        showOtpVerification: false,
        isMobileVerify: true
      });
    }
  };

  const onChangePhoneNumber = (value: string) => setFormDetail({ ...formDetail, phone: value });

  useEffect(() => {
    dispatch(
      requestMFAOTPLength((data: number) => {
        setMfaOTPLength(data);
      })
    );
  }, []);

  useEffect(() => {
    setFormDetail({
      ...formDetail,
      existingEmailAddress: profile.emailAddress,
      ...profile,
      countryCode: profile.countryCode
    });
  }, [profile]);

  const validateMobileNumber = (isVerify?: boolean) => {
    formDetail.countryCode === "" && Validation.NullandEmptyCheck(formDetail.mobileNumber) && (formDetail.countryCode = getDefaultCountryCode());
    const enabledMfa = isVerify || profile.isMFAEnabled;
    if (enabledMfa && !Validation.NullandEmptyCheck(formDetail.mobileNumber)) {
      return {
        message: ValidationContants.MobileNumberWarning,
        error: true
      };
    } else if (
      Validation.NullandEmptyCheck(formDetail.mobileNumber) &&
      !Validation.validatePhoneLength(formDetail.mobileNumber)
    ) {
      return {
        message: ValidationContants.MobileNumberLengthWarning,
        error: true
      };
    } else if (
      (formDetail.countryCode === "" || formDetail.countryCode === undefined) &&
      Validation.NullandEmptyCheck(formDetail.mobileNumber)
    ) {
      return {
        message: ValidationContants.CountryCodeWarning,
        error: true
      };
    } else if (
      formDetail.countryCode !== "" &&
      formDetail.countryCode !== undefined &&
      !Validation.NullandEmptyCheck(formDetail.mobileNumber)
    ) {
      formDetail.countryCode = getDefaultCountryCode();
      return {
        message: "",
        error: false
      };
    } else {
      return {
        message: "",
        error: false
      };
    }
  };

  const onHide = () => {
    props.onHide();
  };
 
  return formDetail && (
    <div>
      <h3 className="heading-blue-1">{ProfileInformation.TITLE}</h3>
      <p className="text-gray-description">{ProfileInformation.DESCRIPTION}</p>
      <Form ref={ref} id="profile-form" noValidate validated={validated}>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="title" xs={2}>
            <Form.Label>{ProfileInformation.LABEL_TITLE}</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              onChange={onFormChange}
              value={formDetail?.title}
              placeholder={ProfileInformation.PLACEHOLDER_TITLE}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="firstName">
            <Form.Label>{ProfileInformation.LABEL_FIRST_NAME}</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              onChange={onFormChange}
              value={formDetail?.firstName}
              required
              disabled={Validation.isControlDisabled("FirstName", profile)}
              placeholder={ProfileInformation.PLACEHOLDER_FIRST_NAME}
              isInvalid={!Validation.ValidateName(formDetail.firstName)}
            />
            <Form.Control.Feedback type="invalid">
              {!formDetail.firstName ? ValidationContants.FirstNameWarning : ValidationContants.NameLengthWarning}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} controlId="lastName">
            <Form.Label>{ProfileInformation.LABEL_LAST_NAME}</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              onChange={onFormChange}
              value={formDetail?.lastName}
              required
              disabled={Validation.isControlDisabled("LastName", profile)}
              placeholder={ProfileInformation.PLACEHOLDER_LAST_NAME}
              isInvalid={!Validation.ValidateName(formDetail.lastName)}
            />
            <Form.Control.Feedback type="invalid">
              {!formDetail.lastName ? ValidationContants.LastNameWarning : ValidationContants.NameLengthWarning}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="emailAddress" xs={4}>
            <Form.Label>{ProfileInformation.LABEL_EMAIL}</Form.Label>
            <Form.Control
              size="sm"
              type="email"
              onChange={onFormChange}
              value={formDetail?.emailAddress}
              required
              disabled={Validation.isControlDisabled("EmailAddress", profile)}
              placeholder={ProfileInformation.PLACEHOLDER_EMAIL}
              isInvalid={!Validation.isValidEmailAddress(formDetail.emailAddress)}
            />
            <Form.Control.Feedback type="invalid">
              {!formDetail?.emailAddress ? ValidationContants.EmailAdrressWarning : ValidationContants.ValidEmailAddressWarning}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group
          
            as={Col}
            className={
              validated && formDetail.phone.length > 0 && formDetail.phone.length < ProfileInformation.PHONE_NUMBER_LENGTH
                ? "input-error"
                : ""
            }
            xs={4}
          >
              <Form.Label>{ProfileInformation.LABEL_PHONE_NUMBER}</Form.Label>
              <span className="optional-label">Optional</span>
            <PhoneNumber
              phoneNumber={formDetail?.phone}
              handleChangePhoneNumber={onChangePhoneNumber}
              disabled={Validation.isControlDisabled("Phone", profile)}
              isInvalid={
                !formDetail.phone &&
                formDetail.extension.length != 0 &&
                formDetail.extension.length < ProfileInformation.EXTENSION_LENGTH
              }
            />
            <Form.Control.Feedback type="invalid">{ValidationContants.PhoneNumberWarning}</Form.Control.Feedback>
            {validated &&
              formDetail.phone.length > 0 &&
              formDetail.phone.length < ProfileInformation.PHONE_NUMBER_LENGTH &&
              formDetail.extension.length != 0 && (
                <div className="input-error-message">
                  {!formDetail.phone && formDetail.extension
                    ? ValidationContants.PhoneNumberWarning
                    : ValidationContants.PhoneNumberLengthWarning}
                </div>
              )}
          </Form.Group>

          <Form.Group
            as={Col}
            controlId="extension"
            xs={4}
            className={validated && formDetail.extension.length > ProfileInformation.EXTENSION_LENGTH ? "input-error" : ""}
          >
            <Form.Label>{ProfileInformation.LABEL_EXTENSION}</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              onChange={onExtChange}
              value={formDetail?.extension}
              disabled={Validation.isControlDisabled("Extension", profile)}
              placeholder={ProfileInformation.PLACEHOLDER_EXTENSION}
              isInvalid={validated && formDetail.extension.length >= ProfileInformation.EXTENSION_LENGTH}
            />
            <Form.Control.Feedback type="invalid">{ValidationContants.ExtensionWarning}</Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Form.Group as={Row} className="mb-3">
          <Col xs={8} className={`${mobileValidation.error ? "input-error" : ""} profile-mobile-number`}>
            <div className="d-flex justify-content-between align-items-center">
              <div className="flex-fill">
                <div className="d-flex justify-content-between pos-relative">
                  <Form.Label>{ProfileInformation.LABEL_MOBILE_NUMBER}</Form.Label>
                  {!profile.isMFAEnabled && <span className="optional-text">Optional</span>}
                </div>
                <CustomePhone
                  onChangeUserMobile={onChangeMobileNumber}
                  initialCountryCode={formDetail.countryCode}
                  initialMobileNumber={formDetail.mobileNumber}
                />

                {mobileValidation.error && <span className="input-error-message w-100">{mobileValidation.message}</span>}
              </div>
              {!formDetail.isMobileVerify && (
                <div className="d-flex align-items-center mt-3">
                  <Button
                    size="sm"
                    variant="link"
                    onClick={() => !formDetail.disableVerifyLink && onVerifyClick()}
                    className="heading-blue-2 cursor-pointer"
                  >
                    {ProfileInformation.VERIFY_TITLE}
                  </Button>
                  <OverlayTrigger
                    trigger="hover"
                    placement="bottom"
                    rootClose
                    overlay={<Tooltip id={"mobile-verification-tooltip"}>{ProfileInformation.VERIFY_HELP_TEXT}</Tooltip>}
                  >
                    {({ ref, ...triggerHandler }) => (
                      <span ref={ref} {...triggerHandler}>
                        <InfoCircle />
                      </span>
                    )}
                  </OverlayTrigger>
                </div>
              )}
            </div>
          </Col>

          <Form.Group as={Col} controlId="fax" xs={4} className="profile-fax-number">
            <div className="d-flex justify-content-between pos-relative">
              <Form.Label>{ProfileInformation.LABEL_FAX_NUMBER}</Form.Label>
              <span className="optional-text">Optional</span>
            </div>
            <Form.Control
              size="sm"
              type="text"
              onChange={onFaxChange}
              value={formDetail?.fax}
              disabled={Validation.isControlDisabled("Fax", profile)}
              placeholder={ProfileInformation.PLACEHOLDER_FAX_NUMBER}
              minLength={ProfileInformation.FAX_LENGTH}
              maxLength={ProfileInformation.FAX_LENGTH}
            />
            <Form.Control.Feedback type="invalid">{ValidationContants.FaxWarning}</Form.Control.Feedback>
          </Form.Group>
        </Form.Group>

        {formDetail.showOtpVerification && !formDetail.isMobileVerify && (
          <div className="verification-container mb-3">
            <div className="mb-3 verification-description">
              <b>Verify Phone Number</b>
              <br />
              Please enter the verification code sent to{" "}
              <b>{`${countryCodeDisplay(formDetail.countryCode)} ${phoneNumberDisplay(formDetail.mobileNumber)}`}</b>
            </div>
            <div className="input-wrapper">
              <Form.Control
                size="sm"
                onChange={onChangeOtpValue}
                value={formDetail.otpValue}
                placeholder={ProfileInformation.PLACEHOLDER_ACCESS_CODE}
              />
              <Button variant={"secondary"} onClick={onOtpSubmit} disabled={mfaOTPLength !== formDetail.otpValue.length}>
                {ProfileInformation.ACCESS_CODE_BUTTON}
              </Button>
            </div>
            <div>
              Didn’t receive the code?
              <Button variant="link" onClick={onVerifyClick}>
                Resend
              </Button>
            </div>
          </div>
        )}

        <div className="profile-footer d-flex justify-content-end">
          <Button className="button-default ss-btn-secondary" onClick={onHide}>
            {ProfileInformation.CANCEL_BUTTON}
          </Button>
          <Button className="button-default ss-btn-primary" style={{ marginLeft: "8px" }} onClick={handleSubmit}>
            {ProfileInformation.OK_BUTTON}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default Profile;
