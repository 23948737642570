import * as React from "react";
import Choice from "../Choice";
import {
  FollowUpQuestionIdentifer,
  IHandleAddChoiceIdentifierPayload,
  ISectionItems,
  ISubQuestionChoiceIdentifierPayload,
  ISubQuestionChoiceTextOnChangePayload
} from "store/models/CustomQuestionModel";
import {
  HandleSubQuestionAddChoice,
  HandleSubQuestionChoiceTextOnChange,
  HandleSubQuestionRemoveChoice
} from "store/slices/form-builder";
import { useAppDispatch } from "common/hooks/redux-hooks";
import { getMaxOptionsDisbaledTooltip } from "helper/HelperFunctions";
import { PlusCircleIcon } from "assets/svg/SVGIconsCollection";
import { AutomationLocators } from "helper/AutomationLocators";
import { cqSettingsDummy } from "helper/dummy";

interface IMultiChoiceQuestionProps {
  sectionItem: ISectionItems;
  sectionItemIndex: number;
  sectionIndex: number;
  followUpQuestionIdentifer: FollowUpQuestionIdentifer;
  questionIndex: number;
}
const MultiChoiceQuestion: React.FC<IMultiChoiceQuestionProps> = ({
  sectionItem,
  sectionIndex,
  sectionItemIndex,
  followUpQuestionIdentifer,
  questionIndex
}) => {
  const customQuestionSettings = cqSettingsDummy;
  const dispatch = useAppDispatch();
  const onChangeHandler = (text: string, choiceIndex: number) => {
    const payload: ISubQuestionChoiceTextOnChangePayload = {
      choiceText: text,
      sectionIndex: sectionIndex,
      sectionItemIndex: sectionItemIndex,
      choiceIndex: followUpQuestionIdentifer,
      subQuestionChoiceIndex: choiceIndex,
      questionIndex: questionIndex
    };
    dispatch(HandleSubQuestionChoiceTextOnChange(payload));
  };

  const onAddChoiceHandler = () => {
    if (sectionItem.choices.length < customQuestionSettings.choiceLimit) {
      const payload: IHandleAddChoiceIdentifierPayload = {
        sectionIndex: sectionIndex,
        sectionItemIndex: sectionItemIndex,
        choiceIndex: followUpQuestionIdentifer,
        questionIndex: questionIndex
      };
      dispatch(HandleSubQuestionAddChoice(payload));
    }
  };
  const onRemoveChoiceHandler = (choiceIndex: number) => {
    if (sectionItem.choices.length > 1) {
      const payload: ISubQuestionChoiceIdentifierPayload = {
        sectionIndex: sectionIndex,
        sectionItemIndex: sectionItemIndex,
        choiceIndex: followUpQuestionIdentifer,
        subQuestionChoiceIndex: choiceIndex,
        questionIndex: questionIndex
      };
      dispatch(HandleSubQuestionRemoveChoice(payload));
    }
  };
  return (
    <div className="question-type-wrapper follow-up-multi-choice">
      <div className="options-wrapper">
        {sectionItem.choices &&
          sectionItem.choices.length > 0 &&
          sectionItem.choices.map((choice, choiceIndex) => (
            <Choice
              key={choiceIndex}
              choiceIndex={choiceIndex}
              choice={choice}
              onChangeHandler={onChangeHandler}
              onRemoveChoiceHandler={onRemoveChoiceHandler}
              sectionItem={sectionItem}
            />
          ))}
        <div
          data-test-auto={AutomationLocators.Settings.CustomQuestions.Section.questionType.multiChoice.addChoice}
          title={
            sectionItem.choices.length >= customQuestionSettings.choiceLimit
              ? getMaxOptionsDisbaledTooltip(customQuestionSettings.choiceLimit)
              : ""
          }
          className={`add-options-button ${sectionItem.choices.length >= customQuestionSettings.choiceLimit ? "isDisabled" : ""}`}
          onClick={onAddChoiceHandler}
        >
          <PlusCircleIcon />
          Add option
        </div>
      </div>
    </div>
  );
};

export default MultiChoiceQuestion;
