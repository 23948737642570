import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { GatherInfoModel } from "common/model/batch-gather-document-process-model";
import { IProformaProcessState, initalProformaProcessState } from "common/model/proforma-info";
import { ClientInformationConstant } from "pages/Constants";

const proformaProcessSlice = createSlice({
    name: 'proformaprocess',
    initialState: initalProformaProcessState as IProformaProcessState,
    reducers: {
        setGatherInfo(state, action: PayloadAction<GatherInfoModel>) {
            state.gatherInfoVM = action.payload;
        },
        setGatherOrganizerDocument(state, action: PayloadAction<string>) {
            state.organizerDocument = action.payload;
        },
        updateGatherClientInfo(state, action: PayloadAction<any>) {
            if(state.gatherInfoVM){
                state.gatherInfoVM.gatherClients = action.payload
            }
        },
        updateGatherProcessInfo(state, action: PayloadAction<any>) {
            if(state.gatherInfoVM){
                state.gatherInfoVM = action.payload
            }
        },
        resetGatherProcessInfo(state) {
            state.gatherInfoVM = null;
        }
    }
})

export const { setGatherInfo, setGatherOrganizerDocument, updateGatherClientInfo, updateGatherProcessInfo, resetGatherProcessInfo } = proformaProcessSlice.actions;
export const { reducer: proformaProcessReducer } = proformaProcessSlice;