import React from "react";
import { DeleteIcon, EditIcon } from "assets/icons/IconCollection";
import { Form } from "react-bootstrap";
import { IMessageData } from "..";
import { EmailTemplateMessage } from "common/model/client-instruction";
import { MessageSettingsConstants } from "pages/Constants";
import { convertTextToHtmlInSavedMessage } from "helper/HelperFunctions";
import DOMPurify from "dompurify";

interface IEmailMessageBoxProps {
  selectedMessage: EmailTemplateMessage;
  onEdit: () => void;
  onDelete: () => void;
}
const EmailMessageBox: React.FC<IEmailMessageBoxProps> = ({ selectedMessage, onEdit, onDelete }) => {
  const sanitizedHtml = DOMPurify.sanitize(selectedMessage?.body);
  return (
    <div className="message-box-wrapper">
      <div>
        <div className="item-group">
          <span>Header</span>
          <p>{selectedMessage?.name}</p>
        </div>
        <div className="item-group">
          <span>Subject</span>
          <p>{selectedMessage?.subject}</p>
        </div>
        <div className="item-group body-field">
          <span>Body</span>
          <p
            dangerouslySetInnerHTML={{
              __html: convertTextToHtmlInSavedMessage(sanitizedHtml)
            }}
          ></p>
        </div>
      </div>
      <footer>
        <button
          onClick={onEdit}
          className="btn-with-icon"
          title={MessageSettingsConstants.EditMessage}
        >
          <EditIcon />
          Edit
        </button>
        {selectedMessage && !selectedMessage.isSystemDefault &&
        (
        <button
          onClick={onDelete}
          className="btn-with-icon delete-icon"
          title={MessageSettingsConstants.DeleteMessage}
        >
          <DeleteIcon />
          Delete
        </button>
        )}
      </footer>
    </div>
  );
};

export default EmailMessageBox;