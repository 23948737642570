import { FC, ReactElement, useEffect } from 'react';
import { Store } from 'redux';
import { userExpired, userFound, userRenewed} from 'store/services/auth-service';
import { userManager } from './userService';
import { useAppDispatch } from 'common/hooks/redux-hooks';

type Props = {
    store: Store
    children: ReactElement
}

const AuthProvider: FC<Props> = ({ store, children }) => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        window.process = {
            ...window.process,
        };
    }, []);
    useEffect(() => {
        const onUserLoaded = (user: any) => {
            dispatch(userFound(user))
        }

        const onAccessTokenExpiring = () => {
            userManager.signinSilent()
                .then((user) => {
                   dispatch(userRenewed(user))
                })
                .catch((error) => {
                    userManager.getUser().then((user) => dispatch(userRenewed(user)))
                })
        }

        const onAccessTokenExpired = () => {
            dispatch(userExpired())
        }

        const onUserSignedOut = () => {
            //store.dispatch(userSignedOut())
        }

        // events for user
        userManager.events.addUserLoaded(onUserLoaded)
        userManager.events.addAccessTokenExpiring(onAccessTokenExpiring)
        userManager.events.addAccessTokenExpired(onAccessTokenExpired)
        userManager.events.addUserSignedOut(onUserSignedOut)

        // Specify how to clean up after this effect:
        return function cleanup() {
            userManager.events.removeUserLoaded(onUserLoaded)
            userManager.events.removeAccessTokenExpiring(onAccessTokenExpiring)
            userManager.events.removeAccessTokenExpired(onAccessTokenExpired)
            userManager.events.removeUserSignedOut(onUserSignedOut)
        }
    }, [store])

    return <>{children}</>
}

export default AuthProvider;
