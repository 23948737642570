import Axios from "services/api/axios-interceptor"
import { DownloadStatus, IDownloadedZipFilesModel, IMyDownloadAllResponse, IMyDownloadRequest } from "common/model/download/downloaded-zip-files";
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { API } from "services/api/api-config";
import { AppState } from "store";
import { DisplayDownloadFile } from "common/components/common/DisplayDownloadFile";
import { AppNotifier } from "common/components/toast/Toast";
import { MyDownloadsConstants, RequestNotification } from "helper/Constants";
import { DownloadType } from "common/enum/GatherEnums";
import { StorageKey, getSessionStorage, setSessionStorage } from "helper/SessionStorageHelper";
import { IDownloadRequest } from "common/model/download/download-query-filters";

export const getMyDownloads = (successcallback?: (data: IDownloadedZipFilesModel[]) => void): ThunkAction<void, AppState, unknown, Action> => {
    return (dispatch) => {
        Axios.get(`${API.MY_DOWNLOADS.MY_DOWNLOADS}`, {
            method: 'GET',
            credentials: 'include'
        })
            .then((response) => response.data as IDownloadedZipFilesModel[])
            .then((data) => {
                if (data.length > 0 && getSessionStorage(StorageKey.DOWNLOADNOW) !== null) {
                    let downloadIds = getSessionStorage(StorageKey.DOWNLOADNOW) as string;
                    var downloadNowIds = JSON.parse(downloadIds) as number[];
                    var updatedDownloadNowIds: number[] = [];

                    downloadNowIds.forEach((id: number) => {
                        var downloadId = data.find((d) => d.id === id);
                        if (downloadId) {
                            if ((downloadId.status.toString() == DownloadStatus[DownloadStatus.Ready]
                                || downloadId.status.toString() == DownloadStatus[DownloadStatus.PartialReady])
                            && downloadId.fileSize > 0) {
                                AppNotifier.Success(RequestNotification.Success.DownloadReady);
                                var request: IMyDownloadRequest = {
                                    jobId: downloadId.jobId,
                                    fileName: downloadId.fileName
                                }
                                dispatch(downloadZip(request, true));
                            }
                            else {
                                if(downloadId.status.toString() == DownloadStatus[DownloadStatus.Error]) {
                                    AppNotifier.Error(downloadId.message);
                                    dispatch(deleteZip(downloadId.id, true));
                                }
                                else {
                                    updatedDownloadNowIds.push(id);
                                }
                                
                            }
                        }
                    });

                    setSessionStorage(StorageKey.DOWNLOADNOW, JSON.stringify(updatedDownloadNowIds));

                }
                successcallback && successcallback(data);
            }).catch((error) => {
                AppNotifier.Error(MyDownloadsConstants.Failure.GetMyDownload);
            })
    }
};

export const deleteZip = (jobId: any, idDownloadNow?: boolean, successcallback?: () => void): ThunkAction<void, AppState, unknown, Action> => {
    return () => {
        Axios.delete(`${API.MY_DOWNLOADS.MY_DOWNLOADS}/${jobId}`, {
            method: 'DELETE',
            credentials: 'include'
        })
            .then((response) => {
                if (response.status === 200) {
                    if (!idDownloadNow) {
                        AppNotifier.Success(MyDownloadsConstants.Success.Delete);
                    }
                    successcallback && successcallback();
                }
            }).catch((error) => {
                AppNotifier.Error(MyDownloadsConstants.Failure.Delete);
            })
    }
};

export const clearAll = (successcallback: () => void): ThunkAction<void, AppState, unknown, Action> => {
    return () => {
        Axios.delete(`${API.MY_DOWNLOADS.CLEAR_ALL_MY_DOWNLOADS}`, {
            method: 'DELETE',
            credentials: 'include'
        })
            .then((response) => {
                if (response.status === 200) {
                    AppNotifier.Success(MyDownloadsConstants.Success.ClearAll);
                    successcallback();
                }
            }).catch((error) => {
                AppNotifier.Error(MyDownloadsConstants.Failure.ClearAll);
            })
    }
};

export const downloadZip = (
    request: IMyDownloadRequest,
    isDownloadNow?: boolean,
    successCallback?: () => void): ThunkAction<void, AppState, unknown, Action> => {
    return (dispatch) => {
        Axios.post(`${API.MY_DOWNLOADS.DOWNLOAD_MY_DOWNLOADS}`, request,
            {
                method: 'POST',
                credentials: 'include'
            })
            .then((response) => response.data)
            .then(function (documentUrl) {
                let displayDownloadFile = new DisplayDownloadFile();
                var url = documentUrl?.value.sas;
                displayDownloadFile.directDownload(url, request.fileName);
                if (isDownloadNow != null && isDownloadNow) {
                    dispatch(deleteZip(request.jobId, isDownloadNow, successCallback));
                }
                successCallback && successCallback();
            }).catch((error) => {
                AppNotifier.Error(MyDownloadsConstants.Failure.DownloadZip);
            });
    }
};

export const downloadAll = (): ThunkAction<void, AppState, unknown, Action> => {
    return () => {
        Axios.post(`${API.MY_DOWNLOADS.DOWNLOAD_ALL_MY_DOWNLOADS}`, {
            method: 'POST',
            credentials: 'include'
        })
            .then((response) => response.data as IMyDownloadAllResponse[])
            .then(function (data) {
                let displayDownloadFile = new DisplayDownloadFile();
                displayDownloadFile.directBulkDownload(data);
            }).catch(error => {
                AppNotifier.Error(MyDownloadsConstants.Failure.DownloadAll);
            });
    }
};

export const addToMyDownload = (
    request: IDownloadRequest,
    isArchived: boolean,
    selectedDownloadType: DownloadType, 
    callback?: any): ThunkAction<void, AppState, unknown, Action> => {
    return (dispatch) => {
        try {
          let url = isArchived ? API.ARCHIVED_GATHER.DOWNLOAD.ADD_MYDOWNLOAD : API.DELIVERED_GATHER.DOWNLOAD.ADD_MYDOWNLOAD;
        Axios.put(`${url}`, request, {
          method: "PUT",
          credentials: "include"
        })
          .then((response) => response)
            .then((data: number) => {
                if (selectedDownloadType === DownloadType.DownloadNow) {
                    if (getSessionStorage(StorageKey.DOWNLOADNOW) === null) {
                        setSessionStorage(StorageKey.DOWNLOADNOW, JSON.stringify([data]));
                    }
                    else {
                        let ids = getSessionStorage(StorageKey.DOWNLOADNOW) as string;
                        var downloadNowIds = JSON.parse(ids);
                        downloadNowIds.push(data);
                        setSessionStorage(StorageKey.DOWNLOADNOW, JSON.stringify(downloadNowIds));
                    }
                }
                if (selectedDownloadType === DownloadType.DownloadToMyDownloads) {
                    AppNotifier.Success(RequestNotification.Success.DownloadWaitProcessing);
                }
                else {
                    AppNotifier.Success(RequestNotification.Success.DownloadNowWaitProcessing);
                }
  
            if (callback) {
              callback();
            }
          });
      } catch (error: any) {
        AppNotifier.Error(RequestNotification.Failure.DownloadGather);
      }
    };
  };

  export const addMergedToMyDownload = (
    isArchived: boolean,
    gatherId: number,
    callback?: any): ThunkAction<void, AppState, unknown, Action> => {
    return (dispatch) => {
        try {
            let url = isArchived ? API.ARCHIVED_GATHER.DOWNLOAD.MERGED_SOURCEDOCUMENTS : API.DELIVERED_GATHER.DOWNLOAD.MERGED_SOURCEDOCUMENTS;
            Axios.get(`${url}/${gatherId}`, undefined, true)
            .then((response) => {
                AppNotifier.Success(RequestNotification.Success.DownloadWaitProcessing);  
            if (callback) {
              callback();
            }
          });
      } catch (error: any) {
        AppNotifier.Error(RequestNotification.Failure.DownloadGather);
      }
    };
  };