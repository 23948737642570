// Define an enum for the session storage keys
export enum StorageKey {
    USER_ID = 'userId',
    AUTH_TOKEN = 'authToken',
    SETTINGS = 'settings',
    DOWNLOADNOW = 'downloadNow',
}

// Define a type for the possible values in sessionStorage
type StorageValue = string | object | number | boolean;

// Helper function to set a value in sessionStorage
export function setSessionStorage(key: StorageKey, value: StorageValue): void {
    const serializedValue = JSON.stringify(value);
    sessionStorage.setItem(key, serializedValue);
}

// Helper function to get a value from sessionStorage
export function getSessionStorage<T>(key: StorageKey): T | null {
    const serializedValue = sessionStorage.getItem(key);
    if (serializedValue) {
        try {
            return JSON.parse(serializedValue) as T;
        } catch (error) {
            console.error('Error parsing sessionStorage value:', error);
            return null;
        }
    }
    return null;
}
