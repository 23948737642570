import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {  IUserLoginHistoryModel } from "common/model/login-history";


const initialLoginHistoryState : IUserLoginHistoryModel = {
    loginHistory: [],
    deviceId: '',
    count: 0
}

const loginHistorySlice = createSlice({
    name:'loginHistory',
    initialState: initialLoginHistoryState as IUserLoginHistoryModel,
    reducers:{
        setLoginHistory(state, action: PayloadAction<IUserLoginHistoryModel>){
            state.loginHistory = action.payload.loginHistory;
            state.deviceId = action.payload.deviceId;
            state.count = action.payload.count;
        },
}
});

export const { setLoginHistory } = loginHistorySlice.actions;

export const {reducer: loginHistoryReducer} = loginHistorySlice;