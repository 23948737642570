import { useEffect } from "react";
import { getCookieValue } from "@sssuite-component-ui/session-timeout";
import { createForethoughtCookie } from "helper/HelperFunctions";
import { ForeThoughtConstants } from "helper/Constants";
import { FORETHOUGHT_URL } from "services/api/api-config";

let forethoughtState: any;

export function useForethought() {

    const closeWidget = () => {
        if (window?.Forethought) {
            window.Forethought(ForeThoughtConstants.WIDGET, "hide");
            window.Forethought("widget", "close");
        }
    };

    const openWidget = () => {
        if (window?.Forethought) {
                window.Forethought(ForeThoughtConstants.WIDGET, "open");
                window.Forethought(ForeThoughtConstants.WIDGET, "show");
            }
    };

    const handleMessage = (event: any) => {
        if (event?.data?.event && [ForeThoughtConstants.WIDGET_CLOSED].includes(event.data.event) && window?.Forethought) {
            createForethoughtCookie(ForeThoughtConstants.WIDGET_VISIBLE, "false");
            closeWidget();
        }
    };


    const onClickForethoughtWidget = () => {
        if (window.Forethought) {
            createForethoughtCookie(ForeThoughtConstants.WIDGET_VISIBLE, "true");
            openWidget();
        }
    }

    useEffect(() => {
        if (window?.addEventListener) {
        window.addEventListener(ForeThoughtConstants.WIDGET_MESSAGE, handleMessage);
        };

        return () => {
        if (window?.removeEventListener) {
            window.removeEventListener(ForeThoughtConstants.WIDGET_MESSAGE, handleMessage);
            }
        };
    }, []);


    useEffect(() => {
        let intervalId: any;
        const checkCookie = () => {
            const cookieValue = getCookieValue(ForeThoughtConstants.WIDGET_VISIBLE);
            if (cookieValue !== forethoughtState) {
                if (cookieValue === "true") {
                    openWidget();
                } else if (cookieValue === "false") {
                    closeWidget();
                }
                forethoughtState = cookieValue;
            }
            if (!forethoughtState) {
                forethoughtState = cookieValue;
        }
        };
        intervalId = setInterval(checkCookie, 1000);
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
    }
        };
    }, []);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = FORETHOUGHT_URL;
        script.id = "safesendreturns-forethought-widget";
        script.setAttribute("data-api-key", "d0e4c96e-6c29-44dc-855c-818a7ce71e1b");
        script.setAttribute("position-x", "right");
        script.setAttribute("position-y", "bottom");
        script.setAttribute("offset-y", "40px");
        script.setAttribute("hidden", "true");
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
}
    }, []);

    return [onClickForethoughtWidget];
}

