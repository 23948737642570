import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialDrlNotApplicableStatusUpdatedModel, drlNotApplicableStatusUpdatedModel } from "common/model/drlNotApplicableStatusUpdatedModel";

const drlNotApplicableStatusUpdatedSlice = createSlice({
    name: 'DrlNotApplicableStatusUpdated',
    initialState: initialDrlNotApplicableStatusUpdatedModel,
    reducers:{
        setIsLoading(state, action: PayloadAction<boolean>){
            state.isLoading = action.payload;
        },
        setDrlNotApplicableStatusUpdatedModel(state, action: PayloadAction<drlNotApplicableStatusUpdatedModel>){
            state.isDrlNotApplicableStatusUpdate = action.payload.isDrlNotApplicableStatusUpdate;
            state.isLoading =false;
        }
    }
});

export const { setIsLoading, setDrlNotApplicableStatusUpdatedModel } = drlNotApplicableStatusUpdatedSlice.actions;
export const {reducer: drlNotApplicableStatusUpdatedReducer} = drlNotApplicableStatusUpdatedSlice;