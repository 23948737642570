import { BatchStatus, ClientType, EngagementType } from "common/enum/GatherEnums";
import { ISavedMessage, initialSavedMessage } from "./saved-message-model";
import { ISenderInfo, initialSenderInfo } from "./sender-model";
import { ProcessSteps, TaxSoftwares } from "common/enum";
import { FormGroup, IEROSigner } from "./GatherDocumentModel";
import { AdditionalEsignDocument, UploadedBatch } from "./gather-document-process-model-types";
import { GatherInfoModel } from "./batch-gather-document-process-model";
import { IDocumentRequestState } from "./request-documents";
import { ISelectedTemplate } from "./custom-questions";
import { IProcessStep } from "./upload-template";
import { INotifyData } from "./user";

export interface IBatchInfo {
    id: string;
    uniqueId: string;
    name: string;
    status: BatchStatus;
    uploadedOn: Date;
    uploadedBy: number;
    deliveredOn: Date | undefined;
    deliveredBy: number;
    taxYear: number;
    engagementType: number;
    ero: number;
    taxSoftware: TaxSoftwares;
    message: string;
    enableEngagementLetter: boolean;
    size: number;
    locationId : number;
    isDeliveredWithoutFillableOrganizer: boolean;
    lastVisitedStep: number;
    hasOrganizerFile: boolean;
    gatherId: number;
    additionalEsignEro? : IEROSigner;
    organizerEro? : IEROSigner;   
}

export interface IBatchInfoVM extends IBatchInfo{
    setting : IBatchSetting | null;
    hasCustomQuestion: boolean;
    formGroups: FormGroup[];
    additionalEsignDocuments: AdditionalEsignDocument[];
    customQuestionTemplateId: number;
}

export interface IBatchSetting{
    documentSetting: IDocumentSetting,
    firstSigner: ClientType,
    ignoreErrors: boolean,
    ignoreWarnings: boolean
}

export interface IDocumentSetting{
    deliverySetting: IDeliverySetting,
    notificationSetting: INotificationSetting,
    reminderSetting: IReminderSetting,
    signatureSetting: ISignatureSetting
}

export interface IDeliverySetting{
    contactPersonId: number[];
    preparerMessage: ISavedMessage;
    sender: ISenderInfo;
}

export interface INotificationSetting{
    notifyELCompletedUser: INotifyData[];
    notifyOrganizerCompletedUser: INotifyData[];
    notifyUploadedFilesUser: INotifyData[];
}

export interface IReminderSetting{
    esignReminder: IRemainderOption;
    organizerReminder: IRemainderOption;
}
export interface ISignatureSetting{
    signatureStampUser: number
}

export interface IRemainderOption {
    enabled: boolean;
    reminderDays: number;
    isReadOnly: boolean;
  }

export const initialDeliverySetting : IDeliverySetting = {
    contactPersonId: [],
    preparerMessage: initialSavedMessage,
    sender: initialSenderInfo
}

export const initialNotificationSetting : INotificationSetting = {
    notifyELCompletedUser: [],
    notifyOrganizerCompletedUser: [],
    notifyUploadedFilesUser: []
}

export const initialReminderOption: IRemainderOption = {
    enabled: false,
    reminderDays: 1,
    isReadOnly: false
}

export const initialReminderSetting: IReminderSetting = {
    organizerReminder: initialReminderOption,
    esignReminder: initialReminderOption
}

export const initialDocumentSetting : IDocumentSetting = {
    deliverySetting: initialDeliverySetting,
    notificationSetting: initialNotificationSetting,
    reminderSetting: initialReminderSetting
}

export const initalBatchSetting : IBatchSetting = {
    documentSetting: initialDocumentSetting,
    clientType: ClientType.Taxpayer,
    ignoreErrors: true,
    ignoreWarnings: true
}

// Define initial models for BatchInfo
const initialBatchInfo: IBatchInfo = {
    id: "",
    uniqueId: "",
    name: "",
    status: BatchStatus.None,
    uploadedOn: new Date(),
    uploadedBy: 0,
    deliveredOn: undefined,
    deliveredBy: 0,
    taxYear: 0,
    engagementType: EngagementType.E1040,
    ero: 0,
    taxSoftware: TaxSoftwares.CCH,
    message: "",
    enableEngagementLetter: false,
    size: 0,
    locationId: 0,
    isDeliveredWithoutFillableOrganizer: false,
    lastVisitedStep: 0,
    hasOrganizerFile: false,
    gatherId: 0
};

export const initialBatchInfoVM : IBatchInfoVM = {
    id: "",
    uniqueId: "",
    name: "",
    status: BatchStatus.None,
    uploadedOn: new Date(),
    uploadedBy: 0,
    deliveredOn: undefined,
    deliveredBy: 0,
    taxYear: 0,
    engagementType: EngagementType.E1040,
    ero: 0,
    taxSoftware: TaxSoftwares.CCH,
    message: "",
    enableEngagementLetter: false,
    size: 0,
    locationId: 0,
    isDeliveredWithoutFillableOrganizer: false,
    lastVisitedStep: 0,
    hasOrganizerFile: false,
    setting: initalBatchSetting,
    hasCustomQuestion: false,
    formGroups: [],
    additionalEsignDocuments: [],
    customQuestionTemplateId: 0,
    gatherId: 0
}


export interface IBatchProcessState{
    batchInfoVM: IBatchInfoVM,
    gatherInfoVM: GatherInfoModel | null,
    batchError : UploadedBatch[],
    organizerDocument : string,
    selectedCSVData : UploadedBatch | null,
    count: Number 
}

export const initalBatchProcessState : IBatchProcessState = {
    batchInfoVM: initialBatchInfoVM,
    gatherInfoVM: null,
    batchError: [],
    organizerDocument: "",
    selectedCSVData : null,
    count: 0,
}

export interface IBatchProcessInfo{
    batchInfo : IBatchInfoVM;
    documentRequestData: IDocumentRequestState;
    questionnaireData: ISelectedTemplate;
}
