import { ThunkAction, Action } from "@reduxjs/toolkit";
import { AppNotifier } from "common/components/toast/Toast";
import { EmailTemplateType } from "common/enum";
import { GatherCompanySettingsModel } from "common/model/company-settings";
import { RequestNotification } from "helper/Constants";
import { API } from "services/api/api-config";
import Axios from "services/api/axios-interceptor";
import { AppState } from "store";
import { setCompanySettingData } from "store/slices/companySettings-slice";

export const getGatherCompanySettingsModel =
  (successCallback?: (data: GatherCompanySettingsModel) => void): ThunkAction<void, AppState, unknown, Action> =>
  (dispatch) => {
    Axios.get(`${API.COMPANY_SETTINGS.GET_COMPANY_SETTINGS}`, {
      method: "GET",
      credentials: "include"
    })
      .then((response) => {
        const settingsData: GatherCompanySettingsModel = response.data as GatherCompanySettingsModel;
        dispatch(setCompanySettingData(settingsData));
        if (settingsData) {
          successCallback && successCallback(settingsData);
        }
      })
      .catch(() => {
        AppNotifier.Error(RequestNotification.Failure.AllGathers);
      });
  };

export const putGatherCompanySettingsModel =
  (companySetting: GatherCompanySettingsModel): ThunkAction<void, AppState, unknown, Action> =>
  (dispatch) => {
    Axios.put(`${API.COMPANY_SETTINGS.GET_COMPANY_SETTINGS}`, companySetting, {
      credentials: "include"
    })
      .then((response) => {
        dispatch(setCompanySettingData(companySetting));
        AppNotifier.Success(RequestNotification.Success.GeneralSetting);
      })
      .catch(() => {
        AppNotifier.Error(RequestNotification.Failure.AllGathers);
      });
  };

export const setDefaultSavedmsg =
  (companySetting: GatherCompanySettingsModel, id: number): ThunkAction<void, AppState, unknown, Action> =>
  (dispatch) => {
    const updatedsettings = { ...companySetting, defaultSettings: { ...companySetting.defaultSettings, savedMessage: id } };
    Axios.put(`${API.COMPANY_SETTINGS.GET_COMPANY_SETTINGS}`, updatedsettings, {
      credentials: "include"
    })
      .then((response: any) => {
        dispatch(setCompanySettingData(updatedsettings));
      })
      .catch(() => {
        AppNotifier.Error(RequestNotification.Failure.AllGathers);
      });
  };

export const setDefaultClientInstruction =
  (
    companySetting: GatherCompanySettingsModel,
    id: number,
    type: EmailTemplateType
  ): ThunkAction<void, AppState, unknown, Action> =>
  (dispatch) => {
    if (type === EmailTemplateType.InitialMail) {
      const settings = { ...companySetting, defaultSettings: { ...companySetting.defaultSettings, initialEmail: id } };
      Axios.put(`${API.COMPANY_SETTINGS.GET_COMPANY_SETTINGS}`, settings, {
        credentials: "include"
      })
        .then((response: any) => {
          dispatch(setCompanySettingData(settings));
        })
        .catch(() => {
          AppNotifier.Error(RequestNotification.Failure.AllGathers);
        });
    }
    if (type === EmailTemplateType.DownloadEngagementLetter) {
      const updatedsettings = {
        ...companySetting,
        defaultSettings: { ...companySetting.defaultSettings, downloadEngagementLetterEmail: id }
      };
      Axios.put(`${API.COMPANY_SETTINGS.GET_COMPANY_SETTINGS}`, updatedsettings, {
        credentials: "include"
      })
        .then((response: any) => {
          dispatch(setCompanySettingData(updatedsettings));
        })
        .catch(() => {
          AppNotifier.Error(RequestNotification.Failure.AllGathers);
        });
    }
  };

export const setDefaultRemainderMail =
  (
    companySetting: GatherCompanySettingsModel,
    id: number,
    type: EmailTemplateType
  ): ThunkAction<void, AppState, unknown, Action> =>
  (dispatch) => {
    if (type === EmailTemplateType.EngagementLetterCompleted) {
      const settings = { ...companySetting, defaultSettings: { ...companySetting.defaultSettings, esignReminderEmail: id } };
      Axios.put(`${API.COMPANY_SETTINGS.GET_COMPANY_SETTINGS}`, settings, {
        credentials: "include"
      })
        .then((response: any) => {
          dispatch(setCompanySettingData(settings));
        })
        .catch(() => {
          AppNotifier.Error(RequestNotification.Failure.AllGathers);
        });
    }
    if (type === EmailTemplateType.OrganizerReminder) {
      const updatedsettings = {
        ...companySetting,
        defaultSettings: { ...companySetting.defaultSettings, organizerReminderEmail: id }
      };
      Axios.put(`${API.COMPANY_SETTINGS.GET_COMPANY_SETTINGS}`, updatedsettings, {
        credentials: "include"
      })
        .then((response: any) => {
          dispatch(setCompanySettingData(updatedsettings));
        })
        .catch(() => {
          AppNotifier.Error(RequestNotification.Failure.AllGathers);
        });
    }
    if (type === EmailTemplateType.SourceDocumentReminderMail) {
      const updatedsettings = {
        ...companySetting,
        defaultSettings: { ...companySetting.defaultSettings, sourceDocReminderEmail: id }
      };
      Axios.put(`${API.COMPANY_SETTINGS.GET_COMPANY_SETTINGS}`, updatedsettings, {
        credentials: "include"
      })
        .then((response: any) => {
          dispatch(setCompanySettingData(updatedsettings));
        })
        .catch(() => {
          AppNotifier.Error(RequestNotification.Failure.AllGathers);
        });
    }
  };
