import React, { useEffect } from "react";
import { AddIcon } from "assets/icons/IconCollection";
import { MessageType } from "../../client-instructions";
import { EmailTemplateMessage } from "common/model/client-instruction";
import { DefaultSetting } from "common/model/company-settings";
import { EmailTemplateType } from "common/enum";
import { MessageSettingsConstants } from "pages/Constants";

export interface IEmailMessageListProps {
  data: EmailTemplateMessage[];
  defaultSettings: DefaultSetting;
  onAddMessage: (messageType: MessageType) => void;
  onSelectMessage: (index: number, messageType: MessageType) => void;
  messageType: MessageType;
  selectedMessage: EmailTemplateMessage;
}

const EmailMessageList: React.FC<IEmailMessageListProps> = ({
  data,
  defaultSettings,
  messageType,
  selectedMessage,
  onAddMessage,
  onSelectMessage
}) => {
  useEffect(() => {
    messagebox(data, defaultSettings);
  }, [data, defaultSettings]);

  const messagebox = (data: EmailTemplateMessage[], defaultSettings: DefaultSetting) => {

    const isActive = (id: number) => selectedMessage?.id === id;

    return data.map((message, index) => (
      <div
        onClick={() => onSelectMessage(index, messageType)}
        key={message.id}
        className={`message-wrapper ${isActive(message.id) ? "active-msg" : ""}`}
        title={message.name}
      >
        <p className="d-inline-block text-truncate">{message.name}</p>
        {renderDefault(message.id, defaultSettings, message)}
      </div>
    ));
  };

  const renderDefault = (id: number, defaultSettings: DefaultSetting, data: EmailTemplateMessage) => {
    if (defaultSettings != undefined) {
      if (messageType === MessageType.REMAINDER_EMAILS) {
        if (data.type === EmailTemplateType.EngagementLetterCompleted)
          return defaultSettings.esignReminderEmail == id && <span>Default</span>;
        if (data.type === EmailTemplateType.OrganizerReminder)
          return defaultSettings.organizerReminderEmail == id && <span>Default</span>;
        if (data.type === EmailTemplateType.SourceDocumentReminderMail)
          return defaultSettings.sourceDocReminderEmail == id && <span>Default</span>;
      }
    }
  };

  return (
    <div className="message-list-box">
      <div>{messagebox(data, defaultSettings)}</div>
      <button onClick={() => onAddMessage(messageType)} className="btn-with-icon" title={MessageSettingsConstants.AddMessage}>
        <AddIcon />
        Add
      </button>
    </div>
  );
};

export default EmailMessageList;
