import { ThunkAction, Action } from '@reduxjs/toolkit';
import { GatherCompanySettingsModel } from 'common/model/company-settings';
import { API } from "services/api/api-config"
import Axios from "services/api/axios-interceptor";
import { AppState } from 'store';
import { EmailTemplateMessage } from 'common/model/client-instruction';
import { addEmailTemplate, deleteEmailTemplate, editEmailTemplate, setEmailTemplates } from 'store/slices/clientInstructions-slice';
import { setDefaultClientInstruction, setDefaultRemainderMail } from './companySettings-service';
import { AppNotifier } from 'common/components/toast/Toast';
import { ClientInstructionsConstants, RequestNotification } from 'helper/Constants';



export const getEmailInstructions = (): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
        Axios.get(`${API.CLIENTINSTRUCTION.GET_CLIENTINSTRUCTION}`, {
            method: 'GET',
            credentials: 'include'
        })
            .then(response => {
                const emailTemplates: EmailTemplateMessage[] = response.data as EmailTemplateMessage[];
                dispatch(setEmailTemplates(emailTemplates));
            }).catch((error: any) => {
                AppNotifier.Error(ClientInstructionsConstants.Failure.GetRecords);
            });
    }

export const editEmailInstructions = (message: EmailTemplateMessage, setdefault: boolean, companysetting: GatherCompanySettingsModel): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
        Axios.put(`${API.CLIENTINSTRUCTION.EDIT_CLIENTINSTRUCTION}`, message, {
            credentials: 'include'
        })
            .then(response => {
                if (response.status === 200)
                    dispatch(editEmailTemplate(message));
                if (setdefault) {
                    dispatch(setDefaultClientInstruction(companysetting, message.id, message.type));
                }
                AppNotifier.Success(ClientInstructionsConstants.Success.Update);
            }).catch((error: any) => {
                AppNotifier.Error(ClientInstructionsConstants.Failure.Update);
            });
    }

export const editRemainderMail = (message: EmailTemplateMessage, setDefault: boolean, companySetting: GatherCompanySettingsModel): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
        Axios.put(`${API.CLIENTINSTRUCTION.EDIT_CLIENTINSTRUCTION}`, message, {
            credentials: 'include'
        })
            .then(response => {
                if (response.status === 200)
                    dispatch(editEmailTemplate(message));
                if (setDefault) {
                    dispatch(setDefaultRemainderMail(companySetting, message.id, message.type))
                }
                AppNotifier.Success(ClientInstructionsConstants.Success.Update);
            }).catch((error: any) => {
                AppNotifier.Error(ClientInstructionsConstants.Failure.Update);
            });
    }

export const addEmailInstructions = (message: EmailTemplateMessage, setDefault: boolean, companySetting: GatherCompanySettingsModel): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
        Axios.post(`${API.CLIENTINSTRUCTION.ADD_CLIENTINSTRUCTION}`, message, {
            credentials: 'include'
        })
            .then(response => {
                const id = response.data
                const updatedmsg: EmailTemplateMessage = { ...message, id: id as number }
                dispatch(addEmailTemplate(updatedmsg));

                if (setDefault) {
                    const id = response.data
                    dispatch(setDefaultClientInstruction(companySetting, id, message.type))
                }
                AppNotifier.Success(ClientInstructionsConstants.Success.Add);
            }).catch((error: any) => {
                AppNotifier.Error(ClientInstructionsConstants.Failure.Add);
            });
    }

export const addRemainderEmail = (message: EmailTemplateMessage, setDefault: boolean, companySetting: GatherCompanySettingsModel): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
        Axios.post(`${API.CLIENTINSTRUCTION.ADD_CLIENTINSTRUCTION}`, message, {
            credentials: 'include'
        })
            .then(response => {
                const id = response.data
                const updatedmsg: EmailTemplateMessage = { ...message, id: id as number }
                dispatch(addEmailTemplate(updatedmsg));

                if (setDefault) {
                    const id = response.data
                    dispatch(setDefaultRemainderMail(companySetting, id, message.type))
                }
            }).catch((error: any) => {
            });
    }

export const deleteEmailInstructions = (deleteMessage: EmailTemplateMessage, callback?: any): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
        const headers = {
            credentials: 'include',
            Accept: "application/json",
        };
        const data = {
            id: deleteMessage.id,
            name: deleteMessage.name,
            body: deleteMessage.body,
            subject: deleteMessage.subject,
            type: deleteMessage.type
        }
        Axios.deleteWithPayload(`${API.CLIENTINSTRUCTION.DELETE_CLIENTINSTRUCTION}`, data, headers)
            .then(response => {
                dispatch(deleteEmailTemplate(deleteMessage));
                AppNotifier.Success(ClientInstructionsConstants.Success.Delete);
            }).catch((error: any) => {
                AppNotifier.Error(ClientInstructionsConstants.Failure.Delete);
            });
    }