export interface IParagraphQuestion {
  answer: string;
}
export interface IChoiceModel {
  id: number;
  text: string;
  isSelected?: boolean;
  isDeleted: boolean;
  isValid?: boolean;
  isDuplicate?: boolean;
  subQuestions: ISectionItems[];
}

export interface ISectionItems {
  id: string;
  sectionItemId: number;
  order: number;
  questionTitle: string;
  isRequired: boolean;
  isDeleted: boolean;
  questionTypeId: QuestionType;
  questionDetails: IParagraphQuestion;
  choices: IChoiceModel[];
  isValid?: boolean;
  hasFollowUpQuestion?: boolean;
  isDuplicate?: boolean;
}
export interface ICustomQuestion {
  id: number;
  name: string;
  description: string;
  isDefault: boolean;
  isDeleted: boolean;
  sections?: ISectionItems[];
  createdOn: Date | string;
  createdBy: number;
  modifiedOn: Date | string;
  modifiedBy: number;

}
export interface ITemplateList {
  id: number;
  name: string;
  description: string;
  isDefault: boolean;
  isDeleted: boolean;
}

export interface ITemplate {
  templateId: number;
  name: string;
  description: string;
}
export interface ICustomQuestionTemplate {
  formId: number,
  template: ITemplate,
  title: string,
  description: string,
  isDeleted: boolean,
  sections: ISection[],
}
export interface ISelectedTemplate {
  formId?: number,
  template?: ITemplate,
  title: string,
  label: string,
  value: number,
  description: string,
  isDefault?: boolean,
  isDeleted?: boolean,
  sections: ISection[]
}
export interface ISection {
  sectionId: number;
  sectionName: string;
  order: number;
  isDeleted: false;
  sectionItems: ISectionItem[];
}
export interface ISections {
  id: number;
  sectionId: number;
  sectionName: string;
  order: number;
  sectionItems: ISectionItem[];
}
export interface ISectionItems {
  id: string;
  sectionItemId: number;
  order: number;
  questionTitle: string;
  isRequired: boolean;
  isDeleted: boolean;
  questionTypeId: QuestionType;
  questionDetails: IParagraphQuestion;
  choices: IChoiceModel[];
  isValid?: boolean;
  hasFollowUpQuestion?: boolean;
  isDuplicate?: boolean;
}

export interface IParagraphQuestion {
  answer: string;
}
export interface IChoiceModel {
  id: number;
  text: string;
  isSelected?: boolean;
  isDeleted: boolean;
  isValid?: boolean;
  isDuplicate?: boolean;
  subQuestions: ISectionItems[];
}

export interface Option {
  label: string;
  isSelected: boolean;
  isDefault: boolean;
}

export enum QuestionType {
  PARAGRAPH = 1,
  YESNO,
  MULTICHOICE
}
export interface ISectionItem {
  SectionItemId: number;
  questionTitle: string;
  questionTypeId: number;
  order: number;
  isRequired: boolean;
  isDeleted: boolean;
  questionDetails: {
      answer: string;
  };
  choices?: IChoiceModel[];
}
export interface CustomQuestionsState {
  selectedTemplate: ISelectedTemplate | null;
  isEditable: boolean;
  questionnaireTemplates: ISelectedTemplate[];
}

export const templatesDummyData: ICustomQuestion[] = 
[
  {
      "id": 300,
      "name": "Shamnad Test",
      "description": "Shamnad Test Update",
      "isDefault": false,
      "isDeleted": false,
      "createdOn": "2023-09-26T05:23:39.91",
      "createdBy": 0,
      "modifiedOn": "2024-02-06T10:34:40.9481769+00:00",
      "modifiedBy": 0
  },
  {
      "id": 301,
      "name": "5 sections",
      "description": "200 questions",
      "isDefault": false,
      "isDeleted": false,
      "createdOn": "2023-09-27T08:31:19.787",
      "createdBy": 0,
      "modifiedOn": "2024-02-06T10:34:40.9481856+00:00",
      "modifiedBy": 0
  },
  {
      "id": 325,
      "name": "10 section test",
      "description": "testing 200 questions",
      "isDefault": false,
      "isDeleted": false,
      "createdOn": "2023-12-04T07:07:08.017",
      "createdBy": 0,
      "modifiedOn": "2024-02-06T10:34:40.9481899+00:00",
      "modifiedBy": 0
  },
  {
      "id": 326,
      "name": "20 section",
      "description": "20 section with 200 quwestions",
      "isDefault": false,
      "isDeleted": false,
      "createdOn": "2023-12-26T07:22:50.157",
      "createdBy": 0,
      "modifiedOn": "2024-02-06T10:34:40.9481925+00:00",
      "modifiedBy": 0
  },
  {
      "id": 327,
      "name": "50 sections",
      "description": "50 section with 200 questions",
      "isDefault": false,
      "isDeleted": false,
      "createdOn": "2023-12-26T07:28:51.08",
      "createdBy": 0,
      "modifiedOn": "2024-02-06T10:34:40.9481951+00:00",
      "modifiedBy": 0
  },
  {
      "id": 328,
      "name": "200 sections",
      "description": "200 section with 200 questions",
      "isDefault": false,
      "isDeleted": false,
      "createdOn": "2023-12-26T07:35:05.953",
      "createdBy": 0,
      "modifiedOn": "2024-02-06T10:34:40.9481978+00:00",
      "modifiedBy": 0
  },
  {
      "id": 329,
      "name": "Test Questionnaire Name",
      "description": "Test Questionnaire Description",
      "isDefault": false,
      "isDeleted": false,
      "createdOn": "2023-12-27T06:50:54.447",
      "createdBy": 0,
      "modifiedOn": "2024-02-06T10:34:40.9482004+00:00",
      "modifiedBy": 0
  },
  {
      "id": 331,
      "name": "Test Yes No",
      "description": "test",
      "isDefault": false,
      "isDeleted": false,
      "createdOn": "2024-01-09T12:45:44.983",
      "createdBy": 0,
      "modifiedOn": "2024-02-06T10:34:40.9482031+00:00",
      "modifiedBy": 0
  },
  {
      "id": 332,
      "name": "CQ Order Test",
      "description": "CQ Order Test on PDF Download",
      "isDefault": false,
      "isDeleted": false,
      "createdOn": "2024-01-17T05:47:02.45",
      "createdBy": 0,
      "modifiedOn": "2024-02-06T10:34:40.9482055+00:00",
      "modifiedBy": 0
  },
  {
      "id": 335,
      "name": "questionnaire name",
      "description": "questionnaire description",
      "isDefault": false,
      "isDeleted": false,
      "createdOn": "2024-01-26T06:17:39.707",
      "createdBy": 0,
      "modifiedOn": "2024-02-06T10:34:40.9482081+00:00",
      "modifiedBy": 0
  }
]