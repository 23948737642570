import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { GatherTableModel, InProcessGatherData, InProcessGatherStoreState } from "common/model/inprocess-gather-table";

export const initialGatherTableModel: GatherTableModel = {
    count: 0,
    documents: []
}

export const initialInProcessGatherState: InProcessGatherStoreState = {
    loading: false,
    model: {
        count: 0,
        documents: []
    },
    popupLoading: false,
    query: ''
}

const inProcessGatherSlice = createSlice({
    name: 'inProcessGather',
    initialState: initialInProcessGatherState,
    reducers: {
        setInProcessGatherData : (state, action: PayloadAction<InProcessGatherData>) => {
            state.model = action.payload.model;
            state.query = action.payload.query;
        },
        startLoader : (state) => {
            state.loading = true;
        },
        stopLoader : (state) => {
            state.loading = false;
        },
        resetInProcessGather: (state) =>{
            state.model = initialGatherTableModel;
            state.query = '';
        }
    }
});

export const { startLoader, setInProcessGatherData, stopLoader, resetInProcessGather } = inProcessGatherSlice.actions;

export const { reducer: inProcessGatherReducer } = inProcessGatherSlice;