import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { GatherFilterType, IGatherFilter } from "common/model/GatherDocumentModel";

interface IReportsFilterStore {
  inProcessFilterList: IGatherFilter[];
  deliverFilterList: IGatherFilter[];
  archiveFilterList: IGatherFilter[];
  isLoading: boolean;
}

const initialReprotsFilterState: IReportsFilterStore = {
  isLoading: false,
  inProcessFilterList: [],
  deliverFilterList: [],
  archiveFilterList: []
};

const reportsFilterSlice = createSlice({
  name: "reportsFilter",
  initialState: initialReprotsFilterState,
  reducers: {
    setInProcessFilterData: (state, action: PayloadAction<IGatherFilter[]>) => {
      state.inProcessFilterList = action.payload;
    },
    setDeliveredFilterData: (state, action: PayloadAction<IGatherFilter[]>) => {
      state.deliverFilterList = action.payload;
    },
    setArchiveFilterData: (state, action: PayloadAction<IGatherFilter[]>) => {
      state.archiveFilterList = action.payload;
    },
    saveFilterData: (state, action: PayloadAction<IGatherFilter>) => {
      switch (action.payload.filterType) {
        case GatherFilterType.InProcess:
          state.inProcessFilterList.push(action.payload);
          break;
        case GatherFilterType.Delivered:
          state.deliverFilterList.push(action.payload);
          break;
        case GatherFilterType.Archived:
          state.archiveFilterList.push(action.payload);
          break;
        default:
          break;
      }
    },
    updateFilterData: (state, action: PayloadAction<IGatherFilter>) => {
      switch (action.payload.filterType) {
        case GatherFilterType.InProcess:
          state.inProcessFilterList.map((item, i) => {
            return item.name === action.payload.name ? action.payload : item;
          });
          break;
        case GatherFilterType.Delivered:
          state.deliverFilterList.map((item, i) => {
            return item.name === action.payload.name ? action.payload : item;
          });
          break;
        case GatherFilterType.Archived:
          state.archiveFilterList.map((item, i) => {
            return item.name === action.payload.name ? action.payload : item;
          });
          break;
        default:
          break;
      }
    },
    deleteFilterData: (state, action: PayloadAction<{ name: string; filterType: GatherFilterType }>) => {
      switch (action.payload.filterType) {
        case GatherFilterType.InProcess:
          state.inProcessFilterList.splice(
            state.inProcessFilterList.findIndex((x) => x.name === action.payload.name),
            1
          );
          break;
        case GatherFilterType.Delivered:
          state.deliverFilterList.splice(
            state.inProcessFilterList.findIndex((x) => x.name === action.payload.name),
            1
          );
          break;
        case GatherFilterType.Archived:
          state.archiveFilterList.splice(
            state.inProcessFilterList.findIndex((x) => x.name === action.payload.name),
            1
          );
          break;
        default:
          break;
      }
    },

    startLoader: (state) => {
      state.isLoading = true;
    },
    stopLoader: (state) => {
      state.isLoading = false;
    }
  }
});

export const {
  startLoader,
  stopLoader,
  updateFilterData,
  deleteFilterData,
  saveFilterData,
  setArchiveFilterData,
  setDeliveredFilterData,
  setInProcessFilterData
} = reportsFilterSlice.actions;

export const { reducer: reportsFilterReducer } = reportsFilterSlice;
