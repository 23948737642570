import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import hardSet from 'redux-persist/es/stateReconciler/hardSet';
import layoutReducer from './slices/layout-slice';
import authReducer from './slices/auth-slice';
import { batchProcessReducer } from './slices/batch-process-slice';
import userSettingReducer from './slices/userSetting-slice';
import documentRequestReducer from './slices/documentRequest-slice';
import formBuilderReducer from "./slices/form-builder";
import customQuestionTemplatesReducer from "./slices/custom-question-templates";
import customQuestionsPreviewReducer from "./slices/custom-questions-preview";
import { companyReducer } from "./slices/company-slice";
import { deliveredGatherReducer, reportColumnSettingReducer } from "./slices/delivered-gather-slice";
import { userReducer } from "./slices/user-slice";
import { archivedGatherReducer } from "./slices/archived-gather-slice";
import { recycledGatherReducer } from "./slices/recycled-gather-slice";
import companySetting from './slices/companySettings-slice'
import savedMessages from './slices/settingSavedMessages-slice';
import clientInstructions from './slices/clientInstructions-slice';
import { undeliveredGatherReducer } from "./slices/undelivered-gather-slice";
import { inProcessGatherReducer } from './slices/inprocess-gather-slice';
import notificationReducer from "./slices/notification";
import { savedMessageReducer } from './slices/preparesaved-message-slice';
import { inProcessBatchReducer } from "./slices/inprocess-batch-slice";
import { userLocationReducer } from "./slices/user-location-slice";
import { uploadTemplateReducer } from './slices/upload-template-slice';
import { userPrivilegeChangedReducer } from './slices/user-previlege-changed-slice';
import { proformaProcessReducer } from './slices/proforma-process-slice';
import { passwordPolicyReducer } from './slices/password-slice';
import { gatherTemplateReducer } from './slices/gathertemplate-settings-slice';
import { reportsFilterReducer } from './slices/reports-filter-slice';
import loadingStatusReducer from './slices/loading-status-slice';
import {engagementsTypeReducer} from  './slices/engagement-type-slice'
import { scheduleOptionReducer } from './slices/schedules-option-slice';
import { loginHistoryReducer } from './slices/login-history-slice';
import { restrictedAccessReducer } from './slices/restricted-access-slice';
import { drlNotApplicableStatusUpdatedReducer } from './slices/drlNotApplicableStatusUpdatedSlice';
import { uteGatherReducer } from './slices/ute-gather-slice';

const reducer: any = combineReducers({
  authReducer,
  layoutReducer,
  customQuestionTemplatesReducer,
  formBuilderReducer,
  customQuestionsPreviewReducer,
  deliveredGatherReducer,
  archivedGatherReducer,
  recycledGatherReducer,
  companyReducer,
  savedMessages,
  clientInstructions,
  undeliveredGatherReducer,
  savedMessageReducer,
  userReducer,
  batchProcessReducer,
  userSettingReducer,
  documentRequestReducer,
  companySetting,
  inProcessGatherReducer,
  notificationReducer,
  inProcessBatchReducer,
  reportColumnSettingReducer,
  userLocationReducer,
  uploadTemplateReducer,
  proformaProcessReducer,
  passwordPolicyReducer,
  userPrivilegeChangedReducer,
  gatherTemplateReducer,
  reportsFilterReducer,
  loadingStatusReducer,
  engagementsTypeReducer,
  scheduleOptionReducer,
  loginHistoryReducer,
  restrictedAccessReducer,
  drlNotApplicableStatusUpdatedReducer,
  uteGatherReducer
});

const persistConfig = {
  key: "root",
  storage,
  stateReconciler: hardSet,
  blacklist: []
};

//const persistedReducer = persistReducer(persistConfig, reducer)

export const store = configureStore({
  reducer: reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }),
  devTools: process.env.NODE_ENV !== "production"
});
export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof store.getState>;

//export const persistor = persistStore(store);
