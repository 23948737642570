import React, { FC } from "react";
import { ClientInstructionMessageVariables, AddEditModalDialog } from "helper/Constants";
import { Accordion } from "react-bootstrap";
import { CopyIcon, SettingsDashCircleIcon, SettingsPlusCircleIcon } from "assets/svg/SVGIconsCollection";
import copy from "copy-to-clipboard";

export interface IVariableListData {
  variable: string;
    helpText: string;

}
export interface IVariableList {
  isSystemDefaultType?: boolean;
  isClose: boolean;
  setIsClose: React.Dispatch<React.SetStateAction<boolean>>;
  className?: string;
}

const VariablesList: FC<IVariableList> = ({ isSystemDefaultType, isClose, setIsClose, className }) => {

  const getVariableList = (): IVariableListData[] => {
    const tempVariableListModel: IVariableListData[] = [];
    tempVariableListModel.push(
      { variable: "COMPANYNAME", helpText: ClientInstructionMessageVariables.CompanyName },
      { variable: "TAXPAYERNAME", helpText: ClientInstructionMessageVariables.TaxPayerName },
      { variable: "SPOUSENAME", helpText: ClientInstructionMessageVariables.SpouseName },
      { variable: "HELPCONTACT", helpText: ClientInstructionMessageVariables.HelpContact },
      { variable: "HELPPHONE", helpText: ClientInstructionMessageVariables.HelpPhone },
      { variable: "HELPEMAIL", helpText: ClientInstructionMessageVariables.HelpEmail },
      { variable: "TAXYEAR", helpText: ClientInstructionMessageVariables.TaxYear },
      { variable: "RECIPIENT", helpText: ClientInstructionMessageVariables.Recipient },
      { variable: "SENDERNAME", helpText: ClientInstructionMessageVariables.SenderName }
    );
    return tempVariableListModel;
  };

  const onToggle = () => {
    setIsClose(!isClose);
  };

  const copyToClipBoard = (variableName: string) => {
    copy(variableName);
  };

  return (
    <Accordion
      className={`settings-accordion ${className || ''}`}
      flush
    >
      <Accordion.Item eventKey="0">
        <Accordion.Header onClick={onToggle}>
          <button title={isSystemDefaultType ? AddEditModalDialog.CLIENT_INSTRUCTION.SYSTEM_DEFAULT_TEMPLATE_HOVER_TEXT : ""}
            onClick={(e) => e.preventDefault()} disabled={isSystemDefaultType}>
            {!isClose ? <SettingsDashCircleIcon /> : <SettingsPlusCircleIcon />}
            {`${!isClose ? "Hide" : "Show"} Variable List`}
          </button>
        </Accordion.Header>
        <Accordion.Body>
          <ul>
            {getVariableList().map((item, index) => {
              let value = `<${item.variable}>`;
              return (
                <div key={value + index} className="variable-item-wrapper">
                  <div className="main-text">
                    <li className="copy">
                      {value}
                      <span onClick={() => copyToClipBoard(value)} title="Copy">
                        <CopyIcon />
                      </span>
                    </li>
                  </div>
                  <div className="help-text">{item.helpText}</div>
                </div>
              );
            })}
          </ul>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default VariablesList;
