import { BrowserRouter } from 'react-router-dom';
import AppRoutes from 'route/routes';
import ErrorBoundary from 'common/pages/ErrorBoundary';
import './App.scss';
import 'styles/index.scss';

function App() {
  return (
    <div className="App">
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
    </div>
  );
}

export default App;
