import { useAppDispatch, useAppSelector } from "common/hooks/redux-hooks";
import LoaderWrapper from "common/pages/LoaderWrapper";
import { getFormattedLocalDateTime } from "helper/HelperFunctions";
import { getBrowserDetails } from "helper/HelperFunctions";
import { FC, useEffect, useState } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { AppState } from "store";
import { getLoginHistory } from "store/services/login-history-service";
import { IUserLoginHistory } from "common/model/login-history";
import Pagination from "../UIComponents/Pagination";
import { DateFormatConstants, LoginHistoryConstants } from "helper/Constants";

const LoginHistoryTable: FC = () => {

    const intialLoginHistory: IUserLoginHistory[] = [
        {
            count: 1,
            userEmail: "user1@example.com",
            loggedInOn: new Date("2024-08-30T08:30:00Z"),
            loggedOutOn: new Date("2024-08-30T17:00:00Z"),
            ipAddress: "192.168.1.1",
            clientApp: "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/127.0.0.0 Safari/537.36",
            deviceId: "device123",
            customData: "Additional info 1"
        },
        {
            count: 2,
            userEmail: "user2@example.com",
            loggedInOn: new Date("2024-08-29T09:15:00Z"),
            loggedOutOn: undefined, 
            ipAddress: "192.168.1.2",
            clientApp: "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/127.0.0.0 Safari/537.36",
            deviceId: "device456",
            customData: "Additional info 2"
        },
        {
            count: 3,
            userEmail: "user3@example.com",
            loggedInOn: new Date("2024-08-28T10:45:00Z"),
            loggedOutOn: new Date("2024-08-28T15:30:00Z"),
            ipAddress: "192.168.1.3",
            clientApp: "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/127.0.0.0 Safari/537.36",
            deviceId: "device789",
            customData: "Additional info 3"
        }
    ];

    const defaultFormatter = (cell: any, row: any) => {
        return (
            <span title={cell}>
                {cell}
            </span>
        );
    };

    const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);
    const dispatch = useAppDispatch();
    const loginHistories = useAppSelector((state: AppState) => state.loginHistoryReducer.loginHistory);
    const count = useAppSelector((state: AppState) => state.loginHistoryReducer.count);
    const deviceId = useAppSelector((state: AppState) => state.loginHistoryReducer.deviceId);

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(LoginHistoryConstants.ExpandedPageSize);
    const columns = [
        {
            text: LoginHistoryConstants.Browser,
            dataField: "clientApp",
            title: (cell: string) => getBrowserDetails(cell),
            headerStyle: { width: "300px" },
            formatter: (cell: string) => getBrowserDetails(cell),
            thStyle: { width: "190px", fontWeight: '700', fontSize: '16px' },
            tdStyle: { fontSize: '14px', fontWeight: '400' }
        },
        {
            text: LoginHistoryConstants.IPaddress,
            dataField: "ipAddress",
            title: (cell: string) => cell,
            thStyle: { width: "90px", fontWeight:'700',fontSize:'16px'},
            tdStyle: { fontSize:'14px',fontWeight:'400'},
            formatter: defaultFormatter
        },
        {
            text: LoginHistoryConstants.LoginDate,
            dataField: "loggedInOn",
            title: (cell: string) => cell,
            thStyle: { width: "100px", fontWeight:'700',fontSize:'16px'},
            tdStyle: { fontSize:'14px',fontWeight:'400'},
            formatter: defaultFormatter
        },
        {
            text: LoginHistoryConstants.LogoutDate,
            dataField: "loggedOutOn",
            title: (cell: string) => cell,
            thStyle: { width: "100px", fontWeight:'700',fontSize:'16px'},
            tdStyle: { fontSize:'14px',fontWeight:'400'},
            formatter: defaultFormatter
        }
    ];

    const customTotal = (from: number, to: number) => (
        <span className="total-count">
            Total <b>{count}</b> items
        </span>
    )

    const getTableData = () => {
        const data = loginHistories ? JSON.parse(JSON.stringify(loginHistories)) : intialLoginHistory;
        data.forEach((history: IUserLoginHistory) => {
            history.loggedInOn = getFormattedLocalDateTime(history.loggedInOn, DateFormatConstants.LAST_LOGIN_DATE_FORMAT);
            if (history.deviceId === deviceId) {
                history.loggedOutOn = "Current Device";
            }
            else if (!history.loggedOutOn) {
                history.loggedOutOn = "Logged In";
            }
            else {
                history.loggedOutOn = getFormattedLocalDateTime(history.loggedOutOn, DateFormatConstants.LAST_LOGIN_DATE_FORMAT);
            }
        });
        return data;
    }

    const handlePageCount = (page: number) => {
        setCurrentPageNumber(page);

    };

    const handlePageChange = (page: number) => {
        setCurrentPageNumber(page);
        dispatch(getLoginHistory(page, LoginHistoryConstants.CountPerPage));
    };

    const options = {
        totalSize: count,
        sizePerPage: LoginHistoryConstants.CountPerPage,
        page: currentPageNumber,
        onPageChange: (page: number) => handlePageChange(page),
        paginationTotalRenderer: customTotal,
        showTotal: true,
        withFirstAndLast: true,
        alwaysShowAllBtns: true,
    }

    const onPageChange = (page: number, pageSize: number) => {
        setPage(page);
        setPageSize(pageSize);
        dispatch(getLoginHistory(page, pageSize));
    }

    useEffect(() => {
        dispatch(getLoginHistory(1, 15));
    }, []);

    return (
        <div className="login-history">
            <LoaderWrapper>
                {columns.length > 0 && (
                    <BootstrapTable
                        keyField="loginHistory"
                        data={getTableData()}
                        remote
                        bordered={false}
                        headerContainerClass="sticky-header"
                        striped={true}
                        hover
                    >
                        {columns.map((value, index) => {
                            return (
                                <TableHeaderColumn
                                    key={index}
                                    dataField={value.dataField}
                                    columnTitle={value.text}
                                    dataFormat={value.formatter}
                                    thStyle={value.thStyle}
                                    tdStyle={value.tdStyle}
                                >
                                    <span title={value.text} className="table-text-sort table-header-text">
                                        {value.text}
                                    </span>
                                </TableHeaderColumn>
                            )
                        })}
                    </BootstrapTable>
                )}
                <div className='d-flex last-login-pagination'>
                    <div>
                        <span className="me-2 total-count">
                            Total <b>{count}</b> items
                        </span>
                    </div>
                    <Pagination
                        totalCount={count}
                        pageIndex={page}
                        pageSize={pageSize}
                        defaultPageSize={LoginHistoryConstants.PageSize}
                        goToPage={onPageChange}
                        sizePerPageList={LoginHistoryConstants.SizePerPageList}
                        showSizePerPageList={false}
                        showGoToPages={false}
                        showpageCount={false}
                        ShowListAndGoto={false}
                    />
                </div>
            </LoaderWrapper>
        </div>
    );
}

export default LoginHistoryTable;