import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { InProcessBatchData, InProcessBatchStoreState } from "common/model/inprocess-Batch-table";

const initialInProcessBatchState: InProcessBatchStoreState = {
    loading: false,
    model: {
        count: 0,
        batches: []
    },
    popupLoading: false,
    query: ''
}

const inProcessBatchSlice = createSlice({
    name: 'inProcessBatch',
    initialState: initialInProcessBatchState,
    reducers: {
        setInProcessBatchData : (state, action: PayloadAction<InProcessBatchData>) => {
            state.model = action.payload.model;
            state.query = action.payload.query;
        },
        startLoader : (state) => {
            state.loading = true;
        },
        stopLoader : (state) => {
            state.loading = false;
        }
    }
});

export const { startLoader, setInProcessBatchData, stopLoader } = inProcessBatchSlice.actions;

export const { reducer: inProcessBatchReducer } = inProcessBatchSlice;