import { DefaultSenderInfoType } from "common/enum/GatherEnums";

export interface ISenderInfo {
    senderType: DefaultSenderInfoType;
    senderId: number;
    senderName: string;
}

export const initialSenderInfo: ISenderInfo = {
    senderType: DefaultSenderInfoType.None,
    senderId: 0,
    senderName: ""
};