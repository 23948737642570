import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthenticationOption, GatherCompanySettingsModel } from "common/model/company-settings";

export const initialCompanySettings: GatherCompanySettingsModel = {
    defaultSettings: {
        savedMessage: 0,
        initialEmail: 0,
        esignReminderEmail: 0,
        organizerReminderEmail: 0,
        sourceDocReminderEmail: 0,
        downloadEngagementLetterEmail: 0,
    },
    authenticationSettings: {
        authenticationOption: AuthenticationOption.None,
    },
    sourceDocumentSettings: {
        enabledSourceDocuments: false,
        notifyEveryTimeUploadOrDeleteSrcDoc: false,
        schNotification1PerDayUploadOrDeleteSrcDoc: false,
        noAdditionalCPANotificationRequired: false,
        enabledDocumentUploadNotification: false,
        documentUploadPercentage: 0,
        k1UploadPercentage: 0,
        enabledDocumentUploadPercentageNotification: false,
        enabledK1UploadPercentageNotification: false
    },
    reminderSettings: {
        automaticReminders: true,
        organizerReminder: {
            reminderDays: 0,
            enabled: false,
            isReadOnly: false,
        },
        esignReminder: {
            reminderDays: 0,
            enabled: false,
            isReadOnly: false,
        },
    },
    sourceDocumentSettingChangeFlag: false
};

const companySettingsSlice = createSlice({
    name: 'companySettings',
    initialState: initialCompanySettings,
    reducers: {
        setCompanySettingData(state, action: PayloadAction<GatherCompanySettingsModel>) {
            state.defaultSettings = action.payload.defaultSettings;
            state.authenticationSettings = action.payload.authenticationSettings;
            state.reminderSettings = action.payload.reminderSettings;
            state.sourceDocumentSettings = action.payload.sourceDocumentSettings;
            state.sourceDocumentSettingChangeFlag = false;
        },
        setenabledSourceDocuments(state, action: PayloadAction<boolean>) {
            state.sourceDocumentSettings.enabledSourceDocuments = !action.payload;
        },

        setnotifyEveryTimeUploadOrDeleteSrcDoc(state, action: PayloadAction<boolean>) {
            state.sourceDocumentSettings.notifyEveryTimeUploadOrDeleteSrcDoc = true;
            state.sourceDocumentSettings.schNotification1PerDayUploadOrDeleteSrcDoc = false
            state.sourceDocumentSettings.noAdditionalCPANotificationRequired = false
        },
        setschNotification1PerDayUploadOrDeleteSrcDoc(state, action: PayloadAction<boolean>) {
            state.sourceDocumentSettings.schNotification1PerDayUploadOrDeleteSrcDoc = true;
            state.sourceDocumentSettings.notifyEveryTimeUploadOrDeleteSrcDoc = false;
            state.sourceDocumentSettings.noAdditionalCPANotificationRequired = false
        },
        setnoAdditionalCPANotificationRequired(state, action: PayloadAction<boolean>) {
            state.sourceDocumentSettings.noAdditionalCPANotificationRequired = true;
            state.sourceDocumentSettings.notifyEveryTimeUploadOrDeleteSrcDoc = false
            state.sourceDocumentSettings.schNotification1PerDayUploadOrDeleteSrcDoc = false
        },

        setEnableUploadNtification(state, action: PayloadAction<boolean>) {
            state.sourceDocumentSettings.enabledDocumentUploadNotification = !action.payload;
        },
        setdocumentUploadPercentage(state, action: PayloadAction<any>) {
            state.sourceDocumentSettings.documentUploadPercentage = action.payload as number;
        },
        setk1UploadPercentage(state, action: PayloadAction<any>) {
            state.sourceDocumentSettings.k1UploadPercentage = action.payload as number;
        },
        setEnabledocumentUploadPercentage(state, action: PayloadAction<any>) {
            state.sourceDocumentSettings.enabledDocumentUploadPercentageNotification= action.payload;
        },
        setEnablek1UploadPercentage(state, action: PayloadAction<any>) {
            state.sourceDocumentSettings.enabledK1UploadPercentageNotification= action.payload;
        },
        setEsignReminder(state, action: PayloadAction<any>) {
            state.reminderSettings.esignReminder.reminderDays = action.payload as number;
        },
        setorganizerReminder(state, action: PayloadAction<any>) {
            state.reminderSettings.organizerReminder.reminderDays = action.payload as number;
        },
        setEnableAutoReminder(state, action: PayloadAction<boolean>) {
            state.reminderSettings.automaticReminders = !action.payload;
            state.reminderSettings.esignReminder.enabled = !action.payload;
            state.reminderSettings.organizerReminder.enabled = !action.payload;
        },
        setSourceDocumentSettingChangeFlag(state, action: PayloadAction<boolean>) {
            state.sourceDocumentSettingChangeFlag = action.payload;
        },
    },
});

export const {
    setCompanySettingData,
    setenabledSourceDocuments,
    setnotifyEveryTimeUploadOrDeleteSrcDoc,
    setschNotification1PerDayUploadOrDeleteSrcDoc,
    setnoAdditionalCPANotificationRequired,
    setEnableUploadNtification,
    setdocumentUploadPercentage,
    setk1UploadPercentage,
    setorganizerReminder,
    setEsignReminder,
    setEnableAutoReminder,
    setEnabledocumentUploadPercentage,
    setEnablek1UploadPercentage,
    setSourceDocumentSettingChangeFlag } = companySettingsSlice.actions;
export default companySettingsSlice.reducer;

