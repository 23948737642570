import { CustomModal, GenericModalProps } from "common/components/UIComponents/CustomModal";
import React, { ReactNode } from "react";
import Alert, { AlertType } from "../alert/Alert";
interface IConfirmDialogProps extends GenericModalProps {
  title: string;
  message: string;
  additionalMessage?: string;
  note?: string;
  confirmButtonName?: string;
  cancelButtonName?: string;
  children?: ReactNode;
  hideConfirmButton?: boolean;
  customAlertType?: AlertType;
  additionalMessageStyle?:string;
}
const ConfirmDialog: React.FC<IConfirmDialogProps> = ({
  show,
  onHide,
  onSubmit,
  children,
  title,
  message,
  additionalMessage,
  note,
  confirmButtonName,
  cancelButtonName,
  hideConfirmButton,
  customAlertType,
  additionalMessageStyle
}) => {
  const alertType = customAlertType || AlertType.WARNING;
  return (    
    <CustomModal
      show={show}
      onSubmit={onSubmit}
      onHide={onHide}
      title={title}
      confirmButtonName={confirmButtonName}
      cancelButtonName={cancelButtonName}
      hideConfirmButton={hideConfirmButton}
      isConfirmModal={true}
      customSize="600"
    >
      {note && <Alert type={alertType} message={note} />}
      <p>{message}</p>
      {additionalMessage && <p className={additionalMessageStyle || ""}>{additionalMessage}</p>}
      {children}
    </CustomModal>
  );
};

export default ConfirmDialog;
