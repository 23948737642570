import { DefaultSenderInfoType, DownloadType } from "common/enum/GatherEnums";
import { INotifyData } from "./user";

export interface IDelegatedEROSigner {
    userId: number,
    firstName: string,
    lastName: string,
    isEnableSignatureDelegation: boolean,
    signaturePath: string,
}

export interface IUserModel {
    userId?: number,
    id?: number
    firstName: string;
    middleName: string;
    lastName: string;
    phoneNumber: string;
    extension: string;
    ptin: string;
    email: string;
    faxNumber: string;
    title: string;
    customUserGroup: IUserGroups[];
    createdOn?: any;
    password?: string;
    isDeceased: boolean;
    mobileNumber: string;
    countryCode: string;
    isMobileVerify: boolean;
    userSettings: IUserDefaultSettingsStates;
}


export interface IUserGroups {
    id: number;
    // groupCategoryId?: IGroupCategory;
    //customUserGroup?: number,
    name: string;
    userId?: number;
    userGroups?: number;

}

export interface IUserDefaultSettingsStates {
    defaultSettings: IDefaultSettings;
    useSignatureStamp: boolean;
    userId: number;
    defaultOrganizerSettings: IDefaultOrganizerSettings;
    defaultUserSettings: IUserDefaultSettings;
    senderType: string;
}

export interface IDefaultSettings {
    manualAddressId: number;
    eroUser: number;
    contactPerson: number;
    sender: DefaultSenderInfo;
    notifyUser: number;
    reportFilter: string;
    enableLastLogin: boolean;
    download: {
        downloadType: DownloadType;
        rememberDefaultDownload: boolean;
    }
}

export interface DefaultSenderInfo {
    senderType: DefaultSenderInfoType;
    senderId: number;
}

export interface IDefaultOrganizerSettings {
    enableCustomQuestionWarning: boolean;
}

export interface IUserDefaultSettings {
    eroUser: number;
    contactPerson: number;
    sender: DefaultSenderInfo;
    notifyELEventsUser: INotifyData[];
    notifyOrganizerEventsUser: INotifyData[];
    notifySourceDocEventsUser: INotifyData[];
    download: {
        downloadType: DownloadType;
        rememberDefaultDownload: boolean;
    }
    gatherTemplate: number;
    enableLastLogin: boolean;
}

export const initalUserSettings: IUserDefaultSettings = {
    contactPerson: 0,
    eroUser: 0,
    notifyELEventsUser: [],
    notifyOrganizerEventsUser: [],
    notifySourceDocEventsUser: [],
    download: {
        downloadType: DownloadType.DownloadNow,
        rememberDefaultDownload: false
    },
    sender: {
        senderId: 0,
        senderType: DefaultSenderInfoType.None
    },
    gatherTemplate: 1,
    enableLastLogin: false
}
