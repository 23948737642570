import * as React from "react";
import { CqDeleteIcon, CqPlusIcon, VerticalEllipsisIcon } from "assets/svg/SVGIconsCollection";
import { useAppSelector } from "common/hooks/redux-hooks";
import { AutomationLocators } from "helper/AutomationLocators";
import { FormBuilderConstants, TemplateModalConstants } from "helper/Constants";
import { generateRandomNumber, getQuestionsLimitErrorMessage, getTotalQuestions } from "helper/HelperFunctions";
import { Dropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { AppState } from "store";
import { IDupicateSectionPayload, ISections } from "store/models/CustomQuestionModel";
import { AddNewQuestionHandler, DuplicateSectionHandler } from "store/slices/form-builder";
import { AppNotifier } from "common/components/toast/Toast";
import ConfirmDialog from "common/components/modal/ConfirmDialog";

interface SectionFooterProps {
  section: ISections;
  setSelectedSection: React.Dispatch<React.SetStateAction<ISections>>;
  handleRenameSection: () => void;
  handleMergeSection: () => void;
  handleMoveSection: () => void;
  setActiveSection: React.Dispatch<React.SetStateAction<number>>;
  sectionIndex: number;
  handleDeleteSection: (sectionIndex: number) => void;
  isExpandedView: boolean;
}

const SectionFooter: React.FC<SectionFooterProps> = ({
  section,
  sectionIndex,
  isExpandedView,
  setSelectedSection,
  handleRenameSection,
  handleMergeSection,
  handleMoveSection,
  setActiveSection,
  handleDeleteSection
}) => {
  const formData = useAppSelector((state: AppState) => state.formBuilderReducer.formData);
  const customQuestionSettings = useAppSelector((state: AppState) => state.formBuilderReducer.customQuestionSettings);
  const [deleteConfirmModal, toogleDeleteConfirmModal] = React.useState(false);
  const dispatch = useDispatch();
  const popperConfigObj = {
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [15, 10]
        }
      }
    ]
  };
  const handleMoveSectionClick = () => {
    if (formData.sections?.length > 1) {
      handleMoveSection();
    }
  };
  const handleDuplicateSectionClick = () => {
    let totalSourceSectionQuestions = section.sectionItems?.length || 0;
    let totalQuestions = getTotalQuestions(formData) + totalSourceSectionQuestions;

    if (totalQuestions <= customQuestionSettings.questionLimit) {
      if (`${section.sectionName}_copy`.length > TemplateModalConstants.SectionNameMaxLength) {
        AppNotifier.Error(FormBuilderConstants.DUPLICATE_SECTION_LIMIT);
      } else {
        const payload: IDupicateSectionPayload = {
          section: { ...section, sectionId: generateRandomNumber(), sectionName: `${section.sectionName}_copy` },
          sectionIndex,
          sectionItemIndex: 0
        };
        dispatch(DuplicateSectionHandler(payload));
      }
    } else {
      AppNotifier.Error(getQuestionsLimitErrorMessage(customQuestionSettings.questionLimit, "duplicate section"));
    }
  };

  const handleMergeSectionClick = () => {
    if (formData.sections?.length > 1) {
      setSelectedSection(section);
      handleMergeSection();
    }
  };

  const handleRenameSectionClick = () => {
    setSelectedSection(section);
    setActiveSection(sectionIndex);
    handleRenameSection();
  };

  const onConfirmDeleteSection = () => {
    handleDeleteSection(sectionIndex);
    toogleDeleteConfirmModal(false);
  };

  const handleAddNewQuestionClick = () => {
    if (isQuestionLimitValid()) {
      dispatch(AddNewQuestionHandler(sectionIndex));
    }
  };
  const isQuestionLimitValid = () => {
    if (getTotalQuestions(formData) + 1 > customQuestionSettings.questionLimit) {
      AppNotifier.Error(getQuestionsLimitErrorMessage(customQuestionSettings.questionLimit, "add question"));
      return false;
    }
    return true;
  };
  const handleDeleteSectionClick = () => {
    toogleDeleteConfirmModal(true);
  };
  return (
    <footer className={`${!isExpandedView && "collapsed-view-footer"}`}>
      {isExpandedView && section?.sectionItems.length > 0 && (
        <div className="section-buttons">
          <button
            data-test-auto={AutomationLocators.Settings.CustomQuestions.Section.addQuestion}
            onClick={handleAddNewQuestionClick}
            className="cq-btn-with-icon cq-btn-blue cq-btn-primary"
          >
            <CqPlusIcon />
            Add New Question
          </button>
          <button
            data-test-auto={AutomationLocators.Settings.CustomQuestions.DeleteSectionWidget}
            onClick={handleDeleteSectionClick}
            className="cq-btn-with-icon"
          >
            <CqDeleteIcon />
            Delete Section
          </button>
        </div>
      )}
      <Dropdown className="section-dropdown">
        <Dropdown.Toggle className="cq-btn-primary">
          <VerticalEllipsisIcon />
        </Dropdown.Toggle>
        <Dropdown.Menu popperConfig={popperConfigObj} className="template-more-options-menu">
          <Dropdown.Item
            className={`template-menu-item${formData.sections?.length === 1 ? "-disabled" : ""}`}
            onClick={handleMoveSectionClick}
          >
            Move
          </Dropdown.Item>
          <Dropdown.Item className="template-menu-item" onClick={handleDuplicateSectionClick}>
            Duplicate
          </Dropdown.Item>
          <Dropdown.Item
            data-test-auto={AutomationLocators.Settings.CustomQuestions.RenameSection}
            className="template-menu-item"
            onClick={handleRenameSectionClick}
          >
            Rename
          </Dropdown.Item>
          <Dropdown.Item
            className={`template-menu-item${formData.sections?.length === 1 ? "-disabled" : ""}`}
            onClick={handleMergeSectionClick}
          >
            Merge
          </Dropdown.Item>
          <Dropdown.Item
            data-test-auto={AutomationLocators.Settings.CustomQuestions.DeleteSectionDropdown}
            onClick={handleDeleteSectionClick}
            className="template-menu-item"
          >
            Delete
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      {deleteConfirmModal && (
        <ConfirmDialog
          title={FormBuilderConstants.DELETE_MODAL_HEADER}
          show={deleteConfirmModal}
          onHide={() => toogleDeleteConfirmModal(false)}
          onSubmit={onConfirmDeleteSection}
          message={FormBuilderConstants.DELETE_MODAL_BODY}
          confirmButtonName="Yes"
        />
      )}
    </footer>
  );
};

export default SectionFooter;
