// Route Paths
export const ORGANIZER_REPORT = '/GatherReport';
export const DELIVERED_ORGANIZER_REPORT = '/DeliveredGatherReport';
export const ARCHIVED_ORGANIZER_REPORT = '/ArchivedGatherReport';
export const UNDELIVERED_BATCH_ORGANIZER_REPORT = '/UndeliveredBatchGatherReport';
export const BATCH_EXPORT_EXCEL_DOWNLOAD = '/BatchExportExcelDownload/:id';
export const RECYCLED_ORGANIZER = '/RecycledGather';
export const CLIENT_INSTRUCTION = '/ClientInstruction';
export const GENERAL = '/General';
export const SAVED_MESSAGES = '/SavedMessages';
export const CUSTOM_QUESTIONS = '/CustomQuestions';
export const GATHER_TEMPLATE = '/GatherTemplate';
export const DEFAULT = '/';
export const FEATURE_DISABLED = '/FeatureDisabled';
export const RESTRICTED_ACCESS = "/restricted-access";

export const LOGIN = "/login";
export const SIGNIN_OIDC = "/signin-oidc";
export const SILENT_SIGNIN_OIDC = "/silent-signin-oidc";
export const SIGNOUT_CALLBACK_OIDC ="/signout-callback-oidc";