import { Action } from "@reduxjs/toolkit"
import { ThunkAction } from "redux-thunk"
import { API } from "services/api/api-config"
import Axios from "services/api/axios-interceptor"
import { AppState } from "store"
import { setRequestUserProfile, setPartners, setSafeSendUsers, setSafeSendUserGroups } from "../slices/user-slice"
import { IUserProfile } from "common/model/userModel"
import { setUserOfficeLocations } from "store/slices/user-location-slice"
import { AppNotifier } from "common/components/toast/Toast"
import { IUserDefaultSettings } from "common/model/userSetting"
import { getDefaultSettingsData } from "store/slices/userSetting-slice"
import { IOneHubAccessCodeDetail, IUserGroupViewModel, IUserViewModel } from "common/model/user"
import { ErrorMessage, RequestNotification, TemplateModalConstants } from "helper/Constants"
import { requestDelegatedSigners, requestDownloadPath } from "./userSetting-service"
import { startLoader, stopLoader } from "store/slices/delivered-gather-slice"

export const getPartners = (): ThunkAction<void, AppState, unknown, Action> =>
	(dispatch) => {
		try {
			Axios.get(`${API.USERS.PARTNERS}`)
				.then((response) => response.data)
				.then((data) => {
					dispatch(setPartners(data));
				})
		}
		catch (error: any) {
			AppNotifier.Error(RequestNotification.Failure.Partners);
		}
	}

export const getSafeSendUsersNotInExchangeAndSignature = (): ThunkAction<void, AppState, unknown, Action> =>
	(dispatch) => {
		try {
			Axios.get(`${API.USERS.SAFESENDUSERSNOTINEXCHANGEANDSIGNATURE}`)
				.then((response) => response.data as IUserViewModel[])
				.then((data) => {
					dispatch(setSafeSendUsers(data));
				})
		}
		catch (error: any) {
			AppNotifier.Error(RequestNotification.Failure.SafeSendUsersNotInExchangeAndSignature);
		}
	}

export const requestUserProfile = (): ThunkAction<void, AppState, unknown, Action> =>
	(dispatch) => {
		try {
			Axios.get(`${API.USER_MANAGEMENT.USER_DETAILS}`, {
				method: 'GET',
				credentials: 'include'
			})
				.then((response) => response)
				.then((data) => {
					var records = data.data as IUserProfile;
					dispatch(setRequestUserProfile(records));
					dispatch(requestDownloadPath(data.data.userId));
					dispatch(requestDelegatedSigners(data.data.userId));
				})
		}
		catch (error: any) {
			AppNotifier.Error(RequestNotification.Failure.UserProfile);
		}
	};

export const getUserOfficeLocations = (): ThunkAction<void, AppState, unknown, Action> =>
	(dispatch) => {
		try {
			Axios.get(`${API.USER.GET_OFFICE_LOCATIONS}`)
				.then((response) => response.data as number[])
				.then((data) => {
					dispatch(setUserOfficeLocations(data));
				})
		}
		catch (error: any) {
			AppNotifier.Error(RequestNotification.Failure.UserOfficeLocations);
		}
	};

export const getDefaultSettingData = (): ThunkAction<void, AppState, unknown, Action> =>
	(dispatch) => {
		try {
			Axios.get(`${API.USER_SETTINGS.DEFAULT}`)
				.then((response) => response.data as IUserDefaultSettings[])
				.then((data) => {
					dispatch(getDefaultSettingsData(data));
				})
		}
		catch (error: any) {
			AppNotifier.Error(RequestNotification.Failure.UserOfficeLocations);
		}
	};

export const saveMyAccount =
	(
		userInfo: IUserProfile,
		notification: boolean,
		onResponse: (response: any, error: any) => void
	): ThunkAction<void, AppState, unknown, Action> =>
		(dispatch) => {
			Axios.put(`${API.USER_MANAGEMENT.USER_DETAILS}`,
				userInfo, {
				credentials: 'include'
			}
			)
				.then((response: any) => {
					const data = response;
					if (onResponse) {
						onResponse(data, null);
					}
				})
				.catch((error) => {
					AppNotifier.Error(ErrorMessage);
				});
		};

export const saveDefaultSettingData = (
	userId: number,
	userSetting: IUserDefaultSettings,
	callback?: () => any): ThunkAction<void, AppState, unknown, Action> => {
	return (dispatch) => {
		Axios.post(`${API.USER_SETTINGS.SAVE_USER_SETTINGS}/${userId}`, userSetting, {
			method: "POST",
			credentials: "include",
		})
			.then((response) => response.data)
			.then((data) => {
				callback && callback();
				AppNotifier.Success(TemplateModalConstants.EditSuccessMessage)
			})
			.catch((error: any) => {
				AppNotifier.Error(ErrorMessage)
			});
	}
};

export const requestOneHubAccessCode = (emails: string[], successCallback?: (accessCodeData: IOneHubAccessCodeDetail[]) => any): ThunkAction<void, AppState, unknown, Action> => {
	return (dispatch) => {
		dispatch(startLoader());
		Axios.post(`${API.USER.GET_ONEHUB_ACCESS_CODE}`, emails, {
			method: "POST",
			credentials: "include"
		})
			.then((response) => response.data as IOneHubAccessCodeDetail[])
			.then((data) => {
				dispatch(stopLoader());
				if (successCallback) {
					successCallback(data);
				}
			}).catch((error: any) => {
				dispatch(stopLoader());
			});
	}
  };

export const generateOneHubAccessCode = (email: string, successCallback?: (accessCodeData: IOneHubAccessCodeDetail) => any): ThunkAction<void, AppState, unknown, Action> => {
	return (dispatch) => {
		dispatch(startLoader());
		Axios.put(`${API.USER.GENERATE_ONEHUB_ACCESS_CODE}?primaryEmail=${email}`, {
			credentials: "include"
		})
			.then((response) => response as IOneHubAccessCodeDetail)
			.then((data) => {
				dispatch(stopLoader());
				if (successCallback) {
					successCallback(data);
				}
			}).catch((error: any) => {
				dispatch(stopLoader());
			});
	}
  }
export const getSafeSendUserGroups = (): ThunkAction<void, AppState, unknown, Action> =>
	(dispatch) => {
		try {
			Axios.get(`${API.USER_GROUPS.SAFESENDUSERGROUPS}`)
				.then((response) => response.data as IUserGroupViewModel[])
				.then((data) => {
					dispatch(setSafeSendUserGroups(data));
				})
		}
		catch (error: any) {
			AppNotifier.Error(RequestNotification.Failure.SafeSendUsersNotInExchangeAndSignature);
		}
	}