export interface ISavedMessage {
    id: number;
    name: string;
    body: string;
    text: string;
    isEditable: boolean;
    isSystemDefault: boolean;
}
export const initialSavedMessage: ISavedMessage = {
    id: 0,
    name: "",
    body: "",
    text: "",
    isEditable: true,
    isSystemDefault: false
};