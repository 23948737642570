import { IDocumentRequest } from "pages/send-organizers/components/request-documents/parts/AutoImportList";
import { IImportedDocumentRequestListModel } from "pages/send-organizers/components/request-documents/parts/TemplateList";

export const initialSelectedTemplate: IImportedDocumentRequestListModel = {
    documentRequestTypeId: 0,
    documentRequestTemplateId: 0,
    templateName: "",
    documentRequests: []
};

export interface IDocumentRequestState {
    gatherId?: number;
    batchId?:number;
    autoDocumentRequestList: IAutoDRModel;
    importedDocumentRequestList: IImportedDocumentRequestListModel;
    isTemplateSelected: boolean;
    selectedTemplate: IImportedDocumentRequestListModel;
};

export interface IImportedTemplateModel {
    documentRequestTemplateId: number;
    templateName: string;
    isDefault?: boolean;
};
export interface IAutoDRModel {
    documentRequestTypeId: number;
    documentRequests: IDocumentRequest[];
    gatherId?: number;
};

const dummyFiles: IDocumentRequest[] = [
    {
        id: 8,
        name: "document 8",
        category: ''
    },
    {
        id: 9,
        name: "document 9",
        category: ''
    }
];

export const initialDocumentRequestState: IDocumentRequestState = {
    gatherId: 0,
    autoDocumentRequestList: {
        documentRequestTypeId: 1,
        documentRequests: []
    },
    isTemplateSelected: false,
    selectedTemplate: initialSelectedTemplate,
    importedDocumentRequestList: 
        {
            documentRequestTypeId: 0,
            documentRequestTemplateId: 0,
            templateName: "",
            documentRequests: []
        }
    
};

export interface IDocumentRequestCategoryVM {
    id: number;
    name: string;
};